import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the ApprovalMaintainFormPreventifScreen state domain
 */

const selectApprovalMaintainFormPreventifScreenDomain = state =>
  state.approvalMaintainFormPreventifScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by ApprovalMaintainFormPreventifScreen
 */

const makeSelectApprovalMaintainFormPreventifScreen = () =>
  createSelector(
    selectApprovalMaintainFormPreventifScreenDomain,
    substate => substate,
  );

export default makeSelectApprovalMaintainFormPreventifScreen;
export { selectApprovalMaintainFormPreventifScreenDomain };
