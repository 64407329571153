import React, {useRef} from "react";
import { Badge, VStack, Box, Button, Icon, AlertDialog } from 'native-base';
import { commonStyle } from "../styles";

const MyAlertDialog = (props) => {
  const {alertTitle, alertDesc, alertNoLabel, alertYesLabel, alertOnClose, alertIsOpen, alertOnConfirm, isLoading, isDisabled} = props;
  const cancelRef = useRef(null);
  return (
    <AlertDialog leastDestructiveRef={cancelRef} isOpen={alertIsOpen} onClose={alertOnClose}>
      <AlertDialog.Content>
        <AlertDialog.CloseButton />
        <AlertDialog.Header>{alertTitle}</AlertDialog.Header>
        <AlertDialog.Body>
          {alertDesc}
        </AlertDialog.Body>
        <AlertDialog.Footer>
          <Button.Group space={2}>
            <Button variant="unstyled" colorScheme="coolGray" onPress={alertOnClose} ref={cancelRef}>
              {alertNoLabel}
            </Button>
            <Button isLoading={isLoading} disabled={isDisabled} spinnerPlacement="end" isLoadingText="Loading..." colorScheme="success" onPress={alertOnConfirm}>
              {alertYesLabel}
            </Button>
          </Button.Group>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
}

export default MyAlertDialog;