import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
  import moment from 'moment';
  import api from '../../util/api';
  import helper from '../../util/helper';
  import * as constants from './constants';
  
  import makeSelectVendorOfferFormScreen from './selector';
import { getVendorOfferFail, getVendorOfferSuccess, submitVendorOfferFail, submitVendorOfferSuccess } from './actions';
  
  export function* doSubmitOffer(action) {
    const dataset = yield select(makeSelectVendorOfferFormScreen());
    try {
      const tglOffer = moment(dataset.date_offer).format("DD-MM-YYYY");
      
      const params = {
        id_submit_penawaran: dataset.vendor_offer?.id,
        no_penawaran: dataset.code_pelaporan,
        id_vendor: dataset.vendor_offer.id_vendor,
        tgl_app_nego: tglOffer,
        nominal_penawaran: dataset.cost_offer,
        desc: dataset.description,
      };

      console.log(params);

      const response = yield call(api.vendor.submitNegoOffer, params);
      yield put(
        submitVendorOfferSuccess(response.data),
      );
      // helper.errorToast("Data berhasil disimpan");
      console.log("res:", response.data);

    } catch (err) {
      console.log(err);
      // helper.errorToast("Gagal Menyimpan data, silahkan hub admin");
      yield put(submitVendorOfferFail(err));
    }
  }

  export function* doGetNegoOfferDetail(action) {
    const dataset = yield select(makeSelectVendorOfferFormScreen());
    try {
      const response = yield call(api.vendor.getDetailNegoOffer, action.id_penawaran_request);
      const {success} = response.data;
  
      yield put(
        getVendorOfferSuccess((helper.isEmpty(success) ? null : success)),
      );
      console.log("res:", success);
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getVendorOfferFail(err));
    }
  }

  // Individual exports for testing
  export default function* vendorNegoFormScreenSaga() {
    yield all([
      takeLatest(constants.SUBMIT_OFFER, doSubmitOffer),
      takeLatest(constants.GET_VENDOR_OFFER, doGetNegoOfferDetail),
    ]);
  }
  