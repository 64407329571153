export const DEFAULT_ACTION = 'screen/VendorNegoFormScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/VendorNegoFormScreen/UPDATE_INPUT';

export const SUBMIT_OFFER = 'screen/VendorNegoFormScreen/SUBMIT_OFFER';
export const SUBMIT_OFFER_LOADING = 'screen/VendorNegoFormScreen/SUBMIT_OFFER_LOADING';
export const SUBMIT_OFFER_SUCCESS = 'screen/VendorNegoFormScreen/SUBMIT_OFFER_SUCCESS';
export const SUBMIT_OFFER_FAIL = 'screen/VendorNegoFormScreen/SUBMIT_OFFER_FAIL';

export const ADD_IMAGE = 'screen/VendorNegoFormScreen/ADD_IMAGE';
export const DEL_IMAGE = 'screen/VendorNegoFormScreen/DEL_IMAGE';

export const ADD_FILE = 'screen/VendorNegoFormScreen/ADD_FILE';
export const DEL_FILE = 'screen/VendorNegoFormScreen/DEL_FILE';

export const GET_VENDOR_OFFER = 'screen/VendorNegoFormScreen/GET_VENDOR_OFFER';
export const GET_VENDOR_OFFER_LOADING = 'screen/VendorNegoFormScreen/GET_VENDOR_OFFER_LOADING';
export const GET_VENDOR_OFFER_SUCCESS = 'screen/VendorNegoFormScreen/GET_VENDOR_OFFER_SUCCESS';
export const GET_VENDOR_OFFER_FAIL = 'screen/VendorNegoFormScreen/GET_VENDOR_OFFER_FAIL';