import {all} from 'redux-saga/effects';
// saga
import globalVarSaga from './util/saga';
import loginScreenSaga from './screens/LoginScreen/saga';
import forgetPassScreenSaga from './screens/ForgetPassScreen/saga';
import homeScreenSaga from './screens/HomeScreen/saga';
import taskScreenSaga from './screens/TaskScreen/saga';
import profileScreenSaga from './screens/ProfileScreen/saga';
import workOrderScreenSaga from './screens/WorkOrderScreen/saga';
import welcomeScreenSaga from './screens/WelcomeScreen/saga';
import formProfileScreenSaga from './screens/FormProfileScreen/saga';
import formChangePassScreenSaga from './screens/FormChangePassScreen/saga';
import taskScannerScreenSaga from './screens/TaskScannerScreen/saga';
// import taskDetailScreenSaga from './screens/TaskDetailScreen/saga';
// import taskDetailInputScreenSaga from './screens/TaskDetailInputScreen/saga';
// import taskHistoryScreenSaga from './screens/TaskHistoryScreen/saga';
import notificationScreenSaga from './screens/NotificationScreen/saga';
import notificationDetailScreenSaga from './screens/NotificationDetailScreen/saga';
import pelaporanScreenSaga from './screens/PelaporanScreen/saga';
import pelaporanDetailScreenSaga from './screens/PelaporanDetailScreen/saga';
import pelaporanDetailInputScreenSaga from './screens/PelaporanDetailInputScreen/saga';
import pelaporanHistoryScreenSaga from './screens/PelaporanHistoryScreen/saga';
import pelaporanHistoryDetailScreenSaga from './screens/PelaporanHistoryDetailScreen/saga';
import verifPelaporanScreenSaga from './screens/VerifPelaporanScreen/saga';
import verifPelaporanInputScreenSaga from './screens/VerifPelaporanInputScreen/saga';
import verifPelaporanHistoryScreenSaga from './screens/VerifPelaporanHistoryScreen/saga';
import taskPreventifScreenSaga from './screens/TaskPreventifScreen/saga';
import taskPreventifDetailScreenSaga from './screens/TaskPreventifDetailScreen/saga';
// import taskPreventifTodoScreenSaga from './screens/TaskPreventifTodoScreen/saga';
import taskPreventifFormScreenSaga from './screens/TaskPreventifFormScreen/saga';
import vendorOfferScreenSaga from './screens/VendorOfferScreen/saga';
import vendorOfferDetailScreenSaga from './screens/VendorOfferDetailScreen/saga';
import vendorOfferFormScreenSaga from './screens/VendorOfferFormScreen/saga';
import vendorProgressScreenSaga from './screens/VendorProgressScreen/saga';
import vendorProgressSurketScreenSaga from './screens/VendorProgressSurketScreen/saga';
import vendorProgressHistoryScreenSaga from './screens/VendorProgressHistoryScreen/saga';
import vendorProgressDetailScreenSaga from './screens/VendorProgressDetailScreen/saga';
import vendorProgressFormScreenSaga from './screens/VendorProgressFormScreen/saga';
import vendorKroscekScreenSaga from './screens/VendorKroscekScreen/saga';
import vendorKroscekHistoryScreenSaga from './screens/VendorKroscekHistoryScreen/saga';
import vendorKroscekFormScreenSaga from './screens/VendorKroscekFormScreen/saga';
import approvalMaintainScreenSaga from './screens/ApprovalMaintainScreen/saga';
import approvalMaintainFormScreenSaga from './screens/ApprovalMaintainFormScreen/saga';
import approvalMaintainFormPreventifScreenSaga from './screens/ApprovalMaintainFormPreventifScreen/saga';
import approvalMaintainFormVendorScreenSaga from './screens/ApprovalMaintainFormVendorScreen/saga';
import vendorNegoFormScreenSaga from './screens/VendorNegoFormScreen/saga';
// end saga
function* combinedSaga() {
  yield all([
    globalVarSaga(),
    loginScreenSaga(),
    forgetPassScreenSaga(),
    homeScreenSaga(),
    taskScreenSaga(),
    profileScreenSaga(),
    workOrderScreenSaga(),
    welcomeScreenSaga(),
    formProfileScreenSaga(),
    formChangePassScreenSaga(),
    taskScannerScreenSaga(),
    // taskDetailScreenSaga(),
    // taskDetailInputScreenSaga(),
    // taskHistoryScreenSaga(),
    notificationScreenSaga(),
    notificationDetailScreenSaga(),
    pelaporanScreenSaga(),
    pelaporanDetailScreenSaga(),
    pelaporanDetailInputScreenSaga(),
    pelaporanHistoryScreenSaga(),
    pelaporanHistoryDetailScreenSaga(),
    verifPelaporanScreenSaga(),
    verifPelaporanInputScreenSaga(),
    verifPelaporanHistoryScreenSaga(),
    taskPreventifScreenSaga(),
    taskPreventifDetailScreenSaga(),
    // taskPreventifTodoScreenSaga(),
    taskPreventifFormScreenSaga(),
    vendorOfferScreenSaga(),
    vendorOfferDetailScreenSaga(),
    vendorOfferFormScreenSaga(),
    vendorProgressScreenSaga(),
    vendorProgressSurketScreenSaga(),
    vendorProgressHistoryScreenSaga(),
    vendorProgressDetailScreenSaga(),
    vendorProgressFormScreenSaga(),
    vendorKroscekScreenSaga(),
    vendorKroscekHistoryScreenSaga(),
    vendorKroscekFormScreenSaga(),
    approvalMaintainScreenSaga(),
    approvalMaintainFormScreenSaga(),
    approvalMaintainFormPreventifScreenSaga(),
    approvalMaintainFormVendorScreenSaga(),
    vendorNegoFormScreenSaga()
  ]);
}
export default combinedSaga;