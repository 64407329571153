import React, { Component, useState, useEffect, forwardRef, useRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectPelaporanScreen from './selector';
import reducer from './reducer';
import saga from './saga';
import {
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Stack,
  Badge,
  Input,
  Image,
  IconButton,
  Text,
  Fab,
  CheckIcon,
  Select, 
  Flex, 
  Button,
  Actionsheet,
  FormControl,
  WarningOutlineIcon,
  ScrollView,
  Spinner} from 'native-base';
import DatePicker from "react-datepicker";
// import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
// import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
// import AntDesign from 'react-native-vector-icons/AntDesign';
import InfiniteScroll from "react-infinite-scroll-component";
import {MdCalendarToday} from 'react-icons/md';
import {AiOutlinePlus} from 'react-icons/ai';
import moment from 'moment';
import { ContainerPage } from '../../components/ContainerPage';
import CardPelaporanList from '../../components/CardPelaporanList';
import CardListPreloader from '../../components/CardListPreloader';
import MyAlert from '../../components/MyAlert';
import RangeDateCustom from '../../components/RangeDateCustom';
import { commonStyle } from '../../styles';
import { defaultAction, getMorePelaporan, getPelaporan, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import history from '../../util/history';

export function PelaporanScreen({
  dispatch,
  navigation,
  pelaporanScreen,
  onUpdateInput,
  onGetResult,
  onGetMoreResult
}){
  
  const [screenAccess, setScreenAccess] = useState([]);
  const [isOpen, setOnOpen] = useState(false);
  const scrollableRef = useRef(null);

  useEffect(() => {
    // get user privileges
    const menus = api.getUserPrivileges();
    const allAccess = helper.generatePrivileges(menus);
    setScreenAccess(allAccess);
      // console.log(allAccess);
    onUpdateInput('page', 1);
    onGetResult();
  },[]);

  const handleGetData = () => {
    onUpdateInput('page', 1);
    onGetResult()
  }
  
  const getItem = (_data, index) => ({..._data[index]});
  
  const getItemCount = _data => pelaporanScreen.results.length;

  const getItemLayout = (data, index) => ({length: 300, offset: 300 * index, index});

  const renderItem = ({item}) => {
    return (
      <Box px={3} mb={5}>
        <CardPelaporanList data={item} access={screenAccess} navigation={navigation} />
      </Box>
    );
  }

  const handleLoadMore = () => {
    console.log('load more:', pelaporanScreen.page);
    if(pelaporanScreen.page < pelaporanScreen.total_page){
      onUpdateInput('page', pelaporanScreen.page + 1);
    }
    onGetMoreResult();
  }

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'home', title: 'Pelaporan Kerusakan', enableAct: true, onPressAction : () => setOnOpen(true)}}>
          {/* <Box mt={3} mx={3}>
            <Heading size="md">Pelaporan Kerusakan</Heading>
            <Text fontWeight="bold" mb="1">Periode</Text>
            <Stack direction="row" mb={3} justifyContent="space-between">
              <Box flex={1}>
                <DatePicker customInput={<InputStartDate/>} portalId='root-portal' className='input-datepicker' popperClassName='pooper-datepicker' selected={pelaporanScreen.start_dt} onChange={(date) => onChangeStartDt(date)} maxDate={pelaporanScreen.end_dt} />
              </Box>
              <Text fontWeight="bold" mx={2} alignSelf="center">s.d</Text>
              <Box flex={1}>
                <DatePicker customInput={<InputEndDate/>} portalId='root-portal' className='input-datepicker' popperClassName='pooper-datepicker' selected={pelaporanScreen.end_dt} onChange={(date) => onChangeEndDt(date)} minDate={pelaporanScreen.start_dt} />
              </Box>
            </Stack>
            <Box>
              
            </Box>
          </Box> */}
          <Flex flex={1}>
            {/* {
              pelaporanScreen.loading && <VStack space={7} px={3} mt={2} mb={35}>
                {[...Array(3)].map((x, i) => (
                  <CardListPreloader />
                ))}
              </VStack>
            }
            {!pelaporanScreen.loading && <Box>
              {pelaporanScreen.results.length == 0 && <Box px={3} mb={5}><MyAlert status="info" title="Maaf, Hasil belum ditemukan."/></Box>}    
              {pelaporanScreen.results.length > 0 && <VirtualizedList
                refreshing={false}
                data={pelaporanScreen.results}
                renderItem={renderItem}
                onRefresh={() => handleGetData()}
                keyExtractor={item => item.id}
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                getItem={getItem}
                getItemCount={getItemCount}
                initialNumToRender={3}
                updateCellsBatchingPeriod={300}
                maxToRenderPerBatch={1}
                windowSize={2}
                removeClippedSubviews={false}
                getItemLayout={getItemLayout}
              ></VirtualizedList>}
            </Box>
            } */}
            {/* <FlatList 
              refreshing={false}
              data={pelaporanScreen.results}
              renderItem={({item}) => (
              <Box px={3} mb={5}>
                {
                  pelaporanScreen.loading && <VStack space={5}>
                    {[...Array(3)].map((x, i) => (
                      <CardListPreloader />
                    ))}
                  </VStack>
                }
                {!pelaporanScreen.loading && <CardPelaporanList data={item} access={screenAccess} navigation={navigation} />}
              </Box>)} 
              onRefresh={() => handleGetData()}
              keyExtractor={item => item.id}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              initialNumToRender={10}
            >
            </FlatList> */}
            <ScrollView ref={scrollableRef} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              {
                pelaporanScreen.loading && <VStack space={7} px={3} mt={2} mb={35}>
                  {[...Array(3)].map((x, i) => (
                    <CardListPreloader key={i} />
                  ))}
                </VStack>
              }
              {
                (!pelaporanScreen.loading && pelaporanScreen.results != '') &&
                <InfiniteScroll
                key={scrollableRef.current}
                  dataLength={pelaporanScreen.results.data.length}
                  next={handleLoadMore}
                  hasMore={(pelaporanScreen.page < pelaporanScreen.total_page)}
                  loader={<p style={{textAlign:'center'}}>Memuat Lebih...</p>}
                  scrollableTarget={scrollableRef.current}
                >
                <VStack space={7} px={3} mt={2} mb={35}>
                  {pelaporanScreen.results.data.length == 0 && 
                  <MyAlert status="info" title="Maaf, Hasil belum ditemukan."/>}
                  {pelaporanScreen.results.data &&
                  pelaporanScreen.results.data.length > 0 && pelaporanScreen.results.data.map((row, i) => (
                    <CardPelaporanList key={i} data={row} access={screenAccess} history={history} />
                  ))}
                </VStack>
              </InfiniteScroll>
              }
              
            </ScrollView>
          </Flex>
          <Actionsheet isOpen={isOpen} onClose={() => setOnOpen(false)}>
            <Actionsheet.Content>
              <Box w="100%" px={4} justifyContent="center">
                <Text fontSize="16" fontWeight="bold" color="gray.500" _dark={{
                color: "gray.300"
              }}>
                  Filter
                </Text>
              </Box>
              <VStack w="100%" px={4}>
                <Box>
                  <FormControl isInvalid={pelaporanScreen.q.length > 0 && pelaporanScreen.q.length < 3} mb="1">
                    <FormControl.Label>Pencarian</FormControl.Label>
                    <Input placeholder="Ketik disini..." w="100%" value={pelaporanScreen.q} onChangeText={txt => onUpdateInput('q', txt)} />
                    <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                      3 characters minimum.
                    </FormControl.ErrorMessage>
                  </FormControl>
                  <FormControl mb="1">
                    <FormControl.Label>Tanggal</FormControl.Label>
                    <RangeDateCustom startDt={pelaporanScreen.start_dt} endDt={pelaporanScreen.end_dt} changeStartDt={(date) => onUpdateInput('start_dt', date)} changeEndDt={(date) => onUpdateInput('end_dt', date)} />
                  </FormControl>
                  <FormControl mb="1">
                    <FormControl.Label>Status</FormControl.Label>
                    <Select 
                      variant='filled' 
                      borderColor="primary.900" 
                      minWidth="200" 
                      accessibilityLabel="Pilih Status" 
                      placeholder="Pilih Status" 
                      _selectedItem={{endIcon: <CheckIcon size="5"/>}}
                      selectedValue={pelaporanScreen.filter_status}
                      onValueChange={itemValue => onUpdateInput('filter_status', itemValue)} 
                      my={2}>
                        <Select.Item label="All" value="0" />
                        <Select.Item label="Created" value="10" />
                        <Select.Item label="Batal" value="20" />
                        <Select.Item label="Progres" value="50" />
                        <Select.Item label="Selesai" value="90" />
                      </Select>
                  </FormControl>
                  </Box>
                  <Box my={1}>
                    <Button isLoading={pelaporanScreen.loading} onPress={() => handleGetData()} spinnerPlacement="end" isLoadingText="Loading..." shadow={3} colorScheme="primary">Terapkan</Button>
                  </Box>
              </VStack>
            </Actionsheet.Content>
          </Actionsheet>
          {screenAccess.includes("01:ADD") && <Fab onPress={() => history.push('scanner', {data_type:"pelaporan", origin_screen: "pelaporan", target_screen: "task_detail"})} renderInPortal={false} shadow={2} size="sm" icon={<Icon color="white" as={<Box><AiOutlinePlus/></Box>} name="plus" size="lg" />} />}
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  pelaporanScreen: makeSelectPelaporanScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetResult: () => dispatch(getPelaporan()),
    onGetMoreResult: () => dispatch(getMorePelaporan()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(PelaporanScreen);