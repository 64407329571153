import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectVendorKroscekFormScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView, TouchableWithoutFeedback, BackHandler, RefreshControl } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Pressable,
  TextArea,
  FormControl,
  Divider, 
  Image,
  Modal,
  Stack,
  Flex,
  Fab,
  ScrollView,
  Select, 
  CheckIcon,
  Button} from 'native-base';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import Files from 'react-files';
import 'react-html5-camera-photo/build/css/index.css';
import {MdAddAPhoto, MdDelete} from 'react-icons/md';

import moment from 'moment';
import {FaCalendarAlt, FaHourglassHalf} from 'react-icons/fa';
import { ContainerPage } from '../../components/ContainerPage';
import MyAlert from '../../components/MyAlert';
import MyAlertDialog from '../../components/MyAlertDialog';
import ImageSlider from '../../components/ImageSlider';
import { commonStyle } from '../../styles';
import { addImage, delImage, defaultAction, getVerifyAssets, getWorkOrderVerif, submitWOVerif, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';

export function VendorKroscekFormScreen({
  dispatch,
  location,
  navigation,
  vendorKroscekFormScreen,
  onAddImage,
  onDelImage,
  onUpdateInput,
  onGetResult,
  onGetResultAssets,
  onSubmitVerifyWO
}){
  const {start_dt, end_dt, dtProgress, id_ruang, readOnly} = location.state;
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const [actionType, setActionType] = useState();
  const [idWorkOrder, setIdWorkOrder] = useState();
  const [openCamera, setOpenCamera] = useState(false);
  const [openModalCamera, setOpenModalCamera] = useState(false);
  const[userData, setUserData] = useState();
  const imageList = [
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
  ];
  useEffect(() => {
      const user = api.getUserData();
        console.log("saved_user:", user);
        console.log("initial:", helper.getInitials(user.user_name));
        setUserData(user);

      dispatch(defaultAction());
      onUpdateInput('id_ruang', id_ruang);
      onUpdateInput('start_dt', start_dt);
      onUpdateInput('end_dt', end_dt);
      onUpdateInput('notes', (dtProgress.description_feedback ? dtProgress.description_feedback : ''));
      console.log('dtProgress', dtProgress.description_feedback);
      // onGetResult(id_ruang, start_dt, end_dt);
      onGetResultAssets(dtProgress.id_work_order_report);
  },[]);
  
  const handleGetData = () => {
    onGetResultAssets(dtProgress.id_work_order_report);
  }

  useEffect(() => {
    onUpdateInput('responseState','');
    
    if(vendorKroscekFormScreen.responseState == 'approved'){
      helper.successToast("Anda telah menyetujui laporan ini");
    }
    if(vendorKroscekFormScreen.responseState == 'done'){
      helper.successToast("Anda telah menyelesaikan laporan ini");
    }
    if(vendorKroscekFormScreen.responseState == 'rejected'){
      helper.warningToast("Anda telah menolak laporan ini")
    }
    if(vendorKroscekFormScreen.responseState == 'error'){
      helper.errorToast("Verifikasi laporan belum berhasil")
    }
  },[vendorKroscekFormScreen.responseState]);

  const handleOnSubmit = (action_type, id_wo) => {
    console.log('notes:', vendorKroscekFormScreen.notes);

    if(helper.isEmpty(vendorKroscekFormScreen.notes) || vendorKroscekFormScreen.notes.length < 10){
      helper.warningToast("Isikan hasil verifikasi, Minimal 10 karakter.");
      return;
    }

    setActionType(action_type);
    setIdWorkOrder(id_wo)
    setIsOpen(true);
  };

  const handleDoSubmit = () => {
    setIsOpen(false);
    onSubmitVerifyWO(actionType, idWorkOrder);
  };

  const handleCameraOpen = () => {
    setOpenModalCamera(true);
    setTimeout(() => {
      setOpenCamera(true);
    }, 300);
  }

  const handleChoosePhoto = async (data) => {
    setOpenCamera(false);
    console.log("img:", data);
    setTimeout(() => {
      setOpenModalCamera(false);
    }, 300);
    if(vendorKroscekFormScreen.assets_verif.length == 4){
      helper.infoToast("Anda melebihi batas maksimum yakni 4 foto.");
      return;
    }

    try {
      if(!helper.isEmpty(data)) onAddImage(data);
      // const granted = await PermissionsAndroid.request(
      //   PermissionsAndroid.PERMISSIONS.CAMERA,
      //   {
      //     title: "App Camera Permission",
      //     message:"App needs access to your camera",
      //     buttonNeutral: "Ask Me Later",
      //     buttonNegative: "Cancel",
      //     buttonPositive: "OK"
      //   }
      // );
      // if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      //   console.log("Camera permission given");
      //   launchCamera({ 
      //     mediaType: 'photo',
      //     maxWidth: 800,
      //     maxHeight: 800,
      //     includeBase64: true,
      //     cameraType: 'back'
      //   }, (response) => {
      //     console.log(response);
      //     if (response) {
      //       if(!helper.isEmpty(response.assets)) onAddImage(response.assets[0]);
      //     }
      //   });
      // } else {
      //   console.log("Camera permission denied");
      // }
    } catch (err) {
      console.warn(err);
    }
  };

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleFileError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
    helper.errorToast(error.message);
  }

  const handleFileChange = (files) => {
    console.log(files);
    const file = files[0];
    getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        const params = {
          base64: result,
          type: file.type,
          name: file.name,
        }

        if(vendorKroscekFormScreen.assets_verif.length == 4){
          helper.infoToast("Anda melebihi batas maksimum yakni 4 foto.");
          return;
        }
        
        onAddImage(params.base64);
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleTakePhotoAnimationDone (dataUri) {
    // Do stuff with the photo...
    // console.log('takePhoto');
  }
  
  function handleCameraError (error) {
    // console.log('handleCameraError', error);
  }
  
  function handleCameraStart (stream) {
    // console.log('handleCameraStart : ', stream);
  }
  
  function handleCameraStop () {
    // console.log('handleCameraStop');
  }


  return (
        <ContainerPage options={{statusBar: true, prevPage: 'vendor-kroscek', title: 'Kroscek Perbaikan', params:{id_item: id_ruang}}}>
          <Flex flex={1}>
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              <VStack space={7} mt={2} mb={5} mx={3}>
                <Box p={5} mt={3} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    <VStack>
                      <Center>
                        <Avatar size="lg" bg="primary.900" mb={4}>
                            {helper.getInitials(dtProgress.nama_inventaris)}
                        </Avatar>
                      </Center>
                      <Center>
                        <Text color="darkText" isTruncated maxWidth="90%" fontSize="md" fontWeight="bold">{dtProgress.nama_inventaris}</Text>
                      </Center>
                      <Center>
                        <Text color="muted.400" isTruncated maxWidth="90%" fontSize="sm">{dtProgress.code_inventaris}</Text>
                      </Center>
                      <Box mt={2}>
                        <VStack>
                          <Text fontSize="xs" color="muted.600">No Penawaran</Text>
                          <Text color="darkText" bold>{dtProgress.code_work_order}</Text>
                        </VStack>
                      </Box>
                      <Box mt={2}>
                        <VStack>
                          <Text fontSize="xs" color="muted.600">Deadline Pekerjaan</Text>
                          <Text color="darkText" bold>{moment(dtProgress.forcast_date_finish, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY")}</Text>
                        </VStack>
                      </Box>
                    </VStack>
                </Box>
                {/* {vendorKroscekFormScreen.results.length == 0 && <MyAlert status="info" title="Maaf, Hasil belum ditemukan."/>} */}
                {/* {vendorKroscekFormScreen.results.length > 0 && vendorKroscekFormScreen.results.map((row_, idx_) => ( */}
                <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    <VStack>
                      <Flex mb="2.5">
                        <ImageSlider images={vendorKroscekFormScreen.assets}/>
                      </Flex>
                      <Box mb={2}>
                        <Text fontWeight="bold">{dtProgress.nama_inventaris}</Text>
                      </Box>
                      <HStack flex={1} mb={2}>
                        <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                          <Box>
                            <Icon color="muted.400" as={<Box><FaCalendarAlt/></Box>} size={3}></Icon>
                          </Box>
                          <Box flex={1} ml={1}>
                            <Text color="muted.400" fontWeight={500} fontSize="xs">{moment(dtProgress.date_create, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY")}</Text>
                          </Box>
                        </Stack>
                        <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                          <Box>
                            <Icon color="muted.400" as={<Box><FaHourglassHalf/></Box>} size={3}></Icon>
                          </Box>
                          <Box flex={1} ml={1}>
                            <Text color="muted.400" fontWeight={500} fontSize="xs">{`${dtProgress.percent_progres}%`}</Text>
                          </Box>
                        </Stack>
                      </HStack>
                      <Box mb={2}>
                        <Text color="darkText" textAlign="justify">
                          {dtProgress.description_progres}
                        </Text>
                      </Box>
                      <Flex>
                        <Divider bg="primary.800" thickness="1" my="2" orientation="horizontal" />
                        <Box mb={2}>
                          <FormControl mb="1" isRequired>
                            <FormControl.Label>Hasil Verifikasi</FormControl.Label>
                            {(dtProgress.status_verifikasi && readOnly) && <Text mb={2}>{dtProgress.description_feedback}</Text>}
                            {!readOnly && <TextArea h={40} value={vendorKroscekFormScreen.notes} placeholder="Ketik disini..." onChangeText={txt => onUpdateInput('notes', txt)} borderRadius="lg" w="100%" />}
                          </FormControl>
                        </Box> 
                      </Flex>
                    </VStack>
                </Box>
                {/* ))} */}
              </VStack>

              <Box p={5} mb={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                <HStack mb={4}>
                  <Flex flex={1}>
                    <Text fontSize="md">Foto verifikasi</Text>
                  </Flex>
                  {!readOnly && 
                  <Box width="120px">
                    <Button onPress={() => handleCameraOpen()} leftIcon={<Icon as={<Box><MdAddAPhoto/></Box>} size="sm" />}>
                      Ambil Foto
                    </Button>
                  </Box>
                  }
                </HStack>
                <VStack space={3}>
                  <Box>
                  <Box justifyContent="center" alignItems="center" mt={2} p={2} flex={1} borderColor="muted.400" borderRadius="lg" borderWidth={1} borderStyle="dashed">
                    <Files
                      className='files-dropzone'
                      onChange={handleFileChange}
                      onError={handleFileError}
                      accepts={['image/png', 'image/jpg','image/jpeg']}
                      maxFileSize={(10000000 * 20)} // 20 MB
                      minFileSize={0}
                      clickable>
                      Drop files here or click to upload
                    </Files>
                  </Box>
                  {/* {openCamera == true && 
                      <Camera
                        onTakePhoto = { (dataUri) => { handleChoosePhoto(dataUri); } }
                        // onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
                        onTakePhotoAnimationDone = { (dataUri) => { handleTakePhotoAnimationDone(dataUri); } }
                        onCameraError = { (error) => { handleCameraError(error); } }
                        idealFacingMode = {FACING_MODES.ENVIRONMENT}
                        idealResolution = {{width: 640, height: 640}}
                        imageType = {IMAGE_TYPES.JPG}
                        imageCompression = {0.70}
                        isMaxResolution = {true}
                        isImageMirror = {false}
                        isSilentMode = {false}
                        isDisplayStartCameraError = {true}
                        isFullscreen = {false}
                        sizeFactor = {1}
                        onCameraStart = { (stream) => { handleCameraStart(stream); } }
                        onCameraStop = { () => { handleCameraStop(); } }
                      />
                    } */}
                  </Box>
                  {console.log('from_index1 : ', vendorKroscekFormScreen)}
                  {console.log('from_index2 : ', vendorKroscekFormScreen.assets_verif.length)}
                  {vendorKroscekFormScreen.assets_verif.length > 0  && 
                  vendorKroscekFormScreen.assets_verif.map((rowimage, imgx) => {
                    return (
                      <Box key={`img-${imgx}`} p={2} flex={1} borderColor="muted.400" borderRadius="lg" borderWidth={1}>
                        <Flex mb={2} justifyContent="center" alignItems="center">
                        <AspectRatio
                        ratio={{
                          base: 3 / 4,
                        }} height={{
                          base: 350,
                        }}>
                          <Image alt={`photo${imgx}`} resizeMode="contain" source={{uri: rowimage.path}}/>
                        </AspectRatio>
                        </Flex>
                        <HStack justifyContent="center" alignItems="center">
                          <Flex flex={1} flexDirection="column">
                            <Text fontSize="sm" fontWeight="bold">Foto {`${imgx+1}`}</Text>
                            {/* <Text fontSize="xs" color="muted.400">28-10-2022 09:40</Text> */}
                          </Flex>
                          {!readOnly &&
                          <Box>
                            <Pressable onPress={() => onDelImage(imgx)}>
                              <Icon as={<Box><MdDelete/></Box>} color="red.500" size="lg"></Icon>
                            </Pressable>
                          </Box>
                          }
                        </HStack>
                      </Box>
                      // <View>
                      //   <Image alt={`photo${imgx}`} style={styles.image} source={{uri: rowimage}}/>
                      // </View>
                    );
                  })
                  }
                </VStack>
              </Box>

              {(helper.isNull(dtProgress.status_verifikasi) && !readOnly) && 
                <Box mb={1}>
                  <HStack>
                    <Box flex={1} mr={3}>
                      <Button isLoading={vendorKroscekFormScreen.loading_submit} spinnerPlacement="end" isLoadingText="Submitting..." colorScheme="success" onPress={() => handleOnSubmit('20', dtProgress.id_work_order_report)}>Setujui</Button>
                    </Box>
                    <Box flex={1}>
                      <Button 
                        isLoading={vendorKroscekFormScreen.loading_submit} 
                        spinnerPlacement="end" 
                        isLoadingText="Submitting..." 
                        colorScheme="amber.700"
                        _text={{ color: "rose.700" }} 
                        variant="outline" 
                        onPress={() => handleOnSubmit('10', dtProgress.id_work_order_report)}
                      >
                        Tolak
                      </Button>
                    </Box>
                  </HStack>
                </Box>
              }
            </ScrollView>
          </Flex>
          <MyAlertDialog 
            alertTitle="Verifikasi Progres" 
            alertDesc={`Apakah anda ingin ${actionType == '20' ? 'Menyetujui' : (actionType == '30' ? 'Menyelesaikan': 'Menolak')} laporan ini ?`}
            alertNoLabel="Tidak"
            alertYesLabel="Ya"
            alertIsOpen={isOpen}
            alertOnClose={onClose}
            alertOnConfirm={() => handleDoSubmit()}
          />
          
          <Modal isOpen={openModalCamera} onClose={() => setOpenModalCamera(false)}>
            <Modal.Content maxWidth="500px">
              <Modal.Header>Ambil Gambar</Modal.Header>
              <Modal.Body maxWidth="450px">
              {openCamera == true && 
              <Camera
                style={{width: "100%"}}
                onTakePhoto = { (dataUri) => { handleChoosePhoto(dataUri); } }
                onTakePhotoAnimationDone = { (dataUri) => { handleTakePhotoAnimationDone(dataUri); } }
                onCameraError = { (error) => { handleCameraError(error); } }
                idealFacingMode = {FACING_MODES.ENVIRONMENT}
                idealResolution = {{width: 640, height: 800}}
                imageType = {IMAGE_TYPES.JPG}
                imageCompression = {0.70}
                isMaxResolution = {true}
                isImageMirror = {false}
                isSilentMode = {false}
                isDisplayStartCameraError = {true}
                isFullscreen = {false}
                sizeFactor = {1}
                onCameraStart = { (stream) => { handleCameraStart(stream); } }
                onCameraStop = { () => { handleCameraStop(); } }
              />
            }
              </Modal.Body>
            </Modal.Content>
          </Modal>
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  vendorKroscekFormScreen: makeSelectVendorKroscekFormScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetResult: (id_item, start_dt, end_dt) => dispatch(getWorkOrderVerif(id_item, start_dt, end_dt)),
    onGetResultAssets: (id_work_order_report) => dispatch(getVerifyAssets(id_work_order_report)),
    onAddImage: asset => dispatch(addImage(asset)),
    onDelImage: idx => dispatch(delImage(idx)),
    onSubmitVerifyWO: (action_type, id_wo) => dispatch(submitWOVerif(action_type, id_wo)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(VendorKroscekFormScreen);