import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectVendorProgressHistoryScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView, TouchableWithoutFeedback, StyleSheet, View } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Image, 
  Flex, 
  Button,
  ScrollView,
  FlatList} from 'native-base';
import moment from 'moment';
import { Chrono } from "react-chrono";
import { ContainerPage } from '../../components/ContainerPage';
import BadgeStatus from '../../components/BadgeStatus';
import { commonStyle } from '../../styles';
import { getWorkOrderStatus, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';

export function VendorProgressHistoryScreen({
  dispatch,
  location,
  navigation,
  vendorProgressHistoryScreen,
  onUpdateInput,
  onGetResults
}){
  const timeline = [
    {date:'Oct, 27 2022',time: '09:00', title: 'Event 1', description: 'Event 1 Description'},
    {date:'Oct, 27 2022',time: '10:45', title: 'Event 2', description: 'Event 2 Description'},
    {date:'Oct, 27 2022',time: '12:00', title: 'Event 3', description: 'Event 3 Description'},
    {date:'Oct, 27 2022',time: '14:00', title: 'Event 4', description: 'Event 4 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 5', description: 'Event 5 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 6', description: 'Event 6 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 7', description: 'Event 7 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 8', description: 'Event 8 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 9', description: 'Event 9 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 10', description: 'Event 10 Description'}
  ];
  const {dtProgress} = location.state;
  const[userData, setUserData] = useState();
  const[historyList, setHistoryList] = useState([]);

  useEffect(() => {
    onGetResults(dtProgress.id_work_order);
  },[]);

  useEffect(() => {
    if(vendorProgressHistoryScreen.results.length > 0){
      console.log("masuk sini");
      let res = [];
      vendorProgressHistoryScreen.results.map((row, idx) => {
        const obj = {
          title: `${moment(row.date_create, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")}`,
          cardTitle: row.description,
          cardSubtitle: ``,
          cardDetailedText: ``,
          timelineContent: <BadgeStatus status={row.status_text}/>
        };
        res.push(obj);
      });
      console.log(res);
      setHistoryList(res);
    }
  }, [vendorProgressHistoryScreen.results]);

  // const renderDetail = (rowData, sectionID, rowID) => {
  //   let title = <View style={{flexDirection:'row'}}><BadgeStatus status={rowData.status_text}/></View>;
  //   var desc = null
  //   if(rowData.description)
  //     desc = (
  //       <View style={styles.descriptionContainer}>
  //         <Text style={[styles.textDescription]}>{rowData.description}</Text>
  //       </View>
  //     )

  //   return (
  //     <View style={{flex:1}}>
  //       {title}
  //       {desc}
  //     </View>
  //   )
  // }

  // const renderTime = (rowData, sectionID, rowID) => {
  //   return (
  //     <Box minWidth={100}>
  //       <Center>
  //         <Text fontWeight="bold">{moment(rowData.date_create, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY")}</Text>
  //         <Text color="muted.800">{moment(rowData.date_create, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}</Text>
  //       </Center>
  //     </Box>
  //   )
  // }
  

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'vendor-progress', title: 'Progress Perbaikan'}}>
          <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor} mx={3} mt={7}>
              <VStack>
                <Center>
                  <Avatar size="lg" bg="primary.900" mb={4}>
                      {helper.getInitials(dtProgress.nama_inventaris)}
                  </Avatar>
                </Center>
                <Center>
                  <Text color="darkText" isTruncated maxWidth="90%" fontSize="md" fontWeight="bold">{dtProgress.nama_inventaris}</Text>
                </Center>
                <Center>
                  <Text color="muted.400" isTruncated maxWidth="90%" fontSize="sm">{dtProgress.code_inventaris}</Text>
                </Center>
                <Box mt={2}>
                  <VStack>
                    <Text fontSize="xs" color="muted.600">No Penawaran</Text>
                    <Text color="darkText" bold>{dtProgress.code_work_order}</Text>
                  </VStack>
                </Box>
                <Box mt={2}>
                  <VStack>
                    <Text fontSize="xs" color="muted.600">Tgl Pelaporan</Text>
                    <Text color="darkText" bold>{moment(dtProgress.date_report, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY")}</Text>
                  </VStack>
                </Box>
              </VStack>
          </Box>
          <Flex flex={1} justifyContent="center" mx={3}>
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              <Box>
              {historyList.length > 0 && 
                <Chrono
                  items={historyList}
                  theme={{
                    primary: commonStyle.primaryColor,
                    secondary: 'white',
                    cardBgColor: commonStyle.baseColor,
                    titleColor: 'black',
                    titleColorActive: commonStyle.primaryColor,
                  }}
                  mode="VERTICAL"
                  cardHeight={80}
                  cardWidth={600}
                  fontSizes={{
                    title: ".8rem"
                  }}
                  classNames={{
                    title: 'history-title',
                  }}
                
                  useReadMore={false}
                  hideControls={true}
                />
                }
                {/* <Timeline
                    data={vendorProgressHistoryScreen.results}
                    renderDetail={renderDetail}
                    renderTime={renderTime}
                    circleSize={18}
                    circleColor={commonStyle.primaryColor}
                    lineColor={commonStyle.primaryColor}
                    timeContainerStyle={{minWidth:100}}
                    innerCircle={'dot'}
                    timeStyle={{
                      textAlign: 'center',
                      padding: 5, 
                      borderRadius:13}}
                    descriptionStyle={{color:'gray'}}
                    options={{
                      style:{paddingTop:20}
                    }}
                    isUsingFlatlist={true}
                  /> */}
                </Box>
            </ScrollView>
          </Flex>
        </ContainerPage>
      );
    
}

const mapStateToProps = createStructuredSelector({
  vendorProgressHistoryScreen: makeSelectVendorProgressHistoryScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetResults: id_work_order => dispatch(getWorkOrderStatus(id_work_order)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(VendorProgressHistoryScreen);