import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
  import api from '../../util/api';
  import helper from '../../util/helper';
import { getPelaporanHistoryFail, getPelaporanHistorySuccess } from './actions';
  import * as constants from './constants';
  
  import makeSelectPelaporanHistoryScreen from './selector';
  
  export function* doGetPelaporanDetailHistory(action) {
    const dataset = yield select(makeSelectPelaporanHistoryScreen());

    let params = {
      tipe: action.h_type,
      id: '',
      id_pegawai: dataset.pelaporan?.id_pegawai,
      id_vendor: dataset.pelaporan?.id_vendor,
      id_work_order_report: dataset.pelaporan?.id_work_order_report
    };

    switch (action.h_type) {
      case 1:
        params.id = dataset.pelaporan?.id_pelaporan;
        break;
      case 2:
        params.id = dataset.pelaporan?.id_korektif;
        break;
    
      default:
        params.id = dataset.pelaporan?.id_work_order_detail;
        break;
    }

    try {
      const response = yield call(api.pelaporan.getPelaporanDetailHistory, params);
      const {data} = response.data;
      // console.log('pelaporan_history', data);
      yield put(
        getPelaporanHistorySuccess(response.data),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getPelaporanHistoryFail(err));
    }
  }

  // Individual exports for testing
  export default function* pelaporanHistoryDetailScreenSaga() {
    yield all([
      takeLatest(constants.GET_PELAPORAN_HISTORY, doGetPelaporanDetailHistory),
    ]);
  }
  