import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectPelaporanHistoryScreen from './selector';
import reducer from './reducer';
import saga from './saga';
import { useHistory } from 'react-router-dom';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Image, 
  Flex, 
  Button,
  FlatList, 
  ScrollView,
  Pressable} from 'native-base';
import moment from 'moment';
import { Chrono } from "react-chrono";
import { ContainerPage } from '../../components/ContainerPage';
import BadgeStatus from '../../components/BadgeStatus';
import { commonStyle } from '../../styles';
import { getPelaporanHistory, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';

export function PelaporanHistoryScreen({
  dispatch,
  pelaporanHistoryScreen,
  onUpdateInput,
  onGetResults
}){
  // const {rawData, id_pelaporan} = route.params;
  const history = useHistory();
  const timeline = [
    {date:'Oct, 27 2022',time: '09:00', title: 'Event 1', description: 'Event 1 Description'},
    {date:'Oct, 27 2022',time: '10:45', title: 'Event 2', description: 'Event 2 Description'},
    {date:'Oct, 27 2022',time: '12:00', title: 'Event 3', description: 'Event 3 Description'},
    {date:'Oct, 27 2022',time: '14:00', title: 'Event 4', description: 'Event 4 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 5', description: 'Event 5 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 6', description: 'Event 6 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 7', description: 'Event 7 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 8', description: 'Event 8 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 9', description: 'Event 9 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 10', description: 'Event 10 Description'}
  ];
  const[userData, setUserData] = useState();
  const[rawData, setRawData] = useState({nm_gedung:'', addres:'', nm_ruang:'', date_report:''});
  const[id_pelaporan, setIdPelaporan] = useState();
  const[historyList, setHistoryList] = useState([]);

  useEffect(() => {
      const user = api.getUserData();
      console.log("saved_user:", user);
      setUserData(user);

      if (history.location.state && history.location.state.rawData) {
        console.log(history.location.state);
        setRawData(history.location.state.rawData);
      }
      if (history.location.state && history.location.state.id_pelaporan) {
        const idP = history.location.state.id_pelaporan;
        setIdPelaporan(idP);
        onGetResults(idP);
      }
  },[]);

  useEffect(() => {
    if(pelaporanHistoryScreen.results.length > 0){
      console.log("masuk sini");
      let res = [];
      let detail = '';

      pelaporanHistoryScreen.results.map((row, idx) => {
        detail = getDataProses(row.status);

        const obj = {
          title: `${moment(row.date_create, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")}`,
          cardTitle: <Pressable onPress={() => handleOnSelect(row)}><Text color="coolGray.800" fontWeight="medium" fontSize="md">{row.nm_inventaris}</Text></Pressable>,
          cardSubtitle: ``,
          cardDetailedText: ``,
          timelineContent: <div>
              <BadgeStatus status={row.status}/>{detail}
            </div>
        };
        res.push(obj);
      });
      console.log(res);
      setHistoryList(res);
    }
  }, [pelaporanHistoryScreen.results]);

  // const renderDetail = (rowData, sectionID, rowID) => {
  //   let title = <Text style={[styles.title]}>{rowData.nm_inventaris}</Text>
  //   var desc = null
  //   if(rowData.status)
  //     desc = (
  //       <View style={styles.descriptionContainer}>
  //         {/* <Text style={[styles.textDescription]}>{rowData.status}</Text> */}
  //         <BadgeStatus status={rowData.status}/>
  //       </View>
  //     )

  //   return (
  //     <View style={{flex:1}}>
  //       {title}
  //       {desc}
  //     </View>
  //   )
  // }

  // const renderTime = (rowData, sectionID, rowID) => {
  //   return (
  //     <Box minWidth={100}>
  //       <Center>
  //         <Text fontWeight="bold">{moment(rowData.date_create, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY")}</Text>
  //         <Text color="muted.800">{moment(rowData.date_create, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}</Text>
  //       </Center>
  //     </Box>
  //   )
  // }

  const items = [
    {
      title: "May 1940",
      cardTitle: "Dunkirk",
      url: "http://google.com",
      cardDetailedText: `On 10 May 1940, Hitler began his long-awaited offensive in the west by invading neutral Holland and Belgium and attacking northern France.`
    },
    {
      title: "25 July 1940",
      cardTitle: "The Battle of Britain",
      cardDetailedText: `After France’s surrender in June 1940, Churchill told the British people, “Hitler knows that he will have to break us in this island or lose the war”.`
    },
    {
      title: "June 1941",
      cardTitle: "Operation Barbarossa",
      cardSubtitle: ``,
      cardDetailedText: ``,
      timelineContent: <BadgeStatus status="created"/>
    }
  ];

  const handleOnSelect = (row) => {
    console.log('on-select-history', row);
    let dtType = 1;

    if(row.status == "WO Created" || row.status == "Penawaran Vendor" || row.status == "Aprove"){
      dtType = 2;
    }

    if(row.status == "Progres" || row.status == "Done"){
      dtType = 3;

      if(helper.isEmpty(row.id_vendor)){
        dtType = 2;
      }
    }

    history.push('pelaporan-history-detail',{...history.location.state, pelaporan: row, history_type: dtType, origin_screen: 'pelaporan-history'});
  };
  
  return (
        <ContainerPage options={{statusBar: true, prevPage: 'pelaporan', title: 'Pelaporan Kerusakan'}}>
          <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
          <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor} mx={3} mt={7}>
            <VStack>
              <Center>
                <Avatar size="lg" bg="primary.900" mb={4}>
                    {helper.getInitials(rawData.nm_gedung)}
                </Avatar>
              </Center>
              <Center>
                <Text color="darkText" fontSize="md" fontWeight="bold">{rawData.nm_ruang}</Text>
              </Center>
              <Center>
                <Text color="muted.400" textAlign="center" fontSize="sm">{rawData.addres}</Text>
              </Center>
              <Center>
                <Text mt={10} color="darkText" fontSize="sm">{moment(rawData.date_report, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")}</Text>
              </Center>
            </VStack>
          </Box>
          <Flex flex={1} mx={3}>
              <Box>
              {historyList.length > 0 && 
              <Chrono
                items={historyList}
                theme={{
                  primary: commonStyle.primaryColor,
                  secondary: 'white',
                  cardBgColor: commonStyle.baseColor,
                  titleColor: 'black',
                  titleColorActive: commonStyle.primaryColor,
                }}
                mode="VERTICAL"
                cardHeight={80}
                cardWidth={600}
                fontSizes={{
                  title: ".8rem"
                }}
                classNames={{
                  title: 'history-title',
                }}
                allowDynamicUpdate={true}
                useReadMore={false}
                hideControls={true}
                // onItemSelected={handleOnSelect}
              />
              }
              </Box>
              {/* <Box flexDirection="row">
                <Timeline
                    data={pelaporanHistoryScreen.results}
                    renderDetail={renderDetail}
                    renderTime={renderTime}
                    circleSize={18}
                    circleColor={commonStyle.primaryColor}
                    lineColor={commonStyle.primaryColor}
                    timeContainerStyle={{minWidth:100}}
                    innerCircle={'dot'}
                    timeStyle={{
                      textAlign: 'center',
                      padding: 5, 
                      borderRadius:13}}
                    descriptionStyle={{color:'gray'}}
                    options={{
                      style:{paddingTop:20}
                    }}
                    isUsingFlatlist={true}
                  />
              </Box> */}
          </Flex>
          </ScrollView>
        </ContainerPage>
      );
    
}

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     padding: 20,
// 	  paddingTop:65,
//     backgroundColor:'white'
//   },
//   list: {
//     flex: 1,
//     marginTop:20,
//   },
//   title:{
//     fontSize:16,
//     fontWeight: 'bold'
//   },
//   descriptionContainer:{
//     flexDirection: 'row',
//     paddingRight: 50,
//   },
//   textDescription: {
//     color: 'gray'
//   }
// });

const mapStateToProps = createStructuredSelector({
  pelaporanHistoryScreen: makeSelectPelaporanHistoryScreen(),
});

function getDataProses(data){
  switch (data.toLowerCase()) {
    case 'created':
      return '\n\n Laporan kerusakan berhasil dibuat.';

    case 'progres':
      return '\n\n Kerusakan sedang proses perbaikan oleh vendor.';

    case 'selesai':
      return '\n\n Kerusakan sudah selesai diperbaiki.';

    case 'finish':
      return '\n\n Pekerjaan telah selesai dilakukan.';

    case 'batal':
      return '\n\n Pelaporan kerusakan telah dibatalkan oleh admin.';

    case 'reject':
      return '\n\n Pelaporan kerusakan tidak valid';

    case 'rejected':
      return '\n\n Pelaporan kerusakan tidak valid';

    case 'aprove':
      return '\n\n Pelaporan kerusakan telah terverifikasi oleh verifikator';

    case 'approved':
      return '\n\n Pelaporan kerusakan telah terverifikasi oleh verifikator';

    case 'asignment review':
      return '\n\n Laporan kerusakan anda sudah dicek dan didelegasikan oleh team lapangan.';

    case 'review':
      return '\n\n Laporan kerusakan anda sudah dicek dan didelegasikan oleh team lapangan.';

    case 'penawaran vendor':
      return '\n\n Laporan kerusakan anda saat ini dalam proses penunjukan vendor.';
    
    case 'wo created':
    return '\n\n Sudah terdapat vendor yang akan memperbaiki laporan kerusakan anda.';

    case 'done':
      return '\n\n Kerusakan sudah selesai diperbaiki.';

    default:
      return '\n\n Pelaporan kerusakan sudah didelegasikan kepada verifikator';

  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetResults: id_pelaporan => dispatch(getPelaporanHistory(id_pelaporan)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(PelaporanHistoryScreen);