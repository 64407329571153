import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getWorkOrderProgress() {
  return {
    type: constants.GET_WORK_ORDER_PROGRESS
  };
}

export function getWorkOrderProgressLoading() {
  return {
    type: constants.GET_WORK_ORDER_PROGRESS_LOADING
  };
}

export function getWorkOrderProgressFail(error) {
  return {
    type: constants.GET_WORK_ORDER_PROGRESS_FAIL,
    error
  };
}

export function getWorkOrderProgressSuccess(results) {
  return {
    type: constants.GET_WORK_ORDER_PROGRESS_SUCCESS,
    results
  };
}