export const DEFAULT_ACTION = 'screen/TaskScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/TaskScreen/UPDATE_INPUT';

export const GET_GEDUNG = 'screen/TaskScreen/GET_GEDUNG';
export const GET_GEDUNG_LOADING = 'screen/TaskScreen/GET_GEDUNG_LOADING';
export const GET_GEDUNG_SUCCESS = 'screen/TaskScreen/GET_GEDUNG_SUCCESS';
export const GET_GEDUNG_FAIL = 'screen/TaskScreen/GET_GEDUNG_FAIL';

export const GET_TASKS = 'screen/TaskScreen/GET_TASKS';
export const GET_TASKS_LOADING = 'screen/TaskScreen/GET_TASKS_LOADING';
export const GET_TASKS_SUCCESS = 'screen/TaskScreen/GET_TASKS_SUCCESS';
export const GET_TASKS_FAIL = 'screen/TaskScreen/GET_TASKS_FAIL';