/*
 *
 * TaskPreventifDetailScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import helper from '../../util/helper';

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    unit_asset: null,
    dtPreventif: null,
    dtAset: [],
    dtTodo: null,
    currPreventif: null
};

/* eslint-disable default-case, no-param-reassign */
const TaskPreventifDetailScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        break;
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.GET_ROOM_ASSET:
        draft.loading = true;
        break;
      case constants.GET_ROOM_ASSET_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error = 1 ;
        draft.dtPreventif = null;
        draft.dtAset = null;
        draft.error_msg= 'Maaf, Hasil pencarian belum ditemukan.';
        break;
      case constants.GET_ROOM_ASSET_SUCCESS:
        draft.unit_asset = action.results;
        if(!helper.isEmpty(action.results.data)){
          draft.dtPreventif = action.results.data;
          draft.dtAset = action.results.list_aset;
          draft.dtAset.map((responseResult, keyy) => {
            if(draft.currPreventif){
              const idCx = draft.currPreventif.findIndex(
                result => result.id_inventaris_aset === responseResult.id_aset,
              );
              if(idCx >= 0){
                draft.dtAset.splice(keyy, 1, {
                  ...draft.dtAset[keyy],
                  id_preventif_exec: draft.currPreventif[idCx].id_preventif_exec,
                  loading: false,
                });
              }else{
                draft.dtAset.splice(keyy, 1, {
                  ...draft.dtAset[keyy],
                  id_preventif_exec: 0,
                  loading: false,
                });
              }
            }else{
              draft.dtAset.splice(keyy, 1, {
                ...draft.dtAset[keyy],
                id_preventif_exec: 0,
                loading: false,
              });
            }
            
          });

          // console.log(draft.dtAset);
        }
        draft.loading = false;
        break;
      case constants.GET_TODO_LIST:
        draft.loading = true;
        break;
      case constants.GET_TODO_LIST_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.dtTodo = null;
        draft.error_msg= 'Maaf, Hasil pencarian belum ditemukan.';
        break;
      case constants.GET_TODO_LIST_SUCCESS:
        if(!helper.isEmpty(action.results.data)){
          draft.dtTodo = action.results.data;
        }
        draft.error = false;
        draft.loading = false;
        break;
      case constants.SUBMIT_PREVENTIF:
        draft.dtAset.forEach(responseResult => {
          const idx = draft.dtAset.findIndex(
            result => result.id_inventaris === action.idx_inventaris,
          );
          draft.dtAset.splice(idx, 1, {...draft.dtAset[idx], loading: true});
        });
        break;
      case constants.SUBMIT_PREVENTIF_SUCCESS:
        draft.error = 0;
        draft.dtAset.forEach(responseResult => {
          const idx = draft.dtAset.findIndex(
            result => result.id_inventaris === action.idx_inventaris,
          );
          draft.dtAset.splice(idx, 1, {...draft.dtAset[idx], 
            id_preventif_exec: action.id_preventif_exec,
            loading: false,
            date_exec: new Date()
          });
        });

        break;
      case constants.SUBMIT_PREVENTIF_FAIL:
        draft.dtAset.forEach(responseResult => {
          const idx = draft.dtAset.findIndex(
            result => result.id_inventaris === action.idx_inventaris,
          );
          draft.dtAset.splice(idx, 1, {...draft.dtAset[idx], loading: false});
        });
        break;
    }
  });

export default TaskPreventifDetailScreenReducer;
