import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getRoomAsset(id_branch, id_item) {
  return {
    type: constants.GET_ROOM_ASSET,
    id_branch,
    id_item
  };
}

export function getRoomAssetLoading() {
  return {
    type: constants.GET_ROOM_ASSET_LOADING
  };
}

export function getRoomAssetFail(error) {
  return {
    type: constants.GET_ROOM_ASSET_FAIL,
    error
  };
}

export function getRoomAssetSuccess(results) {
  return {
    type: constants.GET_ROOM_ASSET_SUCCESS,
    results
  };
}

export function clearSessPelaporan() {
  return {
    type: constants.CLEAR_SESS_PELAPORAN
  };
}