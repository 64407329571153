import React, { Component, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectVerifPelaporanScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import { ScrollView, RefreshControl,BackHandler, TouchableWithoutFeedback, VirtualizedList } from 'react-native';
import {
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Stack,
  Badge,
  Input,
  Image,
  IconButton,
  Text,
  Fab,
  CheckIcon,
  Select, 
  Flex,
  ScrollView, 
  Button,
  Actionsheet,
  FormControl,
  WarningOutlineIcon} from 'native-base';
import InfiniteScroll from "react-infinite-scroll-component";
import { ContainerPage } from '../../components/ContainerPage';
import CardVerifPelaporanList from '../../components/CardVerifPelaporanList';
import CardListPreloader from '../../components/CardListPreloader';
import MyAlert from '../../components/MyAlert';
import { commonStyle } from '../../styles';
import { getMorePelaporan, getPelaporan, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import RangeDateCustom from '../../components/RangeDateCustom';

export function VerifPelaporanScreen({
  dispatch,
  location,
  navigation,
  verifPelaporanScreen,
  onUpdateInput,
  onGetResult,
  onGetMoreResult
}){
  const {id_branch, id_item} = location.state;
  const [screenAccess, setScreenAccess] = useState([]);
  const [isOpen, setOnOpen] = useState(false);
  const scrollableRef = useRef(null);

  useEffect(() => {
    // get user privileges
    const menus = api.getUserPrivileges();
    const allAccess = helper.generatePrivileges(menus);
    setScreenAccess(allAccess);
    onUpdateInput('page', 1);
    onGetResult(id_item);
  },[]);

  const handleGetData = (id_item) => {
    onUpdateInput('page', 1);
    onGetResult(id_item)
  }

  // const renderItem = ({item}) => {
  //   return (
  //     <Box px={3} mb={5}>
  //       <CardVerifPelaporanList data={item} access={screenAccess} navigation={navigation} id_ruang={id_item}/>
  //     </Box>
  //   );
  // }

  // const getItem = (_data, index) => ({..._data[index]});
  
  // const getItemCount = _data => verifPelaporanScreen.results.length;

  // const getItemLayout = (data, index) => ({length: 275, offset: 275 * index, index});

  const handleLoadMore = () => {
    console.log('load more:', verifPelaporanScreen.page);
    if(verifPelaporanScreen.page < verifPelaporanScreen.total_page){
      onUpdateInput('page', verifPelaporanScreen.page + 1);
    }
    onGetMoreResult(id_item);
  }

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'home', title: 'Verifikasi Pelaporan', enableAct: true, onPressAction : () => setOnOpen(true)}}>
          {/* <Box mt={3} mx={3} mb={3}>
            <Heading size="md">Verifikasi Pelaporan</Heading>
          </Box> */}
          <Flex flex={1}>
            {/* {
              verifPelaporanScreen.loading && <VStack space={7} px={3} mt={2} mb={35}>
                {[...Array(3)].map((x, i) => (
                  <CardListPreloader />
                ))}
              </VStack>
            }
            {!verifPelaporanScreen.loading && <Box>
              {verifPelaporanScreen.results.length == 0 && <Box px={3} mb={5}><MyAlert status="info" title="Maaf, Hasil belum ditemukan."/></Box>}    
              {verifPelaporanScreen.results.length > 0 && <VirtualizedList
                refreshing={false}
                data={verifPelaporanScreen.results}
                renderItem={renderItem}
                onRefresh={() => handleGetData()}
                keyExtractor={item => item.id_korektif}
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                getItem={getItem}
                getItemCount={getItemCount}
                initialNumToRender={3}
                updateCellsBatchingPeriod={300}
                maxToRenderPerBatch={1}
                windowSize={2}
                removeClippedSubviews={false}
                getItemLayout={getItemLayout}
              ></VirtualizedList>}
            </Box>
            } */}
            <ScrollView ref={scrollableRef} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              {
                verifPelaporanScreen.loading && <VStack space={7} px={3} mt={2} mb={35}>
                  {[...Array(3)].map((x, i) => (
                    <CardListPreloader key={i} />
                  ))}
                </VStack>
              }
              {
                !verifPelaporanScreen.loading && <>
                  {verifPelaporanScreen.results.length == 0 && <MyAlert status="info" title="Maaf, Hasil belum ditemukan."/>}
                  {verifPelaporanScreen.results?.data?.length > 0 &&
                    <InfiniteScroll
                    key={scrollableRef.current}
                      dataLength={verifPelaporanScreen.results?.data?.length}
                      next={handleLoadMore}
                      hasMore={(verifPelaporanScreen.page < verifPelaporanScreen.total_page)}
                      loader={<p style={{textAlign:'center'}}>Memuat Lebih...</p>}
                      scrollableTarget={scrollableRef.current}
                    >
                    <VStack space={7} px={3} mt={2} mb={35}>
                      {verifPelaporanScreen.results.data.map((row, i) => (
                        <CardVerifPelaporanList key={i} data={row} id_ruang={id_item} access={screenAccess} navigation={navigation} />
                      ))}
                    </VStack>
                    </InfiniteScroll>
                  }
                </>
              }
            </ScrollView>
          </Flex>
          <Actionsheet isOpen={isOpen} onClose={() => setOnOpen(false)}>
            <Actionsheet.Content>
              <Box w="100%" px={4} justifyContent="center">
                <Text fontSize="16" fontWeight="bold" color="gray.500" _dark={{
                color: "gray.300"
              }}>
                  Filter
                </Text>
              </Box>
              <VStack w="100%" px={4}>
                <Box>
                  <FormControl isInvalid={verifPelaporanScreen.q.length > 0 && verifPelaporanScreen.q.length < 3} mb="1">
                    <FormControl.Label>Pencarian</FormControl.Label>
                    <Input placeholder="Ketik disini..." w="100%" value={verifPelaporanScreen.q} onChangeText={txt => onUpdateInput('q', txt)} />
                    <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                      3 characters minimum.
                    </FormControl.ErrorMessage>
                  </FormControl>
                  <FormControl mb="1">
                    <FormControl.Label>Tanggal</FormControl.Label>
                    <RangeDateCustom startDt={verifPelaporanScreen.start_dt} endDt={verifPelaporanScreen.end_dt} changeStartDt={(date) => onUpdateInput('start_dt', date)} changeEndDt={(date) => onUpdateInput('end_dt', date)} />
                  </FormControl>
                  <FormControl mb="1">
                    <FormControl.Label>Status</FormControl.Label>
                    <Select 
                      variant='filled' 
                      borderColor="primary.900" 
                      minWidth="200" 
                      accessibilityLabel="Pilih Status" 
                      placeholder="Pilih Status" 
                      _selectedItem={{endIcon: <CheckIcon size="5"/>}}
                      selectedValue={verifPelaporanScreen.filter_status}
                      onValueChange={itemValue => onUpdateInput('filter_status', itemValue)} 
                      my={2}>
                        <Select.Item label="All" value="0" />
                        <Select.Item label="Batal" value="20" />
                        <Select.Item label="Asignment" value="30" />
                        <Select.Item label="Progres" value="40" />
                        <Select.Item label="Selesai" value="50" />
                      </Select>
                  </FormControl>
                  </Box>
                  <Box my={1}>
                    <Button isLoading={verifPelaporanScreen.loading} onPress={() => handleGetData(id_item)} spinnerPlacement="end" isLoadingText="Loading..." shadow={3} colorScheme="primary">Terapkan</Button>
                  </Box>
              </VStack>
            </Actionsheet.Content>
          </Actionsheet>
          </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  verifPelaporanScreen: makeSelectVerifPelaporanScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetResult: (id_item) => dispatch(getPelaporan(id_item)),
    onGetMoreResult: (id_item) => dispatch(getMorePelaporan(id_item)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(VerifPelaporanScreen);