import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the WorkOrderScreen state domain
 */

const selectWorkOrderScreenDomain = state =>
  state.workOrderScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by WorkOrderScreen
 */

const makeSelectWorkOrderScreen = () =>
  createSelector(
    selectWorkOrderScreenDomain,
    substate => substate,
  );

export default makeSelectWorkOrderScreen;
export { selectWorkOrderScreenDomain };
