import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the PelaporanDetailInputScreen state domain
 */

const selectPelaporanDetailInputScreenDomain = state =>
  state.pelaporanDetailInputScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by PelaporanDetailInputScreen
 */

const makeSelectPelaporanDetailInputScreen = () =>
  createSelector(
    selectPelaporanDetailInputScreenDomain,
    substate => substate,
  );

export default makeSelectPelaporanDetailInputScreen;
export { selectPelaporanDetailInputScreenDomain };
