import React, {useRef, useState} from "react";
import {VStack, HStack, Text, IconButton, CloseIcon, Box, PresenceTransition, Alert } from 'native-base';
import { commonStyle } from "../styles";
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

const MyAlert = (props) => {
  const {title, status} = props;
  const [show, setShow] = useState(true);
  return (
    <Box w="100%">
      <PresenceTransition visible={show} initial={{
      opacity: 0
    }} animate={{
      opacity: 1,
      transition: {
        duration: 250
      }
    }}>
        <Alert w="100%" status={status} variant="left-accent">
          <VStack space={2} flexShrink={1} w="100%">
            <HStack flexShrink={1} space={2} justifyContent="space-between">
              <HStack space={2} flexShrink={1}>
                <Alert.Icon mt="1" />
                <Text fontSize="md" color="coolGray.800">
                  {title}
                </Text>
              </HStack>
              <IconButton variant="unstyled" _focus={{
                  borderWidth: 0
                }} icon={<CloseIcon size="3" />} _icon={{
                  color: "coolGray.600"
                }} onPress={() => setShow(false)} />
            </HStack>
          </VStack>
        </Alert>
      </PresenceTransition>
    </Box>
  );
}

export default MyAlert;