export const DEFAULT_ACTION = 'screen/ApprovalMaintainFormScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/ApprovalMaintainFormScreen/UPDATE_INPUT';

export const GET_APPROVAL_DETAIL = 'screen/ApprovalMaintainFormScreen/GET_APPROVAL_DETAIL';
export const GET_APPROVAL_DETAIL_LOADING = 'screen/ApprovalMaintainFormScreen/GET_APPROVAL_DETAIL_LOADING';
export const GET_APPROVAL_DETAIL_SUCCESS = 'screen/ApprovalMaintainFormScreen/GET_APPROVAL_DETAIL_SUCCESS';
export const GET_APPROVAL_DETAIL_FAIL = 'screen/ApprovalMaintainFormScreen/GET_APPROVAL_DETAIL_FAIL';

export const SUBMIT_APPROVAL = 'screen/ApprovalMaintainFormScreen/SUBMIT_APPROVAL';
export const SUBMIT_APPROVAL_LOADING = 'screen/ApprovalMaintainFormScreen/SUBMIT_APPROVAL_LOADING';
export const SUBMIT_APPROVAL_SUCCESS = 'screen/ApprovalMaintainFormScreen/SUBMIT_APPROVAL_SUCCESS';
export const SUBMIT_APPROVAL_FAIL = 'screen/ApprovalMaintainFormScreen/SUBMIT_APPROVAL_FAIL';

export const GET_VERIF_ASSET = 'screen/ApprovalMaintainFormScreen/GET_VERIF_ASSET';
export const GET_VERIF_ASSET_LOADING = 'screen/ApprovalMaintainFormScreen/GET_VERIF_ASSET_LOADING';
export const GET_VERIF_ASSET_SUCCESS = 'screen/ApprovalMaintainFormScreen/GET_VERIF_ASSET_SUCCESS';
export const GET_VERIF_ASSET_FAIL = 'screen/ApprovalMaintainFormScreen/GET_VERIF_ASSET_FAIL';