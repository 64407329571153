import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import api from '../../util/api';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  // console.log("act:", name, value);
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function login(navigation, user_agent, ip_address, token_device) {
  console.log('masuk action');
  return {
    type: constants.DO_LOGIN,
    navigation,
    user_agent,
    ip_address,
    token_device
  };
}

export function loginSuccess(result) {
  console.log("user:", result);
  // save token
  api.setToken(result.token);
  
  return {
    type: constants.DO_LOGIN_SUCCESS,
    result
  };
}

export function loginFail() {
  return {
    type: constants.DO_LOGIN_FAIL
  };
}

export function getUserPrivileges(id_akun) {
  return {
    type: constants.GET_USER_PRIVILEGES,
    id_akun
  };
}

export function getUserPrivilegesLoading() {
  return {
    type: constants.GET_USER_PRIVILEGES_LOADING
  };
}

export function getUserPrivilegesFail(error) {
  return {
    type: constants.GET_USER_PRIVILEGES_FAIL,
    error
  };
}

export function getUserPrivilegesSuccess(results) {
  console.log("privileges:", results);
  api.setUserPrivilege(results);
  return {
    type: constants.GET_USER_PRIVILEGES_SUCCESS,
    results
  };
}

export function setUserNotif(notif) {
  return {
    type: constants.SET_USER_NOTIF,
    notif
  };
}