import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the WelcomeScreen state domain
 */

const selectGlobalVarDomain = state =>
  state.globalVar || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by WelcomeScreen
 */

const makeSelectGlobalVar = () =>
  createSelector(
    selectGlobalVarDomain,
    substate => substate,
  );

export default makeSelectGlobalVar;
export { selectGlobalVarDomain };
