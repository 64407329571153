import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import api from '../../util/api';
  import helper from '../../util/helper';
  import history from '../../util/history';
  // import * as RootNavigation from '../../../RootNavigation';
  
  import makeSelectTaskPreventifFormScreen from './selector';
import { getDetailTodoFail, getDetailTodoSuccess, submitPreventifFail, submitPreventifSuccess } from './actions';
  
  export function* doGetPreventifTodo(action) {
    const {id_realisasi_preventif, id_inventaris} = action;
    try {
      const response = yield call(api.preventif.getPreventifTodo, id_realisasi_preventif, id_inventaris);
      yield put(
        getDetailTodoSuccess(response.data),
      );
      console.log("form-res:", response.data);
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getDetailTodoFail(err));
    }
  }

  export function* doSubmitPreventif(action) {
    const {id_branch, origin_screen} = action;
    const dataset = yield select(makeSelectTaskPreventifFormScreen());
    try {
      const params = {
          id_preventif_exec: dataset.id_preventif_exec,
          id_inventaris: dataset.id_inventaris,
          id_realisasi_preventif: dataset.id_realisasi_preventif,
          id_item: dataset.id_ruang,
          id_condition: dataset.id_condition,
          attachments: dataset.assets,
          description: dataset.description,
      };

      // console.log(params);

      const response = yield call(api.preventif.submitPreventif, params);
      yield put(
        submitPreventifSuccess(response.data),
      );
      helper.successToast("Data berhasil disimpan");
      console.log("res:", response.data);

      // // save current pelaporan
      // if(origin_screen == 'pelaporan_detail'){
      //   api.saveCurrInputPelaporan(dataset.id_aset, response.data.id_pelaporan).then(() => {
      //     history.replace(origin_screen, {id_branch: id_branch, id_item: dataset.id_item}); 
      //   });
      // }else{
      //   history.replace(origin_screen);
      // }
      
      setTimeout(() => {
        history.replace('preventif-detail',{id_realisasi_preventif: dataset.id_realisasi_preventif, readOnly: false}); 
      }, 1000);
  
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal Menyimpan data, silahkan hub admin");
      yield put(submitPreventifFail(err));
    }
  }

  // Individual exports for testing
  export default function* taskPreventifFormScreenSaga() {
    yield all([
      takeLatest(constants.GET_DETAIL_TODO, doGetPreventifTodo),
      takeLatest(constants.SUBMIT_PREVENTIF, doSubmitPreventif),
    ]);
  }
  