import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}


export function addImage(asset) {
  return {
    type: constants.ADD_IMAGE,
    asset
  };
}

export function delImage(idx) {
  return {
    type: constants.DEL_IMAGE,
    idx
  };
}

export function addFile(asset) {
  return {
    type: constants.ADD_FILE,
    asset
  };
}

export function delFile(idx) {
  return {
    type: constants.DEL_FILE,
    idx
  };
}

export function submitVendorOffer() {
  return {
    type: constants.SUBMIT_OFFER,
  };
}

export function submitVendorOfferFail(error) {
  return {
    type: constants.SUBMIT_OFFER_FAIL,
    error
  };
}

export function submitVendorOfferSuccess(results) {
  return {
    type: constants.SUBMIT_OFFER_SUCCESS,
    results
  };
}

export function getVendorOffer(id_penawaran_request) {
  return {
    type: constants.GET_VENDOR_OFFER,
    id_penawaran_request
  };
}

export function getVendorOfferLoading() {
  return {
    type: constants.GET_VENDOR_OFFER_LOADING
  };
}

export function getVendorOfferFail(error) {
  return {
    type: constants.GET_VENDOR_OFFER_FAIL,
    error
  };
}

export function getVendorOfferSuccess(results) {
  return {
    type: constants.GET_VENDOR_OFFER_SUCCESS,
    results
  };
}