import React, {memo} from "react";
import { commonStyle } from "../styles";
import {
    Icon,
    HStack, 
    Avatar, 
    Heading, 
    VStack, 
    Box, 
    Center,
    Stack,
    Badge,
    Input,
    Image,
    IconButton,
    Pressable,
    Divider,
    HamburgerIcon,
    Menu,
    Text, 
    Flex } from 'native-base';
  import {FaCalendarAlt, FaEnvelope } from 'react-icons/fa';
  import moment from "moment";
  import helper from "../util/helper";

const CardTaskList = (props) => {
  const {data, access} = props;
  return (
    <Box width="100%" backgroundColor="white" shadow={3} borderRadius="lg">
      <VStack>
        <Box px={5} py={3} backgroundColor="#ffd13c" borderTopRadius="lg">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Icon color="darkText" as={<Box><FaCalendarAlt/></Box>} size={5}></Icon>
            </Box>
            <Box flex={1} ml={1}>
              <Text color="darkText" fontWeight={500} >{moment(data.date_create, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")}</Text>
              <Text color="muted.500" fontSize="xs" isTruncated w="100%">{data.tipe_task}</Text>
            </Box>
            <Box ml={3}>
              <Badge rounded="lg" colorScheme="danger">Created</Badge>
            </Box>
          </Stack>
        </Box>
        <Box p={5}>
          <VStack>
            <Flex flexDirection="row">
              <Box>
                <Center>
                  <Avatar size="md" bg="primary.900" mr="1">
                      {helper.getInitials(data.nm_gedung)}
                  </Avatar>
                </Center>
              </Box>
              <VStack width="90%">
                <Text ml={3} fontSize="lg" color={commonStyle.primaryColor} isTruncated width="80%" fontWeight="bold">
                  {data.nm_gedung}
                </Text>
                <Text ml={3} fontSize="sm" color="muted.500" isTruncated width="80%">
                  {data.addres}
                </Text>
              </VStack>
            </Flex>
            <Flex flexDirection="row" mt={4}>
              <Box>
                <Center>
                  <Avatar size="md" bg="primary.900" mr="1">
                      {helper.getInitials(data.name_employee)}
                  </Avatar>
                </Center>
              </Box>
              <VStack width="90%" justifyContent="center">
                <Text ml={3} fontSize="lg" color={commonStyle.primaryColor} isTruncated width="80%" fontWeight="bold">
                  {data.name_employee}
                </Text>
                {/* <Text ml={3} fontSize="sm" color="muted.500" isTruncated width="80%">
                  {data.name_employee}
                </Text> */}
              </VStack>
            </Flex>
            {/* <Box mt={5} backgroundColor="light.200" borderRadius="lg" width="100%">
              <HStack>
                <Flex flex={1} p={2}>
                  <Flex flexDirection="row" alignItems="center">
                    <Image source={require('../images/logo.png')} size="2xs" alt='Logo' />
                    <Text ml={2} color="darkText" isTruncated maxW="140" width="80%" fontWeight="bold">Ruang Toraja oisdosifisfo sdfsdffdsf</Text>
                  </Flex>
                </Flex>
                <Flex alignItems="center" justifyContent="center">
                    <HStack alignItems="center">
                      <Text fontWeight="500" color="muted.500" mx={2}>No Tugas</Text>
                      <Box borderRadius="lg" backgroundColor="amber.500" height="10" justifyContent="center" alignItems="center">
                        <Text color="white" fontWeight="bold" fontSize="lg" m={2} alignSelf="center">101</Text>
                      </Box>
                    </HStack>
                </Flex>
              </HStack>
            </Box> */}
            <Box mt={5} backgroundColor="light.200" borderRadius="lg" width="100%">
              <HStack>
                <Flex flex={1} p={2}>
                  <Flex flexDirection="row" alignItems="center">
                    <Image source={require('../images/logo.png')} size="2xs" alt='Logo' />
                    <Text ml={2} color="darkText" isTruncated maxW="300" width="90%" fontWeight="bold">{data.nm_ruang}</Text>
                  </Flex>
                </Flex>
              </HStack>
            </Box>
            
          </VStack>
        </Box>
      </VStack>
  </Box>
  );
}

export default memo(CardTaskList);