import React, { Component, useState, useEffect } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectNotificationDetailScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView, TouchableWithoutFeedback, useWindowDimensions, SafeAreaView } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Image, 
  Flex,
  ScrollView, 
  Button} from 'native-base';
import moment from 'moment';
import { ContainerPage } from '../../components/ContainerPage';
import MyAlert from '../../components/MyAlert';
import { commonStyle } from '../../styles';
import { getNotifications, getUserPrivileges, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';

export function NotificationDetailScreen({
  dispatch,
  route,
  navigation,
  notificationDetailScreen,
  onUpdateInput,
  onGetNotifications,
  location
}){

  const history = useHistory();
  const[userData, setUserData] = useState();
  const[notif, setNotif] = useState();

  useEffect(() => {
      // console.log("kiriman:", location.state);
      console.log("notif:", history.location.state);
      if (history.location.state && history.location.state.data_notif) {
        setNotif(history.location.state.data_notif);
      }
      const user = api.getUserData();
      setUserData(user);
      console.log(notif);
  },[]);

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'notif', title: 'Notifikasi'}}>
          <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
            <VStack space={3} p={3}>
              {!notif && <MyAlert status="info" title="Belum ada notifikasi."/>}
              {notif && 
              <Box backgroundColor={commonStyle.baseColor} p={5} shadow={3} borderRadius="lg">
                <Text color="muted.500" mb={3}>{moment(notif.date_create, "DD-MM-YYYY").format("ddd, DD MMM YYYY")}</Text>
                <Text color="darkText" mb={2} fontWeight="bold">{notif.subject}</Text>
                <Text color="darkText" textAlign="justify">
                  {notif.body}
                </Text>
              </Box>
              }
            </VStack>
          </ScrollView>
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  notificationDetailScreen: makeSelectNotificationDetailScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetNotifications: id_akun =>  dispatch(getNotifications()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(NotificationDetailScreen);