export const DEFAULT_ACTION = 'screen/TaskScannerScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/TaskScannerScreen/UPDATE_INPUT';

export const RESET_SCAN = "screen/TaskScannerScreen/RESET_SCAN";
export const SCAN_RESULT = "screen/TaskScannerScreen/SCAN_RESULT";
export const SCANNED = "screen/TaskScannerScreen/SCANNED";

export const GET_ROOM_ASSET = 'screen/TaskScannerScreen/GET_ROOM_ASSET';
export const GET_ROOM_ASSET_LOADING = 'screen/TaskScannerScreen/GET_ROOM_ASSET_LOADING';
export const GET_ROOM_ASSET_SUCCESS = 'screen/TaskScannerScreen/GET_ROOM_ASSET_SUCCESS';
export const GET_ROOM_ASSET_FAIL = 'screen/TaskScannerScreen/GET_ROOM_ASSET_FAIL';