import React from "react";
import { commonStyle } from "../styles";
import {
    Icon,
    HStack, 
    Avatar, 
    Heading, 
    VStack, 
    Box, 
    Center,
    Stack,
    Badge,
    Input,
    Image,
    IconButton,
    Pressable,
    Divider,
    HamburgerIcon,
    Skeleton,
    Menu,
    Text, 
    Flex } from 'native-base';

const TaskDetailPreloader = (props) => {
  const {data} = props;
  
  return (
      <VStack minW="300px" space={7} mt={2} mb={35} mx={3}>
        <Box mt={5}>
          <Skeleton borderRadius="lg" startColor="primary.100" />
        </Box>
        <Box p={5} borderRadius="lg" backgroundColor={commonStyle.baseColor}>
          
            <VStack>
              <Center>
                <Skeleton size="60" rounded="full" />
              </Center>
              <Center>
                <Skeleton.Text alignItems="center" lines={2} mt={3} width="70%" />
              </Center>
              <Center>
                <Skeleton.Text alignItems="center" lines={1} mt={10} width="70%" />
              </Center>
            </VStack>
        </Box>

        <Box borderRadius="lg" backgroundColor={commonStyle.baseColor}>
          <VStack m={4} space={2}>
            <Skeleton borderRadius="lg" startColor="primary.100" />
            <Skeleton borderRadius="lg" startColor="primary.100" />
            <Skeleton borderRadius="lg" startColor="primary.100" />
          </VStack>
        </Box>
      </VStack>
  );
}

export default TaskDetailPreloader;