import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the WelcomeScreen state domain
 */

const selectWelcomeScreenDomain = state =>
  state.welcomeScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by WelcomeScreen
 */

const makeSelectWelcomeScreen = () =>
  createSelector(
    selectWelcomeScreenDomain,
    substate => substate,
  );

export default makeSelectWelcomeScreen;
export { selectWelcomeScreenDomain };
