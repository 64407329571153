import React from "react";
import { Badge, VStack, Box, Button, Icon } from 'native-base';
import { commonStyle } from "../styles";
import helper from "../util/helper";

const BadgeStatus = (props) => {
  const {status} = props;

  const labelView = (str) => {
    let textStatus = str;
    if(!helper.isEmpty(str)) textStatus = textStatus.toLowerCase();
    switch (textStatus) {
      case 'created':
        return <Badge w="40" rounded="lg" backgroundColor="#F87171" _text={{color:"white"}}>{str}</Badge>;
      case 'progres':
        return <Badge w="40" rounded="lg" backgroundColor="#F59E0B" _text={{color:"white"}}>{str}</Badge>;
      case 'selesai':
        return <Badge w="40" rounded="lg" backgroundColor="#10B981" _text={{color:"white"}}>{str}</Badge>;
      case 'finish':
        return <Badge w="40" rounded="lg" backgroundColor="#10B981" _text={{color:"white"}}>{str}</Badge>;
      case 'batal':
        return <Badge w="40" rounded="lg" backgroundColor="#F87171" _text={{color:"white"}}>{str}</Badge>;
      case 'reject':
        return <Badge w="40" rounded="lg" backgroundColor="#F87171" _text={{color:"white"}}>{str}</Badge>;
      case 'rejected':
        return <Badge w="40" rounded="lg" backgroundColor="#F87171" _text={{color:"white"}}>{str}</Badge>;
      case 'approve':
        return <Badge w="40" rounded="lg" backgroundColor="#10B981" _text={{color:"white"}}>{str}</Badge>;
      case 'approved':
        return <Badge w="40" rounded="lg" backgroundColor="#10B981" _text={{color:"white"}}>{str}</Badge>;
      case 'done':
        return <Badge w="40" rounded="lg" backgroundColor="#10B981" _text={{color:"white"}}>{str}</Badge>;
      default:
        return <Badge w="40" rounded="lg" backgroundColor="#2689CB" _text={{color:"white"}}>{str}</Badge>;
    }
  }

  return labelView(status);
}

export default BadgeStatus;