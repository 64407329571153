import React, { Component, useState, useEffect, forwardRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectWorkOrderScreen from './selector';
import reducer from './reducer';
import saga from './saga';

import {
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Stack,
  Badge,
  Input,
  Image,
  IconButton,
  Select,
  CheckIcon,
  Text,
  FlatList,
  ScrollView, 
  Flex } from 'native-base';
import {FaCalendarPlus } from 'react-icons/fa';
import {MdCalendarToday} from 'react-icons/md';
import { ContainerPage } from '../../components/ContainerPage';
import CardWorkOrderList from '../../components/CardWorkOrderList';
import CardListPreloader from '../../components/CardListPreloader';
import MyAlert from '../../components/MyAlert';
import DatePicker from "react-datepicker";
import { commonStyle } from '../../styles';
import { getWorkOrder, updateInput } from './actions';
import moment from 'moment';
import helper from '../../util/helper';
import api from '../../util/api';

export function WorkOrderScreen({
  dispatch,
  navigation,
  workOrderScreen,
  onUpdateInput,
  onGetResult
}){
  const [screenAccess, setScreenAccess] = useState([]);
  const[backClickCount, setBackClickCount] = useState(0);

  useEffect(() => {
    // get user privileges
    const menus = api.getUserPrivileges();
    const allAccess = helper.generatePrivileges(menus);
    setScreenAccess(allAccess);

    onGetResult();
  },[]);

  const onChangeStartDt = (selectedDate) => {
    const currentDate = selectedDate;
    onUpdateInput("start_dt", currentDate);
    onGetResult();
  };
  const onChangeEndDt = (selectedDate) => {
    const currentDate = selectedDate;
    onUpdateInput("end_dt", currentDate);
    onGetResult();
  };

  const handleOnRefresh = () => {
    onGetResult();
  }

  const getItem = (_data, index) => ({..._data[index]});
  
  const getItemCount = _data => workOrderScreen.results.length;

  const getItemLayout = (data, index) => ({length: 280, offset: 280 * index, index});

  const renderItem = ({item}) => {
    return (
      <Box px={3} mb={5}>
        <CardWorkOrderList data={item} access={screenAccess} navigation={navigation} />
      </Box>
    );
  }

  const InputStartDate = forwardRef(({ value, onClick }, ref) => (
    <Input 
      value={moment(workOrderScreen.start_dt).format("DD/MM/YYYY")} 
      isReadOnly="true"
      onFocus={onClick} 
      borderRadius="lg" 
      variant="filled"
      ref={ref} 
      borderColor="primary.900" 
      InputLeftElement={<Icon as={<Box><MdCalendarToday/></Box>} size={5} ml="2" color="muted.400" />} 
      />
  ));

  const InputEndDate = forwardRef(({ value, onClick }, ref) => (
    <Input 
      value={moment(workOrderScreen.end_dt).format("DD/MM/YYYY")} 
      isReadOnly="true"
      onFocus={onClick} 
      borderRadius="lg" 
      variant="filled"
      ref={ref} 
      borderColor="primary.900" 
      InputLeftElement={<Icon as={<Box><MdCalendarToday/></Box>} size={5} ml="2" color="muted.400" />} 
      />
  ));

  return (
        <ContainerPage>
          <Box mt={3} mx={3} mb={3}>
            <Heading size="md">Work Order</Heading>
            <Text fontWeight="bold" mb="1">Periode</Text>
            <Stack direction="row" justifyContent="space-between">
            <Box flex={1}>
              <DatePicker customInput={<InputStartDate/>} portalId='root-portal' className='input-datepicker' popperClassName='pooper-datepicker' selected={workOrderScreen.start_dt} onChange={(date) => onChangeStartDt(date)} maxDate={workOrderScreen.end_dt} />
            </Box>
            <Text fontWeight="bold" mx={2} alignSelf="center">s.d</Text>
            <Box flex={1}>
              <DatePicker customInput={<InputEndDate/>} portalId='root-portal' className='input-datepicker' popperClassName='pooper-datepicker' selected={workOrderScreen.end_dt} onChange={(date) => onChangeEndDt(date)} minDate={workOrderScreen.start_dt} />
            </Box>
            </Stack>
          </Box>
          <Flex flex={1}>
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              {
                workOrderScreen.loading && <VStack space={7} px={3} mt={2} mb={35}>
                  {[...Array(3)].map((x, i) => (
                    <CardListPreloader key={i} />
                  ))}
                </VStack>
              }
              {
                !workOrderScreen.loading &&
                <VStack space={7} px={3} mt={2} mb={35}>
                  {workOrderScreen.results.length == 0 && <MyAlert status="info" title="Maaf, Hasil belum ditemukan."/>}
                  {workOrderScreen.results.length > 0 && workOrderScreen.results.map((row, i) => (
                    <CardWorkOrderList key={i} data={row} access={screenAccess} navigation={navigation} />
                  ))}
                </VStack>
              }
            </ScrollView>
          </Flex>
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  workOrderScreen: makeSelectWorkOrderScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetResult: () => dispatch(getWorkOrder()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(WorkOrderScreen);