import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectVerifPelaporanInputScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView, RefreshControl, BackHandler, TouchableWithoutFeedback } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Pressable,
  TextArea,
  FormControl,
  Divider,
  Image,
  Modal,
  Stack, 
  Flex,
  Radio,
  ScrollView,
  Select,
  CheckIcon, 
  Button} from 'native-base';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import Files from 'react-files';
import 'react-html5-camera-photo/build/css/index.css';
import {MdAddAPhoto, MdDelete} from 'react-icons/md';

import {FaCalendarAlt, FaCheckCircle } from 'react-icons/fa';
import { ContainerPage } from '../../components/ContainerPage';
import ImageSlider from '../../components/ImageSlider';
import MyAlertDialog from '../../components/MyAlertDialog';
import MyTextArea from '../../components/MyTextArea';
import { commonStyle } from '../../styles';
import { addImage, defaultAction, delImage, getVerifyPelaporan, submitPelaporan, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';

export function VerifPelaporanInputScreen({
  dispatch,
  location,
  navigation,
  verifPelaporanInputScreen,
  onAddImage,
  onDelImage,
  onUpdateInput,
  onGetVerifyPelaporan,
  onSubmitVerifyPelaporan
}){
  const[userData, setUserData] = useState();
  const {id_korektif, id_ruang, readOnly} = location.state;
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const [actionType, setActionType] = useState("20");
  const [openCamera, setOpenCamera] = useState(false);
  const [openModalCamera, setOpenModalCamera] = useState(false);

  useEffect(() => {
      // reset params
      dispatch(defaultAction());
      // const token = api.getUserData().then(user => {
      //   console.log("saved_user:", user);
      //   console.log("initial:", helper.getInitials(user.user_name));
      //   setUserData(user);
      // });
      console.log("readonly:", readOnly);
      onUpdateInput('id_item', id_ruang);
      onGetVerifyPelaporan(id_korektif);
  },[]);
  
  useEffect(() => {
    onUpdateInput('responseState','');
    
    if(verifPelaporanInputScreen.responseState == 'approved'){
      helper.successToast("Anda telah menyetujui laporan ini");
    }
    if(verifPelaporanInputScreen.responseState == 'done'){
      helper.successToast("Anda telah menyelesaikan laporan ini");
    }
    if(verifPelaporanInputScreen.responseState == 'rejected'){
      helper.warningToast("Anda telah menolak laporan ini")
    }
    if(verifPelaporanInputScreen.responseState == 'error'){
      helper.errorToast("Verifikasi laporan belum berhasil")
    }
  },[verifPelaporanInputScreen.responseState]);

  const handleOnRefresh = () => {
    onGetVerifyPelaporan(id_korektif);
  };

  const handleCameraOpen = () => {
    setOpenModalCamera(true);
    setTimeout(() => {
      setOpenCamera(true);
    }, 300);
  }
  
  const handleOnSubmit = () => {
    if(helper.isEmpty(verifPelaporanInputScreen.notes) || verifPelaporanInputScreen.notes.length < 10){
      helper.warningToast("Isikan hasil verifikasi, Minimal 10 karakter.");
      return;
    }

    if(helper.isEmpty(verifPelaporanInputScreen.assets_verif) || verifPelaporanInputScreen.assets_verif.length < 1){
      helper.warningToast("Input foto hasil verifikasi terlebih dahulu!");
      return;
    }

    // setActionType(action_type);
    setIsOpen(true);
  };

  const handleChoosePhoto = async (data) => {
    setOpenCamera(false);
    console.log("img:", data);
    setTimeout(() => {
      setOpenModalCamera(false);
    }, 300);
    if(verifPelaporanInputScreen.assets_verif.length == 4){
      helper.infoToast("Anda melebihi batas maksimum yakni 4 foto.");
      return;
    }

    try {
      if(!helper.isEmpty(data)) onAddImage(data);
      // const granted = await PermissionsAndroid.request(
      //   PermissionsAndroid.PERMISSIONS.CAMERA,
      //   {
      //     title: "App Camera Permission",
      //     message:"App needs access to your camera",
      //     buttonNeutral: "Ask Me Later",
      //     buttonNegative: "Cancel",
      //     buttonPositive: "OK"
      //   }
      // );
      // if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      //   console.log("Camera permission given");
      //   launchCamera({ 
      //     mediaType: 'photo',
      //     maxWidth: 800,
      //     maxHeight: 800,
      //     includeBase64: true,
      //     cameraType: 'back'
      //   }, (response) => {
      //     console.log(response);
      //     if (response) {
      //       if(!helper.isEmpty(response.assets)) onAddImage(response.assets[0]);
      //     }
      //   });
      // } else {
      //   console.log("Camera permission denied");
      // }
    } catch (err) {
      console.warn(err);
    }
  };

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleFileError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
    helper.errorToast(error.message);
  }

  const handleFileChange = (files) => {
    console.log(files);
    const file = files[0];
    getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        const params = {
          base64: result,
          type: file.type,
          name: file.name,
        }

        if(verifPelaporanInputScreen.assets_verif.length == 4){
          helper.infoToast("Anda melebihi batas maksimum yakni 4 foto.");
          return;
        }
        
        onAddImage(params.base64);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleDoSubmit = () => {
    setIsOpen(false);
    onSubmitVerifyPelaporan(actionType);
  };

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'verif-pelaporan', title: 'Verifikasi Kerusakan', params:{id_item: id_ruang}}}>
          <Flex flex={1}>
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              <VStack space={7} mt={2} mb={35} mx={3}>
                <Box mt={5}>
                  <Center>
                    <HStack space={2} justifyContent="center" alignItems="center">
                      <Icon as={<Box><FaCheckCircle/></Box>} size="8" color="success.700" />
                      <Text color="darkText" fontWeight="bold" isTruncated maxWidth="90%" fontSize="lg">
                        {verifPelaporanInputScreen.pelaporan && verifPelaporanInputScreen.pelaporan.nm_ruangan }
                      </Text>
                    </HStack>
                  </Center>
                </Box>
                <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                  
                    <VStack>
                      <Center>
                        <Avatar size="lg" bg="primary.900" mb={4}>
                        {verifPelaporanInputScreen.pelaporan && helper.getInitials(verifPelaporanInputScreen.pelaporan.nm_ruangan)}
                        </Avatar>
                      </Center>
                      <Center>
                        <Text color="darkText" isTruncated maxWidth="90%" fontSize="md" fontWeight="bold">{verifPelaporanInputScreen.pelaporan && verifPelaporanInputScreen.pelaporan.nm_gedung }</Text>
                      </Center>
                      <Center>
                        <Text color="muted.400" isTruncated maxWidth="90%" fontSize="sm">{verifPelaporanInputScreen.pelaporan && verifPelaporanInputScreen.pelaporan.nm_grp_item } - {verifPelaporanInputScreen.pelaporan && verifPelaporanInputScreen.pelaporan.nm_ruangan}</Text>
                      </Center>
                      <Center>
                        <Text mt={10} color="darkText" fontSize="sm">{verifPelaporanInputScreen.pelaporan && verifPelaporanInputScreen.pelaporan.date_create }</Text>
                      </Center>
                    </VStack>
                </Box>
                {/* {[...Array(2)].map((x, i) => ( */}
                <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    <VStack>
                      <Flex flex={1} height="300px" mb="2.5">
                      {verifPelaporanInputScreen.assets.length > 0 && <ImageSlider images={verifPelaporanInputScreen.assets}/>}
                      </Flex>
                      <Box mb={2}>
                        <Text fontWeight="bold">{verifPelaporanInputScreen.pelaporan && verifPelaporanInputScreen.pelaporan.nm_inventaris }</Text>
                      </Box>
                      <Box mb={2}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Box>
                            <Icon color="muted.400" as={<Box><FaCalendarAlt/></Box>} size={3}></Icon>
                          </Box>
                          <Box flex={1} ml={1}>
                            <Text color="muted.400" fontWeight={500} fontSize="xs">{verifPelaporanInputScreen.pelaporan && verifPelaporanInputScreen.pelaporan.date_create }</Text>
                          </Box>
                        </Stack>
                      </Box>
                      <Box mb={2}>
                        <Text color="darkText" textAlign="justify">
                          {verifPelaporanInputScreen.pelaporan && verifPelaporanInputScreen.pelaporan.description }
                        </Text>
                      </Box>
                      <Flex>
                        <Divider bg="muted.300" thickness="1" my="2" orientation="horizontal" />
                        <Box mb={2}>
                          <FormControl mb="1" isRequired>
                            <FormControl.Label>Hasil Verifikasi</FormControl.Label>
                            {(verifPelaporanInputScreen.id_verifikasi_kerusakan && readOnly) && <Text mb={2}>{verifPelaporanInputScreen.desc_verifikasi_pelaporan}</Text>}
                            {/* {!readOnly && <TextArea value={verifPelaporanInputScreen.notes} h={40} placeholder="Ketik disini..." onChange={txt => onUpdateInput('notes', txt)} onChangeText={txt => onUpdateInput('notes', txt)} borderRadius="lg" w="100%" />} */}
                            {!readOnly && <MyTextArea value={verifPelaporanInputScreen.notes} onChangeText={txt => onUpdateInput('notes', txt)} />}
                          </FormControl>
                        </Box>
                        {!readOnly && 
                        <Box mb={2}>
                          <FormControl mb="1" isRequired>
                            <FormControl.Label>Status</FormControl.Label>
                            <Select 
                            variant='filled' 
                            borderColor="primary.900" 
                            selectedValue={actionType}
                            minWidth="200" 
                            accessibilityLabel="Pilih Status" 
                            placeholder="Pilih Status" 
                            _selectedItem={{endIcon: <CheckIcon size="5"/>}} 
                            my={2}
                            onValueChange={itemValue => setActionType(itemValue)}>
                              <Select.Item label="Setujui" value="20" />
                              <Select.Item label="Tolak" value="10" />
                              <Select.Item label="Selesai" value="30" />
                            </Select>
                            {/* <Radio.Group 
                            name="statusOption" 
                            value={actionType} 
                            onChange={nextValue => setActionType(nextValue)} 
                            accessibilityLabel="pick a size">
                                <Stack direction="row" alignItems="center" justifyContent="space-between" space={4}>
                                  <Radio value="20" colorScheme="green" size="md" my={1}>
                                    Setujui
                                  </Radio>
                                  <Radio value="10" colorScheme="red" size="md" my={1}>
                                    Tolak
                                  </Radio>
                                  <Radio value="30" colorScheme="primary" size="md" my={1}>
                                    Selesai
                                  </Radio>
                                </Stack>
                              </Radio.Group>   */}
                          </FormControl>
                        </Box>
                        }
                      </Flex>
                    </VStack>
                </Box>

                <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                  <HStack mb={4}>
                    <Flex flex={1}>
                      <Text fontSize="md">Foto verifikasi</Text>
                    </Flex>
                    {!readOnly && 
                    <Box width="120px">
                      <Button onPress={() => handleCameraOpen()} leftIcon={<Icon as={<Box><MdAddAPhoto/></Box>} size="sm" />}>
                        Ambil Foto
                      </Button>
                    </Box>
                    }
                  </HStack>
                  <VStack space={3}>
                    <Box>
                    <Box justifyContent="center" alignItems="center" mt={2} p={2} flex={1} borderColor="muted.400" borderRadius="lg" borderWidth={1} borderStyle="dashed">
                      <Files
                        className='files-dropzone'
                        onChange={handleFileChange}
                        onError={handleFileError}
                        accepts={['image/png', 'image/jpg','image/jpeg']}
                        maxFileSize={(10000000 * 20)} // 20 MB
                        minFileSize={0}
                        clickable>
                        Drop files here or click to upload
                      </Files>
                    </Box>
                    {/* {openCamera == true && 
                        <Camera
                          onTakePhoto = { (dataUri) => { handleChoosePhoto(dataUri); } }
                          // onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
                          onTakePhotoAnimationDone = { (dataUri) => { handleTakePhotoAnimationDone(dataUri); } }
                          onCameraError = { (error) => { handleCameraError(error); } }
                          idealFacingMode = {FACING_MODES.ENVIRONMENT}
                          idealResolution = {{width: 640, height: 640}}
                          imageType = {IMAGE_TYPES.JPG}
                          imageCompression = {0.70}
                          isMaxResolution = {true}
                          isImageMirror = {false}
                          isSilentMode = {false}
                          isDisplayStartCameraError = {true}
                          isFullscreen = {false}
                          sizeFactor = {1}
                          onCameraStart = { (stream) => { handleCameraStart(stream); } }
                          onCameraStop = { () => { handleCameraStop(); } }
                        />
                      } */}
                    </Box>
                    {console.log('from_index1 : ', verifPelaporanInputScreen)}
                    {console.log('from_index2 : ', verifPelaporanInputScreen.assets_verif.length)}
                    {verifPelaporanInputScreen.assets_verif.length > 0  && 
                    verifPelaporanInputScreen.assets_verif.map((rowimage, imgx) => {
                      return (
                        <Box key={`img-${imgx}`} p={2} flex={1} borderColor="muted.400" borderRadius="lg" borderWidth={1}>
                          <Flex mb={2} justifyContent="center" alignItems="center">
                          <AspectRatio
                          ratio={{
                            base: 3 / 4,
                          }} height={{
                            base: 350,
                          }}>
                            <Image alt={`photo${imgx}`} resizeMode="contain" source={{uri: rowimage.path}}/>
                          </AspectRatio>
                          </Flex>
                          <HStack justifyContent="center" alignItems="center">
                            <Flex flex={1} flexDirection="column">
                              <Text fontSize="sm" fontWeight="bold">Foto {`${imgx+1}`}</Text>
                              {/* <Text fontSize="xs" color="muted.400">28-10-2022 09:40</Text> */}
                            </Flex>
                            {!readOnly &&
                            <Box>
                              <Pressable onPress={() => onDelImage(imgx)}>
                                <Icon as={<Box><MdDelete/></Box>} color="red.500" size="lg"></Icon>
                              </Pressable>
                            </Box>
                            }
                          </HStack>
                        </Box>
                        // <View>
                        //   <Image alt={`photo${imgx}`} style={styles.image} source={{uri: rowimage}}/>
                        // </View>
                      );
                    })
                    }
                  </VStack>
                </Box>

                {!readOnly && 
                <Box flex={1} mt={1}>
                  <Button shadow={3} isLoading={verifPelaporanInputScreen.loading_submit} spinnerPlacement="end" isLoadingText="Submitting..." colorScheme="primary" onPress={() => handleOnSubmit('20')}>Simpan</Button>
                </Box>
                }
                {/* ))} */}
              </VStack>
              <MyAlertDialog 
                  alertTitle="Verifikasi Laporan" 
                  alertDesc={`Apakah anda ingin ${actionType == '20' ? 'Menyetujui' : (actionType == '30' ? 'Menyelesaikan': 'Menolak')} laporan ini ?`}
                  alertNoLabel="Tidak"
                  alertYesLabel="Ya"
                  alertIsOpen={isOpen}
                  alertOnClose={onClose}
                  alertOnConfirm={() =>handleDoSubmit()}
                />
            </ScrollView>
          </Flex>

          <Modal isOpen={openModalCamera} onClose={() => setOpenModalCamera(false)}>
            <Modal.Content maxWidth="500px">
              <Modal.Header>Ambil Gambar</Modal.Header>
              <Modal.Body>
              {openCamera == true && 
              <Camera
                onTakePhoto = { (dataUri) => { handleChoosePhoto(dataUri); } }
                onTakePhotoAnimationDone = { (dataUri) => { handleTakePhotoAnimationDone(dataUri); } }
                onCameraError = { (error) => { handleCameraError(error); } }
                idealFacingMode = {FACING_MODES.ENVIRONMENT}
                idealResolution = {{width: 640, height: 800}}
                imageType = {IMAGE_TYPES.JPG}
                imageCompression = {0.70}
                isMaxResolution = {true}
                isImageMirror = {false}
                isSilentMode = {false}
                isDisplayStartCameraError = {true}
                isFullscreen = {false}
                sizeFactor = {1}
                onCameraStart = { (stream) => { handleCameraStart(stream); } }
                onCameraStop = { () => { handleCameraStop(); } }
              />
            }
              </Modal.Body>
            </Modal.Content>
          </Modal>

        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  verifPelaporanInputScreen: makeSelectVerifPelaporanInputScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetVerifyPelaporan: (id) => dispatch(getVerifyPelaporan(id)),
    onAddImage: asset => dispatch(addImage(asset)),
    onDelImage: idx => dispatch(delImage(idx)),
    onSubmitVerifyPelaporan: action_type => dispatch(submitPelaporan(action_type)),
  };
}

function handleTakePhotoAnimationDone (dataUri) {
  // Do stuff with the photo...
  // console.log('takePhoto');
}

function handleCameraError (error) {
  // console.log('handleCameraError', error);
}

function handleCameraStart (stream) {
  // console.log('handleCameraStart : ', stream);
}

function handleCameraStop () {
  // console.log('handleCameraStop');
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(VerifPelaporanInputScreen);