import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the PelaporanHistoryScreen state domain
 */

const selectPelaporanHistoryScreenDomain = state =>
  state.pelaporanHistoryScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by PelaporanHistoryScreen
 */

const makeSelectPelaporanHistoryScreen = () =>
  createSelector(
    selectPelaporanHistoryScreenDomain,
    substate => substate,
  );

export default makeSelectPelaporanHistoryScreen;
export { selectPelaporanHistoryScreenDomain };
