import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectPelaporanDetailInputScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView,RefreshControl, BackHandler, PermissionsAndroid, StyleSheet, View, Dimensions } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Pressable,
  TextArea,
  FormControl,
  Divider,
  Image,
  Modal,
  Flex,
  ScrollView, 
  Button} from 'native-base';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import Files from 'react-files';
import 'react-html5-camera-photo/build/css/index.css';
import {FaCheckCircle} from 'react-icons/fa';
import {MdAddAPhoto, MdDelete} from 'react-icons/md';
import { ContainerPage } from '../../components/ContainerPage';
import { commonStyle } from '../../styles';
import { addImage, defaultAction, delImage, getPelaporan, submitPelaporan, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';

export function PelaporanDetailInputScreen({
  dispatch,
  location,
  navigation,
  pelaporanDetailInputScreen,
  onUpdateInput,
  onAddImage,
  onDelImage,
  onSubmitPelaporan,
  onGetPelaporan
}){
  const {id_branch, id_item, id_aset, dtGedung, nm_aset, id_pelaporan, origin_screen, viewOnly} = location.state;
  const[userData, setUserData] = useState();
  const [photo, setPhoto] = useState(null);
  const [openCamera, setOpenCamera] = useState(false);
  const [openModalCamera, setOpenModalCamera] = useState(false);

  useEffect(() => {
    // reset params
    dispatch(defaultAction());

    onUpdateInput('id_pelaporan', id_pelaporan);
    onUpdateInput('nm_aset', nm_aset);
    onUpdateInput('id_item', id_item);
    onUpdateInput('id_aset', id_aset);
    if(!helper.isEmpty(dtGedung)){
      onUpdateInput('nm_ruangan', dtGedung.nm_ruangan);
    }
    if(id_pelaporan > 0){
      onGetPelaporan(id_pelaporan);
    }
    console.log("id_l", id_pelaporan);
  },[]);
  

  const handleChoosePhoto = async (data) => {
    setOpenCamera(false);
    console.log("img:", data);
    setTimeout(() => {
      setOpenModalCamera(false);
    }, 300);
    if(pelaporanDetailInputScreen.assets.length == 4){
      helper.infoToast("Anda melebihi batas maksimum yakni 4 foto.");
      return;
    }

    try {
      if(!helper.isEmpty(data)) onAddImage(data);
      // const granted = await PermissionsAndroid.request(
      //   PermissionsAndroid.PERMISSIONS.CAMERA,
      //   {
      //     title: "App Camera Permission",
      //     message:"App needs access to your camera",
      //     buttonNeutral: "Ask Me Later",
      //     buttonNegative: "Cancel",
      //     buttonPositive: "OK"
      //   }
      // );
      // if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      //   console.log("Camera permission given");
      //   launchCamera({ 
      //     mediaType: 'photo',
      //     maxWidth: 800,
      //     maxHeight: 800,
      //     includeBase64: true,
      //     cameraType: 'back'
      //   }, (response) => {
      //     console.log(response);
      //     if (response) {
      //       if(!helper.isEmpty(response.assets)) onAddImage(response.assets[0]);
      //     }
      //   });
      // } else {
      //   console.log("Camera permission denied");
      // }
    } catch (err) {
      console.warn(err);
    }
  };

  const handleSubmit = () => {
    if(helper.isEmpty(pelaporanDetailInputScreen.description) || pelaporanDetailInputScreen.description.length < 10){
      helper.warningToast("Isikan deskripsi kerusakan, Minimal 10 karakter.");
      return;
    }
    if(pelaporanDetailInputScreen.assets.length == 0){
      helper.warningToast("Bukti foto diperlukan min 1 foto.");
      return;
    }

    console.log('desc:',pelaporanDetailInputScreen.description.length);

    onSubmitPelaporan(id_branch, origin_screen);
  }

  const handleGetData = () => {
    if(id_pelaporan > 0){
      onGetPelaporan(id_pelaporan);
    }
  }

  const handleCameraOpen = () => {
    setOpenModalCamera(true);
    setTimeout(() => {
      setOpenCamera(true);
    }, 300);
  }

  function handleTakePhotoAnimationDone (dataUri) {
    // Do stuff with the photo...
    // console.log('takePhoto');
  }

  function handleCameraError (error) {
    // console.log('handleCameraError', error);
  }

  function handleCameraStart (stream) {
    // console.log('handleCameraStart : ', stream);
  }

  function handleCameraStop () {
    // console.log('handleCameraStop');
  }

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleFileChange = (files) => {
    console.log(files);
    const file = files[0];
    getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        const params = {
          base64: result,
          type: file.type,
          name: file.name,
        }

        onAddImage(params.base64);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleFileError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
    helper.errorToast(error.message);
  }

  return (
        <ContainerPage options={{statusBar: true, prevPage: origin_screen, title: 'Pelaporan Kerusakan', params:{id_branch: id_branch, id_item: id_item, new_data: true}}}>
          <Flex flex={1} minW="300px">
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              <VStack space={7} mt={2} mb={35} mx={3}>
                <Box mt={5}>
                  <Center>
                    <HStack space={2} justifyContent="center" alignItems="center">
                      <Icon as={<Box><FaCheckCircle/></Box>} size="8" color="success.700" />
                      <Text color="darkText" fontWeight="bold" isTruncated maxWidth="90%" fontSize="lg">
                        {pelaporanDetailInputScreen.nm_ruangan}
                      </Text>
                    </HStack>
                  </Center>
                </Box>
                <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    <VStack>
                      <Box mb={2}>
                        <Text fontSize="md">Inventory</Text>
                        <Text fontWeight="bold">{pelaporanDetailInputScreen.nm_aset}</Text>
                      </Box>
                      <Box mb={2}>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Deskripsi Kerusakan</FormControl.Label>
                          <TextArea value={pelaporanDetailInputScreen.description} onChangeText={txt => onUpdateInput('description', txt)} h={40} placeholder="Detail Kerusakan" borderRadius="lg" w="100%" />
                        </FormControl>
                      </Box>
                      <Box mb={2}>
                        <FormControl mb="1">
                          <FormControl.Label>Catatan</FormControl.Label>
                          <TextArea value={pelaporanDetailInputScreen.notes} onChangeText={txt => onUpdateInput('notes', txt)} h={40} placeholder="Catatan jika dibutuhkan" borderRadius="lg" w="100%" />
                        </FormControl>
                      </Box>
                    </VStack>
                </Box>
                <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                  <HStack mb={4}>
                    <Flex flex={1}>
                      <Text fontSize="md">Foto Kerusakan</Text>
                    </Flex>
                    {!viewOnly && 
                    <Box width="120px">
                      <Button onPress={() => handleCameraOpen()} leftIcon={<Icon as={<Box><MdAddAPhoto/></Box>} size="sm" />}>
                        Ambil Foto
                      </Button>
                    </Box>
                    }
                  </HStack>
                  <VStack space={3}>
                    <Box>
                    <Box justifyContent="center" alignItems="center" mt={2} p={2} flex={1} borderColor="muted.400" borderRadius="lg" borderWidth={1} borderStyle="dashed">
                      <Files
                        className='files-dropzone'
                        onChange={handleFileChange}
                        onError={handleFileError}
                        accepts={['image/png', 'image/jpg','image/jpeg']}
                        maxFileSize={(10000000 * 20)} // 20 MB
                        minFileSize={0}
                        clickable>
                        Drop files here or click to upload
                      </Files>
                    </Box>
                    {/* {openCamera == true && 
                        <Camera
                          onTakePhoto = { (dataUri) => { handleChoosePhoto(dataUri); } }
                          // onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
                          onTakePhotoAnimationDone = { (dataUri) => { handleTakePhotoAnimationDone(dataUri); } }
                          onCameraError = { (error) => { handleCameraError(error); } }
                          idealFacingMode = {FACING_MODES.ENVIRONMENT}
                          idealResolution = {{width: 640, height: 640}}
                          imageType = {IMAGE_TYPES.JPG}
                          imageCompression = {0.70}
                          isMaxResolution = {true}
                          isImageMirror = {false}
                          isSilentMode = {false}
                          isDisplayStartCameraError = {true}
                          isFullscreen = {false}
                          sizeFactor = {1}
                          onCameraStart = { (stream) => { handleCameraStart(stream); } }
                          onCameraStop = { () => { handleCameraStop(); } }
                        />
                      } */}
                    </Box>
                    {pelaporanDetailInputScreen.assets.map((rowimage, imgx) => {
                      return (
                        <Box key={`img-${imgx}`} p={2} flex={1} borderColor="muted.400" borderRadius="lg" borderWidth={1}>
                          <Flex mb={2} justifyContent="center" alignItems="center">
                          <AspectRatio
                          ratio={{
                            base: 3 / 4,
                          }} height={{
                            base: 350,
                          }}>
                            <Image alt={`photo${imgx}`} resizeMode="contain" source={{uri: rowimage.path}}/>
                          </AspectRatio>
                          </Flex>
                          <HStack justifyContent="center" alignItems="center">
                            <Flex flex={1} flexDirection="column">
                              <Text fontSize="sm" fontWeight="bold">Foto {`${imgx+1}`}</Text>
                              {/* <Text fontSize="xs" color="muted.400">28-10-2022 09:40</Text> */}
                            </Flex>
                            {!viewOnly &&
                            <Box>
                              <Pressable onPress={() => onDelImage(imgx)}>
                                <Icon as={<Box><MdDelete/></Box>} color="red.500" size="lg"></Icon>
                              </Pressable>
                            </Box>
                            }
                          </HStack>
                        </Box>
                        // <View>
                        //   <Image alt={`photo${imgx}`} style={styles.image} source={{uri: rowimage}}/>
                        // </View>
                      );
                    })
                    }
                  </VStack>
                </Box>
                {!viewOnly &&
                  <Box borderTopRadius="lg" shadow={3}>
                    <Button isLoading={pelaporanDetailInputScreen.loading} spinnerPlacement="end" isLoadingText="Submitting..." colorScheme="primary" onPress={() => handleSubmit()}>Kirim</Button>
                  </Box>
                }
              </VStack>
            </ScrollView>
          </Flex>
          <Modal isOpen={openModalCamera} onClose={() => setOpenModalCamera(false)}>
            <Modal.Content maxWidth="500px">
              <Modal.Header>Ambil Gambar</Modal.Header>
              <Modal.Body>
              {openCamera == true && 
              <Camera
                onTakePhoto = { (dataUri) => { handleChoosePhoto(dataUri); } }
                onTakePhotoAnimationDone = { (dataUri) => { handleTakePhotoAnimationDone(dataUri); } }
                onCameraError = { (error) => { handleCameraError(error); } }
                idealFacingMode = {FACING_MODES.ENVIRONMENT}
                idealResolution = {{width: 640, height: 800}}
                imageType = {IMAGE_TYPES.JPG}
                imageCompression = {0.70}
                isMaxResolution = {true}
                isImageMirror = {false}
                isSilentMode = {false}
                isDisplayStartCameraError = {true}
                isFullscreen = {false}
                sizeFactor = {1}
                onCameraStart = { (stream) => { handleCameraStart(stream); } }
                onCameraStop = { () => { handleCameraStop(); } }
              />
            }
              </Modal.Body>
            </Modal.Content>
          </Modal>
        </ContainerPage>
      );
    
}

const mapStateToProps = createStructuredSelector({
  pelaporanDetailInputScreen: makeSelectPelaporanDetailInputScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onAddImage: asset => dispatch(addImage(asset)),
    onDelImage: idx => dispatch(delImage(idx)),
    onSubmitPelaporan: (id_branch, origin_screen) => dispatch(submitPelaporan(id_branch, origin_screen)),
    onGetPelaporan: (id_pelaporan) => dispatch(getPelaporan(id_pelaporan)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(PelaporanDetailInputScreen);