import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the VendorProgressSurketScreen state domain
 */

const selectVendorProgressSurketScreenDomain = state =>
  state.vendorProgressSurketScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by VendorProgressSurketScreen
 */

const makeSelectVendorProgressSurketScreen = () =>
  createSelector(
    selectVendorProgressSurketScreenDomain,
    substate => substate,
  );

export default makeSelectVendorProgressSurketScreen;
export { selectVendorProgressSurketScreenDomain };
