import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
  import moment from 'moment';
  import api from '../../util/api';
  import helper from '../../util/helper';
  import * as constants from './constants';
  
  import makeSelectApprovalMaintainFormVendorScreen from './selector';
import { getApprovalDetailFail, getApprovalDetailSuccess, getNegoOfferFail, getNegoOfferSuccess, getVerifyAssetsFail, getVerifyAssetsSuccess, submitApprovalFail, submitApprovalSuccess } from './actions';
import history from '../../util/history';

  export function* doGetApprovalDetail(action) {
    console.log('called');
    const {id_apv, code_proses, dt_realisasi} = action;
    const dataset = yield select(makeSelectApprovalMaintainFormVendorScreen());
    try {
      const dtRealisasi = moment(dt_realisasi).format("YYYY-MM-DD");
      const response = yield call(api.getApprovalMaintainDetail, id_apv, (parseInt(code_proses) || 0), dtRealisasi);
      const {data} = response.data;
      console.log("res:", response.data);
      // const rawData = JSON.parse('{"msg":"sukses","data":{"id":38,"id_vendor_pemenang":null,"id_pegawai_asign":1,"code":"REQ_VENDOR/24/02/23/1/1677201714629","date_max_penawaran":"2023-02-24 23:59:59+07","noted":"test app devl terima kasih","date_create":"2023-02-24 08:21:54.628457+07","action":"I","date_action":"2023-02-24 08:21:54.628457+07","id_user":3,"w_o":null,"submit_penawaran":[{"id":27,"id_penawaran_request":38,"id_vendor":1,"id_tiketing":221,"waktu_pengerjaan":"2023-03-31 00:00:00+07","harga":"5000000","pick_satus":"10","date_create":"2023-02-24 08:49:30.724905+07","action":"U","date_action":"2023-09-21 09:24:34.420426+07","id_user":3,"description":"pasti mantap","ms_vendor":{"id":1,"code":null,"nama":"CV Artha Bangun Sejahtera","alamat":"Jl. Bumi Intan Permai A - 2","tlp":null,"hp":"0814231323123123","email":"artha.bangun@gmail.com","status":true,"tgl_create":"2022-10-19 05:38:05.29+07","tgl_action":"2022-12-02 05:58:01.358204+07","action":"U","id_user":3,"jml_pegawai":"90","id_pegawai_cp":1},"submit_attachment":[{"id":75,"id_vendor_submit_penwaran":27,"id_vendor":1,"path":"","status":true,"date_create":"2023-09-21 09:24:34.420426+07","action":"I","date_action":"2023-09-21 09:24:34.420426+07","id_user":3}]}]}}');
      // console.log("res2:", rawData);
      yield put(
        getApprovalDetailSuccess((helper.isEmpty(data) ? [] : data)),
        // getApprovalDetailSuccess(rawData.data),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getApprovalDetailFail(err));
    }
  }

  export function* doSubmitApproval(action) {
    const {code_proses, action_type} = action;
    const dataset = yield select(makeSelectApprovalMaintainFormVendorScreen());
    const {data_approval, rawApv} = dataset;
    try {
      let params = {
          id: data_approval.id,
          id_wo: (data_approval.w_o ? data_approval.w_o.id : 0),
          id_vendor: dataset.raw_penawaran.id_vendor,
          id_pegawai_cp: dataset.raw_penawaran.ms_vendor.id_pegawai_cp,
          id_submit_penawaran: dataset.raw_penawaran.id,
          id_penawaran_request: dataset.raw_penawaran.id_penawaran_request,
          tgl_dateLine: moment(dataset.deadline_dt).format('YYYY-MM-DD'),
          amount_pagu: dataset.budget,
          description: dataset.notes,
        };

      console.log(params, code_proses);
      const response = yield call(api.submitApprovalVendor, params, code_proses);
      console.log(response);
      yield put(
        submitApprovalSuccess(response.data, action_type),
      );
      // history.replace('vendor-kroscek', {id_item: dataset.id_ruang});
    } catch (err) {
      console.log(err);
      yield put(submitApprovalFail(err));
    }
  }

  export function* doGetNegoOfferDetail(action) {
    const dataset = yield select(makeSelectApprovalMaintainFormVendorScreen());
    try {
      // console.log("test_data :", (dataset.list_penawaran[0]));
      const response = yield call(api.vendor.getDetailNegoOffer, (dataset.raw_penawaran !== null)? dataset.raw_penawaran.id:dataset.list_penawaran[0].id);
      const {success} = response.data;
  
      yield put(
        getNegoOfferSuccess((helper.isEmpty(success) ? null : success)),
      );
      console.log("res:", success);
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getNegoOfferFail(err));
    }
  }
  // Individual exports for testing
  export default function* approvalMaintainFormVendorScreenSaga() {
    yield all([
      takeLatest(constants.GET_APPROVAL_DETAIL, doGetApprovalDetail),
      takeLatest(constants.SUBMIT_APPROVAL, doSubmitApproval),
      takeLatest(constants.GET_APPROVAL_DETAIL_SUCCESS, doGetNegoOfferDetail),
      // takeLatest(constants.SUBMIT_WORK_ORDER_VERIF_SUCCESS, doGetApprovalDetail),
    ]);
  }
  