import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the VendorProgressScreen state domain
 */

const selectVendorProgressScreenDomain = state =>
  state.vendorProgressScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by VendorProgressScreen
 */

const makeSelectVendorProgressScreen = () =>
  createSelector(
    selectVendorProgressScreenDomain,
    substate => substate,
  );

export default makeSelectVendorProgressScreen;
export { selectVendorProgressScreenDomain };
