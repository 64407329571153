export const DEFAULT_ACTION = 'util/GLOBAL_VAR/DEFAULT_ACTION';
export const UPDATE_INPUT = 'util/GLOBAL_VAR/UPDATE_INPUT';

export const GET_GEDUNG = 'util/GLOBAL_VAR/GET_GEDUNG';
export const GET_GEDUNG_LOADING = 'util/GLOBAL_VAR/GET_GEDUNG_LOADING';
export const GET_GEDUNG_SUCCESS = 'util/GLOBAL_VAR/GET_GEDUNG_SUCCESS';
export const GET_GEDUNG_FAIL = 'util/GLOBAL_VAR/GET_GEDUNG_FAIL';

export const GET_LANTAI = 'util/GLOBAL_VAR/GET_LANTAI';
export const GET_LANTAI_LOADING = 'util/GLOBAL_VAR/GET_LANTAI_LOADING';
export const GET_LANTAI_SUCCESS = 'util/GLOBAL_VAR/GET_LANTAI_SUCCESS';
export const GET_LANTAI_FAIL = 'util/GLOBAL_VAR/GET_LANTAI_FAIL';

export const GET_PEGAWAI = 'util/GLOBAL_VAR/GET_PEGAWAI';
export const GET_PEGAWAI_LOADING = 'util/GLOBAL_VAR/GET_PEGAWAI_LOADING';
export const GET_PEGAWAI_SUCCESS = 'util/GLOBAL_VAR/GET_PEGAWAI_SUCCESS';
export const GET_PEGAWAI_FAIL = 'util/GLOBAL_VAR/GET_PEGAWAI_FAIL';