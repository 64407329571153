import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the TaskPreventifDetailScreen state domain
 */

const selectTaskPreventifDetailScreenDomain = state =>
  state.taskPreventifDetailScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by TaskPreventifDetailScreen
 */

const makeSelectTaskPreventifDetailScreen = () =>
  createSelector(
    selectTaskPreventifDetailScreenDomain,
    substate => substate,
  );

export default makeSelectTaskPreventifDetailScreen;
export { selectTaskPreventifDetailScreenDomain };
