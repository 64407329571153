import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getRoomAsset(id_realisasi_preventif) {
  return {
    type: constants.GET_ROOM_ASSET,
    id_realisasi_preventif
  };
}

export function getRoomAssetLoading() {
  return {
    type: constants.GET_ROOM_ASSET_LOADING
  };
}

export function getRoomAssetFail(error) {
  return {
    type: constants.GET_ROOM_ASSET_FAIL,
    error
  };
}

export function getRoomAssetSuccess(results) {
  return {
    type: constants.GET_ROOM_ASSET_SUCCESS,
    results
  };
}

export function clearSessPelaporan() {
  return {
    type: constants.CLEAR_SESS_PELAPORAN
  };
}

export function getTodoList(id_realisasi_preventif, id_inventaris) {
  return {
    type: constants.GET_TODO_LIST,
    id_realisasi_preventif,
    id_inventaris
  };
}

export function getTodoListLoading() {
  return {
    type: constants.GET_TODO_LIST_LOADING
  };
}

export function getTodoListFail(error) {
  return {
    type: constants.GET_TODO_LIST_FAIL,
    error
  };
}

export function getTodoListSuccess(results) {
  return {
    type: constants.GET_TODO_LIST_SUCCESS,
    results
  };
}

export function submitPreventif(idx_inventaris) {
  return {
    type: constants.SUBMIT_PREVENTIF,
    idx_inventaris
  };
}

export function submitPreventifSuccess({idx_inventaris, id_preventif_exec}) {
  return {
    type: constants.SUBMIT_PREVENTIF_SUCCESS,
    idx_inventaris,
    id_preventif_exec
  };
}

export function submitPreventifFail(idx_inventaris, error) {
  return {
    type: constants.SUBMIT_PREVENTIF_FAIL,
    idx_inventaris,
    error,
  };
}