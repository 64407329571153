import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the VendorProgressHistoryScreen state domain
 */

const selectVendorProgressHistoryScreenDomain = state =>
  state.vendorProgressHistoryScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by VendorProgressHistoryScreen
 */

const makeSelectVendorProgressHistoryScreen = () =>
  createSelector(
    selectVendorProgressHistoryScreenDomain,
    substate => substate,
  );

export default makeSelectVendorProgressHistoryScreen;
export { selectVendorProgressHistoryScreenDomain };
