/*
 *
 * ApprovalMaintainFormScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import helper from '../../util/helper';
import moment from 'moment';

export const initialState = {
    error: 0,
    error_msg: '',
    responseState: '',
    loading: false,
    loading_submit: false,
    notes: '',
    results:[],
    assets:[],
    start_dt: '',
    end_dt: '',
    est_dt: new Date(),
    id_apv: '',
    id_petugas: '',
    data_approval: null,
    list_preventif: [],
    del_preventif: [],
    nm_inventaris: '',
    nm_ruangan:'-',
    nm_gedung:'-',
    tgl_laporan:'',
    code_pelaporan:'',
    description: '',
    code_proses: '',
    new_data: false,
    rawApv:null,
};

/* eslint-disable default-case, no-param-reassign */
const ApprovalMaintainFormPreventifScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        return {...initialState};
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.UPDATE_INPUT_PREVENTIF: {
        const idx = action.rowid;
        draft.list_preventif.forEach(responseCredential => {
          draft.list_preventif.splice(idx, 1, {...draft.list_preventif[idx], [action.name]: action.value}
          );
        });
        }
        break;
      case constants.DELETE_ROW_PREVENTIF:{
        // add removed preventif
        let cur_del_preventif = draft.del_preventif;
        cur_del_preventif.push(draft.list_preventif[action.rowid]);
        draft.del_preventif = cur_del_preventif;
        console.log(cur_del_preventif);
        // del list preventif
        draft.list_preventif = [...draft.list_preventif.slice(0, action.rowid), ...draft.list_preventif.slice(action.rowid + 1)];
        }
        break;
      case constants.GET_APPROVAL_DETAIL:
        draft.loading = true;
        break;
      case constants.GET_APPROVAL_DETAIL_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data.';
        draft.list_preventif = [];
        break;
      case constants.GET_APPROVAL_DETAIL_SUCCESS:{
        const rawDetail = action.results;
        draft.list_preventif = action.results;
        draft.loading = false;
      }
      break;  
      case constants.GET_VERIF_ASSET:
        draft.loading = true;
        break;
      case constants.GET_VERIF_ASSET_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data.';
        draft.results = [];
        break;
      case constants.GET_VERIF_ASSET_SUCCESS:
        draft.assets = action.results;
        draft.loading = false;
        break;
      case constants.SUBMIT_APPROVAL_PREVENTIF:
        draft.loading_submit = true;
        draft.responseState = '';
        break;
      case constants.SUBMIT_APPROVAL_PREVENTIF_FAIL:
        draft.error = true;
        draft.loading_submit = false;
        draft.responseState = 'error';
        draft.error_msg= 'Maaf, Gagal melakukan approval.';
        break;
      case constants.SUBMIT_APPROVAL_PREVENTIF_SUCCESS:
        draft.loading_submit = false;
        draft.error = false;
        if(action.action_type == '20'){
          draft.responseState = 'approved';
        } else {
          draft.responseState = 'rejected';
        }
        break;
    }
  });

export default ApprovalMaintainFormPreventifScreenReducer;
