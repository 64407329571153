import * as React from 'react';
import { Box, Flex, VStack, Image, Text, Center, Avatar } from 'native-base';
import Carousel from "react-multi-carousel";
import { commonStyle } from '../styles';
import moment from 'moment';
import helper from '../util/helper';

export default class CardSlider extends React.Component {

    constructor(props){
        super(props);
        this.state = {
          activeIndex:0,
          carouselItems: [
          {
              nm_gedung:"Gedung ASSEC Tower",
              nm_lantai: "Lantai 1",
              nm_ruang: "Ruang Toraja 1",
              jadwal: "Selasa, 10:00 - 12:00",
          },
          {
            nm_gedung:"Gedung ASSEC Tower",
            nm_lantai: "Lantai 2",
            nm_ruang: "Ruang Toraja 2",
            jadwal: "Selasa, 10:00 - 12:00",
          },
          {
            nm_gedung:"Gedung ASSEC Tower",
            nm_lantai: "Lantai 3",
            nm_ruang: "Ruang Toraja 3",
            jadwal: "Selasa, 10:00 - 12:00",
          }
        ]
      }
    }

    _renderItem({item,index}){
      console.log('itms:', item);
        return (
          <Box px={15}>
            <Box backgroundColor={commonStyle.baseColor} height="180" borderColor="muted.200" borderWidth={2} p={4} rounded="2xl">
                <VStack>
                  <Flex flexDirection="row">
                    <Image source={require('../images/logo.png')} size="xs" alt='Logo' />
                    <Text ml={2} mt={1} fontSize="lg" color={commonStyle.primaryColor} isTruncated maxW="300" width="80%" fontWeight="bold">{item.nm_gedung}</Text>
                  </Flex>
                  <Box mt={2}>
                    <Flex flexDirection="row">
                      <Box justifyContent="center">
                        <Center>
                          <Avatar size="lg" bg="primary.900" mr="1" borderRadius="lg">
                              {helper.getInitials(item.nm_ruang)}
                          </Avatar>
                        </Center> 
                        {/* <Image source={require('../images/no-image.jpg')} alt='Room' size="lg" borderRadius={20} /> */}
                      </Box>
                      <VStack width="90%">
                        <Text ml={3} mt={2} fontSize="lg" color={commonStyle.primaryColor} isTruncated width="80%" fontWeight="bold">{item.nm_ruang}</Text>
                        <Text ml={3} mt={2} fontSize="sm" color="muted.500" isTruncated width="80%">{item.tipe_task}</Text>
                        <Text ml={3} mt={2} fontSize="md" color="muted.700" fontWeight={500} isTruncated width="80%">{moment(item.date_create, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")}</Text>
                      </VStack>
                    </Flex>
                  </Box>
                </VStack>
            </Box>
          </Box>
        )
    }

  //   get pagination () {
  //     const { carouselItems, activeIndex } = this.state;
  //     const {data} = this.props;
  //     return (
  //         <Pagination
  //           dotsLength={data.length}
  //           activeDotIndex={activeIndex}
  //           containerStyle={{ paddingTop:10, paddingBottom:10}}
  //           dotStyle={{
  //               width: 10,
  //               height: 10,
  //               borderRadius: 5,
  //               backgroundColor: 'rgba(255, 255, 255, 0.92)'
  //           }}
  //           inactiveDotStyle={{
  //               // Define styles for inactive dots here
  //           }}
  //           inactiveDotOpacity={0.4}
  //           inactiveDotScale={1}
  //         />
  //     );
  // }

    render() {
        const responsiveSlider = {
          desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
          },
          tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
          },
          mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
          }
        };

        return (
          <Carousel
              swipeable={true}
              draggable={false}
              showDots={false}
              responsive={responsiveSlider}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlaySpeed={1000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carousel-container"
              arrows={false}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              {this.props.data.map((item, index) => this._renderItem({item, index}))}
            </Carousel>
        );
    }
}