import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the NotificationDetailScreen state domain
 */

const selectNotificationDetailScreenDomain = state =>
  state.notificationDetailScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by NotificationDetailScreen
 */

const makeSelectNotificationDetailScreen = () =>
  createSelector(
    selectNotificationDetailScreenDomain,
    substate => substate,
  );

export default makeSelectNotificationDetailScreen;
export { selectNotificationDetailScreenDomain };
