export const DEFAULT_ACTION = 'screen/VendorKroscekFormScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/VendorKroscekFormScreen/UPDATE_INPUT';

export const GET_WORK_ORDER_VERIF = 'screen/VendorKroscekFormScreen/GET_WORK_ORDER_VERIF';
export const GET_WORK_ORDER_VERIF_LOADING = 'screen/VendorKroscekFormScreen/GET_WORK_ORDER_VERIF_LOADING';
export const GET_WORK_ORDER_VERIF_SUCCESS = 'screen/VendorKroscekFormScreen/GET_WORK_ORDER_VERIF_SUCCESS';
export const GET_WORK_ORDER_VERIF_FAIL = 'screen/VendorKroscekFormScreen/GET_WORK_ORDER_VERIF_FAIL';

export const SUBMIT_WORK_ORDER_VERIF = 'screen/VendorKroscekFormScreen/SUBMIT_WORK_ORDER_VERIF';
export const SUBMIT_WORK_ORDER_VERIF_LOADING = 'screen/VendorKroscekFormScreen/SUBMIT_WORK_ORDER_VERIF_LOADING';
export const SUBMIT_WORK_ORDER_VERIF_SUCCESS = 'screen/VendorKroscekFormScreen/SUBMIT_WORK_ORDER_VERIF_SUCCESS';
export const SUBMIT_WORK_ORDER_VERIF_FAIL = 'screen/VendorKroscekFormScreen/SUBMIT_WORK_ORDER_VERIF_FAIL';

export const GET_VERIF_ASSET = 'screen/VendorKroscekFormScreen/GET_VERIF_ASSET';
export const GET_VERIF_ASSET_LOADING = 'screen/VendorKroscekFormScreen/GET_VERIF_ASSET_LOADING';
export const GET_VERIF_ASSET_SUCCESS = 'screen/VendorKroscekFormScreen/GET_VERIF_ASSET_SUCCESS';
export const GET_VERIF_ASSET_FAIL = 'screen/VendorKroscekFormScreen/GET_VERIF_ASSET_FAIL';

export const ADD_IMAGE = 'screen/VendorKroscekFormScreen/ADD_IMAGE';
export const DEL_IMAGE = 'screen/VendorKroscekFormScreen/DEL_IMAGE';