import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
import * as constants from './constants';
import api from './api';
import helper from './helper';

import makeSelectGlobalVar from './selector';
import { getGedungFail, getGedungSuccess, getLantaiFail, getLantaiSuccess, getPegawaiSuccess, getPegawaiFail } from './actions';
  
  export function* doGetGedung(action) {
    const {id_akun} = action;
    const dataset = yield select(makeSelectGlobalVar());
    try {
      const response = yield call(api.getAllGedung);
      const {data} = response.data;
      // console.log('gd_list:', data);
      yield put(
        getGedungSuccess(data),
      );
      
      // RootNavigation.navigate('page', {screen: 'home'});

    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getGedungFail(err));
    }
  }

  export function* doGetLantai(action) {
    const {id_gedung} = action;
    const dataset = yield select(makeSelectGlobalVar());

    if(parseInt(id_gedung) == 0) return;

    try {
      const response = yield call(api.getLantaiGedung, id_gedung);
      const {success} = response.data;
      console.log('lt_list:', success);
      yield put(
        getLantaiSuccess(success),
      );
      
      // RootNavigation.navigate('page', {screen: 'home'});

    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getLantaiFail(err));
    }
  }

  export function* doGetPegawai(action) {
    const {id_gedung} = action;
    const dataset = yield select(makeSelectGlobalVar());
    try {
      const response = yield call(api.getListPegawai);
      const {success} = response.data;
      console.log('pg_list:', success);
      yield put(
        getPegawaiSuccess(success),
      );
      
      // RootNavigation.navigate('page', {screen: 'home'});

    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getPegawaiFail(err));
    }
  }

  // Individual exports for testing
  export default function* globalVarSaga() {
    yield all([
      takeLatest(constants.GET_GEDUNG, doGetGedung),
      takeLatest(constants.GET_LANTAI, doGetLantai),
      takeLatest(constants.GET_PEGAWAI, doGetPegawai),
    ]);
  }