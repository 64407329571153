import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getWorkOrderVerif(id_ruang) {
  return {
    type: constants.GET_WORK_ORDER_VERIF,
    id_ruang
  };
}

export function getWorkOrderVerifLoading() {
  return {
    type: constants.GET_WORK_ORDER_VERIF_LOADING
  };
}

export function getWorkOrderVerifFail(error) {
  return {
    type: constants.GET_WORK_ORDER_VERIF_FAIL,
    error
  };
}

export function getWorkOrderVerifSuccess(results) {
  // console.log(results);
  return {
    type: constants.GET_WORK_ORDER_VERIF_SUCCESS,
    results
  };
}

export function getMoreWOVerif(id_ruang) {
  return {
    type: constants.GET_MORE_WO_VERIF,
    id_ruang
  };
}

export function getMoreWOVerifLoading() {
  return {
    type: constants.GET_MORE_WO_VERIF_LOADING
  };
}

export function getMoreWOVerifFail(error) {
  return {
    type: constants.GET_MORE_WO_VERIF_FAIL,
    error
  };
}

export function getMoreWOVerifSuccess(results) {
  // console.log(results);
  return {
    type: constants.GET_MORE_WO_VERIF_SUCCESS,
    results
  };
}