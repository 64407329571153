import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
  import moment from 'moment';
  import api from '../../util/api';
  import helper from '../../util/helper';
  import * as constants from './constants';

import { getApprovalDetailFail, getApprovalDetailSuccess, getVerifyAssetsFail, getVerifyAssetsSuccess, submitApprovalPreventifFail, submitApprovalPreventifSuccess, submitWOVerifFail, submitWOVerifSuccess } from './actions';
import history from '../../util/history';
import makeSelectApprovalMaintainFormPreventifScreen from './selector';

export function* doGetApprovalDetail(action) {
  console.log('called');
  const {id_apv, code_proses, dt_realisasi} = action;
  const dataset = yield select(makeSelectApprovalMaintainFormPreventifScreen());
  const {data_approval, rawApv} = dataset;

  // console.log('sg-rawApv:', rawApv);

  try {
    const dtRealisasi = moment(rawApv.date_realisasi).format("YYYY-MM-DD");

    const params = {
        id_gedung: rawApv.id_gedung,
        schedule_type: rawApv.id,
        date_realisasi: dtRealisasi,
    };

    const response = yield call(api.getApprovalMaintainPreventifDetail, params);
    const {data} = response.data;
    console.log("res:", response.data);
    yield put(
      getApprovalDetailSuccess((helper.isEmpty(data) ? [] : data)),
    );
  } catch (err) {
    console.log(err);
    helper.errorToast("Koneksi server gagal");
    yield put(getApprovalDetailFail(err));
  }
}

  export function* doSubmitApprovalPreventif(action) {
    const {action_type, id_wo} = action;
    const dataset = yield select(makeSelectApprovalMaintainFormPreventifScreen());
    const {rawApv, list_preventif, del_preventif} = dataset;
    try {
      const params = {
          id_gedung: rawApv.id_gedung,
          schedule_type: rawApv.id,
          noted: dataset.notes,
          date_realisasi: moment(rawApv.date_realisasi).format('DD-MM-YYYY'),
          date_approve: moment(dataset.est_dt).format('DD-MM-YYYY'),
          jadwal_detail: JSON.stringify(list_preventif),
          jadwal_detail_delete: JSON.stringify(del_preventif)
      };

      const response = yield call(api.submitApprovalPreventif, params);
      console.log(response);
      yield put(
        submitApprovalPreventifSuccess(response.data, action_type),
      );
    } catch (err) {
      console.log(err);
      yield put(submitApprovalPreventifFail(err));
    }
  }
  // Individual exports for testing
  export default function* approvalMaintainFormPreventifScreenSaga() {
    yield all([
      takeLatest(constants.GET_APPROVAL_DETAIL, doGetApprovalDetail),
      takeLatest(constants.SUBMIT_APPROVAL_PREVENTIF, doSubmitApprovalPreventif),
      // takeLatest(constants.SUBMIT_WORK_ORDER_VERIF_SUCCESS, doGetWorkOrderVerif),
    ]);
  }
  