/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from './util/history';
// reducers
import GlobalVarReducer from './util/reducer';
import LoginScreenReducer from './screens/LoginScreen/reducer';
import ForgetPassScreenReducer from './screens/ForgetPassScreen/reducer';
import HomeScreenReducer from './screens/HomeScreen/reducer';
import TaskScreenReducer from './screens/TaskScreen/reducer';
import ProfileScreenReducer from './screens/ProfileScreen/reducer';
import WorkOrderScreenReducer from './screens/WorkOrderScreen/reducer';
import WelcomeScreenReducer from './screens/WelcomeScreen/reducer';
import FormProfileScreenReducer from './screens/FormProfileScreen/reducer';
import FormChangePassScreenReducer from './screens/FormChangePassScreen/reducer';
import TaskScannerScreenReducer from './screens/TaskScannerScreen/reducer';
// import TaskDetailScreenReducer from './screens/TaskDetailScreen/reducer';
// import TaskDetailInputScreenReducer from './screens/TaskDetailInputScreen/reducer';
// import TaskHistoryScreenReducer from './screens/TaskHistoryScreen/reducer';
import NotificationScreenReducer from './screens/NotificationScreen/reducer';
import NotificationDetailScreenReducer from './screens/NotificationDetailScreen/reducer';
import PelaporanScreenReducer from './screens/PelaporanScreen/reducer';
import PelaporanDetailInputScreenReducer from './screens/PelaporanDetailInputScreen/reducer';
import PelaporanDetailScreenReducer from './screens/PelaporanDetailScreen/reducer';
import PelaporanHistoryScreenReducer from './screens/PelaporanHistoryScreen/reducer';
import PelaporanHistoryDetailScreenReducer from './screens/PelaporanHistoryDetailScreen/reducer';
import VerifPelaporanScreenReducer from './screens/VerifPelaporanScreen/reducer';
import VerifPelaporanInputScreenReducer from './screens/VerifPelaporanInputScreen/reducer';
import VerifPelaporanHistoryScreenReducer from './screens/VerifPelaporanHistoryScreen/reducer';
import TaskPreventifScreenReducer from './screens/TaskPreventifScreen/reducer';
import TaskPreventifDetailScreenReducer from './screens/TaskPreventifDetailScreen/reducer';
// import TaskPreventifTodoScreenReducer from './screens/TaskPreventifTodoScreen/reducer';
import TaskPreventifFormScreenReducer from './screens/TaskPreventifFormScreen/reducer';
import VendorOfferScreenReducer from './screens/VendorOfferScreen/reducer';
import VendorOfferDetailScreenReducer from './screens/VendorOfferDetailScreen/reducer';
import VendorOfferFormScreenReducer from './screens/VendorOfferFormScreen/reducer';
import VendorProgressScreenReducer from './screens/VendorProgressScreen/reducer';
import VendorProgressSurketScreenReducer from './screens/VendorProgressSurketScreen/reducer';
import VendorProgressHistoryScreenReducer from './screens/VendorProgressHistoryScreen/reducer';
import VendorProgressDetailScreenReducer from './screens/VendorProgressDetailScreen/reducer';
import VendorProgressFormScreenReducer from './screens/VendorProgressFormScreen/reducer';
import VendorKroscekScreenReducer from './screens/VendorKroscekScreen/reducer';
import VendorKroscekHistoryScreenReducer from './screens/VendorKroscekHistoryScreen/reducer';
import VendorKroscekFormScreenReducer from './screens/VendorKroscekFormScreen/reducer';
import ApprovalMaintainScreenReducer from './screens/ApprovalMaintainScreen/reducer';
import ApprovalMaintainFormScreenReducer from './screens/ApprovalMaintainFormScreen/reducer';
import ApprovalMaintainFormPreventifScreenReducer from './screens/ApprovalMaintainFormPreventifScreen/reducer';
import ApprovalMaintainFormVendorScreenReducer from './screens/ApprovalMaintainFormVendorScreen/reducer';
import VendorNegoFormScreenReducer from './screens/VendorNegoFormScreen/reducer';
// end reducers
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function combinedReducers() {
const rootReducer = combineReducers({
    router: connectRouter(history),
    globalVar: GlobalVarReducer,
    loginScreen: LoginScreenReducer,
    forgetPassScreen: ForgetPassScreenReducer,
    homeScreen: HomeScreenReducer,
    taskScreen: TaskScreenReducer,
    profileScreen: ProfileScreenReducer,
    workOrderScreen: WorkOrderScreenReducer,
    welcomeScreen: WelcomeScreenReducer,
    formProfileScreen: FormProfileScreenReducer,
    formChangePassScreen: FormChangePassScreenReducer,
    taskScannerScreen: TaskScannerScreenReducer,
    // taskDetailScreen: TaskDetailScreenReducer,
    // taskDetailInputScreen: TaskDetailInputScreenReducer,
    // taskHistoryScreen: TaskHistoryScreenReducer,
    notificationScreen: NotificationScreenReducer,
    notificationDetailScreen: NotificationDetailScreenReducer,
    pelaporanScreen: PelaporanScreenReducer,
    pelaporanDetailInputScreen: PelaporanDetailInputScreenReducer,
    pelaporanDetailScreen: PelaporanDetailScreenReducer,
    pelaporanHistoryScreen: PelaporanHistoryScreenReducer,
    pelaporanHistoryDetailScreen : PelaporanHistoryDetailScreenReducer,
    verifPelaporanScreen: VerifPelaporanScreenReducer,
    verifPelaporanInputScreen: VerifPelaporanInputScreenReducer,
    verifPelaporanHistoryScreen: VerifPelaporanHistoryScreenReducer,
    taskPreventifScreen: TaskPreventifScreenReducer,
    taskPreventifDetailScreen: TaskPreventifDetailScreenReducer,
    // taskPreventifTodoScreen: TaskPreventifTodoScreenReducer,
    taskPreventifFormScreen : TaskPreventifFormScreenReducer,
    vendorOfferScreen : VendorOfferScreenReducer,
    vendorOfferDetailScreen : VendorOfferDetailScreenReducer,
    vendorOfferFormScreen : VendorOfferFormScreenReducer,
    vendorProgressScreen: VendorProgressScreenReducer,
    vendorProgressSurketScreen: VendorProgressSurketScreenReducer,
    vendorProgressHistoryScreen : VendorProgressHistoryScreenReducer,
    vendorProgressDetailScreen : VendorProgressDetailScreenReducer,
    vendorProgressFormScreen: VendorProgressFormScreenReducer,
    vendorKroscekScreen: VendorKroscekScreenReducer,
    vendorKroscekHistoryScreen: VendorKroscekHistoryScreenReducer,
    vendorKroscekFormScreen: VendorKroscekFormScreenReducer,
    approvalMaintainScreen: ApprovalMaintainScreenReducer,
    approvalMaintainFormScreen : ApprovalMaintainFormScreenReducer,
    approvalMaintainFormPreventifScreen: ApprovalMaintainFormPreventifScreenReducer,
    approvalMaintainFormVendorScreen: ApprovalMaintainFormVendorScreenReducer,
    vendorNegoFormScreen: VendorNegoFormScreenReducer
});

return rootReducer;
} 