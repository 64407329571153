import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getOffers() {
  return {
    type: constants.GET_OFFERS
  };
}

export function getOffersLoading() {
  return {
    type: constants.GET_OFFERS_LOADING
  };
}

export function getOffersFail(error) {
  return {
    type: constants.GET_OFFERS_FAIL,
    error
  };
}

export function getOffersSuccess(results) {
  return {
    type: constants.GET_OFFERS_SUCCESS,
    results
  };
}

export function getMoreOffer() {
  return {
    type: constants.GET_MORE_OFFERS
  };
}

export function getMoreOfferLoading() {
  return {
    type: constants.GET_MORE_OFFERS_LOADING
  };
}

export function getMoreOfferFail(error) {
  return {
    type: constants.GET_MORE_OFFERS_FAIL,
    error
  };
}

export function getMoreOfferSuccess(results) {
  // console.log(results);
  return {
    type: constants.GET_MORE_OFFERS_SUCCESS,
    results
  };
}