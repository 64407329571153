import React from "react";
import { StyleSheet, View } from "react-native";
import { Badge, VStack, Box, Button, Icon } from 'native-base';
import { commonStyle } from "../styles";
import { MdNotifications } from 'react-icons/md';
// import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import helper from "../util/helper";

const styles = StyleSheet.create({
  badge: {
    borderRadius: 9,
    height: 18,
    minWidth: 0,
    width: 18
  },
  badgeContainer: {
    position: "absolute"
  },
  badgeText: {
    fontSize: 10,
    paddingHorizontal: 0
  }
});

const BadgeIcon = (props) => {
  return (
    <Box alignItems="center">
      <VStack>
        {!helper.isEmpty(props.value) && 
        <Badge // bg="red.400"
        colorScheme="danger" width={5} height={5} rounded="full" mb={-4} zIndex={1} variant="solid" alignSelf="flex-start" _text={{
        fontSize: 12
      }}>
        </Badge>
        }
        <Box>
          <Icon as={<Box><MdNotifications/></Box>} size={9} color={commonStyle.primaryColor} />
        </Box>
      </VStack>
    </Box>
  );
}

export default BadgeIcon;