import React, {memo} from "react";
import { commonStyle } from "../styles";
import {
    Icon,
    HStack, 
    Avatar, 
    Heading, 
    VStack, 
    Box, 
    Center,
    Stack,
    Badge,
    Input,
    Image,
    IconButton,
    Pressable,
    Divider,
    HamburgerIcon,
    Menu,
    Text, 
    Flex } from 'native-base';
  import BadgeStatus from "./BadgeStatus";
  import moment from "moment";
  import helper from "../util/helper";
  import {MdRemoveRedEye, MdModeEdit, MdHistory, MdMoreVert } from 'react-icons/md';
  import {FaCalendarAlt, FaEnvelope, FaAngleDown, FaCalendarTimes, FaCalendarCheck } from 'react-icons/fa';
  import history from "../util/history";

const CardTaskPreventifList = (props) => {
  const {data, access} = props;
  
  return (
    <Box width="100%" backgroundColor={commonStyle.baseColor} shadow={3} borderRadius="lg">
      <VStack>
        <Box px={5} py={3} backgroundColor="#ffd13c" borderTopRadius="lg">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Icon color="darkText" as={<Box><FaCalendarAlt/></Box>} size={5}></Icon>
            </Box>
            <Box flex={1} ml={1}>
              <Text color="darkText" fontWeight={500} >{moment(data.date_approve, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")}</Text>
            </Box>
            <Box ml={3}>
              <BadgeStatus status={data.status_name}/>
            </Box>
            <Box ml={2} alignItems="center" justifyContent="center" width={8} height={8}>
              <Menu mt={2} py={2} placement="bottom right" trigger={triggerProps => {
                return <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                        <Icon as={<Box><MdMoreVert/></Box>} size={8} color="yellow.600" />
                      </Pressable>;
              }}>
                {access.includes('02:VIEW') && 
                <Menu.Item p={1} onPress={() => history.push('preventif-detail',{readOnly: true, rawData:data, id_realisasi_preventif: data.id_realisasi_preventif})}>
                  <HStack space={2} justifyContent="center" alignItems="center">
                    <Icon as={<Box><MdRemoveRedEye/></Box>} size="md" color="info.700" />
                    <Text color="darkText" fontSize="md">
                      View
                    </Text>
                  </HStack>
                </Menu.Item>
                }
                {/* <Divider w="100%" /> */}
                {(access.includes('02:EDIT')) && 
                <Menu.Item p={1} onPress={() => history.push('preventif-detail',{readOnly: false, rawData:data, id_realisasi_preventif: data.id_realisasi_preventif, origin_screen: 'preventif'})}>
                  <HStack space={2} justifyContent="center" alignItems="center">
                    <Icon as={<Box><MdModeEdit/></Box>} size="md" color="info.700" />
                    <Text color="darkText" fontSize="md">
                      Edit
                    </Text>
                  </HStack>
                </Menu.Item>
                }
              </Menu>
            </Box>
          </Stack>
        </Box>
        <Box p={5}>
          <VStack>
            <Flex flexDirection="row">
              <Box>
                <Center>
                  <Avatar size="md" bg="primary.900" mr="1">
                      {helper.getInitials(data.nama_gedung)}
                  </Avatar>
                </Center>
              </Box>
              <VStack width="90%">
                <Text ml={3} fontSize="md" color={commonStyle.primaryColor} isTruncated width="80%" fontWeight="bold">{data.nama_gedung}</Text>
                <Text ml={3} fontSize="sm" color="muted.500" isTruncated width="80%">{data.alamat_gedung}</Text>
              </VStack>
            </Flex>
            <Flex flexDirection="row" mt={4}>
              <Box>
                <Center>
                  <Avatar size="md" bg="primary.900" mr="1">
                      {helper.getInitials(data.nama_ruang)}
                  </Avatar>
                </Center>
              </Box>
              <VStack width="90%">
                <Text ml={3} fontSize="md" color={commonStyle.primaryColor} isTruncated width="80%" fontWeight="bold">{data.nama_ruang}</Text>
                <Text ml={3} fontSize="sm" color="muted.500" isTruncated width="80%">{`Lantai ${data.lantai_ruang}`}</Text>
              </VStack>
            </Flex>
            <Box mt={5} backgroundColor="light.200" p={3} borderRadius="lg" width="100%">
              <VStack space={2}>
                {data.hasil.length == 0 && 
                <Box>
                  <HStack justifyContent="center" alignItems="center">
                    <Box>
                      <Icon color="darkText" as={<Box><FaCalendarTimes/></Box>} size={4}></Icon>
                    </Box>
                    <Box flex={1} ml={1}>
                      <Text color="darkText" fontWeight={500} >Belum ada hasil</Text>
                    </Box>
                  </HStack>
                </Box>}
                {data.hasil.length > 0 && data.hasil.map((row_, idx_)=>{
                  return (
                    <Box key={`urt-${idx_}`}>
                      <HStack justifyContent="center" alignItems="center">
                        <Box>
                          <Icon color="darkText" as={<Box><FaCalendarCheck/></Box>} size={4}></Icon>
                        </Box>
                        <Box flex={1} ml={1}>
                          <Text color="darkText" fontWeight={500} >{row_.nama_inventaris}</Text>
                        </Box>
                      </HStack>
                    </Box>
                  );
                })
                }
              </VStack>
            </Box>
            
          </VStack>
        </Box>
      </VStack>
  </Box>
  );
}

export default memo(CardTaskPreventifList);