export const DEFAULT_ACTION = 'screen/VendorOfferScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/VendorOfferScreen/UPDATE_INPUT';

export const GET_OFFERS = 'screen/VendorOfferScreen/GET_OFFERS';
export const GET_OFFERS_LOADING = 'screen/VendorOfferScreen/GET_OFFERS_LOADING';
export const GET_OFFERS_SUCCESS = 'screen/VendorOfferScreen/GET_OFFERS_SUCCESS';
export const GET_OFFERS_FAIL = 'screen/VendorOfferScreen/GET_OFFERS_FAIL';

export const GET_MORE_OFFERS = 'screen/VendorOfferScreen/GET_MORE_OFFERS';
export const GET_MORE_OFFERS_LOADING = 'screen/VendorOfferScreen/GET_MORE_OFFERS_LOADING';
export const GET_MORE_OFFERS_SUCCESS = 'screen/VendorOfferScreen/GET_MORE_OFFERS_SUCCESS';
export const GET_MORE_OFFERS_FAIL = 'screen/VendorOfferScreen/GET_MORE_OFFERS_FAIL';