/*
 *
 * VendorNegoFormScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import helper from '../../util/helper';
import moment from 'moment';

const date = new Date();
const to = moment(date).toDate();

export const initialState = {
    error: 0,
    error_msg: '',
    responseState: '',
    loading: false,
    loading_submit: false,
    assets:[],
    date_offer: to,
    cost_offer: 0,
    description: "",
    code_pelaporan: "",
    vendor_offer: {
      code_penawaran_request: ''
    },
};

/* eslint-disable default-case, no-param-reassign */
const VendorNegoFormScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        return {...initialState};
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.ADD_IMAGE: {
        let cur_arr = draft.assets;
        const image = `${action.asset.base64}`;
        cur_arr.push({path: image, status: true});
        draft.assets = cur_arr;
        }
        break;
      case constants.DEL_IMAGE:
        draft.assets = [...draft.assets.slice(0, action.idx), ...draft.assets.slice(action.idx + 1)];
        break;
      case constants.ADD_FILE:
        {
        let cur_arr_file = draft.assets;
        const file_ = `${action.asset.base64}`;
        const idx = draft.assets.findIndex(
          result => result.name === action.asset.name,
        );
        if(idx < 0){
          cur_arr_file.push({path: file_, name: action.asset.name, status: true});
        }
        draft.assets = cur_arr_file;
        }
        break;
      case constants.DEL_FILE:
        draft.assets = [...draft.assets.slice(0, action.idx), ...draft.assets.slice(action.idx + 1)];
        break;
      case constants.SUBMIT_OFFER:
        draft.loading_submit = true;
        break;
      case constants.SUBMIT_OFFER_FAIL:
        draft.error = true;
        draft.loading_submit = false;
        draft.responseState = 'error';
        break;
      case constants.SUBMIT_OFFER_SUCCESS:
        draft.preventif = action.results;
        draft.loading_submit = false;
        draft.isSuccess = true;
        draft.error = false;
        draft.responseState = 'success';
        break;
      case constants.GET_VENDOR_OFFER:
        draft.loading = true;
        break;
      case constants.GET_VENDOR_OFFER_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data.';
        break;
      case constants.GET_VENDOR_OFFER_SUCCESS: {
        const resReport = action.results;
        // draft.vendor_offer = resReport;
        draft.loading = false;
        if(!helper.isEmpty(resReport?.nominal_nego)) draft.cost_offer = resReport.nominal_nego;
        if(!helper.isEmpty(resReport?.tgl_app_nego)) draft.date_offer = moment(resReport.tgl_app_nego).toDate();
        if(!helper.isEmpty(resReport?.description)) draft.description = resReport.description;
        // if(!helper.isEmpty(resReport.attachments)) draft.assets = resReport.attachments;
        }
        break;
    }
  });

export default VendorNegoFormScreenReducer;
