import React, { Component, useState, useEffect, forwardRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectTaskScreen from './selector';
import reducer from './reducer';
import saga from './saga';
import {
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Stack,
  Badge,
  Input,
  Image,
  IconButton,
  Text,
  Fab,
  CheckIcon,
  ScrollView,
  Flex } from 'native-base';
import moment from 'moment';
import Select from 'react-select';
import {MdCalendarToday} from 'react-icons/md';
import { ContainerPage } from '../../components/ContainerPage';
import CardTaskList from '../../components/CardTaskList';
import CardListPreloader from '../../components/CardListPreloader';
import MyAlert from '../../components/MyAlert';
import DatePicker from "react-datepicker";
import { commonStyle } from '../../styles';
import { getGedung, getTasks, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';

export function TaskScreen({
  dispatch,
  route,
  navigation,
  taskScreen,
  onUpdateInput,
  onGetGedung,
  onGetTasks
}){
  const [screenAccess, setScreenAccess] = useState([]);
  const[backClickCount, setBackClickCount] = useState(0);

  useEffect(() => {
    // get user privileges
    const menus = api.getUserPrivileges();
    const allAccess = helper.generatePrivileges(menus);
    setScreenAccess(allAccess);

    onGetGedung();
    onGetTasks();
  },[]);

  const onChangeStartDt = (selectedDate) => {
    const currentDate = selectedDate;
    onUpdateInput("start_dt", currentDate);
    onGetTasks();
  };
  const onChangeEndDt = (selectedDate) => {
    const currentDate = selectedDate;
    onUpdateInput("end_dt", currentDate);
    onGetTasks();
  };

  const handleOnSelectGedung = (id_gedung) => {
    console.log('idg:', id_gedung);
    onUpdateInput('id_gedung', id_gedung);
    onGetTasks()
  }

  const handleOnRefresh = () => {
    onGetGedung();
    onGetTasks();
  }

  const getItem = (_data, index) => ({..._data[index]});
  
  const getItemCount = _data => taskScreen.results.length;

  const getItemLayout = (data, index) => ({length: 300, offset: 300 * index, index});

  const renderItem = ({item}) => {
    return (
      <Box px={3} mb={5}>
        <CardTaskList data={item} access={screenAccess} navigation={navigation} />
      </Box>
    );
  }

  const InputStartDate = forwardRef(({ value, onClick }, ref) => (
    <Input 
      value={moment(taskScreen.start_dt).format("DD/MM/YYYY")} 
      isReadOnly="true"
      onFocus={onClick} 
      borderRadius="lg" 
      variant="filled"
      ref={ref} 
      borderColor="primary.900" 
      InputLeftElement={<Icon as={<Box><MdCalendarToday/></Box>} size={5} ml="2" color="muted.400" />} 
      />
  ));

  const InputEndDate = forwardRef(({ value, onClick }, ref) => (
    <Input 
      value={moment(taskScreen.end_dt).format("DD/MM/YYYY")} 
      isReadOnly="true"
      onFocus={onClick} 
      borderRadius="lg" 
      variant="filled"
      ref={ref} 
      borderColor="primary.900" 
      InputLeftElement={<Icon as={<Box><MdCalendarToday/></Box>} size={5} ml="2" color="muted.400" />} 
      />
  ));

  return (
        <ContainerPage>
          <Box mt={3} mx={3}>
            <Heading size="md">Task List</Heading>
            <Text fontWeight="bold" mb="1">Periode</Text>
            <Stack direction="row" justifyContent="space-between">
            <Box flex={1}>
              <DatePicker customInput={<InputStartDate/>} portalId='root-portal' className='input-datepicker' popperClassName='pooper-datepicker' selected={taskScreen.start_dt} onChange={(date) => onChangeStartDt(date)} maxDate={taskScreen.end_dt} />
            </Box>
            <Text fontWeight="bold" mx={2} alignSelf="center">s.d</Text>
            <Box flex={1}>
              <DatePicker customInput={<InputEndDate/>} portalId='root-portal' className='input-datepicker' popperClassName='pooper-datepicker' selected={taskScreen.end_dt} onChange={(date) => onChangeEndDt(date)} minDate={taskScreen.start_dt} />
            </Box>
            </Stack>
            <Box mt={3}>
              <Select
                menuPortalTarget={document.body}
                getOptionLabel={option => `${option.nm_gedung}`}
                getOptionValue={option => option.id}
                options={taskScreen.gedung}
                value={taskScreen.gedung.filter(({id}) => id === taskScreen.id_gedung)}
                onChange={(selectedOption) => handleOnSelectGedung((selectedOption==null) ? " " : selectedOption.id)}
              />
              {/* <Select 
                variant='filled' 
                borderColor="primary.900" 
                selectedValue={taskScreen.id_gedung} 
                minWidth="200" 
                accessibilityLabel="Pilih Gedung" 
                placeholder="Pilih Gedung" 
                _selectedItem={{endIcon: <CheckIcon color="primary.900" size="5"/>}} 
                my={2} 
                onValueChange={itemValue => handleOnSelectGedung(itemValue)}>
                  <Select.Item label="Semua Gedung" value="" />
                  {
                    taskScreen.gedung.map((rGedung, idxG) => {
                      return (
                        <Select.Item label={rGedung.nm_gedung} value={rGedung.id} />
                      );
                    })
                  }
                </Select> */}
            </Box>
          </Box>
          <Flex flex={1}>
            {/* {
              taskScreen.loading && <VStack space={7} px={3} mt={2} mb={35}>
                {[...Array(3)].map((x, i) => (
                  <CardListPreloader />
                ))}
              </VStack>
            }
            {!taskScreen.loading && <Box>
              {taskScreen.results.length == 0 && <Box px={3} mb={5}><MyAlert status="info" title="Maaf, Hasil belum ditemukan."/></Box>}    
              {taskScreen.results.length > 0 && <VirtualizedList
                refreshing={false}
                data={taskScreen.results}
                renderItem={renderItem}
                onRefresh={() => handleOnRefresh()}
                keyExtractor={item => item.date_create}
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                getItem={getItem}
                getItemCount={getItemCount}
                initialNumToRender={5}
                updateCellsBatchingPeriod={50}
                maxToRenderPerBatch={5}
                windowSize={5}
                removeClippedSubviews={false}
                getItemLayout={getItemLayout}
              ></VirtualizedList>}
            </Box>
            } */}
            {/* <FlatList 
              refreshing={false}
              data={taskScreen.results}
              renderItem={({item}) => (
              <Box px={3} mb={5}>
                {
                  taskScreen.loading && <VStack space={5}>
                    {[...Array(3)].map((x, i) => (
                      <CardListPreloader />
                    ))}
                  </VStack>
                }
                {!taskScreen.loading && <CardTaskList data={item} access={screenAccess} navigation={navigation} />}
              </Box>)} 
              onRefresh={() => handleOnRefresh()}
              keyExtractor={item => item.id}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              initialNumToRender={10}
            >
            </FlatList> */}
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              {
                taskScreen.loading && <VStack space={7} px={3} mt={2} mb={35}>
                  {[...Array(3)].map((x, i) => (
                    <CardListPreloader key={i} />
                  ))}
                </VStack>
              }
              {
                !taskScreen.loading &&
              <VStack space={7} px={3} mt={2} mb={35}>
                {taskScreen.results.length == 0 && <MyAlert status="info" title="Maaf, Hasil belum ditemukan."/>}
                {taskScreen.results.length > 0 && taskScreen.results.map((row, i) => (
                  <CardTaskList key={i} data={row} access={screenAccess} navigation={navigation} />
                ))}
              </VStack>
              }
            </ScrollView>
          </Flex>
          {/* <Fab onPress={() => navigation.navigate('scan_task', {data_type:"task"})} renderInPortal={false} shadow={2} size="lg" icon={<Icon color="white" as={AntDesign} name="scan1" size="lg" />} /> */}
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  taskScreen: makeSelectTaskScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetGedung: () => dispatch(getGedung()),
    onGetTasks: () => dispatch(getTasks()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(TaskScreen);