import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the VerifPelaporanScreen state domain
 */

const selectVerifPelaporanScreenDomain = state =>
  state.verifPelaporanScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by VerifPelaporanScreen
 */

const makeSelectVerifPelaporanScreen = () =>
  createSelector(
    selectVerifPelaporanScreenDomain,
    substate => substate,
  );

export default makeSelectVerifPelaporanScreen;
export { selectVerifPelaporanScreenDomain };
