export const DEFAULT_ACTION = 'screen/ApprovalMaintainFormVendorScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/ApprovalMaintainFormVendorScreen/UPDATE_INPUT';

export const GET_APPROVAL_DETAIL = 'screen/ApprovalMaintainFormVendorScreen/GET_APPROVAL_DETAIL';
export const GET_APPROVAL_DETAIL_LOADING = 'screen/ApprovalMaintainFormVendorScreen/GET_APPROVAL_DETAIL_LOADING';
export const GET_APPROVAL_DETAIL_SUCCESS = 'screen/ApprovalMaintainFormVendorScreen/GET_APPROVAL_DETAIL_SUCCESS';
export const GET_APPROVAL_DETAIL_FAIL = 'screen/ApprovalMaintainFormVendorScreen/GET_APPROVAL_DETAIL_FAIL';

export const GET_NEGO_PENAWARAN = 'screen/ApprovalMaintainFormVendorScreen/GET_NEGO_PENAWARAN';
export const GET_NEGO_PENAWARAN_LOADING = 'screen/ApprovalMaintainFormVendorScreen/GET_NEGO_PENAWARAN_LOADING';
export const GET_NEGO_PENAWARAN_SUCCESS = 'screen/ApprovalMaintainFormVendorScreen/GET_NEGO_PENAWARAN_SUCCESS';
export const GET_NEGO_PENAWARAN_FAIL = 'screen/ApprovalMaintainFormVendorScreen/GET_NEGO_PENAWARAN_FAIL';

export const SUBMIT_APPROVAL = 'screen/ApprovalMaintainFormVendorScreen/SUBMIT_APPROVAL';
export const SUBMIT_APPROVAL_LOADING = 'screen/ApprovalMaintainFormVendorScreen/SUBMIT_APPROVAL_LOADING';
export const SUBMIT_APPROVAL_SUCCESS = 'screen/ApprovalMaintainFormVendorScreen/SUBMIT_APPROVAL_SUCCESS';
export const SUBMIT_APPROVAL_FAIL = 'screen/ApprovalMaintainFormVendorScreen/SUBMIT_APPROVAL_FAIL';

export const GET_VERIF_ASSET = 'screen/ApprovalMaintainFormVendorScreen/GET_VERIF_ASSET';
export const GET_VERIF_ASSET_LOADING = 'screen/ApprovalMaintainFormVendorScreen/GET_VERIF_ASSET_LOADING';
export const GET_VERIF_ASSET_SUCCESS = 'screen/ApprovalMaintainFormVendorScreen/GET_VERIF_ASSET_SUCCESS';
export const GET_VERIF_ASSET_FAIL = 'screen/ApprovalMaintainFormVendorScreen/GET_VERIF_ASSET_FAIL';