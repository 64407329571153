export const DEFAULT_ACTION = 'screen/VerifPelaporanInputScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/VerifPelaporanInputScreen/UPDATE_INPUT';

export const GET_VERIFY_PELAPORAN = 'screen/VerifPelaporanInputScreen/GET_VERIFY_PELAPORAN';
export const GET_VERIFY_PELAPORAN_LOADING = 'screen/VerifPelaporanInputScreen/GET_VERIFY_PELAPORAN_LOADING';
export const GET_VERIFY_PELAPORAN_SUCCESS = 'screen/VerifPelaporanInputScreen/GET_VERIFY_PELAPORAN_SUCCESS';
export const GET_VERIFY_PELAPORAN_FAIL = 'screen/VerifPelaporanInputScreen/GET_VERIFY_PELAPORAN_FAIL';

export const SUBMIT_PELAPORAN = 'screen/VerifPelaporanInputScreen/SUBMIT_PELAPORAN';
export const SUBMIT_PELAPORAN_LOADING = 'screen/VerifPelaporanInputScreen/SUBMIT_PELAPORAN_LOADING';
export const SUBMIT_PELAPORAN_SUCCESS = 'screen/VerifPelaporanInputScreen/SUBMIT_PELAPORAN_SUCCESS';
export const SUBMIT_PELAPORAN_FAIL = 'screen/VerifPelaporanInputScreen/SUBMIT_PELAPORAN_FAIL';

export const ADD_IMAGE = 'screen/VerifPelaporanInputScreen/ADD_IMAGE';
export const DEL_IMAGE = 'screen/VerifPelaporanInputScreen/DEL_IMAGE';