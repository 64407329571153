import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getOfferDetail(id_offer) {
  return {
    type: constants.GET_OFFER_DETAIL,
    id_offer
  };
}

export function getOfferDetailLoading() {
  return {
    type: constants.GET_OFFER_DETAIL_LOADING
  };
}

export function getOfferDetailFail(error) {
  return {
    type: constants.GET_OFFER_DETAIL_FAIL,
    error
  };
}

export function getOfferDetailSuccess(results) {
  return {
    type: constants.GET_OFFER_DETAIL_SUCCESS,
    results
  };
}