/*
 *
 * PelaporanHistoryDetailScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    results: [],
    raw: null,
    pelaporan: null,
    history_type: 0,
    attachment: [],
    raw_detail:{}
};

/* eslint-disable default-case, no-param-reassign */
const PelaporanHistoryDetailScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    let dt_detail;
    let dt_attachment;
    switch (action.type) {
      case DEFAULT_ACTION:
        break;
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.GET_PELAPORAN_HISTORY:
        draft.loading = true;
        break;
      case constants.GET_PELAPORAN_HISTORY_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data pelaporan.';
        draft.results = initialState.results;
        break;
      case constants.GET_PELAPORAN_HISTORY_SUCCESS:
        // console.log('reducer-action-new : ', draft.history_type);
        dt_detail = action.results.data[0];
        dt_attachment = action.results.attachment;
        
        if(draft.history_type > 1){
          dt_attachment = action.results.attachment_verif;
        }
        
        if(dt_detail === undefined){
          dt_detail = {
            nama_inventaris: action.results.data.nama_inventaris,
            nama_ruang: action.results.data.nama_ruang,
            nm_vendor: action.results.data.nm_vendor,
            description_progres: action.results.data.description_progres,
            description_feedback: action.results.data.description_feedback,
          };
          dt_attachment = action.results.attachment_verif;
        }
        
        draft.results = action.results;
        draft.attachment = dt_attachment;
        draft.raw_detail = dt_detail;
        // draft.attachment = action.results.attachment;
        // draft.raw_detail = action.results.data[0];
        draft.loading = false;
        draft.error = false;
        break;
    }
  });

export default PelaporanHistoryDetailScreenReducer;
