import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function delImage(idx) {
  return {
    type: constants.DEL_IMAGE,
    idx
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function submitPelaporan(action_type) {
  return {
    type: constants.SUBMIT_PELAPORAN,
    action_type
  };
}

export function submitPelaporanFail(error) {
  return {
    type: constants.SUBMIT_PELAPORAN_FAIL,
    error
  };
}

export function submitPelaporanSuccess(results, action_type) {
  return {
    type: constants.SUBMIT_PELAPORAN_SUCCESS,
    results,
    action_type
  };
}

export function getVerifyPelaporan(id_korektif) {
  return {
    type: constants.GET_VERIFY_PELAPORAN,
    id_korektif
  };
}

export function getVerifyPelaporanFail(error) {
  return {
    type: constants.GET_VERIFY_PELAPORAN_FAIL,
    error
  };
}

export function addImage(asset) {
  return {
    type: constants.ADD_IMAGE,
    asset
  };
}

export function getVerifyPelaporanSuccess(results, attachment, attachment_verif) {
  return {
    type: constants.GET_VERIFY_PELAPORAN_SUCCESS,
    results,
    attachment,
    attachment_verif
  };
}