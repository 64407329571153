export const DEFAULT_ACTION = 'screen/VendorProgressFormScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/VendorProgressFormScreen/UPDATE_INPUT';

export const SUBMIT_PROGRESS = 'screen/VendorProgressFormScreen/SUBMIT_PROGRESS';
export const SUBMIT_PROGRESS_LOADING = 'screen/VendorProgressFormScreen/SUBMIT_PROGRESS_LOADING';
export const SUBMIT_PROGRESS_SUCCESS = 'screen/VendorProgressFormScreen/SUBMIT_PROGRESS_SUCCESS';
export const SUBMIT_PROGRESS_FAIL = 'screen/VendorProgressFormScreen/SUBMIT_PROGRESS_FAIL';

export const ADD_IMAGE = 'screen/VendorProgressFormScreen/ADD_IMAGE';
export const DEL_IMAGE = 'screen/VendorProgressFormScreen/DEL_IMAGE';

export const GET_PROGRESS_REPORT = 'screen/VendorProgressFormScreen/GET_PROGRESS_REPORT';
export const GET_PROGRESS_REPORT_LOADING = 'screen/VendorProgressFormScreen/GET_PROGRESS_REPORT_LOADING';
export const GET_PROGRESS_REPORT_SUCCESS = 'screen/VendorProgressFormScreen/GET_PROGRESS_REPORT_SUCCESS';
export const GET_PROGRESS_REPORT_FAIL = 'screen/VendorProgressFormScreen/GET_PROGRESS_REPORT_FAIL';