import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectPelaporanDetailScreen from './selector';
import makeSelectPelaporanDetailInputScreen from '../PelaporanDetailInputScreen/selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView, RefreshControl, BackHandler, TouchableWithoutFeedback } from 'react-native';
import {
  AspectRatio,
  Icon,
  IconButton,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Image,
  Pressable,
  Divider, 
  Flex,
  ScrollView, 
  Button} from 'native-base';
import { MdCheck } from "react-icons/md";
import { FaCheckCircle, FaChevronRight } from "react-icons/fa";
import { ContainerPage } from '../../components/ContainerPage';
import TaskDetailPreloader from '../../components/TaskDetailPreloader';
import MyAlert from '../../components/MyAlert';
import MyAlertDialog from '../../components/MyAlertDialog';

import { commonStyle } from '../../styles';
import { getRoomAsset, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import history from '../../util/history';

export function PelaporanDetailScreen({
  dispatch,
  location,
  navigation,
  pelaporanDetailScreen,
  pelaporanDetailInputScreen,
  onUpdateInput,
  onGetRoomAsset
}){
  const[userData, setUserData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const {id_branch, id_item, new_data} = location.state;

  useEffect(() => {
    // console.log('My params:', route.params.new_data);
    if(!helper.isEmpty(new_data) && new_data){
        console.log("clear_report:", new_data);
        api.clearCurrInputPelaporan();
        // other action
        onGetRoomAsset(id_branch, id_item);
      }else{
        const curLapor = api.getCurrInputPelaporan();
        console.log("saved_lapor:", curLapor);
        onUpdateInput('currPelaporan', curLapor);
        onGetRoomAsset(id_branch, id_item);
      }
      
  }, [location.state?.new_data]);

  const handleGetData = () => {
    onGetRoomAsset(id_branch, id_item)
  }

  const handleSubmitLapor = () => {
    api.clearCurrInputPelaporan();
    history.push('pelaporan');
      // navigation.navigate('pelaporan');
  }

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'pelaporan', title: 'Pelaporan Kerusakan'}}>
          <Flex flex={1} minWidth="300px">
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
            {pelaporanDetailScreen.loading && <TaskDetailPreloader/> }
            {!pelaporanDetailScreen.loading && 
              <Flex mt={3}>
                {!pelaporanDetailScreen.dtGedung && <MyAlert status="info" title="Maaf, Aset belum ditemukan."/>}
                {pelaporanDetailScreen.dtGedung && 
                <VStack space={7} mb={35} mx={3}>
                  
                  <Box mt={5}>
                    <Center>
                      <HStack space={2} justifyContent="center" alignItems="center">
                        <Icon as={<Box><FaCheckCircle/></Box>} size="8" color="success.700" />
                        <Text color="darkText" fontWeight="bold" isTruncated maxWidth="90%" fontSize="lg">
                          {pelaporanDetailScreen.dtGedung.nm_gedung}
                        </Text>
                      </HStack>
                    </Center>
                  </Box>
                  <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    
                      <VStack>
                        <Center>
                          <Avatar size="lg" bg="primary.900" mb={4}>
                              {helper.getInitials(pelaporanDetailScreen.dtGedung.nm_gedung)}
                          </Avatar>
                        </Center>
                        <Center>
                          <Text color="darkText" fontSize="md" fontWeight="bold">{pelaporanDetailScreen.dtGedung.nm_ruangan}</Text>
                        </Center>
                        <Center>
                          <Text color="muted.400"  fontSize="sm">{pelaporanDetailScreen.dtGedung.nm_grp_ruangan} - {pelaporanDetailScreen.dtGedung.nm_lantai}</Text>
                        </Center>
                        <Center>
                          {/* <Text mt={10} color="darkText" fontSize="sm">26-10-2022 11:24 WIB</Text> */}
                        </Center>
                      </VStack>
                  </Box>

                  <Box borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    <VStack m={4}>
                      {pelaporanDetailScreen.dtAset.map((row, idx) => {
                        const params = {
                          id_branch: id_branch, 
                          id_item: id_item, 
                          id_aset: row.id_aset,
                          nm_aset: row.nama_aset,
                          dtGedung: pelaporanDetailScreen.dtGedung,
                          id_pelaporan: row.id_pelaporan,
                          origin_screen: 'pelaporan-detail'
                        };
                        return (
                          <Pressable key={`li-${idx}`} onPress={() => history.push('pelaporan-form', params)}>
                            <HStack justifyContent="center" alignItems="center">
                              <Icon as={<Box><FaCheckCircle/></Box>} size="lg" color={row.id_pelaporan == 0 ? "muted.400":"success.700"}/>
                              <Flex flex={1} mx={3}>
                                <Text color="darkText">{row.nama_aset}</Text>
                              </Flex>
                              <Icon as={<Box><FaChevronRight/></Box>} size="sm" color="muted.400"/>
                            </HStack>
                            {(pelaporanDetailScreen.dtAset.length > 1 && idx < (pelaporanDetailScreen.dtAset.length-1)) && <Divider my={3}/>}
                          </Pressable>
                        )
                      })}
                      
                    </VStack>
                  </Box>
                  
                  <Box borderTopRadius="lg" shadow={3}>
                    <Button colorScheme="primary" onPress={() => setIsOpen(true)}>Laporkan</Button>
                  </Box>
                  
                </VStack>
                }
              </Flex>
            }
            </ScrollView>
          </Flex>
          <MyAlertDialog 
            alertTitle="Simpan Laporan" 
            alertDesc="Apakah anda yakin ?"
            alertNoLabel="Tidak"
            alertYesLabel="Ya"
            alertIsOpen={isOpen}
            alertOnClose={onClose}
            alertOnConfirm={handleSubmitLapor}
          />
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  pelaporanDetailScreen: makeSelectPelaporanDetailScreen(),
  pelaporanDetailInputScreen: makeSelectPelaporanDetailInputScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetRoomAsset: (id_branch, id_item) => dispatch(getRoomAsset(id_branch, id_item)), 
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(PelaporanDetailScreen);