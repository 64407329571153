import React, {memo} from "react";
import { commonStyle } from "../styles";
import {
    Icon,
    HStack, 
    Avatar, 
    Heading, 
    VStack, 
    Box, 
    Center,
    Stack,
    Badge,
    Input,
    Image,
    IconButton,
    Pressable,
    Divider,
    HamburgerIcon,
    Menu,
    Text, 
    Flex } from 'native-base';
  import BadgeStatus from "./BadgeStatus";
  import moment from "moment";
  import helper from "../util/helper";
  import {MdRemoveRedEye, MdModeEdit, MdHistory, MdMoreVert } from 'react-icons/md';
  import {FaCalendarAlt, FaEnvelope, FaAngleDown } from 'react-icons/fa';

const CardPelaporanList = (props) => {
  const {data, access, history} = props;
  
  return (
    <Box width="100%" backgroundColor={commonStyle.baseColor} shadow={3} borderRadius="lg">
      <VStack>
        <Box px={5} py={3} backgroundColor="#ffd13c" borderTopRadius="lg">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Icon color="darkText" as={<Box><FaCalendarAlt/></Box>} size={5}></Icon>
            </Box>
            <Box flex={1} ml={1}>
              <Text color="darkText" fontWeight={500} >{moment(data.date_report, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")}</Text>
            </Box>
            <Box ml={3}>
              <BadgeStatus status={data.status}/>
            </Box>
            <Box ml={2} alignItems="center" justifyContent="center" width={8} height={8}>
              <Menu mt={2} py={2} placement="bottom right" trigger={triggerProps => {
                return <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                        <Icon as={<Box><MdMoreVert/></Box>} size={8} color="yellow.600" />
                      </Pressable>;
              }}>
                {access.includes('01:VIEW') && 
                <Menu.Item p={1} onPress={() => history.push('pelaporan-form',{rawData:data, id_pelaporan: data.id, viewOnly: true, origin_screen: 'pelaporan'})}>
                  <HStack space={2} justifyContent="center" alignItems="center">
                    <Icon as={<Box><MdRemoveRedEye/></Box>} size="md" color="info.700" />
                    <Text color="darkText" fontSize="md">
                      View
                    </Text>
                  </HStack>
                </Menu.Item>
                }
                {/* <Divider w="100%" /> */}
                {(access.includes('01:EDIT') && data.status == 'Created') && 
                <Menu.Item p={1} onPress={() => history.push('pelaporan-form',{rawData:data, id_pelaporan: data.id, origin_screen: 'pelaporan'})}>
                  <HStack space={2} justifyContent="center" alignItems="center">
                    <Icon as={<Box><MdModeEdit/></Box>} size="md" color="info.700" />
                    <Text color="darkText" fontSize="md">
                      Edit
                    </Text>
                  </HStack>
                </Menu.Item>
                }

                {/* <Divider w="100%" /> */}
                {access.includes('01:VIEW') && 
                <Menu.Item p={1} onPress={() => history.push('pelaporan-history',{rawData:data, id_pelaporan: data.id})}>
                  <HStack space={2} justifyContent="center" alignItems="center">
                    <Icon as={<Box><MdHistory/></Box>} size="md" color="info.700" />
                    <Text color="darkText" fontSize="md">
                      Riwayat Perbaikan
                    </Text>
                  </HStack>
                </Menu.Item>
                }
              </Menu>
            </Box>
          </Stack>
        </Box>
        <Box p={5}>
          <VStack>
            <Flex flexDirection="row">
              <Box>
                <Center>
                  <Avatar size="md" bg="primary.900" mr="1">
                      {helper.getInitials(data.nm_gedung)}
                  </Avatar>
                </Center>
              </Box>
              <VStack width="90%">
                <Text ml={3} fontSize="md" color={commonStyle.primaryColor} isTruncated width="80%" fontWeight="bold">{data.nm_gedung}</Text>
                <Text ml={3} fontSize="sm" color="muted.500" isTruncated width="80%">{data.addres}</Text>
              </VStack>
            </Flex>
            <Flex flexDirection="row" mt={4}>
              <Box>
                <Center>
                  <Avatar size="md" bg="primary.900" mr="1">
                      {helper.getInitials(data.name_employee)}
                  </Avatar>
                </Center>
              </Box>
              <VStack width="90%">
                <Text ml={3} fontSize="md" color={commonStyle.primaryColor} isTruncated width="80%" fontWeight="bold">{data.name_employee}</Text>
                <Text ml={3} fontSize="sm" color="muted.500" isTruncated width="80%">{data.hp}</Text>
              </VStack>
            </Flex>
            <Box mt={5} backgroundColor="light.200" borderRadius="lg" width="100%">
              <HStack>
                <Flex flex={1} p={2}>
                  <Flex flexDirection="row" alignItems="center">
                    <Image source={require('../images/logo.png')} size="2xs" alt='Logo' />
                    <Text ml={2} color="darkText" isTruncated maxW="300" width="90%" fontWeight="bold">{data.nm_ruang}</Text>
                  </Flex>
                </Flex>
              </HStack>
            </Box>
            
          </VStack>
        </Box>
      </VStack>
  </Box>
  );
}

export default memo(CardPelaporanList);