import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectForgetPassScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import { ActivityIndicator, ScrollView, Dimensions, BackHandler, View, TouchableOpacity, Platform, useColorScheme } from 'react-native';
import { Container, Heading, VStack, Box, Alert, Text, Input, Image, Button, FormControl, Stack, WarningOutlineIcon, Center, HStack, Flex, Modal, Pressable } from 'native-base';
import { ContainerScreen } from '../../components/ContainerScreen';
import MyAlert from '../../components/MyAlert';
import MyAlertDialog from '../../components/MyAlertDialog';
import { commonStyle } from '../../styles';
import helper from '../../util/helper';
import history from '../../util/history';
import { defaultAction, forgetPass, updateInput } from './actions';

export function ForgetPassScreen({
  dispatch,
  navigation,
  forgetPassScreen,
  onUpdateInput,
  onForgetPass
}){

  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);

  useEffect(() => {
    dispatch(defaultAction());
  },[]);

  useEffect(() => {
    onUpdateInput('responseState','');

    if(forgetPassScreen.responseState == 'success'){
      setIsOpen(true);
    }
    if(forgetPassScreen.responseState == 'error'){
      // helper.warningToast(forgetPassScreen.msg, 5000, 'top');
      setIsOpen(true);
    }
  },[forgetPassScreen.responseState]);

  const handleSubmit = () => {
    if(helper.isEmpty(forgetPassScreen.email)){
      helper.warningToast("Isikan email akun Anda.");
      return;
    }
    onForgetPass();
  }
      return (
        <ContainerScreen>
          <Flex flex={1} justifyContent="center" mt={100}>
            <Image alt='logo' style={{width: 300, height:83, alignSelf:'center', marginBottom:30}} source={require('../../images/logo-white-h.png')}/>
            <Box>
              <Center>
                <Box w="100%" maxWidth="300px">
                  <Heading size="md" my={3}>
                    Lupa Password
                  </Heading>
                  <Text mb={4}>Silahkan masukkan email anda yang terdaftar pada aplikasi <Text bold>adikandung</Text></Text>
                  <FormControl isRequired>
                    <Stack>
                      <FormControl.Label>Email</FormControl.Label>
                      <Input onChangeText={txt => onUpdateInput('email', txt)} type="email" backgroundColor="common.100" borderRadius="lg" variant="filled" borderColor="warmGray.200" placeholder="Ketikkan Email" />
                      <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                        Email are required.
                      </FormControl.ErrorMessage>
                    </Stack>
                  </FormControl>
                  <Stack direction="row" my={4}>
                    <Box flex={2}>
                      <Button isLoading={forgetPassScreen.loading} spinnerPlacement="end" isLoadingText="Submitting" onPress={() => handleSubmit()}>Kirim</Button>
                    </Box>
                    {/* <Box flex={1}>
                      <Button onPress={() => console.log("hello world")}>Click Me</Button>
                    </Box> */}
                  </Stack>
                  <Box mx={4}>
                    <Flex flexDirection="row" justifyContent="flex-end">
                      <Pressable onPress={() => history.push("/login")}>
                        <Text style={commonStyle.linkText} fontWeight="bold">Punya Akun ?</Text>
                      </Pressable>
                    </Flex>
                  </Box>
                  {/* {forgetPassScreen.msg != "" &&
                  <Box mt={3}>
                    <Alert maxWidth="95%" alignSelf="center" flexDirection="row" status={forgetPassScreen.isValid ? "success" :"warning"} variant="subtle">
                      <VStack space={1} flexShrink={1} w="100%">
                        <HStack flexShrink={1} alignItems="center" justifyContent="space-between">
                          <HStack space={2} flexShrink={1} alignItems="center">
                            <Alert.Icon />
                            <Text fontSize="md" fontWeight="medium" flexShrink={1} color="darkText">
                              {forgetPassScreen.isValid ? "Sukses" :"Perhatian"}
                            </Text>
                          </HStack>
                        </HStack>
                        <Text px="6" color="darkText">
                          {forgetPassScreen.msg}
                        </Text>
                      </VStack>
                    </Alert>
                  </Box>
                  } */}
                </Box>
              </Center>
            </Box>
          </Flex>
          <Modal isOpen={isOpen} onClose={() => onClose()}>
            <Modal.Content maxWidth="400px">
              <Modal.CloseButton />
              <Modal.Header>Reset Password</Modal.Header>
              <Modal.Body>
                <Text color={forgetPassScreen.error ? 'red.500':'success.700'} m={3}>{forgetPassScreen.msg}</Text>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => onClose()}>
                    Tutup
                  </Button>
                  {!forgetPassScreen.error &&
                    <Button onPress={() => history.push('login')}>
                      Ok
                    </Button>
                  }
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </ContainerScreen>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  forgetPassScreen: makeSelectForgetPassScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onForgetPass: () => dispatch(forgetPass()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(ForgetPassScreen);