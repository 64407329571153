import React, { Component, useState, useEffect, forwardRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectApprovalMaintainFormPreventifScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView, TouchableWithoutFeedback, BackHandler, RefreshControl } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Image,
  Pressable,
  Divider, 
  Flex,
  Stack,
  Fab,
  Actionsheet,
  FormControl,
  TextArea,
  ScrollView,
  Menu,
  Select,
  CheckIcon,
  Input, 
  Button} from 'native-base';
import moment from 'moment';
import DatePicker from "react-datepicker";
import {FaCalendarAlt, FaCheckCircle, FaGenderless, FaHourglassHalf, FaHouseUser, FaTh} from 'react-icons/fa';
import { ContainerPage } from '../../components/ContainerPage';
import MyAlert from '../../components/MyAlert';
import MyAlertDialog from '../../components/MyAlertDialog';
import ImageSlider from '../../components/ImageSlider';
import { commonStyle } from '../../styles';
import { defaultAction, deleteRowPreventif, getApprovalDetail, getVerifyAssets, getWorkOrderVerif, submitApprovalPreventif, submitWOVerif, updateInput, updateInputPreventif } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import history from '../../util/history';
import { MdCalendarToday, MdDelete } from 'react-icons/md';
import CardListPreloader from '../../components/CardListPreloader';
import { getLantai } from '../../util/actions';
import makeSelectGlobalVar from '../../util/selector';
import MySelect2 from '../../components/MySelect2';

export function ApprovalMaintainFormPreventifScreen({
  dispatch,
  location,
  navigation,
  globalVar,
  approvalMaintainFormPreventifScreen,
  onUpdateInput,
  onGetResult,
  onGetLantai,
  onDeleteRowPreventif,
  onUpdateInputPreventif,
  onGetResultAssets,
  onSubmitApprovalPreventif
}){
  const {rawData, id_apv, readOnly, addNew} = location.state;
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const [isOpenCard, setIsOpenCard] = useState(false);
  const onCloseCard = () => setIsOpenCard(false);
  const [actionType, setActionType] = useState();
  const [preventifIdx, setPreventifIdx] = useState();
  const [idWorkOrder, setIdWorkOrder] = useState();
  const[userData, setUserData] = useState();
  const imageList = [
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
  ];
  useEffect(() => {
      const user = api.getUserData();
        console.log("saved_user:", user);
        console.log("initial:", helper.getInitials(user.user_name));
        console.log("rawData:", rawData);
        setUserData(user);

      dispatch(defaultAction());
      onUpdateInput('id_apv', id_apv);
      onUpdateInput('nm_ruangan', rawData.nm_ruangan);
      onUpdateInput('nm_gedung', rawData.nm_gedung);
      onUpdateInput('code_proses', rawData.code_proses);
      onUpdateInput('rawApv', rawData);
      onUpdateInput('new_data', addNew);
      onGetResult(id_apv, rawData.code_proses, rawData.date_realisasi);
      onGetLantai(rawData.id_gedung);
  },[]);
  
  const handleGetData = () => {
    onGetResultAssets(rawData.id);
  }

  useEffect(() => {
    onUpdateInput('responseState','');
    
    if(approvalMaintainFormPreventifScreen.responseState == 'approved'){
      helper.successToast("Anda telah menyetujui laporan ini");
    }
    if(approvalMaintainFormPreventifScreen.responseState == 'done'){
      helper.successToast("Anda telah menyelesaikan laporan ini");
    }
    if(approvalMaintainFormPreventifScreen.responseState == 'rejected'){
      helper.warningToast("Anda telah menolak laporan ini")
    }
    if(approvalMaintainFormPreventifScreen.responseState == 'error'){
      helper.errorToast("Verifikasi laporan belum berhasil")
    }
  },[approvalMaintainFormPreventifScreen.responseState]);

  const handleOnSubmit = (action_type) => {
    if(action_type == '10'){
      helper.warningToast("Anda telah menolak laporan ini");
      history.replace('approval-maintenance');
      return;
    }

    if(helper.isEmpty(approvalMaintainFormPreventifScreen.notes) || approvalMaintainFormPreventifScreen.notes.length < 10){
      helper.warningToast("Isikan hasil verifikasi, Minimal 10 karakter.");
      return;
    }

    setActionType(action_type);
    // setIdWorkOrder(id_wo)
    setIsOpen(true);
  };

  const handleDoSubmit = () => {
    setIsOpen(false);
    onSubmitApprovalPreventif(actionType, idWorkOrder);
  };

  const InputStartDate = forwardRef(({ value, onClick }, ref) => (
    <Input 
      width="full"
      value={moment(approvalMaintainFormPreventifScreen.est_dt).format("DD/MM/YYYY")} 
      isReadOnly="true"
      onFocus={onClick} 
      borderRadius="lg" 
      variant="filled"
      ref={ref} 
      borderColor="primary.900" 
      InputLeftElement={<Icon as={<Box><MdCalendarToday/></Box>} size={5} ml="2" color="muted.400" />} 
      />
  ));

  const onChangeEstDt = (selectedDate) => {
    const currentDate = selectedDate;
    onUpdateInput("est_dt", currentDate);
    // onGetResult();
  };

  const handleOnDeleteRow = idx => {
        setIsOpenCard(true);
        setPreventifIdx(idx);
  }

  const CardPreventif = (props) => {
    const {deleteRow, rowId, onUpdateInput, rowData, globalVar} = props;

    const handleChangeLantai = (id_lantai) => {
      let id_lt = parseInt(id_lantai) || 0;
      const idxLantai = globalVar.list_lantai.map(function(x) {return x.id; }).indexOf(id_lt);
      // console.log('rlantai', idxLantai, globalVar.list_lantai[idxLantai]);
      onUpdateInput(rowId, 'id_lantai', id_lt);
      onUpdateInput(rowId, 'nm_lantai', globalVar.list_lantai[idxLantai].name.toString());
    }

    const handleChangePetugas = (id_pg) => {
      let id_peg = parseInt(id_pg) || 0;
      const idxPeg = globalVar.list_pegawai.map(function(x) {return x.id; }).indexOf(id_peg);
      // console.log('rpeg', idxPeg, globalVar.list_pegawai[idxPeg]);
      onUpdateInput(rowId, 'id_pegawai', id_peg);
      onUpdateInput(rowId, 'nm_pegawai', globalVar.list_pegawai[idxPeg].name_employee);
    }

    return (
      <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
        <VStack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box>
                <Icon color="darkText" as={<Box><FaGenderless/></Box>} size={5}></Icon>
              </Box>
              <HStack flex={1} ml={1} justifyContent="space-between">
                <Text color="darkText" fontWeight={500} >Approval Detail</Text>
                {!readOnly &&
                <Box>
                  <Pressable onPress={() => deleteRow(rowId)}>
                    <Icon as={<Box><MdDelete/></Box>} color="red.500" size="lg"></Icon>
                  </Pressable>
                </Box>
                }
              </HStack>
            </Stack>
            <Box mb={2}>
            <FormControl mb="1">
                <FormControl.Label>Lantai</FormControl.Label>
                {globalVar.list_lantai && 
                // <Select 
                //   variant='filled' 
                //   borderColor="primary.900" 
                //   selectedValue={rowData.id_lantai.toString()} 
                //   minWidth="200" 
                //   accessibilityLabel="Pilih Lantai" 
                //   placeholder="Pilih Lantai" 
                //   _selectedItem={{endIcon: <CheckIcon size="5"/>}} 
                //   my={2}
                //   onValueChange={itemValue => handleChangeLantai(itemValue)}>
                //     {globalVar.list_lantai.map((v, i) => (
                //       <Select.Item key={`lt${i}`} label={`${v.code}, ${v.name}`} value={`${v.id}`} />
                //       ))}
                // </Select>

                <MySelect2
                  key="opt-lantai"
                  isClearable
                  defaultValue="0"
                  placeholder="Pilih Lantai"
                  menuPortalTarget={document.body}
                  getOptionLabel={option => `${option.name}`}
                  getOptionValue={option => option.id}
                  options={globalVar.list_lantai}
                  value={globalVar.list_lantai.filter(({id}) => id === rowData.id_lantai)}
                  onChange={(selectedOption) => handleChangeLantai((selectedOption==null) ? 0 : selectedOption.id)}
                />

                  }
              </FormControl>
              <FormControl mb="1">
                <FormControl.Label>Pelaksana</FormControl.Label>
                  {globalVar.list_pegawai &&

                  <MySelect2
                    key="opt-pegawai"
                    isClearable
                    defaultValue="0"
                    placeholder="Pilih Pelaksana"
                    menuPortalTarget={document.body}
                    getOptionLabel={option => `${option.name_employee}`}
                    getOptionValue={option => option.id}
                    options={globalVar.list_pegawai}
                    value={globalVar.list_pegawai.filter(({id}) => id === rowData.id_pegawai)}
                    onChange={(selectedOption) => handleChangePetugas((selectedOption==null) ? 0 : selectedOption.id)}
                  />

                  // <Select 
                  // variant='filled' 
                  // borderColor="primary.900" 
                  // selectedValue={rowData.id_pegawai.toString()} 
                  // minWidth="200" 
                  // accessibilityLabel="Pilih Pelaksana" 
                  // placeholder="Pilih Pelaksana" 
                  // _selectedItem={{endIcon: <CheckIcon size="5"/>}} 
                  // my={2}
                  // onValueChange={itemValue => handleChangePetugas(itemValue)}>
                  //   {globalVar.list_pegawai.map((v, i) => (
                  //     <Select.Item key={`lt${i}`} label={`${v.name_employee}`} value={`${v.id}`} />
                  //     ))}
                  // </Select>
                  }
                </FormControl>
            </Box>
        
        </VStack>
      </Box>
    );
  }

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'approval-maintenance', title: 'Aproval Pemeliharaan'}}>
          <Flex flex={1}>
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              <VStack space={7} mt={2} mb={40} mx={3}>
                <Box mt={5}>
                    <Center>
                      <HStack space={2} justifyContent="center" alignItems="center">
                        <Icon as={<Box><FaCheckCircle/></Box>} size="8" color="success.700" />
                        <Text color="darkText" fontWeight="bold" isTruncated maxWidth="90%" fontSize="lg">
                          {approvalMaintainFormPreventifScreen.nm_gedung}
                        </Text>
                      </HStack>
                    </Center>
                  </Box>
                {/* <Box p={5} mt={3} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    <VStack>
                      <Center>
                        <Avatar size="lg" bg="primary.900" mb={4}>
                            {helper.getInitials(`Daun Pintu`)}
                        </Avatar>
                      </Center>
                      <Center>
                        <Text color="darkText" isTruncated maxWidth="90%" fontSize="md" fontWeight="bold">{`Daun Pintu`}</Text>
                      </Center>
                      <Center>
                        <Text color="muted.400" isTruncated maxWidth="90%" fontSize="sm">{dtProgress.code_inventaris}</Text>
                      </Center>
                      <Box mt={2}>
                        <VStack>
                          <Text fontSize="xs" color="muted.600">No Penawaran</Text>
                          <Text color="darkText" bold>{dtProgress.code_work_order}</Text>
                        </VStack>
                      </Box>
                      <Box mt={2}>
                        <VStack>
                          <Text fontSize="xs" color="muted.600">Deadline Pekerjaan</Text>
                          <Text color="darkText" bold>{moment(dtProgress.forcast_date_finish, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY")}</Text>
                        </VStack>
                      </Box>
                    </VStack>
                </Box> */}
                <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                  <FormControl mb="1">
                    <FormControl.Label>Perkiraan tanggal selesai</FormControl.Label>
                    <DatePicker customInput={<InputStartDate/>} portalId='root-portal' className='input-datepicker' popperClassName='pooper-datepicker' selected={approvalMaintainFormPreventifScreen.est_dt} onChange={(date) => onChangeEstDt(date)}/>
                  </FormControl>
                  <FormControl mb="1">
                    <FormControl.Label>Catatan</FormControl.Label>
                    <TextArea h={40} value={approvalMaintainFormPreventifScreen.notes} placeholder="Catatan Pemeliharaan" onChangeText={txt => onUpdateInput('notes', txt)} borderRadius="lg" w="100%" />
                  </FormControl>
                </Box>
                {
                  approvalMaintainFormPreventifScreen.loading && <VStack space={7} mt={2} mb={35}>
                    {[...Array(3)].map((x, i) => (
                      <CardListPreloader key={i} />
                    ))}
                  </VStack>
                }
                {!approvalMaintainFormPreventifScreen.loading &&
                <VStack space={7} mt={2} mb={35}>
                  {approvalMaintainFormPreventifScreen.list_preventif.length == 0 && <MyAlert status="info" title="Maaf, Hasil belum ditemukan."/>}
                  {approvalMaintainFormPreventifScreen.list_preventif.length > 0 && approvalMaintainFormPreventifScreen.list_preventif.map((row, i) => (
                    // <Box p={5} key={`inp-${i}`} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    //   <VStack>
                    //       <Stack direction="row" justifyContent="space-between" alignItems="center">
                    //         <Box>
                    //           <Icon color="darkText" as={<Box><FaGenderless/></Box>} size={5}></Icon>
                    //         </Box>
                    //         <HStack flex={1} ml={1} justifyContent="space-between">
                    //           <Text color="darkText" fontWeight={500} >Approval Detail</Text>
                    //           {!readOnly &&
                    //           <Box>
                    //             <Pressable>
                    //               <Icon as={<Box><MdDelete/></Box>} color="red.500" size="lg"></Icon>
                    //             </Pressable>
                    //           </Box>
                    //           }
                    //         </HStack>
                    //       </Stack>
                    //       <Box mb={2}>
                    //       <FormControl mb="1">
                    //           <FormControl.Label>Lantai</FormControl.Label>
                    //           {globalVar.list_lantai && 
                    //           <Select 
                    //             variant='filled' 
                    //             borderColor="primary.900" 
                    //             selectedValue={approvalMaintainFormPreventifScreen.id_lantai} 
                    //             minWidth="200" 
                    //             accessibilityLabel="Pilih Lantai" 
                    //             placeholder="Pilih Lantai" 
                    //             _selectedItem={{endIcon: <CheckIcon size="5"/>}} 
                    //             my={2}
                    //             onValueChange={itemValue => onUpdateInput('id_lantai', itemValue)}>
                    //               {globalVar.list_lantai.map((v, i) => (
                    //                 <Select.Item key={`lt${i}`} label={`${v.code}, ${v.name}`} value={`${v.id}`} />
                    //                 ))}
                    //             </Select>
                    //             }
                    //         </FormControl>
                    //         <FormControl mb="1">
                    //           <FormControl.Label>Pelaksana</FormControl.Label>
                    //             {globalVar.list_pegawai &&
                    //             <Select 
                    //             variant='filled' 
                    //             borderColor="primary.900" 
                    //             selectedValue={approvalMaintainFormPreventifScreen.id_petugas} 
                    //             minWidth="200" 
                    //             accessibilityLabel="Pilih Pelaksana" 
                    //             placeholder="Pilih Pelaksana" 
                    //             _selectedItem={{endIcon: <CheckIcon size="5"/>}} 
                    //             my={2}
                    //             onValueChange={itemValue => onUpdateInput('id_petugas', itemValue)}>
                    //               {globalVar.list_pegawai.map((v, i) => (
                    //                 <Select.Item key={`lt${i}`} label={`${v.name_employee}`} value={`${v.id}`} />
                    //                 ))}
                    //             </Select>
                    //             }
                    //           </FormControl>
                    //       </Box>
                      
                    //   </VStack>
                    // </Box>
                    <CardPreventif 
                      key={`inp-${i}`}
                      rowId={i}
                      globalVar={globalVar} 
                      rowData={row}
                      deleteRow={idx => handleOnDeleteRow(idx)}
                      onUpdateInput={(idx, name, val) => onUpdateInputPreventif(idx, name, val)}
                    />
                  ))}
                </VStack>
                }
              </VStack>
            </ScrollView>
            <Box p={3} mb={2} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
              <HStack>
                <Box flex={1} mr={3}>
                  <Button isLoading={approvalMaintainFormPreventifScreen.loading_submit} spinnerPlacement="end" isLoadingText="Submitting..." onPress={() => handleOnSubmit('20')} colorScheme="success" >Setujui</Button>
                </Box>
                <Box flex={1}>
                  <Button isLoading={approvalMaintainFormPreventifScreen.loading_submit} spinnerPlacement="end" isLoadingText="Submitting..." onPress={() => handleOnSubmit('10')} colorScheme="success" variant="outline" >Tolak</Button>
                </Box>
              </HStack>
            </Box>
          </Flex>
          <MyAlertDialog 
            alertTitle="Aproval Pemeliharaan" 
            alertDesc={`Apakah anda ingin ${actionType == '20' ? 'Menyetujui' : (actionType == '30' ? 'Menyelesaikan': 'Menolak')} laporan ini ?`}
            alertNoLabel="Tidak"
            alertYesLabel="Ya"
            alertIsOpen={isOpen}
            alertOnClose={onClose}
            alertOnConfirm={() => handleDoSubmit()}
          />
          <MyAlertDialog 
            alertTitle="Hapus Detail Preventif" 
            alertDesc={`Apakah anda ingin menghapus detail ini ?`}
            alertNoLabel="Tidak"
            alertYesLabel="Ya"
            alertIsOpen={isOpenCard}
            alertOnClose={onCloseCard}
            alertOnConfirm={() => {onDeleteRowPreventif(preventifIdx); setIsOpenCard(false);}}
          />
          {/* <Fab onPress={() => navigation.navigate('vendor_offer_form')} renderInPortal={false} shadow={2} size="lg" icon={<Icon color="white" as={AntDesign} name="doubleright" size="lg" />} /> */}
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  approvalMaintainFormPreventifScreen: makeSelectApprovalMaintainFormPreventifScreen(),
  globalVar: makeSelectGlobalVar(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetLantai: (id_gedung) => dispatch(getLantai(id_gedung)),
    onGetResult: (id, code_proses, dt_realisasi) => dispatch(getApprovalDetail(id, code_proses, dt_realisasi)),
    onDeleteRowPreventif: idx => dispatch(deleteRowPreventif(idx)),
    onUpdateInputPreventif: (rowid, name, value) => dispatch(updateInputPreventif({rowid, name, value})),
    onGetResultAssets: (id_work_order_report) => dispatch(getVerifyAssets(id_work_order_report)),
    onSubmitApprovalPreventif: (action_type, id_wo) => dispatch(submitApprovalPreventif(action_type, id_wo)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(ApprovalMaintainFormPreventifScreen);