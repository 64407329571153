import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getRoomAsset(resScan, data_type) {
  return {
    type: constants.GET_ROOM_ASSET,
    resScan, 
    data_type
  };
}

export function getRoomAssetLoading() {
  return {
    type: constants.GET_ROOM_ASSET_LOADING
  };
}

export function getRoomAssetFail(error) {
  return {
    type: constants.GET_ROOM_ASSET_FAIL,
    error
  };
}

export function getRoomAssetSuccess(results) {
  return {
    type: constants.GET_ROOM_ASSET_SUCCESS,
    results
  };
}

export function scanResult(result, data_type) {
  return {
    type: constants.SCAN_RESULT,
    result,
    data_type
  };
}

export function resetScan() {
  return {
    type: constants.RESET_SCAN
  };
}

export function scanned() {
  return {
    type: constants.SCANNED
  };
}