import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the FormChangePassScreen state domain
 */

const selectFormChangePassScreenDomain = state =>
  state.formChangePassScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by FormChangePassScreen
 */

const makeSelectFormChangePassScreen = () =>
  createSelector(
    selectFormChangePassScreenDomain,
    substate => substate,
  );

export default makeSelectFormChangePassScreen;
export { selectFormChangePassScreenDomain };
