import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectTaskPreventifFormScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView, RefreshControl, TouchableWithoutFeedback, PermissionsAndroid } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Image,
  Pressable,
  Divider,
  Checkbox,
  FormControl,
  TextArea,
  Select, 
  Flex,
  CheckIcon,
  ScrollView,
  Modal, 
  Button} from 'native-base';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import Files from 'react-files';
import 'react-html5-camera-photo/build/css/index.css';
import {MdAddAPhoto,MdDelete } from 'react-icons/md';
import {FaCheckCircle } from 'react-icons/fa';
import { ContainerPage } from '../../components/ContainerPage';
import { commonStyle } from '../../styles';
import { addImage, defaultAction, delImage, getDetailTodo, submitPreventif, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import moment from 'moment';

export function TaskPreventifFormScreen({
  dispatch,
  location,
  navigation,
  taskPreventifFormScreen,
  onUpdateInput,
  onGetDetailTodo,
  onAddImage,
  onDelImage,
  onSubmitPreventif,
}){
  const[userData, setUserData] = useState();
  const [kerusakan, setKerusakan] = useState();
  const [openCamera, setOpenCamera] = useState(false);
  const [openModalCamera, setOpenModalCamera] = useState(false);
  const {id_realisasi_preventif, id_aset, dtPreventif, readOnly} = location.state;

  useEffect(() => {
    // reset params
    dispatch(defaultAction());
    onUpdateInput('id_realisasi_preventif', id_realisasi_preventif);
    onUpdateInput('id_ruang', dtPreventif.id_ruang);
    onGetDetailTodo(id_realisasi_preventif, id_aset);
  },[]);

  const handleGetData = () => {
    onGetDetailTodo(id_realisasi_preventif, id_aset);
  }

  // const handleChoosePhoto = async () => {
  //   if(taskPreventifFormScreen.assets.length == 4){
  //     helper.infoToast("Anda melebihi batas maksimum yakni 4 foto.");
  //     return;
  //   }

  //   try {
  //     const granted = await PermissionsAndroid.request(
  //       PermissionsAndroid.PERMISSIONS.CAMERA,
  //       {
  //         title: "App Camera Permission",
  //         message:"App needs access to your camera",
  //         buttonNeutral: "Ask Me Later",
  //         buttonNegative: "Cancel",
  //         buttonPositive: "OK"
  //       }
  //     );
  //     if (granted === PermissionsAndroid.RESULTS.GRANTED) {
  //       console.log("Camera permission given");
  //       launchCamera({ 
  //         mediaType: 'photo',
  //         maxWidth: 800,
  //         maxHeight: 800,
  //         includeBase64: true,
  //         cameraType: 'back'
  //       }, (response) => {
  //         console.log(response);
  //         if (response) {
  //           if(!helper.isEmpty(response.assets)) onAddImage(response.assets[0]);
  //         }
  //       });
  //     } else {
  //       console.log("Camera permission denied");
  //     }
  //   } catch (err) {
  //     console.warn(err);
  //   }
  // };

  const handleChoosePhoto = async (data) => {
    setOpenCamera(false);
    console.log("img:", data);
    setTimeout(() => {
      setOpenModalCamera(false);
    }, 300);
    if(taskPreventifFormScreen.assets.length == 4){
      helper.infoToast("Anda melebihi batas maksimum yakni 4 foto.");
      return;
    }

    try {
      if(!helper.isEmpty(data)) onAddImage(data);
    } catch (err) {
      console.warn(err);
    }
  };

  const handleSubmit = () => {
    if(helper.isEmpty(taskPreventifFormScreen.description) || taskPreventifFormScreen.description.length < 10){
      helper.warningToast("Isikan deskripsi kerusakan, Minimal 10 karakter.");
      return;
    }
    // if(taskPreventifFormScreen.assets.length == 0){
    //   helper.warningToast("Bukti foto diperlukan min 1 foto.");
    //   return;
    // }

    console.log('desc:',taskPreventifFormScreen.description.length);
    onSubmitPreventif();
    // setSuccess(true);
  }

  const handleCameraOpen = () => {
    setOpenModalCamera(true);
    setTimeout(() => {
      setOpenCamera(true);
    }, 300);
  }

  function handleTakePhotoAnimationDone (dataUri) {
    // Do stuff with the photo...
    // console.log('takePhoto');
  }

  function handleCameraError (error) {
    // console.log('handleCameraError', error);
  }

  function handleCameraStart (stream) {
    // console.log('handleCameraStart : ', stream);
  }

  function handleCameraStop () {
    // console.log('handleCameraStop');
  }

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleFileChange = (files) => {
    console.log(files);
    const file = files[0];
    getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        const params = {
          base64: result,
          type: file.type,
          name: file.name,
        }

        onAddImage(params.base64);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleFileError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
    helper.errorToast(error.message);
  }

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'preventif-detail', title: 'Pemeliharaan Preventif', params:{id_realisasi_preventif: id_realisasi_preventif, readOnly: false}}}>
          <Flex flex={1}>
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              <VStack space={7} mt={2} mb={35} mx={3}>
                {/* <Box mt={5}>
                  <HStack>
                    <Flex>
                      <Box height={10} width={10} justifyContent="center" backgroundColor="primary.900" borderRadius="full">
                        <Center>
                          <Text color="white" bold fontSize="lg">1</Text>
                        </Center>
                      </Box>
                    </Flex>
                    <Flex flex={1} justifyContent="center">
                      <Center>
                        <Divider bg="primary.900" thickness="6" orientation="horizontal" />
                      </Center>
                    </Flex>
                    <Flex>
                      <Box height={10} width={10} justifyContent="center" backgroundColor="primary.900" borderRadius="full">
                        <Center>
                          <Text color="white" bold fontSize="lg">2</Text>
                        </Center>
                      </Box>
                    </Flex>
                    <Flex flex={1} justifyContent="center">
                      <Center>
                        <Divider bg={taskPreventifFormScreen.isSuccess ? "primary.900":"muted.300"} thickness="6" orientation="horizontal" />
                      </Center>
                    </Flex>
                    <Flex>
                      <Box height={10} width={10} justifyContent="center" backgroundColor={taskPreventifFormScreen.isSuccess ? "primary.900":"muted.300"} borderRadius="full">
                        <Center>
                          <Text color="white" bold fontSize="lg">3</Text>
                        </Center>
                      </Box>
                    </Flex>
                  </HStack>
                </Box> */}
                {!taskPreventifFormScreen.isSuccess && 
                <Box mt={5}>
                  <Center>
                    <HStack space={2} justifyContent="center" alignItems="center">
                      <Icon as={<Box><FaCheckCircle/></Box>} size="8" color="success.700" />
                      <Text color="darkText" fontWeight="bold" isTruncated maxWidth="90%" fontSize="lg">
                        {taskPreventifFormScreen.nm_ruangan}
                      </Text>
                    </HStack>
                  </Center>
                </Box>
                }
                {!taskPreventifFormScreen.isSuccess &&
                <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    <VStack>
                      <Box mb={2}>
                        <Text fontSize="md">Inventory</Text>
                        <Text fontWeight="bold">{taskPreventifFormScreen.nm_aset}</Text>
                      </Box>
                      
                      <Box mb={2}>
                        <FormControl mb="1">
                          <FormControl.Label>Jenis Kerusakan</FormControl.Label>
                          <Select 
                            variant='filled' 
                            borderColor="muted.300" 
                            selectedValue={taskPreventifFormScreen.id_condition} 
                            minWidth="200" 
                            accessibilityLabel="Pilih Jenis" 
                            placeholder="Pilih Jenis" 
                            _selectedItem={{endIcon: <CheckIcon size="5"/>}} 
                            my={2} 
                            onValueChange={itemValue => onUpdateInput('id_condition', itemValue)}>
                              <Select.Item label="Ringan" value="2" />
                              <Select.Item label="Berat" value="3" />
                            </Select>
                        </FormControl>
                      </Box>
                      <Box mb={2}>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Deskripsi Kerusakan</FormControl.Label>
                          <TextArea h={40} placeholder="Detail Kerusakan" value={taskPreventifFormScreen.description} onChangeText={ txt => onUpdateInput('description', txt)} borderRadius="lg" w="100%" />
                        </FormControl>
                      </Box>
                    </VStack>
                </Box>
                }
                {!taskPreventifFormScreen.isSuccess &&
                <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                  <HStack mb={4}>
                    <Flex flex={1}>
                      <Text fontSize="md">Foto Kerusakan</Text>
                    </Flex>
                    {!readOnly &&
                    <Box width="120px">
                      <Button onPress={() => handleCameraOpen()} leftIcon={<Icon as={<Box><MdAddAPhoto/></Box>} size="sm" />}>
                        Ambil Foto
                      </Button>
                    </Box>
                    }
                  </HStack>
                  <VStack space={3}>
                  <Box justifyContent="center" alignItems="center" p={2} borderColor="muted.400" borderRadius="lg" borderWidth={1} borderStyle="dashed">
                    <Files
                      className='files-dropzone'
                      onChange={handleFileChange}
                      onError={handleFileError}
                      accepts={['image/png', 'image/jpg','image/jpeg']}
                      maxFileSize={(10000000 * 20)} // 20 MB
                      minFileSize={0}
                      clickable>
                      Drop files here or click to upload
                    </Files>
                  </Box>
                    {taskPreventifFormScreen.assets.map((rowimage, imgx) => {
                      return (
                        <Box key={`img-${imgx}`} p={2} flex={1} borderColor="muted.400" borderRadius="lg" borderWidth={1}>
                          <Flex mb={2} justifyContent="center" alignItems="center">
                          <AspectRatio ratio={{
                            base: 3 / 4,
                          }} height={{
                            base: 350,
                          }}>
                            <Image alt={`photo${imgx}`} resizeMode="contain" source={{uri: rowimage.path}}/>
                          </AspectRatio>
                          </Flex>
                          <HStack justifyContent="center" alignItems="center">
                            <Flex flex={1} flexDirection="column">
                              <Text fontSize="sm" fontWeight="bold">Foto {`${imgx+1}`}</Text>
                              {/* <Text fontSize="xs" color="muted.400">28-10-2022 09:40</Text> */}
                            </Flex>
                            {!readOnly &&
                            <Box>
                              <Pressable onPress={() => onDelImage(imgx)}>
                                <Icon as={<Box><MdDelete/></Box>} color="red.500" size="lg"></Icon>
                              </Pressable>
                            </Box>
                            }
                          </HStack>
                        </Box>
                        // <View>
                        //   <Image alt={`photo${imgx}`} style={styles.image} source={{uri: rowimage}}/>
                        // </View>
                      );
                    })
                    }
                  </VStack>
                </Box>
                }
                {(!taskPreventifFormScreen.isSuccess && !readOnly) &&
                <Box>
                  <Button isLoading={taskPreventifFormScreen.loading_submit} spinnerPlacement="end" isLoadingText="Submitting..." colorScheme="primary" onPress={() => handleSubmit()} shadow={3}>Simpan</Button>
                </Box>
                }
                {taskPreventifFormScreen.isSuccess &&
                <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    <VStack>
                    <Center>
                          <Avatar size="lg" bg="primary.900" mb={4}>
                              {helper.getInitials(dtPreventif.nama_gedung)}
                          </Avatar>
                        </Center>
                        <Center>
                          <Text color="darkText" fontSize="md" fontWeight="bold">{dtPreventif.nama_ruang}</Text>
                        </Center>
                        <Center>
                          <Text color="muted.400"  fontSize="sm">Lantai - {dtPreventif.lantai_ruang}</Text>
                        </Center>
                    </VStack>
                </Box>
                }
                {taskPreventifFormScreen.isSuccess &&
                <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    <VStack space={4}>
                      <Center>
                        <Image mb={3} source={require('../../images/checked.png')} alt='Room' size="lg" />
                      </Center>
                      <Center>
                        <Text color="darkText" isTruncated maxWidth="90%" fontSize="md" fontWeight="bold">Data Berhasil disimpan</Text>
                      </Center>
                      <Center>
                        <Text color="muted.400" textAlign="center" fontSize="sm">Data pemeliharaan preventif telah tersimpan pada {moment().format('ddd, DD MMMM YYYY HH:mm')}. Terimakasih telah menggunakan sistem ADIKANDUNG.</Text>
                      </Center>
                      <Center>
                        <Button colorScheme="success" onPress={() => navigation.navigate('preventif', {id_item : null})} width="50%" shadow={3}>Selesai</Button>
                      </Center>
                    </VStack>
                </Box>
                }
              </VStack>
            </ScrollView>
          </Flex>
          <Modal isOpen={openModalCamera} onClose={() => setOpenModalCamera(false)}>
            <Modal.Content maxWidth="500px">
              <Modal.Header>Ambil Gambar</Modal.Header>
              <Modal.Body>
              {openCamera == true && 
              <Camera
                onTakePhoto = { (dataUri) => { handleChoosePhoto(dataUri); } }
                onTakePhotoAnimationDone = { (dataUri) => { handleTakePhotoAnimationDone(dataUri); } }
                onCameraError = { (error) => { handleCameraError(error); } }
                idealFacingMode = {FACING_MODES.ENVIRONMENT}
                idealResolution = {{width: 640, height: 800}}
                imageType = {IMAGE_TYPES.JPG}
                imageCompression = {0.70}
                isMaxResolution = {true}
                isImageMirror = {false}
                isSilentMode = {false}
                isDisplayStartCameraError = {true}
                isFullscreen = {false}
                sizeFactor = {1}
                onCameraStart = { (stream) => { handleCameraStart(stream); } }
                onCameraStop = { () => { handleCameraStop(); } }
              />
            }
              </Modal.Body>
            </Modal.Content>
          </Modal>
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  taskPreventifFormScreen: makeSelectTaskPreventifFormScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetDetailTodo: (id_realisasi_preventif, id_inventaris) => dispatch(getDetailTodo(id_realisasi_preventif, id_inventaris)),
    onAddImage: asset => dispatch(addImage(asset)),
    onDelImage: idx => dispatch(delImage(idx)),
    onSubmitPreventif: () => dispatch(submitPreventif()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(TaskPreventifFormScreen);