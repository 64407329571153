import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function deleteRowPreventif(rowid) {
  return {
    type: constants.DELETE_ROW_PREVENTIF,
    rowid
  };
}

export function updateInputPreventif({ rowid, name, value }) {
  return {
    type: constants.UPDATE_INPUT_PREVENTIF,
    rowid,
    name,
    value,
  };
}

export function getApprovalDetail(id_apv, code_proses, dt_realisasi) {
  return {
    type: constants.GET_APPROVAL_DETAIL,
    id_apv,
    code_proses,
    dt_realisasi
  };
}

export function getApprovalDetailLoading() {
  return {
    type: constants.GET_APPROVAL_DETAIL_LOADING
  };
}

export function getApprovalDetailFail(error) {
  return {
    type: constants.GET_APPROVAL_DETAIL_FAIL,
    error
  };
}

export function getApprovalDetailSuccess(results) {
  // console.log(results);
  return {
    type: constants.GET_APPROVAL_DETAIL_SUCCESS,
    results
  };
}

export function submitApprovalPreventif(action_type, id_wo) {
  return {
    type: constants.SUBMIT_APPROVAL_PREVENTIF,
    action_type,
    id_wo
  };
}

export function submitApprovalPreventifFail(error) {
  return {
    type: constants.SUBMIT_APPROVAL_PREVENTIF_FAIL,
    error
  };
}

export function submitApprovalPreventifSuccess(results, action_type) {
  return {
    type: constants.SUBMIT_APPROVAL_PREVENTIF_SUCCESS,
    results,
    action_type
  };
}

export function getVerifyAssets(id_work_order_report) {
  return {
    type: constants.GET_VERIF_ASSET,
    id_work_order_report
  };
}

export function getVerifyAssetsLoading() {
  return {
    type: constants.GET_VERIF_ASSET_LOADING
  };
}

export function getVerifyAssetsFail(error) {
  return {
    type: constants.GET_VERIF_ASSET_FAIL,
    error
  };
}

export function getVerifyAssetsSuccess(results) {
  // console.log(results);
  return {
    type: constants.GET_VERIF_ASSET_SUCCESS,
    results
  };
}