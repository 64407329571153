import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the VendorOfferFormScreen state domain
 */

const selectVendorNegoFormScreenDomain = state =>
  state.vendorNegoFormScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by VendorOfferFormScreen
 */

const makeSelectVendorNegoFormScreen = () =>
  createSelector(
    selectVendorNegoFormScreenDomain,
    substate => substate,
  );

export default makeSelectVendorNegoFormScreen;
export { selectVendorNegoFormScreenDomain };
