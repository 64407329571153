import React, { Component, useState, useEffect, useCallback, forwardRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectVendorOfferFormScreen from './selector';
import reducer from './reducer';
import saga from './saga';

import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Pressable,
  TextArea,
  FormControl,
  Divider,
  Image,
  Input, 
  Flex,
  IconButton,
  ScrollView, 
  Button,
  Stack} from 'native-base';
  import DatePicker from "react-datepicker";
import {FaCalendarAlt, FaBuilding, FaArrowRight, FaEnvelope, FaPlus, FaFileInvoice} from 'react-icons/fa';
import {MdCalendarToday, MdDelete} from 'react-icons/md';
import { ContainerPage } from '../../components/ContainerPage';
import { commonStyle } from '../../styles';
import { addFile, addImage, defaultAction, delFile, delImage, getVendorOffer, submitNegoOffer, submitVendorOffer, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import moment from 'moment';
import Files from 'react-files';
import history from '../../util/history';
import MyAlertDialog from '../../components/MyAlertDialog';
import CurrencyInput from 'react-currency-input-field';

export function VendorOfferFormScreen({
  dispatch,
  location,
  navigation,
  vendorOfferFormScreen,
  onUpdateInput,
  onAddImage,
  onDelImage,
  onAddFile,
  onDelFile,
  onSubmitOffer,
  onGetResult,
  onSubmitNegoOffer
}){
  const {vendor_offer, readOnly} = location.state;
  const[userData, setUserData] = useState();
  const [fileResponse, setFileResponse] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const [actionType, setActionType] = useState("40");

  useEffect(() => {
      console.log("readonly:", readOnly);
      dispatch(defaultAction());
      onUpdateInput('vendor_offer', vendor_offer);
      // const token = api.getUserData().then(user => {
      //   console.log("saved_user:", user);
      //   console.log("initial:", helper.getInitials(user.user_name));
      //   setUserData(user);
      // });
      onGetResult(vendor_offer.id_penawaran_request);
  },[]);

  useEffect(() => {
    onUpdateInput('responseState','');

    if(vendorOfferFormScreen.responseState == 'success'){
      helper.successToast("Penawaran berhasil disimpan");
      history.replace('vendor-offer');
    }
    if(vendorOfferFormScreen.responseState == 'error'){
      helper.errorToast("Gagal Menyimpan data, silahkan hub admin")
    }
  },[vendorOfferFormScreen.responseState]);

  const onChangeDt = (selectedDate) => {
    const currentDate = selectedDate;
    onUpdateInput("date_offer", currentDate);
  };

  const onChangeNegoDt = (selectedDate) => {
    const currentDate = selectedDate;
    onUpdateInput("nego_date_offer", currentDate);
  };

  // const handleOpenDate = () => {
  //   DateTimePickerAndroid.open({
  //     value: vendorOfferFormScreen.date_offer,
  //     onChange: onChangeDt,
  //     minimumDate: new Date(),
  //     mode: 'date',
  //     is24Hour: true,
  //   });
  // }

  const handleChoosePhoto = async () => {
    if(vendorOfferFormScreen.assets.length == 4){
      helper.infoToast("Anda melebihi batas maksimum yakni 4 foto.");
      return;
    }

    // try {
    //   const granted = await PermissionsAndroid.request(
    //     PermissionsAndroid.PERMISSIONS.CAMERA,
    //     {
    //       title: "App Camera Permission",
    //       message:"App needs access to your camera",
    //       buttonNeutral: "Ask Me Later",
    //       buttonNegative: "Cancel",
    //       buttonPositive: "OK"
    //     }
    //   );
    //   if (granted === PermissionsAndroid.RESULTS.GRANTED) {
    //     console.log("Camera permission given");
    //     launchCamera({ 
    //       mediaType: 'photo',
    //       maxWidth: 800,
    //       maxHeight: 800,
    //       includeBase64: true,
    //       cameraType:'back'
    //     }, (response) => {
    //       console.log(response);
    //       if (response) {
    //         if(!helper.isEmpty(response.assets)) onAddImage(response.assets[0]);
    //       }
    //     });
    //   } else {
    //     console.log("Camera permission denied");
    //   }
    // } catch (err) {
    //   console.warn(err);
    // }
  };

  const handleSubmit = () => {
    if(helper.isEmpty(vendorOfferFormScreen.description) || vendorOfferFormScreen.description.length < 10){
      helper.warningToast("Isikan deskripsi, Minimal 10 karakter.");
      return;
    }
    if(helper.isEmpty(vendorOfferFormScreen.cost_offer)){
      helper.warningToast("Isikan nominal penawaran.");
      return;
    }
    if(vendorOfferFormScreen.assets.length == 0){
      helper.warningToast("Bukti foto diperlukan min 1 foto.");
      return;
    }
    onSubmitOffer();
  }

  const handleGetData = () => {
    onGetResult(vendor_offer.id_penawaran_request);
  }

  const handleDocumentSelection = useCallback(async () => {
    // try {
    //   const response = await DocumentPicker.pick({
    //     presentationStyle: 'fullScreen',
    //     type: [types.docx, types.pdf, types.doc]
    //   });

    //   if(!helper.isEmpty(response)){
    //     const selectedFile = response[0];

    //     // check size
    //     if(selectedFile.size && selectedFile.size > (2 * 1046576)){ // 2MB
    //       helper.warningToast("Ukuran dokumen terlalu besar, maksimum 2MB.");
    //       return;
    //     }
        
    //     // convert to base64
    //     RNFetchBlob.fs.readFile(selectedFile.uri, 'base64').then((data) => {
    //       // console.log("bs64-file:", data);
    //       const params = {
    //         base64: data,
    //         type: selectedFile.type,
    //         name: selectedFile.name,
    //       }

    //       onAddFile(params);
    //     }).catch((err) => {
    //       console.log("bs64:",err);
    //     });
    //   }
    //   console.log("file:", response);
    // } catch (err) {
    //   console.warn(err);
    // }
  }, []);

  const handleNegoSubmit = () => {
    setIsOpen(false);
    onSubmitNegoOffer(actionType);
  };

  const InputDate = forwardRef(({ value, onClick }, ref) => (
    <Input 
      value={moment(vendorOfferFormScreen.date_offer).format("DD/MM/YYYY")} 
      isReadOnly="true"
      onFocus={onClick} 
      borderRadius="lg" 
      variant="filled"
      ref={ref} 
      borderColor="primary.900" 
      InputLeftElement={<Icon as={<Box><MdCalendarToday/></Box>} size={5} ml="2" color="muted.400" />} 
      />
  ));

  const InputNegoDate = forwardRef(({ value, onClick }, ref) => (
    <Input 
      value={moment(vendorOfferFormScreen.nego_date_offer).format("DD/MM/YYYY")} 
      isReadOnly="true"
      onFocus={onClick} 
      borderRadius="lg" 
      variant="filled"
      ref={ref} 
      borderColor="primary.900" 
      InputLeftElement={<Icon as={<Box><MdCalendarToday/></Box>} size={5} ml="2" color="muted.400" />} 
      />
  ));

  const handleDocumentPicker = files => {
    console.log('file:', files);
    // alert(files.map(x => x.fileUrl).join("\n"));
  }

  const handleChange = (files) => {
    console.log(files);
    const file = files[0];
    getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        const params = {
          base64: result,
          type: file.type,
          name: file.name,
        }

        onAddFile(params);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
    helper.errorToast(error.message);
  }

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleOnSubmit = (action_type) => {
    setActionType(action_type);
    if(helper.isEmpty(vendorOfferFormScreen.nego_description) || vendorOfferFormScreen.nego_description.length < 10){
      helper.warningToast("Isikan hasil verifikasi, Minimal 10 karakter.");
      return;
    }

    
    setIsOpen(true);
  };

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'vendor-offer-detail', title: 'Penawaran Vendor', params:{id_offer: vendor_offer.id_penawaran_request, readOnly: readOnly}}}>
          <Flex flex={1}>
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              <VStack space={7} mt={2} mb={35} mx={3}>
                <Box mt={5}>
                  <Center>
                    <HStack space={2} justifyContent="center" alignItems="center">
                      <Icon as={<Box><FaEnvelope/></Box>} size="5" color="success.700" />
                      <Text color="darkText" fontWeight="bold" fontSize="md">
                        {vendorOfferFormScreen.vendor_offer.code_penawaran_request}
                      </Text>
                    </HStack>
                  </Center>
                </Box>
                <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    <VStack>
                      <Box mb={2}>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Tanggal Perkiraan Selesai</FormControl.Label>
                          <DatePicker customInput={<InputDate/>} portalId='root-portal' className='input-datepicker' popperClassName='pooper-datepicker' selected={vendorOfferFormScreen.date_offer} onChange={(date) => onChangeDt(date)} />
                        </FormControl>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Nominal Penawaran</FormControl.Label>
                          {/* <Input onChangeText={txt => onUpdateInput('cost_offer', txt)} value={vendorOfferFormScreen.cost_offer} keyboardType='numeric' borderRadius="lg" /> */}
                          <CurrencyInput
                              id="input-nominal-penawaran"
                              name="input-nominal-penawaran"
                              placeholder="0"
                              style={{textAlign: 'right'}}
                              decimalSeparator="," 
                              groupSeparator="."
                              borderRadius="lg"
                              defaultValue={vendorOfferFormScreen.cost_offer}
                              value={vendorOfferFormScreen.cost_offer}
                              decimalsLimit={0}
                              onValueChange={txt => onUpdateInput('cost_offer', txt)}
                          />
                        </FormControl>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Deskripsi</FormControl.Label>
                          <TextArea h={40} placeholder="Detail Hasil Pemeriksaan" borderRadius="lg" w="100%" onChangeText={txt => onUpdateInput('description', txt)} value={vendorOfferFormScreen.description} />
                        </FormControl>
                      </Box>
                    </VStack>
                </Box>
                <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                  <VStack mb={4}>
                    <Flex flex={1}>
                      <Text fontSize="md">Upload RAB</Text>
                    </Flex>
                    <Box justifyContent="center" alignItems="center" mt={2} p={2} flex={1} borderColor="muted.400" borderRadius="lg" borderWidth={1} borderStyle="dashed">
                    <Files
                      className='files-dropzone'
                      onChange={handleChange}
                      onError={handleError}
                      accepts={['image/png', '.pdf', 'image/jpg','image/jpeg','.doc']}
                      maxFileSize={(10000000 * 20)} // 20 MB
                      minFileSize={0}
                      clickable>
                      Drop files here or click to upload
                    </Files>
                      {/* <Button onPress={() => handleDocumentSelection()} leftIcon={<Icon as={<Box><FaPlus/></Box>} size="sm" />}>
                        Add
                      </Button> */}
                    </Box>
                  </VStack>
                  <VStack space={3}>
                    {vendorOfferFormScreen.assets.map((rowFile, imgx) => {
                      return (
                        <Box key={`as${imgx}`} p={2} flex={1} borderColor="muted.400" borderRadius="lg" borderWidth={1}>
                          <HStack justifyContent="center" alignItems="center">
                            <Flex flex={1} flexDirection="column">
                              <Text fontSize="sm" fontWeight="bold">File {`${imgx+1}`}</Text>
                              {/* <Text fontSize="xs" color="muted.400">28-10-2022 09:40</Text> */}
                            </Flex>
                            <Box>
                              <Pressable onPress={() => onDelFile(imgx)}>
                                <Icon as={<Box><MdDelete/></Box>} color="red.500" size="lg"></Icon>
                              </Pressable>
                            </Box>
                          </HStack>
                          <Flex mb={2}>
                            {/* <AspectRatio ratio={{
                              base: 3 / 4,
                            }} height={{
                              base: 350,
                            }}>
                              <Image alt={`photo${imgx}`} resizeMode="contain" source={{uri: rowFile.path}}/>
                            </AspectRatio> */}
                            <Text>{rowFile.name ? rowFile.name : `RAB_${vendorOfferFormScreen.vendor_offer.code_penawaran_request}_${imgx+1}`}</Text>
                          </Flex>
                        </Box>
                        // <View>
                        //   <Image alt={`photo${imgx}`} style={styles.image} source={{uri: rowFile}}/>
                        // </View>
                      );
                    })
                    }
                  </VStack>
                </Box>
                {(!readOnly && !vendorOfferFormScreen.raw_nego) &&
                <Box >
                  <Button isLoading={vendorOfferFormScreen.loading_submit} spinnerPlacement="end" isLoadingText="Submitting..." onPress={() => handleSubmit()} shadow={3} colorScheme="primary">Simpan</Button>
                  {/* <Button mt={4} onPress={handleDocumentSelection} >Open Doc</Button> */}
                </Box>
                }
                {vendorOfferFormScreen.raw_nego && 
                <>
                <Box width="100%" borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    <VStack>
                      <Box px={5} py={3} backgroundColor="#E4F6F4" borderTopRadius="lg">
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Box>
                            <Icon color="darkText" as={<Box><FaFileInvoice/></Box>} size={5}></Icon>
                          </Box>
                          <Box flex={1} ml={1}>
                            <Text color="darkText" fontWeight={500} >Negosiasi Penawaran</Text>
                          </Box>
                        </Stack>
                      </Box>
                      <Box mb={2} p={5}>
                        <FormControl mb="1">
                          <FormControl.Label>Deadline Aproval Nego</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text>{moment(vendorOfferFormScreen.raw_nego.tgl_app_nego).format('DD-MM-YYYY')}</Text>
                          </Box>
                        </FormControl>
                        <FormControl mb="1">
                          <FormControl.Label>Harga Nego</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text textAlign="right">{(parseInt(vendorOfferFormScreen.raw_nego.nominal_nego) || 0).toLocaleString()}</Text>
                          </Box>
                        </FormControl>
                        <FormControl mb="1">
                          <FormControl.Label>Noted</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text>{vendorOfferFormScreen.raw_nego.description}</Text>
                          </Box>
                        </FormControl>
                      </Box>
                      {/* <Box mb={2} p={5}>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Deadline Aproval Nego</FormControl.Label>
                          <DatePicker customInput={<InputNegoDate/>} portalId='root-portal' className='input-datepicker' popperClassName='pooper-datepicker' selected={vendorOfferFormScreen.nego_date_offer} onChange={(date) => onChangeNegoDt(date)} />
                        </FormControl>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Harga Nego</FormControl.Label>
                          <Input onChangeText={txt => onUpdateInput('nego_cost_offer', txt)} value={vendorOfferFormScreen.nego_cost_offer} keyboardType='numeric' borderRadius="lg" />
                        </FormControl>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Catatan</FormControl.Label>
                          <TextArea h={40} placeholder="Detail Hasil Pemeriksaan" borderRadius="lg" w="100%" onChangeText={txt => onUpdateInput('nego_description', txt)} value={vendorOfferFormScreen.nego_description} />
                        </FormControl>
                        
                      </Box> */}
                      
                    </VStack>
                </Box>
                {!readOnly &&
                <Box mt={2}>
                  <HStack>
                    <Box flex={1} mr={3}>
                      <Button onPress={() => handleOnSubmit('40')} isLoading={vendorOfferFormScreen.loading_submit} spinnerPlacement="end" isLoadingText="Submitting..." colorScheme="success" >Setujui</Button>
                    </Box>
                    <Box flex={1}>
                      <Button onPress={() => handleOnSubmit('30')} isLoading={vendorOfferFormScreen.loading_submit} spinnerPlacement="end" isLoadingText="Submitting..." colorScheme="danger">Tolak</Button>
                    </Box>
                  </HStack>
                </Box>
                } 
                </>
                } 
                
              </VStack>
            </ScrollView>
          </Flex>
          <MyAlertDialog 
            alertTitle="Nego Penawaran" 
            alertDesc={`Apakah anda ingin ${actionType == '40' ? 'Menyetujui' :  'Menolak'} pengajuan ini ?`}
            alertNoLabel="Tidak"
            alertYesLabel="Ya"
            alertIsOpen={isOpen}
            alertOnClose={onClose}
            alertOnConfirm={() => handleNegoSubmit()}
          />
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  vendorOfferFormScreen: makeSelectVendorOfferFormScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onAddImage: asset => dispatch(addImage(asset)),
    onDelImage: idx => dispatch(delImage(idx)),
    onAddFile: asset => dispatch(addFile(asset)),
    onDelFile: idx => dispatch(delFile(idx)),
    onSubmitOffer: () => dispatch(submitVendorOffer()),
    onGetResult: (id_offer) => dispatch(getVendorOffer(id_offer)),
    onSubmitNegoOffer: (act_type) => dispatch(submitNegoOffer(act_type))
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(VendorOfferFormScreen);