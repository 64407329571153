import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the ApprovalMaintainFormVendorScreen state domain
 */

const selectApprovalMaintainFormVendorScreenDomain = state =>
  state.approvalMaintainFormVendorScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by ApprovalMaintainFormVendorScreen
 */

const makeSelectApprovalMaintainFormVendorScreen = () =>
  createSelector(
    selectApprovalMaintainFormVendorScreenDomain,
    substate => substate,
  );

export default makeSelectApprovalMaintainFormVendorScreen;
export { selectApprovalMaintainFormVendorScreenDomain };
