import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the VendorOfferFormScreen state domain
 */

const selectVendorOfferFormScreenDomain = state =>
  state.vendorOfferFormScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by VendorOfferFormScreen
 */

const makeSelectVendorOfferFormScreen = () =>
  createSelector(
    selectVendorOfferFormScreenDomain,
    substate => substate,
  );

export default makeSelectVendorOfferFormScreen;
export { selectVendorOfferFormScreenDomain };
