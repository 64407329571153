import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the VendorKroscekScreen state domain
 */

const selectVendorKroscekScreenDomain = state =>
  state.vendorKroscekScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by VendorKroscekScreen
 */

const makeSelectVendorKroscekScreen = () =>
  createSelector(
    selectVendorKroscekScreenDomain,
    substate => substate,
  );

export default makeSelectVendorKroscekScreen;
export { selectVendorKroscekScreenDomain };
