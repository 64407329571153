/*
 *
 * ForgetPassScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    email:'',
    msg:'',
    responseState: '',
    isValid: false,
    isChecked: false,
};

/* eslint-disable default-case, no-param-reassign */
const ForgetPassScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        return {...initialState};
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.FORGET_PASS:
        draft.loading = true;
        break;
      case constants.FORGET_PASS_SUCCESS:
        draft.loading = false;
        draft.error = 0 ;
        draft.error_msg= '';
        draft.isValid = true;
        draft.isChecked = true;
        draft.msg = action.result;
        draft.responseState = 'success';
        break;
      case constants.FORGET_PASS_FAIL:
        draft.loading = false;
        draft.isValid = false;
        draft.isChecked = true;
        draft.error = 1 ;
        draft.msg = action.msg;
        draft.responseState = 'error';
        break;
    }
  });

export default ForgetPassScreenReducer;
