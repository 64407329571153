import React, { Component, useState, useEffect, forwardRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectVendorProgressFormScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView, TouchableWithoutFeedback, PermissionsAndroid, RefreshControl } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Pressable,
  TextArea,
  FormControl,
  Divider,
  Image,
  Input, 
  Flex,
  Slider,
  Select,
  CheckIcon,
  IconButton,
  ScrollView,
  Modal, 
  Button} from 'native-base';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import Files from 'react-files';
import 'react-html5-camera-photo/build/css/index.css';
import {FaCalendarAlt, FaBuilding, FaArrowRight, FaEnvelope, FaPlus, FaCheckCircle} from 'react-icons/fa';
import {MdCalendarToday, MdDelete, MdAddAPhoto} from 'react-icons/md';
import moment from 'moment';
import DatePicker from "react-datepicker";
// import { DateTimePickerAndroid } from '@react-native-community/datetimepicker';
import { ContainerPage } from '../../components/ContainerPage';
import { commonStyle } from '../../styles';
import { addImage, defaultAction, delImage, getProgressReport, submitProgressReport, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';

export function VendorProgressFormScreen({
  dispatch,
  location,
  navigation,
  vendorProgressFormScreen,
  onUpdateInput,
  onAddImage,
  onDelImage,
  onSubmitProgress,
  onGetProgressReport
}){
  const {id_work_order, id_work_order_detail, work_order, work_order_detail, addNew, readOnly, origin_screen} = location.state;
  const[userData, setUserData] = useState();
  const [status, setStatus] = useState();
  const [progress, setProgress] = useState(10);
  const [openCamera, setOpenCamera] = useState(false);
  const [openModalCamera, setOpenModalCamera] = useState(false);

  useEffect(() => {
      dispatch(defaultAction());
      onUpdateInput('id_work_order_detail', id_work_order_detail);
      onUpdateInput('id_work_order', id_work_order);
      onUpdateInput('new_data', addNew);
      // onUpdateInput('dtProgress', work_order);
      // onUpdateInput('dtProgressDetail', work_order_detail);
      onGetProgressReport(id_work_order_detail);
      // const token = api.getUserData().then(user => {
      //   console.log("saved_user:", user);
      //   console.log("initial:", helper.getInitials(user.user_name));
      //   setUserData(user);
      // });
  },[]);

  useEffect(() => {
    onUpdateInput('responseState','');
    // console.log("state:", vendorProgressFormScreen.responseState);
    if(vendorProgressFormScreen.responseState == 'success'){
      helper.successToast("Progress Perbaikan berhasil disimpan");
    }
    if(vendorProgressFormScreen.responseState == 'error'){
      helper.errorToast("Gagal Menyimpan data, silahkan hub admin")
    }
  },[vendorProgressFormScreen.responseState]);

  const onChangeDt = (selectedDate) => {
    const currentDate = selectedDate;
    onUpdateInput("tgl_deadline", currentDate);
  };

  // const handleOpenDate = () => {
  //   DateTimePickerAndroid.open({
  //     value: vendorProgressFormScreen.tgl_deadline,
  //     onChange: onChangeDt,
  //     minimumDate: new Date(),
  //     mode: 'date',
  //     is24Hour: true,
  //   });
  // }

  const handleChoosePhoto = async (data) => {
    setOpenCamera(false);
    console.log("img:", data);
    setTimeout(() => {
      setOpenModalCamera(false);
    }, 300);
    if(vendorProgressFormScreen.assets.length == 4){
      helper.infoToast("Anda melebihi batas maksimum yakni 4 foto.");
      return;
    }

    try {
      if(!helper.isEmpty(data)) onAddImage(data);
      // const granted = await PermissionsAndroid.request(
      //   PermissionsAndroid.PERMISSIONS.CAMERA,
      //   {
      //     title: "App Camera Permission",
      //     message:"App needs access to your camera",
      //     buttonNeutral: "Ask Me Later",
      //     buttonNegative: "Cancel",
      //     buttonPositive: "OK"
      //   }
      // );
      // if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      //   console.log("Camera permission given");
      //   launchCamera({ 
      //     mediaType: 'photo',
      //     maxWidth: 800,
      //     maxHeight: 800,
      //     includeBase64: true,
      //     cameraType:'back'
      //   }, (response) => {
      //     console.log(response);
      //     if (response) {
      //       if(!helper.isEmpty(response.assets)) onAddImage(response.assets[0]);
      //     }
      //   });
      // } else {
      //   console.log("Camera permission denied");
      // }
    } catch (err) {
      console.warn(err);
    }
  };

  const handleSubmit = () => {
    if(helper.isEmpty(vendorProgressFormScreen.description) || vendorProgressFormScreen.description.length < 10){
      helper.warningToast("Isikan deskripsi, Minimal 10 karakter.");
      return;
    }
    if(vendorProgressFormScreen.assets.length == 0){
      helper.warningToast("Bukti foto diperlukan min 1 foto.");
      return;
    }
    onSubmitProgress();
  }

  const handleGetData = () => {
    onGetProgressReport(id_work_order_detail);
  }

  const InputDate = forwardRef(({ value, onClick }, ref) => (
    <Input 
      value={moment(vendorProgressFormScreen.tgl_deadline).format("DD/MM/YYYY")} 
      isReadOnly="true"
      onFocus={onClick} 
      borderRadius="lg" 
      variant="filled"
      ref={ref} 
      borderColor="primary.900" 
      InputLeftElement={<Icon as={<Box><MdCalendarToday/></Box>} size={5} ml="2" color="muted.400" />} 
      />
  ));

  const handleCameraOpen = () => {
    setOpenModalCamera(true);
    setTimeout(() => {
      setOpenCamera(true);
    }, 300);
  }

  function handleTakePhotoAnimationDone (dataUri) {
    // Do stuff with the photo...
    // console.log('takePhoto');
  }

  function handleCameraError (error) {
    // console.log('handleCameraError', error);
  }

  function handleCameraStart (stream) {
    // console.log('handleCameraStart : ', stream);
  }

  function handleCameraStop () {
    // console.log('handleCameraStop');
  }

  const handleOnChangeProgress = (v) => {
    onUpdateInput('progress', Math.floor(v));
    if(v < 100){
      onUpdateInput('status', '30');
    }
  }

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleFileChange = (files) => {
    console.log(files);
    const file = files[0];
    getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        const params = {
          base64: result,
          type: file.type,
          name: file.name,
        }

        onAddImage(params.base64);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleFileError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
    helper.errorToast(error.message);
  }

  return (
        <ContainerPage options={{statusBar: true, prevPage: origin_screen, title: 'Progress Perbaikan', params:{id_work_order:id_work_order}}}>
          <Flex flex={1}>
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              <VStack space={7} mt={2} mb={35} mx={3}>
                <Box mt={5}>
                  <Center>
                    <HStack space={2} justifyContent="center" alignItems="center">
                      <Icon as={<Box><FaCheckCircle/></Box>} size="8" color="success.700" />
                      <Text color="darkText" fontWeight="bold" isTruncated maxWidth="90%" fontSize="lg">
                        {vendorProgressFormScreen.dtProgressDetail && vendorProgressFormScreen.dtProgressDetail.code_work_order}
                      </Text>
                    </HStack>
                  </Center>
                </Box>
                <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    <VStack>
                      <Box mb={2}>
                        <Text fontSize="md">Ruang</Text>
                        <Text fontWeight="bold">{vendorProgressFormScreen.dtProgressDetail && vendorProgressFormScreen.dtProgressDetail.nama_ruang}</Text>
                      </Box>
                      <Box mb={2}>
                        <Text fontSize="md">Aset</Text>
                        <Text fontWeight="bold">{vendorProgressFormScreen.dtProgressDetail && vendorProgressFormScreen.dtProgressDetail.nama_inventaris}</Text>
                      </Box>
                      <Box mb={2}>
                        {vendorProgressFormScreen.dtProgressDetail && 
                        <FormControl mb="1">
                          <FormControl.Label>Progress Pekerjaan {`(${vendorProgressFormScreen.progress}%)`}</FormControl.Label>
                          <Slider defaultValue={parseInt(vendorProgressFormScreen.progress) || 0} onChange={val => handleOnChangeProgress(val)} size="lg" colorScheme="success">
                            <Slider.Track>
                              <Slider.FilledTrack />
                            </Slider.Track>
                            <Slider.Thumb />
                          </Slider>
                        </FormControl>
                        }
                        <FormControl mb="1">
                          <FormControl.Label>Status Pekerjaan</FormControl.Label>
                          <Select 
                            variant='filled' 
                            borderColor="primary.900" 
                            selectedValue={vendorProgressFormScreen.status}
                            isDisabled={vendorProgressFormScreen.progress != 100} 
                            minWidth="200" 
                            accessibilityLabel="Pilih Status" 
                            placeholder="Pilih Status" 
                            _selectedItem={{endIcon: <CheckIcon size="5"/>}} 
                            my={2}
                            onValueChange={itemValue => onUpdateInput('status', itemValue)}>
                              <Select.Item label="Progress" value="30" />
                              <Select.Item label="Selesai" value="40" />
                            </Select>
                        </FormControl>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Tanggal Perkiraan Selesai</FormControl.Label>
                          <DatePicker customInput={<InputDate/>} portalId='root-portal' className='input-datepicker' popperClassName='pooper-datepicker' selected={vendorProgressFormScreen.tgl_deadline} onChange={(date) => onChangeDt(date)} />
                          {/* <Input
                            value={moment(vendorProgressFormScreen.tgl_deadline).format("DD/MM/YYYY")}  
                            InputRightElement={<IconButton onPress={handleOpenDate} size="sm" borderLeftRadius="0" height="full" backgroundColor="primary.900" variant="solid" _icon={{as: FontAwesome5,name: "calendar-plus"}} />} 
                            isReadOnly="true" 
                            borderRadius="lg" 
                            variant="filled" 
                            borderColor="primary.900" 
                            InputLeftElement={<Icon as={<MaterialIcons name="calendar-today" />} size={5} ml="2" color="muted.400" />} 
                            placeholder="Tgl Selesai" /> */}
                        </FormControl>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Deskripsi</FormControl.Label>
                          <TextArea h={40} onChangeText={txt => onUpdateInput('description', txt)} value={vendorProgressFormScreen.description} placeholder="Detail Hasil Pemeriksaan" borderRadius="lg" w="100%" />
                        </FormControl>
                      </Box>
                    </VStack>
                </Box>
                <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                  <HStack mb={4}>
                    <Flex flex={1}>
                      <Text fontSize="md">Upload Foto Progres</Text>
                    </Flex>
                    {!readOnly && 
                    <Box width="120px">
                      <Button onPress={() => handleCameraOpen()} leftIcon={<Icon as={<Box><MdAddAPhoto/></Box>} size="sm" />}>
                        Ambil Foto
                      </Button>
                    </Box>
                    }
                  </HStack>
                  <VStack>
                  <Box justifyContent="center" alignItems="center" mb={2} mt={2} p={2} borderColor="muted.400" borderRadius="lg" borderWidth={1} borderStyle="dashed">
                    <Files
                      className='files-dropzone'
                      onChange={handleFileChange}
                      onError={handleFileError}
                      accepts={['image/png', 'image/jpg','image/jpeg']}
                      maxFileSize={(10000000 * 20)} // 20 MB
                      minFileSize={0}
                      clickable>
                      Drop files here or click to upload
                    </Files>
                  </Box>
                  {vendorProgressFormScreen.assets.map((rowimage, imgx) => {
                      return (
                        <Box key={imgx} p={2} mb={2} flex={1} borderColor="muted.400" borderRadius="lg" borderWidth={1}>
                          <Flex mb={2} justifyContent="center" alignItems="center">
                          <AspectRatio ratio={{
                            base: 3 / 4,
                          }} height={{
                            base: 350,
                          }}>
                            <Image alt={`photo${imgx}`} resizeMode="contain" source={{uri: rowimage.path}}/>
                          </AspectRatio>
                          </Flex>
                          <HStack justifyContent="center" alignItems="center">
                            <Flex flex={1} flexDirection="column">
                              <Text fontSize="sm" fontWeight="bold">Foto {`${imgx+1}`}</Text>
                              {/* <Text fontSize="xs" color="muted.400">28-10-2022 09:40</Text> */}
                            </Flex>
                            {!readOnly && 
                            <Box>
                              <Pressable onPress={() => onDelImage(imgx)}>
                                <Icon as={<Box><MdDelete/></Box>} color="red.500" size="lg"></Icon>
                              </Pressable>
                            </Box>
                            }
                          </HStack>
                        </Box>
                        // <View>
                        //   <Image alt={`photo${imgx}`} style={styles.image} source={{uri: rowimage}}/>
                        // </View>
                      );
                    })
                    }
                  </VStack>
                </Box>
                {!readOnly && 
                <Box >
                  <Button isLoading={vendorProgressFormScreen.loading_submit} spinnerPlacement="end" isLoadingText="Submitting..." onPress={() => handleSubmit()} shadow={3} colorScheme="primary">Simpan</Button>
                </Box>
                }
              </VStack>
            </ScrollView>
          </Flex>
          <Modal isOpen={openModalCamera} onClose={() => setOpenModalCamera(false)}>
            <Modal.Content maxWidth="500px">
              <Modal.Header>Ambil Gambar</Modal.Header>
              <Modal.Body>
              {openCamera == true && 
              <Camera
                onTakePhoto = { (dataUri) => { handleChoosePhoto(dataUri); } }
                onTakePhotoAnimationDone = { (dataUri) => { handleTakePhotoAnimationDone(dataUri); } }
                onCameraError = { (error) => { handleCameraError(error); } }
                idealFacingMode = {FACING_MODES.ENVIRONMENT}
                idealResolution = {{width: 640, height: 800}}
                imageType = {IMAGE_TYPES.JPG}
                imageCompression = {0.70}
                isMaxResolution = {true}
                isImageMirror = {false}
                isSilentMode = {false}
                isDisplayStartCameraError = {true}
                isFullscreen = {false}
                sizeFactor = {1}
                onCameraStart = { (stream) => { handleCameraStart(stream); } }
                onCameraStop = { () => { handleCameraStop(); } }
              />
            }
              </Modal.Body>
            </Modal.Content>
          </Modal>
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  vendorProgressFormScreen: makeSelectVendorProgressFormScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onAddImage: asset => dispatch(addImage(asset)),
    onDelImage: idx => dispatch(delImage(idx)),
    onGetProgressReport: (id) => dispatch(getProgressReport(id)),
    onSubmitProgress: () => dispatch(submitProgressReport()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(VendorProgressFormScreen);