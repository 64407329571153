/*
 *
 * WorkOrderScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import moment from 'moment';

const date = new Date();
const from = moment(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)).toDate(); // one days ago
const to = moment(date).toDate();

export const initialState = {
    error: 0,
    error_msg: '',
    start_dt: from,
    end_dt: to,
    loading: false,
    results: []
};

/* eslint-disable default-case, no-param-reassign */
const WorkOrderScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        break;
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.GET_WORK_ORDER:
        draft.loading = true;
        break;
      case constants.GET_WORK_ORDER_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data.';
        draft.results = [];
        break;
      case constants.GET_WORK_ORDER_LOADING:
        draft.loading = true;
        break;
      case constants.GET_WORK_ORDER_SUCCESS:
        draft.results = action.results;
        draft.loading = false;
        break;
    }
  });

export default WorkOrderScreenReducer;
