import React from "react";
import history from "../util/history";
import logo from "../images/logo.png";

const PageNotFound = (props) => {

  return (
    <div>
        <div className="error-message">
            <div className="message">
                <h1>404</h1>
                <h2>ERROR</h2>
            </div>
        </div>
        <div className="message-body">
            <div>Sorry, we can’t find the page you requested.</div>
            <a href="/home">Head back home</a>
        </div>
    </div>
  );
}

export default PageNotFound;