import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getWorkOrderStatus(id_work_order) {
  return {
    type: constants.GET_WORK_ORDER_STATUS,
    id_work_order
  };
}

export function getWorkOrderStatusLoading() {
  return {
    type: constants.GET_WORK_ORDER_STATUS_LOADING
  };
}

export function getWorkOrderStatusFail(error) {
  return {
    type: constants.GET_WORK_ORDER_STATUS_FAIL,
    error
  };
}

export function getWorkOrderStatusSuccess(results) {
  return {
    type: constants.GET_WORK_ORDER_STATUS_SUCCESS,
    results
  };
}