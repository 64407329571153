export const DEFAULT_ACTION = 'screen/FormProfileScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/FormProfileScreen/UPDATE_INPUT';

export const GET_PEGAWAI = 'screen/FormProfileScreen/GET_PEGAWAI';
export const GET_PEGAWAI_LOADING = 'screen/FormProfileScreen/GET_PEGAWAI_LOADING';
export const GET_PEGAWAI_SUCCESS = 'screen/FormProfileScreen/GET_PEGAWAI_SUCCESS';
export const GET_PEGAWAI_FAIL = 'screen/FormProfileScreen/GET_PEGAWAI_FAIL';

export const SUBMIT_PEGAWAI = 'screen/FormProfileScreen/SUBMIT_PEGAWAI';
export const SUBMIT_PEGAWAI_LOADING = 'screen/FormProfileScreen/SUBMIT_PEGAWAI_LOADING';
export const SUBMIT_PEGAWAI_SUCCESS = 'screen/FormProfileScreen/SUBMIT_PEGAWAI_SUCCESS';
export const SUBMIT_PEGAWAI_FAIL = 'screen/FormProfileScreen/SUBMIT_PEGAWAI_FAIL';