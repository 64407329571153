import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
  import { submitPassFail, submitPassSuccess } from './actions';
  import api from '../../util/api';
  import helper from '../../util/helper';
  import * as constants from './constants';
  
  import makeSelectFormChangePassScreen from './selector';
  
  export function* doSubmitPass(action) {
    // console.log('masuk saga:', action);
    const dataset = yield select(makeSelectFormChangePassScreen());
    // console.log(dataset);
    try {
      const payloads = {
        old_pass: dataset.old_pass,
        new_pass: dataset.new_pass,
      };

      // console.log("payloads:", payloads);
      const response = yield call(api.user.changePassword, payloads);
      // console.log("res-api:", response);
      const { success } = response.data;
      yield put(submitPassSuccess(success));

      helper.successToast("Password Berhasil diubah");
      // action.navigation.navigate('welcome');
    } catch (err) {
      console.log("err:", err);
      yield put(submitPassFail());
    }
  }

  // Individual exports for testing
  export default function* formChangePassScreenSaga() {
    yield all([
      takeLatest(constants.SUBMIT_PASSWORD, doSubmitPass),
    ]);
  }
  