/*
 *
 * VendorOfferFormScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import helper from '../../util/helper';
import moment from 'moment';

const date = new Date();
const to = moment(date).toDate();

export const initialState = {
    error: 0,
    error_msg: '',
    responseState: '',
    loading: false,
    loading_submit: false,
    assets:[],
    date_offer: to,
    cost_offer: 0,
    description: "",
    vendor_offer: {
      code_penawaran_request: ''
    },
    raw_nego: null,
    nego_date_offer: to,
    nego_cost_offer: 0,
    nego_description: "",
};

/* eslint-disable default-case, no-param-reassign */
const VendorOfferFormScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        return {...initialState};
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.ADD_IMAGE: {
        let cur_arr = draft.assets;
        const image = `${action.asset.base64}`;
        cur_arr.push({path: image, status: true});
        draft.assets = cur_arr;
        }
        break;
      case constants.DEL_IMAGE:
        draft.assets = [...draft.assets.slice(0, action.idx), ...draft.assets.slice(action.idx + 1)];
        break;
      case constants.ADD_FILE:
        {
        let cur_arr_file = draft.assets;
        const file_ = `${action.asset.base64}`;
        const idx = draft.assets.findIndex(
          result => result.name === action.asset.name,
        );
        if(idx < 0){
          cur_arr_file.push({path: file_, name: action.asset.name, status: true});
        }
        draft.assets = cur_arr_file;
        }
        break;
      case constants.DEL_FILE:
        draft.assets = [...draft.assets.slice(0, action.idx), ...draft.assets.slice(action.idx + 1)];
        break;
      case constants.SUBMIT_OFFER:
        draft.loading_submit = true;
        break;
      case constants.SUBMIT_OFFER_FAIL:
        draft.error = true;
        draft.loading_submit = false;
        draft.responseState = 'error';
        break;
      case constants.SUBMIT_OFFER_SUCCESS:
        draft.preventif = action.results;
        draft.loading_submit = false;
        draft.isSuccess = true;
        draft.error = false;
        draft.responseState = 'success';
        break;
      case constants.GET_VENDOR_OFFER:
        draft.loading = true;
        break;
      case constants.GET_VENDOR_OFFER_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data.';
        break;
      case constants.GET_VENDOR_OFFER_SUCCESS: {
        const resReport = action.results;
        draft.vendor_offer = resReport;
        draft.loading = false;
        draft.cost_offer = resReport.nominal_penawaran;
        if(!helper.isEmpty(resReport.perkiraan_tanggal_selesai_penawaran)) draft.date_offer = moment(resReport.perkiraan_tanggal_selesai_penawaran).toDate();
        draft.description = resReport.description_submit_penawaran;
        if(!helper.isEmpty(resReport.attachments)) draft.assets = resReport.attachments;
        }
        break;
      case constants.GET_NEGO_PENAWARAN:
        draft.loading = true;
        break;
      case constants.GET_NEGO_PENAWARAN_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data.';
        break;
      case constants.GET_NEGO_PENAWARAN_SUCCESS: {
        const resNego = action.results;
        draft.loading = false;
        draft.raw_nego = resNego;
        if(!helper.isEmpty(resNego?.nominal_nego)) draft.nego_cost_offer = resNego.nominal_nego;
        if(!helper.isEmpty(resNego?.tgl_app_nego)) draft.nego_date_offer = moment(resNego.tgl_app_nego).toDate();
        if(!helper.isEmpty(resNego?.description)) draft.nego_description = resNego.description;
        }
        break;
      case constants.SUBMIT_NEGO_PENAWARAN:
        draft.loading_submit = true;
        draft.responseState = '';
        break;
      case constants.SUBMIT_NEGO_PENAWARAN_FAIL:
        draft.error = true;
        draft.loading_submit = false;
        draft.responseState = 'error';
        draft.error_msg= 'Maaf, Gagal melakukan approval.';
        break;
      case constants.SUBMIT_NEGO_PENAWARAN_SUCCESS:
        draft.loading_submit = false;
        draft.error = false;
        if(action.action_type == '40'){
          draft.responseState = 'approved';
        } else {
          draft.responseState = 'rejected';
        }
        break;
    }
  });

export default VendorOfferFormScreenReducer;
