/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import combinedReducers from './reducers';
import combinedSaga from './sagas';

export default function configureStore(initialState = {}, history) {
let composeEnhancers = compose;
const reduxSagaMonitorOptions = {};

const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

// Create the store with two middlewares
// 1. sagaMiddleware: Makes redux-sagas work

const middlewares = [sagaMiddleware, routerMiddleware(history)];

const enhancers = [applyMiddleware(...middlewares)];

const store = createStore(
    combinedReducers(),
    initialState,
    composeEnhancers(...enhancers),
);

sagaMiddleware.run(combinedSaga);

return store;
} 