export const DEFAULT_ACTION = 'screen/Login/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/Login/UPDATE_INPUT';

export const DO_LOGIN = 'screen/Login/DO_LOGIN';
export const DO_LOGIN_SUCCESS = 'screen/Login/DO_LOGIN_SUCCESS';
export const DO_LOGIN_FAIL = 'screen/Login/DO_LOGIN_FAIL';

export const GET_USER_PRIVILEGES = 'screen/Login/GET_USER_PRIVILEGES';
export const GET_USER_PRIVILEGES_LOADING = 'screen/Login/GET_USER_PRIVILEGES_LOADING';
export const GET_USER_PRIVILEGES_SUCCESS = 'screen/Login/GET_USER_PRIVILEGES_SUCCESS';
export const GET_USER_PRIVILEGES_FAIL = 'screen/Login/GET_USER_PRIVILEGES_FAIL';

export const SET_USER_NOTIF = 'screen/Login/SET_USER_NOTIF';