import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import api from '../util/api';
import helper from '../util/helper';
import PageNotFound from '../components/PageNotFound';
import CameraScreen from '../components/CameraScreen';

import GlobalVar from '../util/globalVar';
import LoginScreen from './LoginScreen/index';
import ForgetPassScreen from './ForgetPassScreen/index';
import WelcomeScreen from './WelcomeScreen/index';
import HomeScreen from './HomeScreen/index';
import ProfileScreen from './ProfileScreen/index';
import FormProfileScreen from './FormProfileScreen/index';
import FormChangePassScreen from './FormChangePassScreen/index';
import WorkOrderScreen from './WorkOrderScreen/index';
import TaskScreen from './TaskScreen/index';
import NotificationScreen from './NotificationScreen/index';
import NotificationDetailScreen from './NotificationDetailScreen/index';
import TaskScannerScreen from './TaskScannerScreen/index';
import PelaporanScreen from './PelaporanScreen/index';
import PelaporanHistoryScreen from './PelaporanHistoryScreen/index';
import PelaporanHistoryDetailScreen from './PelaporanHistoryDetailScreen/index';
import PelaporanDetailScreen from './PelaporanDetailScreen/index';
import PelaporanDetailInputScreen from './PelaporanDetailInputScreen/index';
import VerifPelaporanScreen from './VerifPelaporanScreen/index';
import VerifPelaporanHistoryScreen from './VerifPelaporanHistoryScreen/index';
import VerifPelaporanInputScreen from './VerifPelaporanInputScreen/index';
import TaskPreventifScreen from './TaskPreventifScreen/index';
import TaskPreventifDetailScreen from './TaskPreventifDetailScreen/index';
import TaskPreventifFormScreen from './TaskPreventifFormScreen/index';
import VendorOfferScreen from './VendorOfferScreen/index';
import VendorOfferDetailScreen from './VendorOfferDetailScreen/index';
import VendorOfferFormScreen from './VendorOfferFormScreen/index';
import VendorNegoFormScreen from './VendorNegoFormScreen/index';
import VendorKroscekScreen from './VendorKroscekScreen/index';
import VendorKroscekHistoryScreen from './VendorKroscekHistoryScreen/index';
import VendorKroscekFormScreen from './VendorKroscekFormScreen/index';
import VendorProgressScreen from './VendorProgressScreen/index';
import VendorProgressHistoryScreen from './VendorProgressHistoryScreen/index';
import VendorProgressSurketScreen from './VendorProgressSurketScreen/index';
import VendorProgressDetailScreen from './VendorProgressDetailScreen/index';
import VendorProgressFormScreen from './VendorProgressFormScreen/index';
import ApprovalMaintainScreen from './ApprovalMaintainScreen/index';
import ApprovalMaintainFormScreen from './ApprovalMaintainFormScreen/index';
import ApprovalMaintainFormPreventifScreen from './ApprovalMaintainFormPreventifScreen/index';
import ApprovalMaintainFormVendorScreen from './ApprovalMaintainFormVendorScreen/index';


const PrivateRoute = ({
    pageId,
    pageName,
    pageDesc,
    loadMap,
    component: Component,
    ...rest
  }) => {
    let isAuthenticated = false;
    let isGranted = true;
    //check authorization
    const token = api.getToken();
    if ( token != null) isAuthenticated = true;
    else isAuthenticated = false;
  
    return (
      <Route
        {...rest}
        render={props => {
          if (isAuthenticated && isGranted) {
            return (
              <>
                <GlobalVar/>
                <Component {...props} />
              </>
            );
          }
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          );
        }}
      />
    );
  };

  export default function Main() {
  
    useEffect(() => {
      // code here
    }, []);
  
    return (
      <>
        <Switch>
          <Route exact path="/" component={LoginScreen} />
          <Route exact path="/login" component={LoginScreen} />
          <Route exact path="/forgetpass" component={ForgetPassScreen} />
          <PrivateRoute
            exact
            path="/welcome"
            component={WelcomeScreen}
            pageName="WELCOME PAGE"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/home"
            component={HomeScreen}
            pageName="HOMEPAGE"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/profile"
            component={ProfileScreen}
            pageName="PROFILE"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/update-profile"
            component={FormProfileScreen}
            pageName="FORM PROFILE"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/changepass"
            component={FormChangePassScreen}
            pageName="PROFILE"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/work-order"
            component={WorkOrderScreen}
            pageName="WORK ORDER"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/tasks"
            component={TaskScreen}
            pageName="TASKS"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/notif"
            component={NotificationScreen}
            pageName="NOTIFICATION"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/notif-detail"
            component={NotificationDetailScreen}
            pageName="NOTIFICATION DETAIL"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/scanner"
            component={TaskScannerScreen}
            pageName="SCANNER"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/pelaporan"
            component={PelaporanScreen}
            pageName="PELAPORAN"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/pelaporan-history"
            component={PelaporanHistoryScreen}
            pageName="PELAPORAN HISTORY"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/pelaporan-history-detail"
            component={PelaporanHistoryDetailScreen}
            pageName="PELAPORAN HISTORY"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/pelaporan-detail"
            component={PelaporanDetailScreen}
            pageName="PELAPORAN DETAIL"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/pelaporan-form"
            component={PelaporanDetailInputScreen}
            pageName="FORM PELAPORAN"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/camera"
            component={CameraScreen}
            pageName="FORM PELAPORAN"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/verif-pelaporan"
            component={VerifPelaporanScreen}
            pageName="VERIFIKASI PELAPORAN"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/verif-pelaporan-history"
            component={VerifPelaporanHistoryScreen}
            pageName="VERIFIKASI PELAPORAN HISTORY"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/verif-pelaporan-form"
            component={VerifPelaporanInputScreen}
            pageName="VERIFIKASI PELAPORAN FROM"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/preventif"
            component={TaskPreventifScreen}
            pageName="PREVENTIF"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/preventif-detail"
            component={TaskPreventifDetailScreen}
            pageName="PREVENTIF DETAIL"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/preventif-form"
            component={TaskPreventifFormScreen}
            pageName="PREVENTIF FORM"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/vendor-offer"
            component={VendorOfferScreen}
            pageName="VENDOR OFFER"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/vendor-offer-detail"
            component={VendorOfferDetailScreen}
            pageName="VENDOR OFFER DETAIL"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/vendor-offer-form"
            component={VendorOfferFormScreen}
            pageName="VENDOR OFFER FORM"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/vendor-nego-form"
            component={VendorNegoFormScreen}
            pageName="VENDOR NEGO FORM"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/vendor-kroscek"
            component={VendorKroscekScreen}
            pageName="VENDOR KROSCEK"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/vendor-kroscek-history"
            component={VendorKroscekHistoryScreen}
            pageName="VENDOR KROSCEK HISTORY"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/vendor-kroscek-form"
            component={VendorKroscekFormScreen}
            pageName="VENDOR KROSCEK FORM"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/vendor-progress"
            component={VendorProgressScreen}
            pageName="VENDOR PROGRESS"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/vendor-progress-history"
            component={VendorProgressHistoryScreen}
            pageName="VENDOR PROGRESS"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/vendor-progress-surket"
            component={VendorProgressSurketScreen}
            pageName="VENDOR PROGRESS SURAT KERJA"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/vendor-progress-detail"
            component={VendorProgressDetailScreen}
            pageName="VENDOR PROGRESS DETAIL"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/vendor-progress-form"
            component={VendorProgressFormScreen}
            pageName="VENDOR PROGRESS FORM"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/approval-maintenance"
            component={ApprovalMaintainScreen}
            pageName="APROVAL MAINTENANCE"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/apv-maintain-form"
            component={ApprovalMaintainFormScreen}
            pageName="APROVAL MAINTENANCE FORM"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/apv-maintain-preventif"
            component={ApprovalMaintainFormPreventifScreen}
            pageName="APROVAL MAINTENANCE FORM PREVENTIF"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/apv-maintain-vendor"
            component={ApprovalMaintainFormVendorScreen}
            pageName="APROVAL MAINTENANCE FORM VENDOR"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </>
    );
  }