import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getProgressDetail(id_work_order) {
  return {
    type: constants.GET_PROGRESS_DETAIL,
    id_work_order
  };
}

export function getProgressDetailLoading() {
  return {
    type: constants.GET_PROGRESS_DETAIL_LOADING
  };
}

export function getProgressDetailFail(error) {
  return {
    type: constants.GET_PROGRESS_DETAIL_FAIL,
    error
  };
}

export function getProgressDetailSuccess(results) {
  return {
    type: constants.GET_PROGRESS_DETAIL_SUCCESS,
    results
  };
}