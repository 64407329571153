/*
 *
 * VendorKroscekFormScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import helper from '../../util/helper';

export const initialState = {
    error: 0,
    error_msg: '',
    responseState: '',
    loading: false,
    loading_submit: false,
    notes: '',
    results:[],
    assets:[],
    assets_verif:[],
    start_dt: '',
    end_dt: '',
    id_ruang: '',
};

/* eslint-disable default-case, no-param-reassign */
const VendorKroscekFormScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        return {...initialState};
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.GET_WORK_ORDER_VERIF:
        draft.loading = true;
        break;
      case constants.GET_WORK_ORDER_VERIF_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data.';
        draft.results = [];
        break;
      case constants.GET_WORK_ORDER_VERIF_SUCCESS:
        draft.results = action.results;
        draft.loading = false;
        break;
      case constants.GET_VERIF_ASSET:
        draft.loading = true;
        break;
      case constants.GET_VERIF_ASSET_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data.';
        draft.results = [];
        break;
      case constants.GET_VERIF_ASSET_SUCCESS:
        console.log('reducer-new1 : ', action);

        draft.assets = action.results;
        draft.assets_verif = action.results_verif;
        draft.loading = false;
        break;
      case constants.SUBMIT_WORK_ORDER_VERIF:
        draft.loading_submit = true;
        draft.responseState = '';
        break;
      case constants.SUBMIT_WORK_ORDER_VERIF_FAIL:
        draft.error = true;
        draft.loading_submit = false;
        draft.responseState = 'error';
        draft.error_msg= 'Maaf, Gagal melakukan approval.';
        break;
      case constants.ADD_IMAGE:{
        let cur_arr = draft.assets_verif;
        const image = action.asset;
        cur_arr.push({path: image});
        draft.assets_verif = cur_arr;
      }
        break;
      case constants.DEL_IMAGE:
        draft.assets_verif = [...draft.assets_verif.slice(0, action.idx), ...draft.assets_verif.slice(action.idx + 1)];
        break;
      case constants.SUBMIT_WORK_ORDER_VERIF_SUCCESS:
        draft.loading_submit = false;
        draft.error = false;
        if(action.action_type == '20'){
          draft.responseState = 'approved';
        } else {
          draft.responseState = 'rejected';
        }
        break;
    }
  });

export default VendorKroscekFormScreenReducer;
