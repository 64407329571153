import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getPelaporanHistory(id_korektif) {
  return {
    type: constants.GET_PELAPORAN_HISTORY,
    id_korektif
  };
}

export function getPelaporanHistoryFail(error) {
  return {
    type: constants.GET_PELAPORAN_HISTORY_FAIL,
    error
  };
}

export function getPelaporanHistorySuccess(results) {
  console.log(results);
  return {
    type: constants.GET_PELAPORAN_HISTORY_SUCCESS,
    results,
  };
}