import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the ForgetPassScreen state domain
 */

const selectForgetPassScreenDomain = state =>
  state.forgetPassScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by ForgetPassScreen
 */

const makeSelectForgetPassScreen = () =>
  createSelector(
    selectForgetPassScreenDomain,
    substate => substate,
  );

export default makeSelectForgetPassScreen;
export { selectForgetPassScreenDomain };
