/*
 *
 * PelaporanDetailScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import helper from '../../util/helper';

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    dtGedung: null,
    dtAset: [],
    currPelaporan: null
};

/* eslint-disable default-case, no-param-reassign */
const PelaporanDetailScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        break;
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.GET_ROOM_ASSET:
        draft.loading = true;
        break;
      case constants.GET_ROOM_ASSET_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error = 1 ;
        draft.dtGedung = null;
        draft.dtAset = null;
        draft.error_msg= 'Maaf, Hasil pencarian belum ditemukan.';
        break;
      case constants.GET_ROOM_ASSET_SUCCESS:
        draft.unit_asset = action.results;
        if(!helper.isEmpty(action.results.dtGedung)){
          draft.dtGedung = action.results.dtGedung;
          draft.dtAset = action.results.dtAset;
          draft.dtAset.map((responseResult, keyy) => {
            if(draft.currPelaporan){
              const idCx = draft.currPelaporan.findIndex(
                result => result.id_aset === responseResult.id_aset,
              );
              if(idCx >= 0){
                draft.dtAset.splice(keyy, 1, {
                  ...draft.dtAset[keyy],
                  id_pelaporan: draft.currPelaporan[idCx].id_pelaporan,
                });
              }else{
                draft.dtAset.splice(keyy, 1, {
                  ...draft.dtAset[keyy],
                  id_pelaporan: 0,
                });
              }
            }else{
              draft.dtAset.splice(keyy, 1, {
                ...draft.dtAset[keyy],
                id_pelaporan: 0,
              });
            }
            
          });

          // console.log(draft.dtAset);
        }
        draft.loading = false;
        break;
    }
  });

export default PelaporanDetailScreenReducer;
