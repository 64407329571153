import React from "react";
import { Badge, VStack, Box, Button, Icon, Center, Text, Pressable, Flex, HStack } from 'native-base';
import { commonStyle } from "../styles";
import {FaRegFolder} from 'react-icons/fa';
import history from "../util/history";

const MenuIcon = (props) => {

  const handleOnPress = (screen, params) => {
    if(screen != ""){
      // RootNavigation.navigate(screen, params);
      history.push(screen, params);
    }

    return;
  }

  return (
    <Flex flex={1} justifyContent="flex-start">
      <Pressable onPress={() => handleOnPress(props.screen, props.screenParams)}>
        <VStack alignItems="center">
          <Center width="50" height="50" bg={commonStyle.baseColor} rounded="xl" shadow={3} justifyContent="center">
              <Icon as={<Box>{props.icon}</Box>} size={5} color={commonStyle.primaryColor} />
          </Center>
          <Center>
            <Text textAlign="center" alignSelf="center" mt={1} fontSize="xs" fontWeight="500">{props.title ? props.title : 'Menu'}</Text>
          </Center>
        </VStack>
      </Pressable>
    </Flex>
  );
}

export default MenuIcon;