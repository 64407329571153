import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the FormProfileScreen state domain
 */

const selectFormProfileScreenDomain = state =>
  state.formProfileScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by FormProfileScreen
 */

const makeSelectFormProfileScreen = () =>
  createSelector(
    selectFormProfileScreenDomain,
    substate => substate,
  );

export default makeSelectFormProfileScreen;
export { selectFormProfileScreenDomain };
