import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectGlobalVar from './selector';
import { getGedung, getPegawai, updateInput } from './actions';
import api from './api';
import helper from './helper';

export function GlobalVar({
  dispatch,
  globalVar,
  onUpdateInput,
  onGetGedung,
  onGetPegawai
}){

  useEffect(() => {
    onGetGedung();
    onGetPegawai();
  },[]);

  return (
        <></>
      );
    
}

const mapStateToProps = createStructuredSelector({
  globalVar: makeSelectGlobalVar(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetGedung: () =>  dispatch(getGedung()),
    onGetPegawai: () =>  dispatch(getPegawai()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(GlobalVar);