import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the VendorProgressDetailScreen state domain
 */

const selectVendorProgressDetailScreenDomain = state =>
  state.vendorProgressDetailScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by VendorProgressDetailScreen
 */

const makeSelectVendorProgressDetailScreen = () =>
  createSelector(
    selectVendorProgressDetailScreenDomain,
    substate => substate,
  );

export default makeSelectVendorProgressDetailScreen;
export { selectVendorProgressDetailScreenDomain };
