import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getDetailTodo(id_realisasi_preventif, id_inventaris) {
  return {
    type: constants.GET_DETAIL_TODO,
    id_realisasi_preventif,
    id_inventaris
  };
}

export function getDetailTodoLoading() {
  return {
    type: constants.GET_DETAIL_TODO_LOADING
  };
}

export function getDetailTodoFail(error) {
  return {
    type: constants.GET_DETAIL_TODO_FAIL,
    error
  };
}

export function getDetailTodoSuccess(results) {
  return {
    type: constants.GET_DETAIL_TODO_SUCCESS,
    results
  };
}

export function addImage(asset) {
  return {
    type: constants.ADD_IMAGE,
    asset
  };
}

export function delImage(idx) {
  return {
    type: constants.DEL_IMAGE,
    idx
  };
}

export function submitPreventif() {
  return {
    type: constants.SUBMIT_PREVENTIF,
  };
}

export function submitPreventifFail(error) {
  return {
    type: constants.SUBMIT_PREVENTIF_FAIL,
    error
  };
}

export function submitPreventifSuccess(results) {
  return {
    type: constants.SUBMIT_PREVENTIF_SUCCESS,
    results
  };
}