import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
  import api from '../../util/api';
  import helper from '../../util/helper';
import { getRoomAssetFail, getRoomAssetSuccess, getTodoListFail, getTodoListSuccess, submitPreventifFail, submitPreventifSuccess } from './actions';
 
  import * as constants from './constants';
  
  import makeSelectTaskPreventifDetailScreen from './selector';
  
  export function* doGetPreventifAsset(action) {
    const {id_realisasi_preventif} = action;
    try {
      const response = yield call(api.preventif.getPreventif, id_realisasi_preventif);
      yield put(
        getRoomAssetSuccess(response.data),
      );
      console.log("red:", response.data);
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getRoomAssetFail(err));
    }
  }

  export function* doGetPreventifTodo(action) {
    const {id_realisasi_preventif, id_inventaris} = action;
    try {
      const response = yield call(api.preventif.getPreventifTodo, id_realisasi_preventif, id_inventaris);
      yield put(
        getTodoListSuccess(response.data),
      );
      // console.log("red:", response.data);
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getTodoListFail(err));
    }
  }

  export function* doSubmitPreventif(action) {
    const {idx_inventaris} = action;
    const dataset = yield select(makeSelectTaskPreventifDetailScreen());
    try {
      const params = {
          id_preventif_exec: dataset.dtPreventif.id_preventif_exec,
          id_inventaris: idx_inventaris,
          id_realisasi_preventif: dataset.dtPreventif.id_realisasi_preventif,
          id_item: dataset.dtPreventif.id_ruang,
          id_condition: 1,
          attachments: [],
          description: "Kondisi Baik",
      };

      // console.log(params);

      const response = yield call(api.preventif.submitPreventif, params);
      const {id_preventif_exec } = response.data;
      yield put(
        submitPreventifSuccess({idx_inventaris, id_preventif_exec}),
      );
      helper.successToast("Data berhasil disimpan");
      console.log("res:", response.data);
  
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal Menyimpan data, silahkan hub admin");
      yield put(submitPreventifFail(idx_inventaris, err));
    }
  }

  // Individual exports for testing
  export default function* taskPreventifDetailScreenSaga() {
    yield all([
      takeLatest(constants.GET_ROOM_ASSET, doGetPreventifAsset),
      takeLatest(constants.GET_TODO_LIST, doGetPreventifTodo),
      takeLatest(constants.SUBMIT_PREVENTIF, doSubmitPreventif),
    ]);
  }
  