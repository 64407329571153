export const DEFAULT_ACTION = 'screen/TaskPreventifDetailScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/TaskPreventifDetailScreen/UPDATE_INPUT';

export const GET_ROOM_ASSET = 'screen/TaskPreventifDetailScreen/GET_ROOM_ASSET';
export const GET_ROOM_ASSET_LOADING = 'screen/TaskPreventifDetailScreen/GET_ROOM_ASSET_LOADING';
export const GET_ROOM_ASSET_SUCCESS = 'screen/TaskPreventifDetailScreen/GET_ROOM_ASSET_SUCCESS';
export const GET_ROOM_ASSET_FAIL = 'screen/TaskPreventifDetailScreen/GET_ROOM_ASSET_FAIL';

export const CLEAR_SESS_PELAPORAN = 'screen/TaskPreventifDetailScreen/CLEAR_SESS_PELAPORAN';

export const GET_TODO_LIST = 'screen/TaskPreventifDetailScreen/GET_TODO_LIST';
export const GET_TODO_LIST_LOADING = 'screen/TaskPreventifDetailScreen/GET_TODO_LIST_LOADING';
export const GET_TODO_LIST_SUCCESS = 'screen/TaskPreventifDetailScreen/GET_TODO_LIST_SUCCESS';
export const GET_TODO_LIST_FAIL = 'screen/TaskPreventifDetailScreen/GET_TODO_LIST_FAIL';

export const SUBMIT_PREVENTIF = 'screen/TaskPreventifDetailScreen/SUBMIT_PREVENTIF';
export const SUBMIT_PREVENTIF_LOADING = 'screen/TaskPreventifDetailScreen/SUBMIT_PREVENTIF_LOADING';
export const SUBMIT_PREVENTIF_SUCCESS = 'screen/TaskPreventifDetailScreen/SUBMIT_PREVENTIF_SUCCESS';
export const SUBMIT_PREVENTIF_FAIL = 'screen/TaskPreventifDetailScreen/SUBMIT_PREVENTIF_FAIL';