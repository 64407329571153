import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectApprovalMaintainFormScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView, TouchableWithoutFeedback, BackHandler, RefreshControl } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Image,
  Pressable,
  Divider, 
  Flex,
  Stack,
  Badge,
  Input,
  Fab,
  Actionsheet,
  FormControl,
  TextArea,
  ScrollView,
  Menu,
  CheckIcon, 
  Select,
  Button} from 'native-base';
import moment from 'moment';
import {FaCalendarAlt, FaCheckCircle, FaHourglassHalf, FaHouseUser} from 'react-icons/fa';
import { ContainerPage } from '../../components/ContainerPage';
import MyAlert from '../../components/MyAlert';
import MyAlertDialog from '../../components/MyAlertDialog';
import ImageSlider from '../../components/ImageSlider';
import { commonStyle } from '../../styles';
import { defaultAction, getApprovalDetail, getVerifyAssets, submitApproval, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import history from '../../util/history';
import makeSelectGlobalVar from '../../util/selector';
import { getLantai, getPegawai } from '../../util/actions';
import MySelect2 from '../../components/MySelect2';

export function ApprovalMaintainFormScreen({
  dispatch,
  location,
  navigation,
  approvalMaintainFormScreen,
  globalVar,
  onUpdateInput,
  onGetResult,
  onGetResultAssets,
  onSubmitApproval
}){
  const {rawData, id_apv, readOnly, addNew} = location.state;
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const [actionType, setActionType] = useState();
  const [idWorkOrder, setIdWorkOrder] = useState();
  const[userData, setUserData] = useState();
  const imageList = [
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
  ];
  useEffect(() => {
      const user = api.getUserData();
        console.log("saved_user:", user);
        console.log("initial:", helper.getInitials(user.user_name));
        setUserData(user);

      dispatch(defaultAction());
      onUpdateInput('id_apv', id_apv);
      onUpdateInput('nm_ruangan', rawData.nm_ruangan);
      onUpdateInput('code_proses', rawData.code_proses);
      onUpdateInput('rawApv', rawData);
      onUpdateInput('new_data', addNew);
      // onUpdateInput('notes', dtProgress.description_feedback);
      onGetResult(id_apv, rawData.code_proses, rawData.date_realisasi);
  },[]);

  useEffect(() => {
    onUpdateInput('responseState','');
    
    if(approvalMaintainFormScreen.responseState == 'approved'){
      helper.successToast("Anda telah menyetujui laporan ini");
    }
    if(approvalMaintainFormScreen.responseState == 'done'){
      helper.successToast("Anda telah menyelesaikan laporan ini");
    }
    if(approvalMaintainFormScreen.responseState == 'rejected'){
      helper.warningToast("Anda telah menolak laporan ini")
    }
    if(approvalMaintainFormScreen.responseState == 'error'){
      helper.errorToast("Aproval pemeliharaan belum berhasil")
    }
  },[approvalMaintainFormScreen.responseState]);

  const handleOnSubmit = (action_type, id_wo) => {
    if(action_type == '10'){
      helper.warningToast("Anda telah menolak laporan ini");
      history.replace('approval-maintenance');
      return;
    }

    if(helper.isEmpty(approvalMaintainFormScreen.id_petugas) || approvalMaintainFormScreen.id_petugas == 0){
      helper.warningToast("Pilih Petugas terlebih dahulu.");
      return;
    }

    if(helper.isEmpty(approvalMaintainFormScreen.notes) || approvalMaintainFormScreen.notes.length < 10){
      helper.warningToast("Isikan hasil verifikasi, Minimal 10 karakter.");
      return;
    }

    setActionType(action_type);
    setIdWorkOrder(id_wo)
    setIsOpen(true);
  };

  const handleDoSubmit = () => {
    setIsOpen(false);
    onSubmitApproval(actionType, idWorkOrder);
  };

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'approval-maintenance', title: 'Aproval Pemeliharaan'}}>
          <Flex flex={1}>
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              <VStack space={7} mt={2} mb={40} mx={3}>
                <Box mt={5}>
                    <Center>
                      <HStack space={2} justifyContent="center" alignItems="center">
                        <Icon as={<Box><FaCheckCircle/></Box>} size="8" color="success.700" />
                        <Text color="darkText" fontWeight="bold" isTruncated maxWidth="90%" fontSize="lg">
                          {approvalMaintainFormScreen.code_pelaporan}
                        </Text>
                      </HStack>
                    </Center>
                  </Box>
                {/* <Box p={5} mt={3} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    <VStack>
                      <Center>
                        <Avatar size="lg" bg="primary.900" mb={4}>
                            {helper.getInitials(`Daun Pintu`)}
                        </Avatar>
                      </Center>
                      <Center>
                        <Text color="darkText" isTruncated maxWidth="90%" fontSize="md" fontWeight="bold">{`Daun Pintu`}</Text>
                      </Center>
                      <Center>
                        <Text color="muted.400" isTruncated maxWidth="90%" fontSize="sm">{dtProgress.code_inventaris}</Text>
                      </Center>
                      <Box mt={2}>
                        <VStack>
                          <Text fontSize="xs" color="muted.600">No Penawaran</Text>
                          <Text color="darkText" bold>{dtProgress.code_work_order}</Text>
                        </VStack>
                      </Box>
                      <Box mt={2}>
                        <VStack>
                          <Text fontSize="xs" color="muted.600">Deadline Pekerjaan</Text>
                          <Text color="darkText" bold>{moment(dtProgress.forcast_date_finish, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY")}</Text>
                        </VStack>
                      </Box>
                    </VStack>
                </Box> */}
                <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    <VStack>
                      <Flex mb="2.5">
                        <ImageSlider images={approvalMaintainFormScreen.assets}/>
                      </Flex>
                      <Box mb={2}>
                        <Text fontWeight="bold">{approvalMaintainFormScreen.nm_inventaris}</Text>
                      </Box>
                      <HStack flex={1} mb={2}>
                        <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                          <Box>
                            <Icon color="muted.400" as={<Box><FaCalendarAlt/></Box>} size={3}></Icon>
                          </Box>
                          <Box flex={1} ml={1}>
                            <Text color="muted.400" fontWeight={500} fontSize="xs">{moment(approvalMaintainFormScreen.tgl_laporan).format('DD-MM-YYYY')}</Text>
                          </Box>
                        </Stack>
                        <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                          <Box>
                            <Icon color="muted.400" as={<Box><FaHouseUser/></Box>} size={3}></Icon>
                          </Box>
                          <Box flex={1} ml={1}>
                            <Text color="muted.400" fontWeight={500} fontSize="xs">{approvalMaintainFormScreen.nm_ruangan}</Text>
                          </Box>
                        </Stack>
                      </HStack>
                      <Box mb={2}>
                        <Text color="darkText" textAlign="justify">
                          {approvalMaintainFormScreen.description}
                        </Text>
                      </Box>
                      {!readOnly &&
                      <Flex>
                        <Divider bg="primary.800" thickness="1" my="2" orientation="horizontal" />
                        <Box mb={2}>
                          <FormControl mb="1">
                            <FormControl.Label>Pelaksana</FormControl.Label>

                            <MySelect2
                              key="opt-pegawai"
                              isClearable
                              defaultValue="0"
                              placeholder="Pilih Pelaksana"
                              menuPortalTarget={document.body}
                              getOptionLabel={option => `${option.name_employee}`}
                              getOptionValue={option => option.id}
                              options={globalVar.list_pegawai}
                              value={globalVar.list_pegawai.filter(({id}) => id === approvalMaintainFormScreen.id_petugas)}
                              onChange={(selectedOption) => onUpdateInput('id_petugas', (selectedOption==null) ? 0 : selectedOption.id)}
                            />

                            {/* <Select 
                              variant='filled' 
                              borderColor="primary.900" 
                              selectedValue={approvalMaintainFormScreen.id_petugas} 
                              minWidth="200" 
                              accessibilityLabel="Pilih Pelaksana" 
                              placeholder="Pilih Pelaksana" 
                              _selectedItem={{endIcon: <CheckIcon size="5"/>}} 
                              my={2}
                              onValueChange={itemValue => onUpdateInput('id_petugas', itemValue)}>
                                {globalVar.list_pegawai.map((v, i) => (
                                  <Select.Item key={`lt${i}`} label={`${v.name_employee}`} value={`${v.id}`} />
                                  ))}
                              </Select> */}
                          </FormControl>
                          <FormControl mb="1" isRequired>
                            <FormControl.Label>Keterangan</FormControl.Label>
                            <TextArea h={40} value={approvalMaintainFormScreen.notes} placeholder="Ketik disini..." onChangeText={txt => onUpdateInput('notes', txt)} borderRadius="lg" w="100%" />
                          </FormControl>
                        </Box> 
                        <Box mb={2}>
                          <HStack>
                            <Box flex={1} mr={3}>
                              <Button isLoading={approvalMaintainFormScreen.loading_submit} spinnerPlacement="end" isLoadingText="Submitting..." onPress={() => handleOnSubmit('20', approvalMaintainFormScreen.code_proses)} colorScheme="success" >Setujui</Button>
                            </Box>
                            <Box flex={1}>
                              <Button isLoading={approvalMaintainFormScreen.loading_submit} spinnerPlacement="end" isLoadingText="Submitting..." onPress={() => handleOnSubmit('10', approvalMaintainFormScreen.code_proses)} colorScheme="success" variant="outline" >Tolak</Button>
                            </Box>
                          </HStack>
                        </Box>
                      </Flex>
                      }
                    </VStack>
                </Box>
              </VStack>
            </ScrollView>
          </Flex>
          <MyAlertDialog 
            alertTitle="Aproval Pemeliharaan" 
            alertDesc={`Apakah anda ingin ${actionType == '20' ? 'Menyetujui' : (actionType == '30' ? 'Menyelesaikan': 'Menolak')} laporan ini ?`}
            alertNoLabel="Tidak"
            alertYesLabel="Ya"
            alertIsOpen={isOpen}
            alertOnClose={onClose}
            alertOnConfirm={() => handleDoSubmit()}
          />
          {/* <Fab onPress={() => navigation.navigate('vendor_offer_form')} renderInPortal={false} shadow={2} size="lg" icon={<Icon color="white" as={AntDesign} name="doubleright" size="lg" />} /> */}
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  approvalMaintainFormScreen: makeSelectApprovalMaintainFormScreen(),
  globalVar: makeSelectGlobalVar(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetResult: (id, code_proses, dt_realisasi) => dispatch(getApprovalDetail(id, code_proses, dt_realisasi)),
    onGetResultAssets: (id_work_order_report) => dispatch(getVerifyAssets(id_work_order_report)),
    onSubmitApproval: (action_type, id_wo) => dispatch(submitApproval(action_type, id_wo)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(ApprovalMaintainFormScreen);