export const DEFAULT_ACTION = 'screen/TaskPreventifScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/TaskPreventifScreen/UPDATE_INPUT';

export const GET_PELAPORAN = 'screen/TaskPreventifScreen/GET_PELAPORAN';
export const GET_PELAPORAN_LOADING = 'screen/TaskPreventifScreen/GET_PELAPORAN_LOADING';
export const GET_PELAPORAN_SUCCESS = 'screen/TaskPreventifScreen/GET_PELAPORAN_SUCCESS';
export const GET_PELAPORAN_FAIL = 'screen/TaskPreventifScreen/GET_PELAPORAN_FAIL';

export const GET_MORE_PELAPORAN = 'screen/TaskPreventifScreen/GET_MORE_PELAPORAN';
export const GET_MORE_PELAPORAN_LOADING = 'screen/TaskPreventifScreen/GET_MORE_PELAPORAN_LOADING';
export const GET_MORE_PELAPORAN_SUCCESS = 'screen/TaskPreventifScreen/GET_MORE_PELAPORAN_SUCCESS';
export const GET_MORE_PELAPORAN_FAIL = 'screen/TaskPreventifScreen/GET_MORE_PELAPORAN_FAIL';