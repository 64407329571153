/*
 *
 * ApprovalMaintainFormVendorScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import helper from '../../util/helper';
import moment from 'moment';

export const initialState = {
    error: 0,
    error_msg: '',
    responseState: '',
    loading: false,
    loading_submit: false,
    notes: '',
    results:[],
    assets:[],
    start_dt: '',
    end_dt: '',
    deadline_dt: new Date(),
    id_apv: '',
    id_petugas: '',
    id_vendor:'',
    budget: '',
    data_approval: null,
    nm_inventaris: '',
    nm_ruangan:'',
    tgl_laporan:'',
    code_pelaporan:'',
    description: '',
    code_proses: '',
    new_data: false,
    rawApv:null,
    list_penawaran: [],
    raw_penawaran: null,
    raw_nego: null,
};

/* eslint-disable default-case, no-param-reassign */
const ApprovalMaintainFormVendorScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        return {...initialState};
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.GET_APPROVAL_DETAIL:
        draft.loading = true;
        break;
      case constants.GET_APPROVAL_DETAIL_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data.';
        draft.data_approval = null;
        break;
      case constants.GET_APPROVAL_DETAIL_SUCCESS:{
        const rawDetail = action.results;
        draft.data_approval = action.results;
        draft.loading = false;
        draft.code_pelaporan = rawDetail.code;
        draft.list_penawaran = rawDetail.submit_penawaran;
        // draft.nm_inventaris = rawDetail.pemeliharaan_korektf[0].ms_inventori.nama;
        // draft.tgl_laporan = rawDetail.tgl_create;
        // draft.description = rawDetail.pemeliharaan_korektf[0].description;
        // draft.assets = rawDetail.pelaporan_attachment;
        // inputan
        if(!draft.new_data && rawDetail.w_o){
          draft.id_vendor = rawDetail.w_o.id_vendor.toString();
          draft.deadline_dt = moment(rawDetail.w_o.date_line).toDate();
          draft.notes = rawDetail.w_o.noted;
          draft.budget = rawDetail.w_o.harga;
          const idxP = draft.list_penawaran.map(function(x) {return x.id_vendor; }).indexOf(rawDetail.w_o.id_vendor);
          const rawP = draft.list_penawaran[idxP];
          draft.raw_penawaran = rawP;
        }
        
      }
        break;
      case constants.GET_VERIF_ASSET:
        draft.loading = true;
        break;
      case constants.GET_VERIF_ASSET_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data.';
        draft.results = [];
        break;
      case constants.GET_VERIF_ASSET_SUCCESS:
        draft.assets = action.results;
        draft.loading = false;
        break;
      case constants.SUBMIT_APPROVAL:
        draft.loading_submit = true;
        draft.responseState = '';
        break;
      case constants.SUBMIT_APPROVAL_FAIL:
        draft.error = true;
        draft.loading_submit = false;
        draft.responseState = 'error1';
        draft.error_msg= 'Maaf, Gagal melakukan approval.';
        break;
      case constants.SUBMIT_APPROVAL_SUCCESS:
        draft.loading_submit = false;
        draft.error = false;
        if(action.action_type == '20'){
          draft.responseState = 'approved';
        } else {
          draft.responseState = 'rejected';
        }
        break;
      case constants.GET_NEGO_PENAWARAN:
        draft.loading = true;
        break;
      case constants.GET_NEGO_PENAWARAN_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data.';
        break;
      case constants.GET_NEGO_PENAWARAN_SUCCESS: {
        const resNego = action.results;
        draft.loading = false;
        draft.raw_nego = resNego;
        }
        break;
    }
  });

export default ApprovalMaintainFormVendorScreenReducer;
