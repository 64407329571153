import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
import api from '../../util/api';
import helper from '../../util/helper';
import history from '../../util/history';
// import * as RootNavigation from '../../../RootNavigation';
import { getPelaporanFail, getPelaporanSuccess, submitPelaporanFail, submitPelaporanSuccess } from './actions';

  import * as constants from './constants';
  
  import makeSelectPelaporanDetailInputScreen from './selector';
  
  export function* doSubmitPelaporan(action) {
    const {id_branch, origin_screen} = action;
    const dataset = yield select(makeSelectPelaporanDetailInputScreen());
    try {
      const params = {
          id_pelaporan: dataset.id_pelaporan,
          id_ruang: dataset.id_item,
          id_inventaris: dataset.id_aset,
          description: dataset.description,
          notes: dataset.notes,
          attachment: dataset.assets,
      };

      // console.log(params);

      const response = yield call(api.pelaporan.submitPelaporan, params);
      yield put(
        submitPelaporanSuccess(response.data, dataset.id_aset),
      );
      helper.successToast("Data berhasil disimpan");
      console.log("res:", response.data);

      // save current pelaporan
      if(origin_screen == 'pelaporan-detail'){
        api.saveCurrInputPelaporan(dataset.id_aset, response.data.id_pelaporan);
        history.push(origin_screen, {id_branch: id_branch, id_item: dataset.id_item, new_data: false});
      }else{
        history.push(origin_screen);
      }
      
  
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal Menyimpan data, silahkan hub admin");
      yield put(submitPelaporanFail(err));
    }
  }

  export function* doGetPelaporan(action) {
    const dataset = yield select(makeSelectPelaporanDetailInputScreen());
    try {
      const response = yield call(api.pelaporan.getPelaporan, action.id_pelaporan);
      const {data, attachment} = response.data;
  
      yield put(
        getPelaporanSuccess(data[0], attachment),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getPelaporanFail(err));
    }
  }

  // Individual exports for testing
  export default function* pelaporanDetailInputScreenSaga() {
    yield all([
      takeLatest(constants.SUBMIT_PELAPORAN, doSubmitPelaporan),
      takeLatest(constants.GET_PELAPORAN, doGetPelaporan),
    ]);
  }
  