/*
 *
 * WelcomeScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    loading_gedung: false,
    loading_lantai: false,
    loading_pegawai: false,
    list_gedung: [],
    list_lantai: [],
    list_pegawai: [],
};

/* eslint-disable default-case, no-param-reassign */
const GlobalVarReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        break;
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.GET_GEDUNG:
        draft.loading_gedung = true;
        break;
      case constants.GET_GEDUNG_FAIL:
        draft.error = true;
        draft.loading_gedung = false;
        break;
      case constants.GET_GEDUNG_LOADING:
        draft.loading_gedung = true;
        break;
      case constants.GET_GEDUNG_SUCCESS:
        draft.list_gedung = action.results;
        draft.loading_gedung = false;
        break;
      case constants.GET_LANTAI:
        draft.list_lantai = [];
        draft.loading_lantai = true;
      break;
      case constants.GET_LANTAI_FAIL:
        draft.error = true;
        draft.loading_lantai = false;
        break;
      case constants.GET_LANTAI_LOADING:
        draft.loading_lantai = true;
        break;
      case constants.GET_LANTAI_SUCCESS:
        draft.list_lantai = action.results;
        draft.loading_lantai = false;
        break;
      case constants.GET_PEGAWAI:
        draft.loading_pegawai = true;
      break;
      case constants.GET_PEGAWAI_FAIL:
        draft.error = true;
        draft.loading_pegawai = false;
        break;
      case constants.GET_PEGAWAI_LOADING:
        draft.loading_pegawai = true;
        break;
      case constants.GET_PEGAWAI_SUCCESS:
        draft.list_pegawai = action.results;
        draft.loading_pegawai = false;
        break;
    }
  });

export default GlobalVarReducer;
