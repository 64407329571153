import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the PelaporanHistoryDetailScreen state domain
 */

const selectPelaporanHistoryDetailScreenDomain = state =>
  state.pelaporanHistoryDetailScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by PelaporanHistoryDetailScreen
 */

const makeSelectPelaporanHistoryDetailScreen = () =>
  createSelector(
    selectPelaporanHistoryDetailScreenDomain,
    substate => substate,
  );

export default makeSelectPelaporanHistoryDetailScreen;
export { selectPelaporanHistoryDetailScreenDomain };
