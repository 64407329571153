import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
import * as constants from './constants';
import api from '../../util/api';
import helper from '../../util/helper';
// import * as RootNavigation from '../../../RootNavigation';
import makeSelectWelcomeScreen from './selector';
import { getUserPrivilegesFail, getUserPrivilegesSuccess } from './actions';
  
  export function* doGetUserPrivileges(action) {
    const {id_akun} = action;
    const dataset = yield select(makeSelectWelcomeScreen());
    try {
      const response = yield call(api.user.getPrivileges, id_akun);
      const {success} = response.data;
  
      yield put(
        getUserPrivilegesSuccess(success),
      );
      
      // RootNavigation.navigate('page', {screen: 'home'});

    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getUserPrivilegesFail(err));
    }
  }

  // Individual exports for testing
  export default function* welcomeScreenSaga() {
    yield all([
      takeLatest(constants.GET_USER_PRIVILEGES, doGetUserPrivileges),
    ]);
  }
  