import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import api from '../../util/api';
  import helper from '../../util/helper';
  import history from '../../util/history';
  // import * as RootNavigation from '../../../RootNavigation';
  import * as constants from './constants';
  
  import makeSelectTaskScannerScreen from './selector';
  import { getRoomAsset, getRoomAssetFail, getRoomAssetSuccess, scanned } from './actions';
  
  export function* doGetRoomAsset(action) {
    const {resScan, data_type} = action;
    const params = resScan.split("|");
    const id_branch = params[1];
    const id_item = params[2];
    console.log("p-saga:",id_branch, id_item);
    try {
      const response = yield call(api.unit.getAsset, id_branch, id_item);
      const res = response.data;
      console.log('res_obj:', res);

      // check if result not empty
      if(helper.isEmpty(res.success) || res.success.length == 0){
        yield put(getRoomAssetFail());
        return;
      }

      yield put(
        getRoomAssetSuccess(response.data),
      );
      
      switch (data_type) {
        case 'pelaporan':
          history.replace('pelaporan-detail',{id_branch: id_branch, id_item: id_item, new_data: true});
          break;
        case 'verif_pelaporan':
          history.replace('verif-pelaporan',{id_branch: id_branch, id_item: id_item});
          break;
        case 'preventif':
          history.replace('preventif',{id_branch: id_branch, id_item: id_item});
          break;
        case 'kroscek_progres':
          history.replace('vendor-kroscek',{id_branch: id_branch, id_item: id_item});
          break;
        default:
          break;
      }

    } catch (err) {
      console.log(err);
      // helper.errorToast("Koneksi server gagal");
      yield put(getRoomAssetFail(err));
    }
  }

  export function* scanResultHandle(action) {
    const {result, data_type} = action;
    const dataset = yield select(makeSelectTaskScannerScreen());
    if(!dataset.scanned){
      yield put(scanned());
      console.log("scan result:", action.result);
      yield put(getRoomAsset(result, data_type));
      console.log("QR scanned");
    }else{
      // skip if scanned
      console.log("QR Already scanned");
    }
  }

  // Individual exports for testing
  export default function* taskScannerScreenSaga() {
    yield all([
      takeLatest(constants.GET_ROOM_ASSET, doGetRoomAsset),
      takeLatest(constants.SCAN_RESULT, scanResultHandle),
    ]);
  }
  