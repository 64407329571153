import React from "react";
import { commonStyle } from "../styles";
import {
    Icon,
    HStack, 
    Avatar, 
    Heading, 
    VStack, 
    Box, 
    Center,
    Stack,
    Badge,
    Input,
    Image,
    IconButton,
    Pressable,
    Divider,
    HamburgerIcon,
    Skeleton,
    Menu,
    Text, 
    Flex } from 'native-base';

const CardListPreloader = (props) => {
  const {data} = props;
  
  return (
    <Box width="100%" minW="300px" backgroundColor="white" opacity={0.8} borderRadius="lg">
      <VStack>
        <Skeleton borderTopRadius="lg" startColor="#ffd13c" />
        <Box p={5}>
          <VStack>
            <Flex flexDirection="row">
              <Skeleton size="60" rounded="full" />
              <Skeleton.Text lines={2} ml={3} width="70%" />
            </Flex>
            <Flex flexDirection="row" mt={4}>
              <Skeleton size="60" rounded="full" />
              <Skeleton.Text lines={2} ml={3} width="70%" />
            </Flex>
            <Skeleton mt={5} borderRadius="lg" startColor="#e7e5e4" />
          </VStack>
        </Box>
      </VStack>
  </Box>
  );
}

export default CardListPreloader;