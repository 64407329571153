import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
  import api from '../../util/api';
  import helper from '../../util/helper';
  import moment from 'moment';
  import * as constants from './constants';
  
  import makeSelectVendorProgressFormScreen from './selector';
import { defaultAction, getProgressReportFail, getProgressReportSuccess, submitProgressReportFail, submitProgressReportSuccess } from './actions';
import history from '../../util/history';
  
  export function* doGetProgressReport(action) {
    const dataset = yield select(makeSelectVendorProgressFormScreen());
    try {
      const response = yield call(api.work_order.getProgressReport, action.id_work_order_detail);
      const {data, attachment} = response.data;
      console.log(data);
      yield put(
        getProgressReportSuccess(data),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal mengambil data");
      yield put(getProgressReportFail(err));
    }
  }

  export function* doSubmitProgressReport(action) {
    const {action_type} = action;
    const dataset = yield select(makeSelectVendorProgressFormScreen());
    try {
      const params = {
          id_work_order_report: dataset.id_work_order_report,
          id_work_order: dataset.id_work_order,
          id_work_order_detail: dataset.id_work_order_detail,
          progress: dataset.progress,
          status: dataset.status,
          tgl_deadline: moment(dataset.tgl_deadline).format('DD-MM-YYYY'),
          description: dataset.description,
          assets: dataset.assets
      };

      console.log(params);

      const response = yield call(api.work_order.submitProgressReport, params);
      // console.log(response);
      yield put(submitProgressReportSuccess(response.data));
      yield put(defaultAction());

      history.replace('vendor-progress'); 
    } catch (err) {
      console.log(err);
      yield put(submitProgressReportFail(err));
    }
  }
  // Individual exports for testing
  export default function* vendorProgressFormScreenSaga() {
    yield all([
      takeLatest(constants.GET_PROGRESS_REPORT, doGetProgressReport),
      takeLatest(constants.SUBMIT_PROGRESS, doSubmitProgressReport),
    ]);
  }
  