import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectHomeScreen from './selector';
import reducer from './reducer';
import saga from './saga';

//import { Image, ScrollView, TouchableWithoutFeedback, SafeAreaView, BackHandler, RefreshControl } from 'react-native';
// import messaging from '@react-native-firebase/messaging';
import {
  Badge,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text, 
  Flex, 
  FlatList,
  ScrollView,
  Pressable} from 'native-base';
import { ContainerPage } from '../../components/ContainerPage';
import BadgeIcon from '../../components/BadgeIcon';
import MenuIcon from '../../components/MenuIcon';
import CardSlider from '../../components/CardSlider';
import api from '../../util/api';
import { commonStyle } from '../../styles';
import { getTasks, updateInput } from './actions';
import helper from '../../util/helper';
import history from '../../util/history';
import makeSelectLoginScreen from '../LoginScreen/selector';
import { onMessage } from 'firebase/messaging';
import { onMessageListener, messaging } from '../../firebase';
import { FaAddressBook } from 'react-icons/fa';

export function HomeScreen({
  dispatch,
  navigation,
  homeScreen,
  loginScreen,
  onUpdateInput,
  onGetTasks
}){
  const menuScreen = api.getMenuScreen();

  const[userData, setUserData] = useState();
  const[userNotif, setUserNotif] = useState();
  const[menuList, setMenuList] = useState();
  const[backClickCount, setBackClickCount] = useState(0);

  useEffect(() => {
    // set userdata
    const user = api.getUserData();
    setUserData(user);
    // get notif
    const notif = api.getUserNotif();
    setUserNotif(notif);
    // set menus
    let allowedMenu = [];
    const menus = api.getUserPrivileges();
    const allAccess = helper.generatePrivileges(menus);
    menuScreen.forEach(row => {
      const idx = menus.findIndex(
        result => result.kode_menu === row.code,
      );
      if(row.featured && allAccess.includes(`${row.code}:ENB`)) allowedMenu.push({...row, name : menus[idx].nama_menu});
    });
    setMenuList(allowedMenu);

    // get tasks
    onGetTasks();

    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Received fcm message ', payload);
      let msg = JSON.stringify(payload);
      localStorage.setItem('adikandung:notif', msg);
      setUserNotif(msg);
      helper.infoToast("Ada notifikasi baru telah masuk.", 3000, 'top');
    });

    return unsubscribe;

    // const unsubscribe_fbm = messaging().onMessage(async remoteMessage => {
    //   console.log("cur-notif-fbm:", remoteMessage);
    //   setUserNotif(remoteMessage);
    // });
    // return unsubscribe_fbm;
},[]);

useEffect(() => {
  // const unsubscribe = [
  //   navigation.addListener('focus', () => {
  //     // The screen is focused
  //     const notif = api.getUserNotif().then(notif => {
  //       console.log('cur-notif:', notif);
  //       setUserNotif(notif);
  //     });
  //   })
  // ];
  
  // return function doUnsubscribe() {
  //   unsubscribe.forEach((unSub) => {
  //     unSub();
  //   });
  // }
},[navigation]);

// useEffect(() => {
//   const notif = api.getUserNotif().then(notif => {
//     console.log('cur-notif:', notif);
//     setUserNotif(notif);
//   });
// },[loginScreen.user_notif]);

const handleOnRefresh = () => {
  onGetTasks();
}
return (
        <ContainerPage full>
          <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
          <VStack flex={1} space={4} mt={7}>
            <Flex direction="row" mb="2.5" mt="1.5" mx={5}>
              <Box>
                <Avatar size="md" bg="amber.900" mr="1">
                      {userData && helper.getInitials(userData.user_name)}
                </Avatar>
              </Box>
              <Box ml={3}>
                <VStack>
                  <Text fontSize="md">{helper.getGreetingText()},</Text>
                  <Text fontSize="lg" color={commonStyle.primaryColor} isTruncated maxW="250" width="100%" fontWeight="bold">{userData && userData.user_name}</Text>
                </VStack>
              </Box>
              <Box position="absolute" width="30" right={0} top={0}>
                <Pressable onPress={() => history.push('notif')}>
                  <BadgeIcon value={userNotif} icon="notifications"></BadgeIcon>
                </Pressable>
              </Box>
            </Flex>
            <Flex mx={5}>
              <HStack alignItems="center">
                <Box>
                  <Text fontSize="lg" fontWeight="bold" mr={3}>Jadwal Pemeliharaan:</Text>
                </Box>
                <Box flexDirection="row" backgroundColor="primary.900" p={2} rounded="xl">
                  <Text fontWeight={500} color="white" mr={1}>Hari ini</Text>
                  <Badge variant="solid" colorScheme="error" rounded="full">
                    {homeScreen.tasks.length}
                  </Badge>
                </Box>
              </HStack>
            </Flex>
            {homeScreen.tasks.length > 0 &&
            <Box style={{height: 220}}>
              <Flex flex={1} mb="2.5" height="230">
                <CardSlider data={homeScreen.tasks}/>
              </Flex>
            </Box>
            }
            {menuList &&
            <Box style={{flex: 1}}>
              {/* <FlatList 
                numColumns={4}
                data={menuList}
                renderItem={({ item }) => (
                      <MenuIcon screen={item.screen} screenParams={item.screen_params} icon={item.icon} title={item.name}/>
                )}
              /> */}
              <div className='row'>
                {menuList.map((item, idx) => 
                  <div className='col-3 mb-3' key={`menu${idx}`}>
                    <MenuIcon screen={item.screen} screenParams={item.screen_params} icon={item.icon} title={item.name}/>
                  </div>
                )}
              </div>
            </Box>
            }
          </VStack>
            {/* <Image source={require('../../images/logo-white-h.png')} style={{alignSelf: 'center', marginBottom: 10 }} />
            <Box>
              <Center>
                <Box w="100%" maxWidth="300px">
                  <Heading size="md" my={3}>
                    Welcome To Homepage
                  </Heading>
                </Box>
                {[...Array(30)].map((x, i) => (
                    <Heading size="md" my={3}>
                      Welcome To Homepage
                    </Heading>)
                  )}
              </Center>
            </Box> */}
          </ScrollView>
        </ContainerPage>
      );
    
}

const mapStateToProps = createStructuredSelector({
  homeScreen: makeSelectHomeScreen(),
  loginScreen: makeSelectLoginScreen()
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetTasks: () => dispatch(getTasks()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(HomeScreen);