import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import api from '../../util/api';
  import helper from '../../util/helper';
  import moment from 'moment';
  import makeSelectTaskScreen from './selector';
import { getGedungFail, getGedungSuccess, getTasksFail, getTasksSuccess } from './actions';
  
  export function* doGetGedung(action) {
    try {
      const response = yield call(api.unit.getGedung);
      const {success, data} = response.data;
      console.log("r-gedung:", data);
      yield put(
        getGedungSuccess(data),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal mengambil data gedung");
      yield put(getGedungFail(err));
    }
  }

  export function* doGetListTask(action) {
    const dataset = yield select(makeSelectTaskScreen());
    try {
      const tglAwal = moment(dataset.start_dt).format("YYYY-MM-DD 00:00:00");
      const tglAkhir = moment(dataset.end_dt).format("YYYY-MM-DD 23:59:59");
      const response = yield call(api.task.getList, tglAwal, tglAkhir, dataset.id_gedung);
      const {data} = response.data;
      
      yield put(
        getTasksSuccess((helper.isEmpty(data) ? [] : data)),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal mengambil data task");
      yield put(getTasksFail(err));
    }
  }

  // Individual exports for testing
  export default function* taskScreenSaga() {
    yield all([
      takeLatest(constants.GET_GEDUNG, doGetGedung),
      takeLatest(constants.GET_TASKS, doGetListTask),
    ]);
  }
  