import logo from './logo.svg';
import './App.css';
import React, {useState, useEffect} from "react";
import {onMessageListener, requestForToken, messaging} from './firebase';
import { getMessaging, onMessage } from "firebase/messaging";

import Main from './screens/Main';
import helper from './util/helper';


function App() {
  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Received fcm message ', payload);
      let msg = JSON.stringify(payload);
      localStorage.setItem('adikandung:notif', msg);
      helper.infoToast("Ada notifikasi baru telah masuk.", 3000, 'top');
    });

    return unsubscribe;
  },[])

  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React Jhon
        </a>
      </header>
      <Box>Hello world</Box> */}
      <Main/>
    </div>
  );
}

export default App;
