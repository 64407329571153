import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import api from './api';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getGedung() {
  return {
    type: constants.GET_GEDUNG
  };
}

export function getGedungLoading() {
  return {
    type: constants.GET_GEDUNG_LOADING
  };
}

export function getGedungFail(error) {
  return {
    type: constants.GET_GEDUNG_FAIL,
    error
  };
}

export function getGedungSuccess(results) {
  return {
    type: constants.GET_GEDUNG_SUCCESS,
    results
  };
}

export function getLantai(id_gedung) {
  return {
    type: constants.GET_LANTAI,
    id_gedung
  };
}

export function getLantaiLoading() {
  return {
    type: constants.GET_LANTAI_LOADING
  };
}

export function getLantaiFail(error) {
  return {
    type: constants.GET_LANTAI_FAIL,
    error
  };
}

export function getLantaiSuccess(results) {
  return {
    type: constants.GET_LANTAI_SUCCESS,
    results
  };
}

export function getPegawai() {
  return {
    type: constants.GET_PEGAWAI
  };
}

export function getPegawaiLoading() {
  return {
    type: constants.GET_PEGAWAI_LOADING
  };
}

export function getPegawaiFail(error) {
  return {
    type: constants.GET_PEGAWAI_FAIL,
    error
  };
}

export function getPegawaiSuccess(results) {
  return {
    type: constants.GET_PEGAWAI_SUCCESS,
    results
  };
}