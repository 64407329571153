/*
 *
 * VendorOfferDetailScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    results: null
};

/* eslint-disable default-case, no-param-reassign */
const VendorOfferDetailScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        break;
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.GET_OFFER_DETAIL:
        draft.loading = true;
        break;
      case constants.GET_OFFER_DETAIL_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data pelaporan.';
        draft.results = null;
        break;
      case constants.GET_OFFER_DETAIL_SUCCESS:
        draft.results = action.results;
        draft.loading = false;
        break;
    }
  });

export default VendorOfferDetailScreenReducer;
