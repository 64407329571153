/*
 *
 * FormChangePassScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export const initialState = {
    error: false,
    error_msg: '',
    loading: false,
    result: null,
    isValid: false,
    isChecked: false,
    old_pass: '',
    new_pass: '',
    new_pass_conf: ''
};

/* eslint-disable default-case, no-param-reassign */
const FormChangePassScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        break;
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.SUBMIT_PASSWORD:
        draft.loading = true;
        break;
      case constants.SUBMIT_PASSWORD_SUCCESS:
        draft.loading = false;
        draft.error = false ;
        draft.error_msg= '';
        draft.isValid = true;
        draft.isChecked = true;
        draft.result = action.result;
        break;
      case constants.SUBMIT_PASSWORD_FAIL:
        draft.loading = false;
        draft.isValid = false;
        draft.isChecked = true;
        draft.error = false ;
        draft.error_msg= 'Maaf, Kombinasi username atau password masih belum sesuai.';
        break;
    }
  });

export default FormChangePassScreenReducer;
