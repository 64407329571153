/*
 *
 * ApprovalMaintainFormScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import helper from '../../util/helper';

export const initialState = {
    error: 0,
    error_msg: '',
    responseState: '',
    loading: false,
    loading_submit: false,
    notes: '',
    results:[],
    assets:[],
    start_dt: '',
    end_dt: '',
    id_apv: '',
    id_petugas: '',
    data_approval: null,
    nm_inventaris: '',
    nm_ruangan:'',
    tgl_laporan:'',
    code_pelaporan:'',
    description: '',
    code_proses: '',
    new_data: false,
    rawApv:null,
};

/* eslint-disable default-case, no-param-reassign */
const ApprovalMaintainFormScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        return {...initialState};
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.GET_APPROVAL_DETAIL:
        draft.loading = true;
        break;
      case constants.GET_APPROVAL_DETAIL_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data.';
        draft.data_approval = null;
        break;
      case constants.GET_APPROVAL_DETAIL_SUCCESS:{
        const rawDetail = action.results;
        let tmp_nm_nm_inventaris = '';
        let tmp_description = '';
        let tmp_assets = '';
        let tmp_tgl_laporan = '';
        
        if(rawDetail.pemeliharaan_korektf){
          tmp_nm_nm_inventaris = rawDetail.pemeliharaan_korektf[0].ms_inventori.nama;
        }

        if(rawDetail.wo_detail){
          tmp_nm_nm_inventaris = rawDetail.wo_detail.ms_inventaris.nama;
        }

        if(rawDetail.pemeliharaan_korektf){
          tmp_description = rawDetail.pemeliharaan_korektf[0].description;
        }

        if(rawDetail.description_progres){
          tmp_description = rawDetail.description_progres;
        }
        
        if(rawDetail.pelaporan_attachment){
          tmp_assets = rawDetail.pelaporan_attachment;
        }

        if(rawDetail.wo_report_attechment){
          tmp_assets = rawDetail.wo_report_attechment;
        }

        if(rawDetail.tgl_create){
          tmp_tgl_laporan = rawDetail.tgl_create;
        }

        if(rawDetail.date_create){
          tmp_tgl_laporan = rawDetail.date_create;
        }


        draft.data_approval = action.results;
        draft.loading = false;
        draft.code_pelaporan = rawDetail.code_pelaporan;
        draft.nm_inventaris = tmp_nm_nm_inventaris;
        draft.tgl_laporan = tmp_tgl_laporan;
        draft.description = tmp_description;
        draft.assets = tmp_assets;
        // inputan
        if(!draft.new_data){
          let tmp_id_petugas = 0;
          let tmp_notes = '';

          if(rawDetail.pemeliharaan_korektf){
            tmp_id_petugas = rawDetail.pemeliharaan_korektf[0].id_pegawai_asign;
          }

          if(rawDetail.id_pegawai_verif){
            tmp_id_petugas = rawDetail.id_pegawai_verif;
          }

          if(rawDetail.pemeliharaan_korektf){
            tmp_notes = rawDetail.pemeliharaan_korektf[0].description;
          }

          if(rawDetail.noted){
            tmp_notes = rawDetail.noted;
          }


          draft.id_petugas = tmp_id_petugas;
          draft.notes = tmp_notes;
        }
      }
        break;
      case constants.GET_VERIF_ASSET:
        draft.loading = true;
        break;
      case constants.GET_VERIF_ASSET_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data.';
        draft.results = [];
        break;
      case constants.GET_VERIF_ASSET_SUCCESS:
        draft.assets = action.results;
        draft.loading = false;
        break;
      case constants.SUBMIT_APPROVAL:
        draft.loading_submit = true;
        draft.responseState = '';
        break;
      case constants.SUBMIT_APPROVAL_FAIL:
        draft.error = true;
        draft.loading_submit = false;
        draft.responseState = 'error';
        draft.error_msg= 'Maaf, Gagal melakukan approval.';
        break;
      case constants.SUBMIT_APPROVAL_SUCCESS:
        draft.loading_submit = false;
        draft.error = false;
        if(action.action_type == '20'){
          draft.responseState = 'approved';
        } else {
          draft.responseState = 'rejected';
        }
        break;
    }
  });

export default ApprovalMaintainFormScreenReducer;
