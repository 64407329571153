import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the TaskScreen state domain
 */

const selectTaskScreenDomain = state =>
  state.taskScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by TaskScreen
 */

const makeSelectTaskScreen = () =>
  createSelector(
    selectTaskScreenDomain,
    substate => substate,
  );

export default makeSelectTaskScreen;
export { selectTaskScreenDomain };
