import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the ApprovalMaintainScreen state domain
 */

const selectApprovalMaintainScreenDomain = state =>
  state.approvalMaintainScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by ApprovalMaintainScreen
 */

const makeSelectApprovalMaintainScreen = () =>
  createSelector(
    selectApprovalMaintainScreenDomain,
    substate => substate,
  );

export default makeSelectApprovalMaintainScreen;
export { selectApprovalMaintainScreenDomain };
