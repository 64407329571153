import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function addImage(asset) {
  return {
    type: constants.ADD_IMAGE,
    asset
  };
}

export function delImage(idx) {
  return {
    type: constants.DEL_IMAGE,
    idx
  };
}

export function getProgressReport(id_work_order_detail) {
  return {
    type: constants.GET_PROGRESS_REPORT,
    id_work_order_detail
  };
}

export function getProgressReportLoading() {
  return {
    type: constants.GET_PROGRESS_REPORT_LOADING
  };
}

export function getProgressReportFail(error) {
  return {
    type: constants.GET_PROGRESS_REPORT_FAIL,
    error
  };
}

export function getProgressReportSuccess(results) {
  return {
    type: constants.GET_PROGRESS_REPORT_SUCCESS,
    results
  };
}

export function submitProgressReport() {
  return {
    type: constants.SUBMIT_PROGRESS
  };
}

export function submitProgressReportFail(error) {
  return {
    type: constants.SUBMIT_PROGRESS_FAIL,
    error
  };
}

export function submitProgressReportSuccess(results) {
  return {
    type: constants.SUBMIT_PROGRESS_SUCCESS,
    results
  };
}