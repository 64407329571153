import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
  import moment from 'moment';
  import * as constants from './constants';
  
  import makeSelectTaskPreventifScreen from './selector';
  import api from '../../util/api';
  import helper from '../../util/helper';
  import { getMorePelaporanFail, getMorePelaporanSuccess, getPelaporanFail, getPelaporanSuccess } from './actions';

  export function* doGetListPelaporan(action) {
    const dataset = yield select(makeSelectTaskPreventifScreen());
    try {
      const tglAwal = moment(dataset.start_dt).format("DD-MM-YYYY");
      const tglAkhir = moment(dataset.end_dt).format("DD-MM-YYYY");

      const params = {
        date_start: tglAwal,
        date_end: tglAkhir,
        id_ruang: action.id_ruang,
        search: dataset.q,
        status: dataset.filter_status
      };

      if(helper.isEmpty(dataset.start_dt)) delete params.date_start;
      if(helper.isEmpty(dataset.end_dt)) delete params.date_end;
      if(helper.isEmpty(dataset.q)) delete params.search;
      if(helper.isEmpty(dataset.filter_status)) delete params.status;

      const response = yield call(api.preventif.getList, params, dataset.page);
      const {data} = response.data;
      
      if(dataset.page == 1){
        yield put(
          getPelaporanSuccess((helper.isEmpty(data) ? [] : data)),
        );
      }else{
        yield put(
          getMorePelaporanSuccess((helper.isEmpty(data) ? [] : data)),
        );
      }
      
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      if(dataset.page == 1){ 
        yield put(getPelaporanFail(err));
      }else{
        yield put(getMorePelaporanFail(err));
      }
    }
  }
  
  // Individual exports for testing
  export default function* taskPreventifScreenSaga() {
    yield all([
      takeLatest(constants.GET_PELAPORAN, doGetListPelaporan),
      takeLatest(constants.GET_MORE_PELAPORAN, doGetListPelaporan),
    ]);
  }