import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
  import moment from 'moment';
  import api from '../../util/api';
  import helper from '../../util/helper';
  import * as constants from './constants';
  
  import makeSelectVendorOfferFormScreen from './selector';
import { getNegoOfferFail, getNegoOfferSuccess, getVendorOfferFail, getVendorOfferSuccess, submitNegoOfferFail, submitNegoOfferSuccess, submitVendorOfferFail, submitVendorOfferSuccess } from './actions';
  
  export function* doSubmitOffer(action) {
    const dataset = yield select(makeSelectVendorOfferFormScreen());
    try {
      const tglOffer = moment(dataset.date_offer).format("DD-MM-YYYY");
      
      const params = {
          id_penawaran_request: dataset.vendor_offer.id_penawaran_request,
          perkiraan_tanggal_selesai: tglOffer,
          nominal_penawaran: dataset.cost_offer,
          attachments: dataset.assets,
          description: dataset.description,
      };

      console.log(params);

      const response = yield call(api.vendor.submitOffer, params);
      yield put(
        submitVendorOfferSuccess(response.data),
      );
      // helper.errorToast("Data berhasil disimpan");
      console.log("res:", response.data);

    } catch (err) {
      console.log(err);
      // helper.errorToast("Gagal Menyimpan data, silahkan hub admin");
      yield put(submitVendorOfferFail(err));
    }
  }

  export function* doGetOfferDetail(action) {
    const dataset = yield select(makeSelectVendorOfferFormScreen());
    try {
      const response = yield call(api.vendor.getDetailOffer, action.id_penawaran_request);
      const {data} = response.data;
  
      yield put(
        getVendorOfferSuccess((helper.isEmpty(data) ? null : data)),
      );
      console.log("res:", data);
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getVendorOfferFail(err));
    }
  }

  export function* doGetNegoOfferDetail(action) {
    const dataset = yield select(makeSelectVendorOfferFormScreen());
    try {
      if(!helper.isEmpty(dataset.vendor_offer.id_submit_penawaran)){
        const response = yield call(api.vendor.getDetailNegoOffer, dataset.vendor_offer?.id_submit_penawaran);
        const {success} = response.data;
    
        yield put(
          getNegoOfferSuccess((helper.isEmpty(success) ? null : success)),
        );
        console.log("res:", success);
      }
      
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getNegoOfferFail(err));
    }
  }

  export function* doSubmitNegoOffer(action) {
    const {action_type} = action;
    const dataset = yield select(makeSelectVendorOfferFormScreen());
    const {vendor_offer} = dataset;
    try {
      const params = {
          id_submit_penawaran: vendor_offer.id_submit_penawaran,
          no_penawaran: vendor_offer.code_penawaran_request,
          status: action_type
      };

      // console.log(params);

      const response = yield call(api.vendor.submitApvNegoOffer, params);
      console.log(response);
      yield put(
        submitNegoOfferSuccess(response.data, action_type),
      );
      helper.successToast("Data berhasil disimpan");
    } catch (err) {
      console.log(err);
      yield put(submitNegoOfferFail(err));
    }
  }

  // Individual exports for testing
  export default function* vendorOfferFormScreenSaga() {
    yield all([
      takeLatest(constants.SUBMIT_OFFER, doSubmitOffer),
      takeLatest(constants.GET_VENDOR_OFFER, doGetOfferDetail),
      takeLatest(constants.GET_VENDOR_OFFER_SUCCESS, doGetNegoOfferDetail),
      takeLatest(constants.SUBMIT_NEGO_PENAWARAN, doSubmitNegoOffer),
    ]);
  }
  