import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectFormChangePassScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView, TouchableWithoutFeedback, BackHandler } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar,
  Stack, 
  Heading, 
  VStack,
  FormControl,
  Pressable,
  Input, 
  Box, 
  Center,
  Text,
  Image, 
  Flex, 
  Button} from 'native-base';
import {MdVisibility, MdVisibilityOff} from 'react-icons/md';
import { ContainerPage } from '../../components/ContainerPage';
import { commonStyle } from '../../styles';
import { submitPass, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';

export function FormChangePassScreen({
  dispatch,
  navigation,
  formChangePassScreen,
  onUpdateInput,
  onSubmitPass
}){
  const [userData, setUserData] = useState();
  const [show, setShow] = useState(false);
  const [showBaru, setShowBaru] = useState(false);
  const [showConfBaru, setShowConfBaru] = useState(false);

  useEffect(() => {
      const user = api.getUserData();
      setUserData(user);
  },[]);

  const handleSubmitPass = () => {
    if(helper.isEmpty(formChangePassScreen.new_pass) || helper.isEmpty(formChangePassScreen.old_pass)){
      helper.warningToast("Lengkapi semua data terlebih dahulu");
      return;
    }   
    if(formChangePassScreen.new_pass !== formChangePassScreen.new_pass_conf){
      helper.warningToast("Pastikan password baru sama dengan kolom konfirmasi");
      return;
    }

    onSubmitPass();
  }

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'profile', title: 'Ubah Password'}} >
            <Flex>
              <Box mx={3} mt={5} p={4} backgroundColor="white" shadow={3} borderRadius="lg">
                <Box>
                  <VStack space={2}>
                    <Box>
                      <FormControl mb="1" isRequired>
                        <Stack>
                          <FormControl.Label>Password Lama</FormControl.Label>
                          <Stack space={4} w="100%">
                            <Input value={formChangePassScreen.old_pass} onChangeText={txt => onUpdateInput('old_pass', txt)} type={show ? "text" : "password"} InputRightElement={<Pressable onPress={() => setShow(!show)}>
                                  <Icon as={(show ? <Box><MdVisibility /></Box> : <Box><MdVisibilityOff /></Box>)} size={5} mr="2" color="muted.400" />
                                </Pressable>} borderRadius="lg" placeholder="Ketikkan Password"  />
                          </Stack>
                        </Stack>
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl mb="1" isRequired>
                        <Stack>
                          <FormControl.Label>Password Baru</FormControl.Label>
                          <Stack space={4} w="100%">
                            <Input value={formChangePassScreen.new_pass} onChangeText={txt => onUpdateInput('new_pass', txt)} type={showBaru ? "text" : "password"} InputRightElement={<Pressable onPress={() => setShowBaru(!showBaru)}>
                                  <Icon as={(showBaru ? <Box><MdVisibility /></Box> : <Box><MdVisibilityOff /></Box>)} size={5} mr="2" color="muted.400" />
                                </Pressable>} borderRadius="lg" placeholder="Ketikkan Password"  />
                          </Stack>
                        </Stack>
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl mb="1" isRequired>
                        <Stack>
                          <FormControl.Label>Ketik Ulang Password</FormControl.Label>
                          <Stack space={4} w="100%">
                            <Input value={formChangePassScreen.new_pass_conf} onChangeText={txt => onUpdateInput('new_pass_conf', txt)} type={showConfBaru ? "text" : "password"} InputRightElement={<Pressable onPress={() => setShowConfBaru(!showConfBaru)}>
                                  <Icon as={(showConfBaru ? <Box><MdVisibility /></Box> : <Box><MdVisibilityOff /></Box>)} size={5} mr="2" color="muted.400" />
                                </Pressable>} borderRadius="lg" placeholder="Ketikkan Password"  />
                          </Stack>
                        </Stack>
                      </FormControl>
                    </Box>
                  </VStack>
                </Box>
              </Box>
            </Flex>
            <Flex my={5} mx={3}>
              <Button isLoading={formChangePassScreen.loading} spinnerPlacement="end" isLoadingText="Submitting..." onPress={() => handleSubmitPass()} shadow={3}>Simpan</Button>
            </Flex>
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  formChangePassScreen: makeSelectFormChangePassScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onSubmitPass: () => dispatch(submitPass()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(FormChangePassScreen);