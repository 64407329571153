import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import api from '../../util/api';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getNotifications(id_notif = null) {
  return {
    type: constants.GET_NOTIFICATIONS,
    id_notif
  };
}

export function getNotificationsLoading() {
  return {
    type: constants.GET_NOTIFICATIONS_LOADING
  };
}

export function getNotificationsFail(error) {
  return {
    type: constants.GET_NOTIFICATIONS_FAIL,
    error
  };
}

export function getNotificationsSuccess(results) {

  return {
    type: constants.GET_NOTIFICATIONS_SUCCESS,
    results
  };
}