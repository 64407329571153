import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { useClearCache } from 'react-clear-cache';
import makeSelectWelcomeScreen from './selector';
import reducer from './reducer';
import saga from './saga';

import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Image, 
  Flex, 
  Button} from 'native-base';
import { MdPhone, MdOutlineEmail } from 'react-icons/md';
import { ContainerScreen } from '../../components/ContainerScreen';
import MyAlertDialog from '../../components/MyAlertDialog';
import { commonStyle } from '../../styles';
import { getUserPrivileges, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import history from '../../util/history';

export function WelcomeScreen({
  dispatch,
  navigation,
  welcomeScreen,
  onUpdateInput,
  onGetUserPrivileges
}){
  const[userData, setUserData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const [updatingApp, setUpdatingApp] = useState(false);

  useEffect(() => {
      const user = api.getUserData(); 
      console.log("saved_user:", user);
      console.log("initial:", helper.getInitials(user.user_name));
      setUserData(user);
  },[]);

  const onHandleGetPrivilege = () => {
    onGetUserPrivileges(userData.akun_id);
    history.push('home');
    // navigation.navigate('page',{screen:'home'});
  }

  const onHandleLogout = () => {
    api.clearUserData();
    window.location.href = "/login";
  }

  const handleOnUpdatingApp = (evt) => {
    evt.preventDefault();
    setUpdatingApp(true);
    emptyCacheStorage();
  }

  return (
        <ContainerScreen>
          {!isLatestVersion &&
          <Flex>
            <HStack space={1} justifyContent="space-between" alignItems="center">
              <Text fontSize="sm">New Version Available</Text>
              <Button size="sm" colorScheme="amber" variant="subtle" onPress={evt => handleOnUpdatingApp(evt)} spinnerPlacement="end" isLoading={updatingApp} isLoadingText="Loading">
                Update Now
              </Button>
            </HStack>
          </Flex>
          }
          <Flex flex={1} justifyContent="center" mt={100}>
              <Center>
                <Box>
                  <VStack>
                    <Box>
                        <Image alt='logo' style={{width: 300, height:83, alignSelf:'center'}} source={require('../../images/logo-white-h.png')}/>
                    </Box>
                    <Box>
                      <Center>
                        <Heading>Selamat Datang!</Heading>
                        {/* <Box width={281} mt={3}>
                          <Center>
                            <Text color="danger.700">"Status menunggu aktivasi dari administrator Sistem".</Text>
                          </Center>
                        </Box> */}
                      </Center>
                    </Box>
                    <Box mt={10} mb={10} width={300}  backgroundColor="white" shadow={3} p={5} borderRadius="2xl">
                      <VStack alignItems="center">
                        <Box mb={3}>
                          <Center>
                            <Avatar size="lg" bg="primary.900" mr="1">
                                {userData && helper.getInitials(userData.user_name)}
                            </Avatar>
                          </Center>
                        </Box>
                        <Heading mb={3}>{userData && userData.user_name}</Heading>
                        {/* <HStack mb={2} justifyContent="center">
                          <Icon as={<MaterialIcons name='credit-card'></MaterialIcons>} size="md" mr={2} />
                          <Text fontSize="md">{userData && userData.akun_id}</Text> 
                        </HStack> */}
                        <HStack mb={2} justifyContent="center">
                          <Icon as={<Box><MdPhone/></Box>} size="md" mr={2} />
                          <Text fontSize="md">{userData && userData.hp}</Text> 
                        </HStack>
                        <HStack justifyContent="center">
                          <Icon as={<Box><MdOutlineEmail/></Box>} size="md" mr={2} />
                          <Text fontSize="md">{userData && userData.email}</Text> 
                        </HStack>
                      </VStack>
                    </Box>
                    <Button isLoading={welcomeScreen.loading} spinnerPlacement="end" isLoadingText="Checking" borderRadius="2xl" onPress={() => onHandleGetPrivilege()}>Mulai Sekarang</Button>
                    {/* <Button isLoading={welcomeScreen.loading} spinnerPlacement="end" isLoadingText="Checking" borderRadius="2xl" onPress={() => setIsOpen(true)}>Logout</Button> */}
                  </VStack>
                </Box>
              </Center>
              <MyAlertDialog 
                  alertTitle="Keluar Aplikasi" 
                  alertDesc="Apakah anda ingin keluar aplikasi ?"
                  alertNoLabel="Tidak"
                  alertYesLabel="Ya"
                  alertIsOpen={isOpen}
                  alertOnClose={onClose}
                  alertOnConfirm={onHandleLogout}
                />
          </Flex>
        </ContainerScreen>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  welcomeScreen: makeSelectWelcomeScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetUserPrivileges: id_akun =>  dispatch(getUserPrivileges(id_akun)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(WelcomeScreen);