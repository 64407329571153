/*
 *
 * PelaporanHistoryScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    results: [],
};

/* eslint-disable default-case, no-param-reassign */
const PelaporanHistoryScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        break;
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.GET_PELAPORAN_HISTORY:
        draft.loading = true;
        break;
      case constants.GET_PELAPORAN_HISTORY_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data pelaporan.';
        draft.results = [];
        break;
      case constants.GET_PELAPORAN_HISTORY_SUCCESS:
        draft.results = action.results;
        draft.loading = false;
        draft.error = false;
        break;
    }
  });

export default PelaporanHistoryScreenReducer;
