import React, { Component, useState, useEffect, forwardRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectApprovalMaintainFormVendorScreenScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView, TouchableWithoutFeedback, BackHandler, RefreshControl } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Image,
  Pressable,
  Divider, 
  Flex,
  Stack,
  Fab,
  FormControl,
  TextArea,
  ScrollView,
  Select,
  CheckIcon,
  Input, 
  Button,
  Menu} from 'native-base';
import moment from 'moment';
import DatePicker from "react-datepicker";
import {FaCalendarAlt, FaCheckCircle, FaFileInvoice, FaHourglassHalf, FaHouseUser} from 'react-icons/fa';
import { ContainerPage } from '../../components/ContainerPage';
import MyAlert from '../../components/MyAlert';
import MyAlertDialog from '../../components/MyAlertDialog';
import ImageSlider from '../../components/ImageSlider';
import { commonStyle } from '../../styles';
import { defaultAction, getApprovalDetail, getVerifyAssets, submitApproval, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import history from '../../util/history';
import makeSelectGlobalVar from '../../util/selector';
import { MdCalendarToday, MdEditDocument, MdHistory, MdMoreVert } from 'react-icons/md';
import BadgeStatus from '../../components/BadgeStatus';
import CurrencyInput from 'react-currency-input-field';

export function ApprovalMaintainFormVendorScreen({
  dispatch,
  location,
  navigation,
  approvalMaintainFormVendorScreen,
  globalVar,
  onUpdateInput,
  onGetResult,
  onGetResultAssets,
  onSubmitApproval
}){
  const {rawData, id_apv, readOnly, addNew} = location.state;
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const [actionType, setActionType] = useState();
  const [idWorkOrder, setIdWorkOrder] = useState();
  const[userData, setUserData] = useState();
  const imageList = [
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
  ];
  useEffect(() => {
      const user = api.getUserData();
        console.log("saved_user:", user);
        console.log("initial:", helper.getInitials(user.user_name));
        setUserData(user);

      dispatch(defaultAction());
      onUpdateInput('id_apv', id_apv);
      onUpdateInput('nm_ruangan', rawData.nm_ruangan);
      onUpdateInput('code_proses', rawData.code_proses);
      onUpdateInput('rawApv', rawData);
      onUpdateInput('new_data', addNew);
      // onUpdateInput('notes', dtProgress.description_feedback);
      onGetResult(id_apv, rawData.code_proses, rawData.date_realisasi);
  },[]);

  useEffect(() => {
    onUpdateInput('responseState','');
    
    if(approvalMaintainFormVendorScreen.responseState == 'approved'){
      helper.successToast("Anda telah menyetujui laporan ini");
    }
    if(approvalMaintainFormVendorScreen.responseState == 'done'){
      helper.successToast("Anda telah menyelesaikan laporan ini");
    }
    if(approvalMaintainFormVendorScreen.responseState == 'rejected'){
      helper.warningToast("Anda telah menolak laporan ini")
    }
    if(approvalMaintainFormVendorScreen.responseState == 'error'){
      helper.errorToast("Aproval pemeliharaan belum berhasil")
    }
    if(approvalMaintainFormVendorScreen.responseState == 'error1'){
      helper.errorToast("Project sudah memiliki pemenang, vendor tidak dapat diubah!")
    }
  },[approvalMaintainFormVendorScreen.responseState]);

  const handleOnSubmit = (action_type, id_wo) => {
    const budget_amount = parseInt(approvalMaintainFormVendorScreen.budget) || 0;

    if(action_type == '10'){
      helper.warningToast("Anda telah menolak laporan ini");
      history.replace('approval-maintenance');
      return;
    }

    if(helper.isEmpty(approvalMaintainFormVendorScreen.id_vendor)){
      helper.warningToast("Pilih Vendor terlebih dahulu.");
      return;
    }

    console.log('data-in-index', approvalMaintainFormVendorScreen.budget);
    if(helper.isEmpty(approvalMaintainFormVendorScreen.budget) || budget_amount < 100){
      helper.warningToast("Isikan Anggaran terlebih dahulu dan minimal 100.");
      return;
    }

    if(helper.isEmpty(approvalMaintainFormVendorScreen.notes) || approvalMaintainFormVendorScreen.notes.length < 10){
      helper.warningToast("Isikan hasil verifikasi, Minimal 10 karakter.");
      return;
    }

    setActionType(action_type);
    setIdWorkOrder(id_wo)
    setIsOpen(true);
  };

  const handleDoSubmit = () => {
    setIsOpen(false);
    onSubmitApproval(actionType, idWorkOrder);
  };

  const handleChangeVendor = (id_vendor) => {
    let id_vd = parseInt(id_vendor) || 0;
    const idxPenawaran = approvalMaintainFormVendorScreen.list_penawaran.map(function(x) {return x.id_vendor; }).indexOf(id_vd);
    const raw_penawaran = approvalMaintainFormVendorScreen.list_penawaran[idxPenawaran];

    console.log('raw_penawaran', raw_penawaran);

    onUpdateInput('id_vendor', id_vendor);
    onUpdateInput('raw_penawaran', raw_penawaran);
    onUpdateInput('notes', raw_penawaran.noted);
  };

  const InputDate = forwardRef(({ value, onClick }, ref) => (
    <Input 
      value={moment(approvalMaintainFormVendorScreen.deadline_dt).format("DD/MM/YYYY")} 
      isReadOnly="true"
      onFocus={onClick} 
      borderRadius="lg" 
      variant="filled"
      ref={ref} 
      borderColor="primary.900" 
      InputLeftElement={<Icon as={<Box><MdCalendarToday/></Box>} size={5} ml="2" color="muted.400" />} 
      />
  ));

  const onChangeDt = (selectedDate) => {
    const currentDate = selectedDate;
    onUpdateInput("deadline_dt", currentDate);
  };

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'approval-maintenance', title: 'Aproval Pemeliharaan'}}>
          <Flex flex={1}>
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              <VStack space={3} mt={2} mb={40} mx={3}>
                <Box mt={5}>
                    <Center>
                      <HStack space={2} justifyContent="center" alignItems="center">
                        <Icon as={<Box><FaCheckCircle/></Box>} size="8" color="success.700" />
                        <Text color="darkText" fontWeight="bold" isTruncated maxWidth="98%" fontSize="lg">
                          {approvalMaintainFormVendorScreen.code_pelaporan}
                        </Text>
                      </HStack>
                    </Center>
                  </Box>
                {/* <Box p={5} mt={3} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    <VStack>
                      <Center>
                        <Avatar size="lg" bg="primary.900" mb={4}>
                            {helper.getInitials(`Daun Pintu`)}
                        </Avatar>
                      </Center>
                      <Center>
                        <Text color="darkText" isTruncated maxWidth="90%" fontSize="md" fontWeight="bold">{`Daun Pintu`}</Text>
                      </Center>
                      <Center>
                        <Text color="muted.400" isTruncated maxWidth="90%" fontSize="sm">{dtProgress.code_inventaris}</Text>
                      </Center>
                      <Box mt={2}>
                        <VStack>
                          <Text fontSize="xs" color="muted.600">No Penawaran</Text>
                          <Text color="darkText" bold>{dtProgress.code_work_order}</Text>
                        </VStack>
                      </Box>
                      <Box mt={2}>
                        <VStack>
                          <Text fontSize="xs" color="muted.600">Deadline Pekerjaan</Text>
                          <Text color="darkText" bold>{moment(dtProgress.forcast_date_finish, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY")}</Text>
                        </VStack>
                      </Box>
                    </VStack>
                </Box> */}
                <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    <VStack>
                      
                      <Flex>
                        <Box mb={2}>
                          <FormControl mb="1">
                            <FormControl.Label>Vendor</FormControl.Label>
                            {approvalMaintainFormVendorScreen.list_penawaran.length == 0 && <Text color="danger.400">Belum ada penawaran.</Text>}
                            {approvalMaintainFormVendorScreen.list_penawaran.length > 0 && 
                            <Select 
                              variant='filled' 
                              borderColor="primary.900" 
                              selectedValue={approvalMaintainFormVendorScreen.id_vendor} 
                              minWidth="200" 
                              accessibilityLabel="Pilih Vendor" 
                              placeholder="Pilih Vendor" 
                              _selectedItem={{endIcon: <CheckIcon size="5"/>}} 
                              my={2}
                              onValueChange={itemValue => handleChangeVendor(itemValue)}>
                                {approvalMaintainFormVendorScreen.list_penawaran.map((v, i) => (
                                <Select.Item key={`vdr${i}`} label={`${v.ms_vendor.nama}`} value={`${v.id_vendor}`} />
                                ))}
                              </Select>
                            }
                          </FormControl>
                          <FormControl mb="1">
                            <FormControl.Label>Tanggal Perkiraan Selesai</FormControl.Label>
                            <DatePicker customInput={<InputDate/>} portalId='root-portal' className='input-datepicker' popperClassName='pooper-datepicker' selected={approvalMaintainFormVendorScreen.deadline_dt} onChange={(date) => onChangeDt(date)} />
                          </FormControl>
                          <FormControl mb="1">
                            <FormControl.Label>Anggaran</FormControl.Label>
                            {/* <Input textAlign="right" autoComplete='off' onChangeText={txt => onUpdateInput('budget', txt)} value={approvalMaintainFormVendorScreen.budget} keyboardType='numeric' borderRadius="lg" /> */}
                            <CurrencyInput
                              id="input-anggaran"
                              name="input-anggaran"
                              placeholder="0"
                              style={{textAlign: 'right'}}
                              decimalSeparator="," 
                              groupSeparator="."
                              borderRadius="lg"
                              defaultValue={approvalMaintainFormVendorScreen.budget}
                              value={approvalMaintainFormVendorScreen.budget}
                              decimalsLimit={0}
                              onValueChange={txt => onUpdateInput('budget', txt)}
                            />
                          </FormControl>
                          {/* <FormControl mb="1">
                            <FormControl.Label>Pelaksana</FormControl.Label>
                            <Select 
                              variant='filled' 
                              borderColor="primary.900" 
                              selectedValue={approvalMaintainFormVendorScreen.id_petugas} 
                              minWidth="200" 
                              accessibilityLabel="Pilih Pelaksana" 
                              placeholder="Pilih Pelaksana" 
                              _selectedItem={{endIcon: <CheckIcon size="5"/>}} 
                              my={2}
                              onValueChange={itemValue => onUpdateInput('id_petugas', itemValue)}>
                                {globalVar.list_pegawai.map((v, i) => (
                                  <Select.Item key={`lt${i}`} label={`${v.name_employee}`} value={`${v.id}`} />
                                  ))}
                              </Select>
                          </FormControl> */}
                          <FormControl mb="1">
                            <FormControl.Label>Catatan</FormControl.Label>
                            <TextArea h={40} value={approvalMaintainFormVendorScreen.notes} placeholder="Ketik disini..." onChangeText={txt => onUpdateInput('notes', txt)} borderRadius="lg" w="100%" />
                          </FormControl>
                        </Box> 
                        {!readOnly &&
                          <Box mb={2}>
                            <HStack>
                              <Box flex={1} mr={3}>
                                <Button isLoading={approvalMaintainFormVendorScreen.loading_submit} spinnerPlacement="end" isLoadingText="Submitting..." onPress={() => handleOnSubmit('20', approvalMaintainFormVendorScreen.code_proses)} colorScheme="success" >Setujui</Button>
                              </Box>
                              <Box flex={1}>
                                <Button isLoading={approvalMaintainFormVendorScreen.loading_submit} spinnerPlacement="end" isLoadingText="Submitting..." onPress={() => handleOnSubmit('10', approvalMaintainFormVendorScreen.code_proses)} colorScheme="success" variant="outline" >Tolak</Button>
                              </Box>
                            </HStack>
                          </Box>
                        }
                      </Flex>
                      
                    </VStack>
                </Box>
                
                <>
                <Flex mt={3}>
                  {/* <Text color="#b3a26c" fontSize="lg">Informasi Penawaran</Text>
                  <Divider bg="#b3a26c" thickness="2" my="2" orientation="horizontal" /> */}
                  {!approvalMaintainFormVendorScreen.raw_penawaran && <MyAlert status="info" title="Pilih Vendor terlebih dahulu."/>}
                </Flex>
                {approvalMaintainFormVendorScreen.raw_penawaran && 
                <Box width="100%" backgroundColor={commonStyle.baseColor} shadow={3} borderRadius="lg">
                  <VStack>
                    <Box px={5} py={3} backgroundColor="#E4F6F4" borderTopRadius="lg">
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Box>
                          <Icon color="darkText" as={<Box><FaFileInvoice/></Box>} size={5}></Icon>
                        </Box>
                        <Box flex={1} ml={1}>
                          <Text color="darkText" fontWeight={500} >Detail Penawaran</Text>
                        </Box>
                        <Box ml={3}>
                          {approvalMaintainFormVendorScreen.raw_nego && <BadgeStatus status={`Nego`}/>}
                        </Box>
                        <Box ml={2} alignItems="center" justifyContent="center" width={8} height={8}>
                          <Menu mt={2} py={2} placement="bottom right" trigger={triggerProps => {
                            return <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                                    <Icon as={<Box><MdMoreVert/></Box>} size={8} color="darkText" />
                                  </Pressable>;
                          }}>
                            <Menu.Item p={1} onPress={() => history.push('vendor-nego-form',{...location.state, vendor_offer: approvalMaintainFormVendorScreen.raw_penawaran, code_pelaporan: approvalMaintainFormVendorScreen.code_pelaporan})}>
                              <HStack space={2} justifyContent="center" alignItems="center">
                                <Icon as={<Box><MdEditDocument/></Box>} size="md" color="info.700" />
                                <Text color="darkText" fontSize="md">
                                  Nego Penawaran
                                </Text>
                              </HStack>
                            </Menu.Item>
                            
                          </Menu>
                        </Box>
                      </Stack>
                    </Box>
                    <Box p={5}>
                    <VStack mb={1} space={1}>
                        {approvalMaintainFormVendorScreen.raw_penawaran.submit_attachment.map((x, i) => (
                          <Button key={`btn-doc${i}`} onPress={() => window.open(x.path_url)} colorScheme="primary" >Open Dokumen</Button>
                        ))}
                      </VStack>
                      {/* <Divider bg="primary.800" thickness="1" my="2" orientation="horizontal" /> */}
                      <Box mb={2}>
                        <FormControl mb="1">
                          <FormControl.Label>Nominal Penawaran</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text textAlign="right">{(parseInt(approvalMaintainFormVendorScreen.raw_penawaran.harga) || 0).toLocaleString()}</Text>
                          </Box>
                        </FormControl>
                        <FormControl mb="1">
                          <FormControl.Label>Perkiraan Tanggal Selesai</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text>{moment(approvalMaintainFormVendorScreen.raw_penawaran.waktu_pengerjaan).format('DD-MM-YYYY')}</Text>
                          </Box>
                        </FormControl>
                        <FormControl mb="1">
                          <FormControl.Label>Deskripsi</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text>{approvalMaintainFormVendorScreen.raw_penawaran.description}</Text>
                          </Box>
                        </FormControl>
                      </Box>
                    </Box>
                  </VStack>
                </Box>
                }
                
                </>
                
                {/* {[...Array(1)].map((x, i) => (
                  <Box key={`vdr${i}`} p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    <Box mb={1}>
                      <Button colorScheme="primary" >Open Dokumen</Button>
                    </Box>
                    <Box mb={2}>
                      <FormControl mb="1">
                        <FormControl.Label>Nominal Penawaran</FormControl.Label>
                        <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                          <Text textAlign="right">100.000.000</Text>
                        </Box>
                      </FormControl>
                      <FormControl mb="1">
                        <FormControl.Label>Perkiraan Tanggal Selesai</FormControl.Label>
                        <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                          <Text>12-12-2023</Text>
                        </Box>
                      </FormControl>
                      <FormControl mb="1">
                        <FormControl.Label>Deskripsi</FormControl.Label>
                        <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                          <Text>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s</Text>
                        </Box>
                      </FormControl>
                    </Box>
                  </Box>
                ))} */}
              </VStack>
            </ScrollView>
          </Flex>
          <MyAlertDialog 
            alertTitle="Aproval Pemeliharaan" 
            alertDesc={`Apakah anda ingin ${actionType == '20' ? 'Menyetujui' : (actionType == '30' ? 'Menyelesaikan': 'Menolak')} laporan ini ?`}
            alertNoLabel="Tidak"
            alertYesLabel="Ya"
            alertIsOpen={isOpen}
            alertOnClose={onClose}
            alertOnConfirm={() => handleDoSubmit()}
          />
          {/* <Fab onPress={() => navigation.navigate('vendor_offer_form')} renderInPortal={false} shadow={2} size="lg" icon={<Icon color="white" as={AntDesign} name="doubleright" size="lg" />} /> */}
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  approvalMaintainFormVendorScreen: makeSelectApprovalMaintainFormVendorScreenScreen(),
  globalVar: makeSelectGlobalVar(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetResult: (id, code_proses, dt_realisasi) => dispatch(getApprovalDetail(id, code_proses, dt_realisasi)),
    onGetResultAssets: (id_work_order_report) => dispatch(getVerifyAssets(id_work_order_report)),
    onSubmitApproval: (action_type, id_wo) => dispatch(submitApproval(action_type, id_wo)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(ApprovalMaintainFormVendorScreen);