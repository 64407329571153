export const DEFAULT_ACTION = 'screen/VendorOfferFormScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/VendorOfferFormScreen/UPDATE_INPUT';

export const SUBMIT_OFFER = 'screen/VendorOfferFormScreen/SUBMIT_OFFER';
export const SUBMIT_OFFER_LOADING = 'screen/VendorOfferFormScreen/SUBMIT_OFFER_LOADING';
export const SUBMIT_OFFER_SUCCESS = 'screen/VendorOfferFormScreen/SUBMIT_OFFER_SUCCESS';
export const SUBMIT_OFFER_FAIL = 'screen/VendorOfferFormScreen/SUBMIT_OFFER_FAIL';

export const ADD_IMAGE = 'screen/VendorOfferFormScreen/ADD_IMAGE';
export const DEL_IMAGE = 'screen/VendorOfferFormScreen/DEL_IMAGE';

export const ADD_FILE = 'screen/VendorOfferFormScreen/ADD_FILE';
export const DEL_FILE = 'screen/VendorOfferFormScreen/DEL_FILE';

export const GET_VENDOR_OFFER = 'screen/VendorOfferFormScreen/GET_VENDOR_OFFER';
export const GET_VENDOR_OFFER_LOADING = 'screen/VendorOfferFormScreen/GET_VENDOR_OFFER_LOADING';
export const GET_VENDOR_OFFER_SUCCESS = 'screen/VendorOfferFormScreen/GET_VENDOR_OFFER_SUCCESS';
export const GET_VENDOR_OFFER_FAIL = 'screen/VendorOfferFormScreen/GET_VENDOR_OFFER_FAIL';

export const GET_NEGO_PENAWARAN = 'screen/ApprovalMaintainFormVendorScreen/GET_NEGO_PENAWARAN';
export const GET_NEGO_PENAWARAN_LOADING = 'screen/ApprovalMaintainFormVendorScreen/GET_NEGO_PENAWARAN_LOADING';
export const GET_NEGO_PENAWARAN_SUCCESS = 'screen/ApprovalMaintainFormVendorScreen/GET_NEGO_PENAWARAN_SUCCESS';
export const GET_NEGO_PENAWARAN_FAIL = 'screen/ApprovalMaintainFormVendorScreen/GET_NEGO_PENAWARAN_FAIL';

export const SUBMIT_NEGO_PENAWARAN = 'screen/ApprovalMaintainFormVendorScreen/SUBMIT_NEGO_PENAWARAN';
export const SUBMIT_NEGO_PENAWARAN_LOADING = 'screen/ApprovalMaintainFormVendorScreen/SUBMIT_NEGO_PENAWARAN_LOADING';
export const SUBMIT_NEGO_PENAWARAN_SUCCESS = 'screen/ApprovalMaintainFormVendorScreen/SUBMIT_NEGO_PENAWARAN_SUCCESS';
export const SUBMIT_NEGO_PENAWARAN_FAIL = 'screen/ApprovalMaintainFormVendorScreen/SUBMIT_NEGO_PENAWARAN_FAIL';