export const DEFAULT_ACTION = 'screen/ApprovalMaintainFormPreventifScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/ApprovalMaintainFormPreventifScreen/UPDATE_INPUT';
export const DELETE_ROW_PREVENTIF = 'screen/ApprovalMaintainFormPreventifScreen/DELETE_ROW_PREVENTIF';
export const UPDATE_INPUT_PREVENTIF = 'screen/ApprovalMaintainFormPreventifScreen/UPDATE_INPUT_PREVENTIF';

export const GET_APPROVAL_DETAIL = 'screen/ApprovalMaintainFormPreventifScreen/GET_APPROVAL_DETAIL';
export const GET_APPROVAL_DETAIL_LOADING = 'screen/ApprovalMaintainFormPreventifScreen/GET_APPROVAL_DETAIL_LOADING';
export const GET_APPROVAL_DETAIL_SUCCESS = 'screen/ApprovalMaintainFormPreventifScreen/GET_APPROVAL_DETAIL_SUCCESS';
export const GET_APPROVAL_DETAIL_FAIL = 'screen/ApprovalMaintainFormPreventifScreen/GET_APPROVAL_DETAIL_FAIL';

export const SUBMIT_APPROVAL_PREVENTIF = 'screen/ApprovalMaintainFormPreventifScreen/SUBMIT_APPROVAL_PREVENTIF';
export const SUBMIT_APPROVAL_PREVENTIF_LOADING = 'screen/ApprovalMaintainFormPreventifScreen/SUBMIT_APPROVAL_PREVENTIF_LOADING';
export const SUBMIT_APPROVAL_PREVENTIF_SUCCESS = 'screen/ApprovalMaintainFormPreventifScreen/SUBMIT_APPROVAL_PREVENTIF_SUCCESS';
export const SUBMIT_APPROVAL_PREVENTIF_FAIL = 'screen/ApprovalMaintainFormPreventifScreen/SUBMIT_APPROVAL_PREVENTIF_FAIL';

export const GET_VERIF_ASSET = 'screen/ApprovalMaintainFormPreventifScreen/GET_VERIF_ASSET';
export const GET_VERIF_ASSET_LOADING = 'screen/ApprovalMaintainFormPreventifScreen/GET_VERIF_ASSET_LOADING';
export const GET_VERIF_ASSET_SUCCESS = 'screen/ApprovalMaintainFormPreventifScreen/GET_VERIF_ASSET_SUCCESS';
export const GET_VERIF_ASSET_FAIL = 'screen/ApprovalMaintainFormPreventifScreen/GET_VERIF_ASSET_FAIL';