/*
 *
 * HomeScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import moment from 'moment';

const date = new Date();
const from = moment(date).toDate();
const to = moment(date).toDate();

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    id_gedung: null,
    start_dt: from,
    end_dt: to,
    tasks: [],
};

/* eslint-disable default-case, no-param-reassign */
const HomeScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        break;
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.GET_TASKS:
        draft.loading = true;
        break;
      case constants.GET_TASKS_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.tasks = [];
        break;
      case constants.GET_TASKS_SUCCESS:
        draft.tasks = action.results;
        draft.loading = false;
        break;
    }
  });

export default HomeScreenReducer;
