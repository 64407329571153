import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectVendorProgressDetailScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView, TouchableWithoutFeedback, RefreshControl} from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Image,
  Pressable,
  Divider, 
  Flex,
  Stack,
  Fab,
  ScrollView, 
  Button} from 'native-base';
import moment from 'moment';
import {FaEnvelope, FaChevronRight, FaCalendarAlt, FaHourglassHalf, FaBuilding} from 'react-icons/fa';
import { ContainerPage } from '../../components/ContainerPage';
import ImageSlider from '../../components/ImageSlider';
import TaskDetailPreloader from '../../components/TaskDetailPreloader';
import MyAlert from '../../components/MyAlert';
import BadgeStatus from '../../components/BadgeStatus';
import { commonStyle } from '../../styles';
import { getProgressDetail, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import history from '../../util/history';

export function VendorProgressDetailScreen({
  dispatch,
  location,
  navigation,
  vendorProgressDetailScreen,
  onUpdateInput,
  onGetResult
}){
  const {id_work_order} = location.state;
  const[userData, setUserData] = useState();
  const imageList = [
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
  ];
  
  useEffect(() => {
    onGetResult(id_work_order);
  },[]);

  const handleGetData = () => {
    onGetResult(id_work_order)
  }

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'vendor-progress-surket', title: 'Progress Perbaikan'}}>
          <Flex flex={1}>
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
            {vendorProgressDetailScreen.loading && <TaskDetailPreloader/> }
            {!vendorProgressDetailScreen.loading && 
              <Flex mt={3}>
                {!vendorProgressDetailScreen.dtProgress && <MyAlert status="info" title="Maaf, Work Order belum ditemukan."/>}
                {vendorProgressDetailScreen.dtProgress &&
                <VStack space={7} mt={2} mb={40} mx={3}>
                  <Box p={5} mt={3} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                      <VStack>
                        <Center>
                          <Avatar size="lg" bg="primary.900" mb={4}>
                              {helper.getInitials(vendorProgressDetailScreen.dtProgress.nama_vendor)}
                          </Avatar>
                        </Center>
                        <Center mb={3}>
                          <Text color="darkText" isTruncated maxWidth="90%" fontSize="md" fontWeight="bold">{vendorProgressDetailScreen.dtProgress.nama_vendor}</Text>
                        </Center>
                        <Box px={2} mb={2}>
                          <VStack>
                            <Text fontSize="xs" color="muted.600">No Penawaran</Text>
                            <Text color="darkText" bold>{vendorProgressDetailScreen.dtProgress.code_work_order}</Text>
                          </VStack>
                        </Box>
                        <Box px={2} mb={2}>
                          <VStack>
                            <Text fontSize="xs" color="muted.600">Deadline Penawaran</Text>
                            <Text color="darkText" bold>{moment(vendorProgressDetailScreen.dtProgress.deadline_work_order, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY")}</Text>
                          </VStack>
                        </Box>
                        <Box px={2} mb={2}>
                          <VStack>
                            <Text fontSize="xs" color="muted.600">Status</Text>
                            <Flex flexDirection="row">
                              <BadgeStatus status={vendorProgressDetailScreen.dtProgress.status_text}/>
                            </Flex>
                          </VStack>
                        </Box>
                      </VStack>
                  </Box>
                  {vendorProgressDetailScreen.dtProgress.list_detail.map((row_, idx_) => {
                    return(
                      <Box key={idx_} p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                        <VStack>
                          <Flex flex={1} flexDirection="row" justifyContent="center" mb="2.5">
                            <ImageSlider images={row_.attachments}/>
                          </Flex>
                          <Box mb={2}>
                            <Text fontWeight="bold">{row_.nama_inventaris}</Text>
                          </Box>
                          <HStack flex={1} mb={2}>
                            <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                              <Box>
                                <Icon color="muted.400" as={<Box><FaCalendarAlt/></Box>} size={3}></Icon>
                              </Box>
                              <Box flex={1} ml={1}>
                                <Text color="muted.400" fontWeight={500} fontSize="xs">
                                  {moment(vendorProgressDetailScreen.dtProgress.deadline_work_order, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY")}
                                </Text>
                              </Box>
                            </Stack>
                            <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                              <Box>
                                <Icon color="muted.400" as={<Box><FaHourglassHalf/></Box>} size={3}></Icon>
                              </Box>
                              <Box flex={1} ml={1}>
                                <Text color="muted.400" fontWeight={500} fontSize="xs">{row_.status_text}</Text>
                              </Box>
                            </Stack>
                          </HStack>
                          <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                            <Box>
                              <Icon color="muted.400" as={<Box><FaBuilding/></Box>} size={3}></Icon>
                            </Box>
                            <Box flex={1} ml={1}>
                              <Text color="muted.400" fontWeight={500} fontSize="xs">{row_.nama_gedung}</Text>
                            </Box>
                          </Stack>
                          <Box mb={5} mt={3}>
                            <Text color="darkText" textAlign="justify">
                            {`Kode Inventaris: ${row_.code_inventaris} \nRuang: ${row_.nama_ruang} \nLantai: ${row_.lantai_ruang}`}
                            </Text>
                          </Box>
                          <Box>
                          {row_.status < 50 &&
                            <Button onPress={() => history.push('vendor-progress-form',{
                              id_work_order: vendorProgressDetailScreen.dtProgress.id_work_order, work_order: vendorProgressDetailScreen.dtProgress, 
                              work_order_detail: row_, 
                              id_work_order_detail: row_.id_work_order_detail,
                              addNew: true,
                              readOnly: false,
                              origin_screen: 'vendor-progress-detail'
                              })}>Update Progress</Button>
                            }
                          </Box>
                        </VStack>
                      </Box>
                    );
                    
                  })}
                </VStack>
                }
              </Flex>
            }
            </ScrollView>
          </Flex>
          {/* <Fab onPress={() => navigation.navigate('vendor_offer_form')} renderInPortal={false} shadow={2} size="lg" icon={<Icon color="white" as={AntDesign} name="doubleright" size="lg" />} /> */}
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  vendorProgressDetailScreen: makeSelectVendorProgressDetailScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetResult: (id_work_order) => dispatch(getProgressDetail(id_work_order)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(VendorProgressDetailScreen);