import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectTaskPreventifDetailScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView, RefreshControl, TouchableWithoutFeedback } from 'react-native';
import {
  AspectRatio,
  Icon,
  IconButton,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Image,
  Pressable,
  Divider,
  Checkbox, 
  Flex,
  Spinner,
  ScrollView, 
  Button} from 'native-base';
import {FaCalendarAlt, FaCheckCircle, FaChevronRight } from 'react-icons/fa';
import { ContainerPage } from '../../components/ContainerPage';
import TaskDetailPreloader from '../../components/TaskDetailPreloader';
import MyAlert from '../../components/MyAlert';
import MyAlertDialog from '../../components/MyAlertDialog';
import { commonStyle } from '../../styles';
import { getRoomAsset, getTodoList, submitPreventif, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import history from '../../util/history';

export function TaskPreventifDetailScreen({
  dispatch,
  location,
  navigation,
  taskPreventifDetailScreen,
  onUpdateInput,
  onGetRoomAsset,
  onGetDetailTodo,
  onSubmitPreventif
}){
  const[userData, setUserData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const {id_realisasi_preventif, readOnly} = location.state;

  useEffect(() => {
    const curLapor = api.getCurrInputPreventif();
    console.log("saved_preventif:", curLapor);
    onUpdateInput('currPreventif', curLapor);
    onGetRoomAsset(id_realisasi_preventif);
  },[]);
  

  const handleGetData = () => {
    onGetRoomAsset(id_realisasi_preventif)
  }

  const handleSubmitPreventif = () => {
    api.clearCurrInputPelaporan();
    history.push('preventif', {id_item : null});
  }

  const handleOnCheck = (evt, row) => {
    console.log("chk:", evt, row);
    const params = {
      id_aset: row.id_inventaris,
      nm_aset: row.nama,
      id_realisasi_preventif: id_realisasi_preventif,
      dtPreventif: taskPreventifDetailScreen.dtPreventif
    };
    if(!helper.isEmpty(row.date_exec)){
      history.push("preventif-form", params);
    }else{
      onSubmitPreventif(row.id_inventaris);
    }
    
  }

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'preventif', title: 'Pemeliharaan Preventif', params:{id_item: null}}}>
          <Flex flex={1}>
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              {taskPreventifDetailScreen.loading && <TaskDetailPreloader/> }
              {!taskPreventifDetailScreen.loading && 
              <Flex mt={3}>
                {!taskPreventifDetailScreen.dtPreventif && <MyAlert status="info" title="Maaf, Data Preventif belum ditemukan."/>}
                {taskPreventifDetailScreen.dtPreventif &&
                <VStack space={7} mt={2} mb={35} mx={3}>
                  <Box mt={5}>
                    <Center>
                      <HStack space={2} justifyContent="center" alignItems="center">
                        <Icon as={<Box><FaCheckCircle/></Box>} size="8" color="success.700" />
                        <Text color="darkText" fontWeight="bold" isTruncated maxWidth="90%" fontSize="lg">
                          {taskPreventifDetailScreen.dtPreventif.nama_gedung}
                        </Text>
                      </HStack>
                    </Center>
                  </Box>
                  <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    
                      <VStack>
                        <Center>
                          <Avatar size="lg" bg="primary.900" mb={4}>
                              {helper.getInitials(taskPreventifDetailScreen.dtPreventif.nama_gedung)}
                          </Avatar>
                        </Center>
                        <Center>
                          <Text color="darkText" fontSize="md" fontWeight="bold">{taskPreventifDetailScreen.dtPreventif.nama_ruang}</Text>
                        </Center>
                        <Center>
                          <Text color="muted.400"  fontSize="sm">Lantai - {taskPreventifDetailScreen.dtPreventif.lantai_ruang}</Text>
                        </Center>
                        <Center>
                          {/* <Text mt={10} color="darkText" fontSize="sm">26-10-2022 11:24 WIB</Text> */}
                        </Center>
                      </VStack>
                  </Box>

                  <Box borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    <VStack m={4}>
                      {taskPreventifDetailScreen.dtAset.map((row, idx) => {
                        const params = {
                          id_aset: row.id_inventaris,
                          nm_aset: row.nama,
                          id_realisasi_preventif: id_realisasi_preventif,
                          dtPreventif: taskPreventifDetailScreen.dtPreventif,
                          id_pelaporan: row.id_pelaporan,
                          readOnly : readOnly,
                        };
                        return (
                          <Box key={`l-${idx}`}>
                            <HStack justifyContent="center" alignItems="center">
                              <Box>
                                {row.loading && <Spinner size="sm" color="success.600" mr={1} />}
                                {!row.loading && 
                                <Checkbox isDisabled={readOnly} size="md" my={1} onChange={evt => handleOnCheck(evt, row)} isChecked={!helper.isEmpty(row.date_exec)} colorScheme={row.id_inventaris_condition == 3 ? "danger" : (row.id_inventaris_condition == 2 ? "yellow" : "success")}>
                                  <></>
                                </Checkbox>
                                }
                              </Box>
                              <Flex flex={1} mx={3}>
                                <Text color="darkText">{row.nama}</Text>
                              </Flex>
                              <Pressable onPress={() => history.push('preventif-form', params)}>
                                <Box px={2}>
                                      <Icon as={<Box><FaChevronRight/></Box>} size={5} color="black" />
                                </Box>
                              </Pressable>
                              {/* <IconButton colorScheme="light" borderRadius="full" variant="subtle" _icon={{
                                as: <Box><FaChevronRight/></Box>,
                                justifyContent: "center",
                                alignItems:"center",
                                paddingLeft: 1
                              }} onPress={() => history.push('preventif-form', params)} /> */}
                            </HStack>
                            {(taskPreventifDetailScreen.dtAset.length > 1 && idx < (taskPreventifDetailScreen.dtAset.length-1)) && <Divider my={3}/>}
                          </Box>
                        )
                      })}
                    </VStack>
                  </Box>
                  {!readOnly &&
                  <Box>
                    <Button colorScheme="primary" onPress={() => setIsOpen(true)} shadow={3}>Laporkan</Button>
                  </Box>
                  }
                </VStack>
                }
              </Flex>
              }
            </ScrollView>
          </Flex>
          <MyAlertDialog 
            alertTitle="Simpan Data" 
            alertDesc="Apakah anda yakin ?"
            alertNoLabel="Tidak"
            alertYesLabel="Ya"
            alertIsOpen={isOpen}
            alertOnClose={onClose}
            alertOnConfirm={handleSubmitPreventif}
          />
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  taskPreventifDetailScreen: makeSelectTaskPreventifDetailScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetRoomAsset: (id_realisasi_preventif) => dispatch(getRoomAsset(id_realisasi_preventif)),
    onGetDetailTodo: (id_realisasi_preventif, id_inventaris) => dispatch(getTodoList(id_realisasi_preventif, id_inventaris)),
    onSubmitPreventif: (id_inventaris) => dispatch(submitPreventif(id_inventaris)), 
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(TaskPreventifDetailScreen);