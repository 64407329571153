import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
import api from '../../util/api';
import helper from '../../util/helper';
import { getRoomAssetFail, getRoomAssetSuccess } from './actions';
 
  import * as constants from './constants';
  
  import makeSelectPelaporanDetailScreen from './selector';

  export function* doGetRoomAsset(action) {
    const {id_branch, id_item} = action;
    try {
      const response = yield call(api.unit.getAsset, id_branch, id_item);
      yield put(
        getRoomAssetSuccess(response.data),
      );
      
      console.log("red:", response.data);

    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getRoomAssetFail(err));
    }
  }


  // Individual exports for testing
  export default function* pelaporanDetailScreenSaga() {
    yield all([
      takeLatest(constants.GET_ROOM_ASSET, doGetRoomAsset),
    ]);
  }
  