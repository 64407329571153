import React, {useRef, useState} from "react";
import Select from 'react-select';

const MySelect2 = (props) => {
  const {title, status} = props;
  const [show, setShow] = useState(true);
  return (
    <Select {...props} />
  );
}

export default MySelect2;