/*
 *
 * TaskScannerScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export const initialState = {
    error: false,
    error_msg: '',
    loading: false,
    results: null,
    scanned: false,
    scan_result: '-',
    data_type: '',
};

/* eslint-disable default-case, no-param-reassign */
const TaskScannerScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        return {...initialState};
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.GET_ROOM_ASSET:
        draft.loading = true;
        break;
      case constants.GET_ROOM_ASSET_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.results = null;
        draft.error_msg= 'Maaf, Hasil pencarian belum ditemukan.';
        break;
      case constants.GET_ROOM_ASSET_SUCCESS:
        draft.results = action.results;
        draft.loading = false;
        draft.error = false;
        break;
      case constants.SCAN_RESULT:
        draft.scan_result = action.result;
        break;
      case constants.SCANNED:
        draft.scanned = true;
        break;
      case constants.RESET_SCAN:
        // console.log("kereset ini!")
        draft.scanned = false;
        draft.scan_result = '-';
        draft.loading = false;
        draft.error_msg = "";
        draft.error = false;
        break;
    }
  });

export default TaskScannerScreenReducer;
