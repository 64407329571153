import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the VendorOfferDetailScreen state domain
 */

const selectVendorOfferDetailScreenDomain = state =>
  state.vendorOfferDetailScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by VendorOfferDetailScreen
 */

const makeSelectVendorOfferDetailScreen = () =>
  createSelector(
    selectVendorOfferDetailScreenDomain,
    substate => substate,
  );

export default makeSelectVendorOfferDetailScreen;
export { selectVendorOfferDetailScreenDomain };
