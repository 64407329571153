import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the TaskPreventifScreen state domain
 */

const selectTaskPreventifScreenDomain = state =>
  state.taskPreventifScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by TaskPreventifScreen
 */

const makeSelectTaskPreventifScreen = () =>
  createSelector(
    selectTaskPreventifScreenDomain,
    substate => substate,
  );

export default makeSelectTaskPreventifScreen;
export { selectTaskPreventifScreenDomain };
