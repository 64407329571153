import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
  import moment from 'moment';
  import api from '../../util/api';
  import helper from '../../util/helper';
 
  import * as constants from './constants';
  
  import makeSelectWorkOrderScreen from './selector';
import { getWorkOrderFail, getWorkOrderSuccess } from './actions';

  export function* doGetWorkOrder(action) {
    const dataset = yield select(makeSelectWorkOrderScreen());
    try {
      const tglAwal = moment(dataset.start_dt).format("DD-MM-YYYY");
      const tglAkhir = moment(dataset.end_dt).format("DD-MM-YYYY");
      const response = yield call(api.work_order.getList, tglAwal, tglAkhir);
      const {data} = response.data;
      console.log("res:", response.data);
      yield put(
        getWorkOrderSuccess((helper.isEmpty(data) ? [] : data)),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getWorkOrderFail(err));
    }
  }
  
  // Individual exports for testing
  export default function* workOrderScreenSaga() {
    yield all([
      takeLatest(constants.GET_WORK_ORDER, doGetWorkOrder),
    ]);
  }
  