import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getWorkOrder() {
  return {
    type: constants.GET_WORK_ORDER
  };
}

export function getWorkOrderLoading() {
  return {
    type: constants.GET_WORK_ORDER_LOADING
  };
}

export function getWorkOrderFail(error) {
  return {
    type: constants.GET_WORK_ORDER_FAIL,
    error
  };
}

export function getWorkOrderSuccess(results) {
  return {
    type: constants.GET_WORK_ORDER_SUCCESS,
    results
  };
}