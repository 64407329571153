import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectVerifVerifPelaporanHistoryScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView, TouchableWithoutFeedback, BackHandler, StyleSheet, View } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Image, 
  Flex, 
  Button,
  ScrollView,
  FlatList} from 'native-base';
import moment from 'moment';
import { Chrono } from "react-chrono";
import { ContainerPage } from '../../components/ContainerPage';
import BadgeStatus from '../../components/BadgeStatus';
import { commonStyle } from '../../styles';
import { getPelaporanHistory, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';

export function VerifPelaporanHistoryScreen({
  dispatch,
  navigation,
  location,
  verifPelaporanHistoryScreen,
  onUpdateInput,
  onGetResults
}){
  const {rawData, id_korektif, id_ruang} = location.state;

  const timeline = [
    {date:'Oct, 27 2022',time: '09:00', title: 'Event 1', description: 'Event 1 Description'},
    {date:'Oct, 27 2022',time: '10:45', title: 'Event 2', description: 'Event 2 Description'},
    {date:'Oct, 27 2022',time: '12:00', title: 'Event 3', description: 'Event 3 Description'},
    {date:'Oct, 27 2022',time: '14:00', title: 'Event 4', description: 'Event 4 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 5', description: 'Event 5 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 6', description: 'Event 6 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 7', description: 'Event 7 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 8', description: 'Event 8 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 9', description: 'Event 9 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 10', description: 'Event 10 Description'}
  ];
  const[userData, setUserData] = useState();
  const[historyList, setHistoryList] = useState([]);

  useEffect(() => {
      const user = api.getUserData();
        console.log("saved_user:", user);
        setUserData(user);

      onGetResults(id_korektif);
  },[]);

  useEffect(() => {
    if(verifPelaporanHistoryScreen.results.length > 0){
      console.log("masuk sini");
      let res = [];
      verifPelaporanHistoryScreen.results.map((row, idx) => {
        const obj = {
          title: `${moment(row.date_create, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")}`,
          cardTitle: row.nm_inventaris,
          cardSubtitle: ``,
          cardDetailedText: ``,
          timelineContent: <BadgeStatus status={row.status}/>
        };
        res.push(obj);
      });
      console.log(res);
      setHistoryList(res);
    }
  }, [verifPelaporanHistoryScreen.results]);

  // const renderDetail = (rowData, sectionID, rowID) => {
  //   let title = <Text style={[styles.title]}>{rowData.nm_inventaris}</Text>
  //   var desc = null
  //   if(rowData.status)
  //     desc = (
  //       <View style={styles.descriptionContainer}>
  //         {/* <Text style={[styles.textDescription]}>{rowData.status}</Text> */}
  //         <BadgeStatus status={rowData.status}/>
  //       </View>
  //     )

  //   return (
  //     <View style={{flex:1}}>
  //       {title}
  //       {desc}
  //     </View>
  //   )
  // }

  // const renderTime = (rowData, sectionID, rowID) => {
  //   return (
  //     <Box minWidth={100}>
  //       <Center>
  //         <Text fontWeight="bold">{moment(rowData.date_create, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY")}</Text>
  //         <Text color="muted.800">{moment(rowData.date_create, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}</Text>
  //       </Center>
  //     </Box>
  //   )
  // }

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'verif-pelaporan', title: 'Verifikasi Kerusakan', params:{id_item: id_ruang}}}>
          <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor} mx={3} mt={7}>
            <VStack>
              <Center>
                <Avatar size="lg" bg="primary.900" mb={4}>
                    {helper.getInitials(rawData.nm_ruangan)}
                </Avatar>
              </Center>
              <Center>
                <Text color="darkText" fontSize="md" fontWeight="bold">{rawData.nm_ruangan}</Text>
              </Center>
              <Center>
                <Text color="muted.400" textAlign="center" fontSize="sm">{rawData.nm_inventaris}</Text>
              </Center>
              <Center>
                <Text mt={10} color="darkText" fontSize="sm">{rawData.date_create}</Text>
              </Center>
            </VStack>
          </Box>
          <Flex flex={1} justifyContent="center" mx={3}>
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              <Box>
              {historyList.length > 0 && 
              <Chrono
                items={historyList}
                theme={{
                  primary: commonStyle.primaryColor,
                  secondary: 'white',
                  cardBgColor: commonStyle.baseColor,
                  titleColor: 'black',
                  titleColorActive: commonStyle.primaryColor,
                }}
                mode="VERTICAL"
                cardHeight={80}
                cardWidth={600}
                fontSizes={{
                  title: ".8rem"
                }}
                classNames={{
                  title: 'history-title',
                }}
              
                useReadMore={false}
                hideControls={true}
              />
              }
                {/* <Timeline
                    data={verifPelaporanHistoryScreen.results}
                    renderDetail={renderDetail}
                    renderTime={renderTime}
                    circleSize={18}
                    circleColor={commonStyle.primaryColor}
                    lineColor={commonStyle.primaryColor}
                    timeContainerStyle={{minWidth:100}}
                    innerCircle={'dot'}
                    timeStyle={{
                      textAlign: 'center',
                      padding: 5, 
                      borderRadius:13}}
                    descriptionStyle={{color:'gray'}}
                    options={{
                      style:{paddingTop:20}
                    }}
                    isUsingFlatlist={true}
                  /> */}
                </Box>
            </ScrollView>
          </Flex>
        </ContainerPage>
      );
    
}

const mapStateToProps = createStructuredSelector({
  verifPelaporanHistoryScreen: makeSelectVerifVerifPelaporanHistoryScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetResults: id_korektif => dispatch(getPelaporanHistory(id_korektif)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(VerifPelaporanHistoryScreen);