import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function submitPass() {
  return {
    type: constants.SUBMIT_PASSWORD
  };
}

export function submitPassSuccess(result) {
  return {
    type: constants.SUBMIT_PASSWORD_SUCCESS,
    result
  };
}

export function submitPassFail() {
  return {
    type: constants.SUBMIT_PASSWORD_FAIL
  };
}