import React,{memo} from "react";
import { commonStyle } from "../styles";
import {
    Icon,
    HStack, 
    Avatar, 
    Heading, 
    VStack, 
    Box, 
    Center,
    Stack,
    Badge,
    Input,
    Image,
    IconButton,
    Pressable,
    Divider,
    HamburgerIcon,
    Menu,
    Text, 
    Flex } from 'native-base';
  import {FaCalendarAlt, FaEnvelope } from 'react-icons/fa';
  import BadgeStatus from "./BadgeStatus";
  import moment from "moment";
  import helper from "../util/helper";

const CardWorkOrderList = (props) => {
  const {data, access} = props;
  return (
    <Box width="100%" backgroundColor="white" shadow={3} borderRadius="lg">
      <VStack>
        <Box px={5} py={3} backgroundColor="#ffd13c" borderTopRadius="lg">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Icon color="darkText" as={<Box><FaCalendarAlt/></Box>} size={5}></Icon>
            </Box>
            <Box flex={1} ml={1}>
              <Text color="darkText" fontWeight={500} >{moment(data.date_create, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")}</Text>
              {/* <Text color="muted.500" fontSize="xs" isTruncated w="100%">Work Order Task</Text> */}
            </Box>
            <Box ml={3}>
              <BadgeStatus status={data.stat_wo}/>
            </Box>
          </Stack>
        </Box>
        <Box p={5}>
          <VStack>
            <Flex flexDirection="row" mb={3} alignItems="center">
              <Box width={8} height={8} borderRadius="lg" backgroundColor="primary.400" justifyContent="center">
                <Center>
                  <Icon color="white" as={<Box><FaEnvelope/></Box>} size={5}></Icon>
                </Center>
              </Box>
              <Box ml={2} flex={1}>
                <Text color="darkText" isTruncated>
                  {data.code}
                </Text>
              </Box>
            </Flex>
            <VStack mb={3}>
              <Text color="darkText" bold>Deadline Penawaran</Text>
              <Text color="darkText">{moment(data.date_line, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY")}</Text>
            </VStack>
            <Flex flexDirection="row" mb={1}>
              <Box>
                <Center>
                  <Avatar size="md" bg="primary.900" mr="1">
                  {helper.getInitials(data.nm_vendor)}
                  </Avatar>
                </Center>
              </Box>
              <VStack width="90%" justifyContent="center">
                <Text ml={3} fontSize="md" color={commonStyle.primaryColor} fontWeight="bold">
                  {data.nm_vendor}
                </Text>
              </VStack>
            </Flex>
            
          </VStack>
        </Box>
      </VStack>
  </Box>
  );
}

export default memo(CardWorkOrderList);