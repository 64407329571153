import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getPelaporan(id_item) {
  return {
    type: constants.GET_PELAPORAN,
    id_item
  };
}

export function getPelaporanLoading() {
  return {
    type: constants.GET_PELAPORAN_LOADING
  };
}

export function getPelaporanFail(error) {
  return {
    type: constants.GET_PELAPORAN_FAIL,
    error
  };
}

export function getPelaporanSuccess(results) {
  console.log(results);
  
  return {
    type: constants.GET_PELAPORAN_SUCCESS,
    results
  };
}

export function getMorePelaporan(id_item) {
  return {
    type: constants.GET_MORE_PELAPORAN,
    id_item
  };
}

export function getMorePelaporanLoading() {
  return {
    type: constants.GET_MORE_PELAPORAN_LOADING
  };
}

export function getMorePelaporanFail(error) {
  return {
    type: constants.GET_MORE_PELAPORAN_FAIL,
    error
  };
}

export function getMorePelaporanSuccess(results) {
  // console.log(results);
  return {
    type: constants.GET_MORE_PELAPORAN_SUCCESS,
    results
  };
}