import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
  import moment from 'moment';
  import api from '../../util/api';
  import helper from '../../util/helper';
  import * as constants from './constants';
  
  import makeSelectVendorKroscekFormScreen from './selector';
import { getVerifyAssetsFail, getVerifyAssetsSuccess, getWorkOrderVerifFail, getWorkOrderVerifSuccess, submitWOVerifFail, submitWOVerifSuccess } from './actions';
import history from '../../util/history';

  export function* doGetWorkOrderVerif(action) {
    const dataset = yield select(makeSelectVendorKroscekFormScreen());
    try {
      const tglAwal = moment(dataset.start_dt).format("DD-MM-YYYY");
      const tglAkhir = moment(dataset.end_dt).format("DD-MM-YYYY");
      const response = yield call(api.work_order.getVerifyWorkOrder, tglAwal, tglAkhir, dataset.id_ruang);
      const {data} = response.data;
      console.log("res:", response.data);
      yield put(
        getWorkOrderVerifSuccess((helper.isEmpty(data) ? [] : data)),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getWorkOrderVerifFail(err));
    }
  }

  export function* doGetVerifyAssets(action) {
    const dataset = yield select(makeSelectVendorKroscekFormScreen());
    try {
      const response = yield call(api.work_order.getVerifyAssets, action.id_work_order_report);
      // console.log("res:", response);
      const {data, data_attachment_feedback} = response.data;
      yield put(
        getVerifyAssetsSuccess((helper.isEmpty(data) ? [] : data), (helper.isEmpty(data_attachment_feedback) ? [] : data_attachment_feedback)),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getVerifyAssetsFail(err));
    }
  }

  export function* doSubmitVerifyWO(action) {
    const {action_type, id_wo} = action;
    const dataset = yield select(makeSelectVendorKroscekFormScreen());
    try {
      const params = {
          description: dataset.notes,
          id_work_order_report: id_wo,
          attachment: dataset.assets_verif,
          approved: (action_type == '20' ? true : false),
      };

      // console.log(params);

      const response = yield call(api.work_order.submitVerifyWO, params);
      console.log(response);
      yield put(
        submitWOVerifSuccess(response.data, action_type),
      );
      history.replace('vendor-kroscek', {id_item: dataset.id_ruang});
    } catch (err) {
      console.log(err);
      yield put(submitWOVerifFail(err));
    }
  }
  // Individual exports for testing
  export default function* vendorKroscekFormScreenSaga() {
    yield all([
      takeLatest(constants.GET_WORK_ORDER_VERIF, doGetWorkOrderVerif),
      takeLatest(constants.GET_VERIF_ASSET, doGetVerifyAssets),
      takeLatest(constants.SUBMIT_WORK_ORDER_VERIF, doSubmitVerifyWO),
      // takeLatest(constants.SUBMIT_WORK_ORDER_VERIF_SUCCESS, doGetWorkOrderVerif),
    ]);
  }
  