import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the VendorProgressFormScreen state domain
 */

const selectVendorProgressFormScreenDomain = state =>
  state.vendorProgressFormScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by VendorProgressFormScreen
 */

const makeSelectVendorProgressFormScreen = () =>
  createSelector(
    selectVendorProgressFormScreenDomain,
    substate => substate,
  );

export default makeSelectVendorProgressFormScreen;
export { selectVendorProgressFormScreenDomain };
