import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getTasks() {
  return {
    type: constants.GET_TASKS,
  };
}

export function getTasksFail(error) {
  return {
    type: constants.GET_TASKS_FAIL,
    error
  };
}

export function getTasksSuccess(results) {
  return {
    type: constants.GET_TASKS_SUCCESS,
    results
  };
}