import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
  import api from '../../util/api';
  import helper from '../../util/helper';
import { getPelaporanHistoryFail, getPelaporanHistorySuccess } from './actions';
  import * as constants from './constants';
  
  import makeSelectVerifPelaporanHistoryScreen from './selector';
  
  export function* doGetPelaporanHistory(action) {
    const dataset = yield select(makeSelectVerifPelaporanHistoryScreen());
    try {
      const response = yield call(api.pelaporan.getHistoyKorektif, action.id_korektif);
      const {data} = response.data;
      console.log(data);
      yield put(
        getPelaporanHistorySuccess(data),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getPelaporanHistoryFail(err));
    }
  }

  // Individual exports for testing
  export default function* verifPelaporanHistoryScreenSaga() {
    yield all([
      takeLatest(constants.GET_PELAPORAN_HISTORY, doGetPelaporanHistory),
    ]);
  }
  