import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the VerifPelaporanHistoryScreen state domain
 */

const selectVerifPelaporanHistoryScreenDomain = state =>
  state.verifPelaporanHistoryScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by VerifPelaporanHistoryScreen
 */

const makeSelectVerifPelaporanHistoryScreen = () =>
  createSelector(
    selectVerifPelaporanHistoryScreenDomain,
    substate => substate,
  );

export default makeSelectVerifPelaporanHistoryScreen;
export { selectVerifPelaporanHistoryScreenDomain };
