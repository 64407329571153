import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the PelaporanScreen state domain
 */

const selectPelaporanScreenDomain = state =>
  state.pelaporanScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by PelaporanScreen
 */

const makeSelectPelaporanScreen = () =>
  createSelector(
    selectPelaporanScreenDomain,
    substate => substate,
  );

export default makeSelectPelaporanScreen;
export { selectPelaporanScreenDomain };
