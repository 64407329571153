import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectPelaporanHistoryDetailScreen from './selector';
import reducer from './reducer';
import saga from './saga';
import { useHistory } from 'react-router-dom';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Image, 
  Flex, 
  Button,
  FlatList, 
  ScrollView,
  Stack} from 'native-base';
import moment from 'moment';
import { Chrono } from "react-chrono";
import { ContainerPage } from '../../components/ContainerPage';
import BadgeStatus from '../../components/BadgeStatus';
import { commonStyle } from '../../styles';
import { getPelaporanHistory, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import dummy from '../../util/dummy';
import { FaBuilding, FaCalendarAlt, FaUser, FaWrench  } from 'react-icons/fa';
import ImageSlider from '../../components/ImageSlider';

export function PelaporanHistoryDetailScreen({
  dispatch,
  pelaporanHistoryDetailScreen,
  onUpdateInput,
  onGetResults
}){
  // const {rawData, id_pelaporan} = route.params;
  const history = useHistory();
  const {pelaporan, history_type, origin_screen} = history.location.state;
  const timeline = [
    {date:'Oct, 27 2022',time: '09:00', title: 'Event 1', description: 'Event 1 Description'},
    {date:'Oct, 27 2022',time: '10:45', title: 'Event 2', description: 'Event 2 Description'},
    {date:'Oct, 27 2022',time: '12:00', title: 'Event 3', description: 'Event 3 Description'},
    {date:'Oct, 27 2022',time: '14:00', title: 'Event 4', description: 'Event 4 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 5', description: 'Event 5 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 6', description: 'Event 6 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 7', description: 'Event 7 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 8', description: 'Event 8 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 9', description: 'Event 9 Description'},
    {date:'Oct, 27 2022',time: '16:30', title: 'Event 10', description: 'Event 10 Description'}
  ];

  
  const[userData, setUserData] = useState();
  const[rawData, setRawData] = useState({nm_gedung:'', addres:'', nm_ruang:'', date_report:''});
  const[id_pelaporan, setIdPelaporan] = useState();
  const[historyList, setHistoryList] = useState([]);

  useEffect(() => {
      const user = api.getUserData();
      console.log("saved_user:", user);
      setUserData(user);

      if (history.location.state && history.location.state.rawData) {
        console.log(history.location.state);
        setRawData(history.location.state.rawData);
        onUpdateInput('raw',history.location.state.rawData);
        onUpdateInput('pelaporan', pelaporan);
        onUpdateInput('history_type', history_type);
      }
      if (history.location.state && history.location.state.rawData) {
        const idP = history.location.state.id_pelaporan;
        setIdPelaporan(idP);
        onGetResults(idP, history_type);
      }
  },[]);

  useEffect(() => {
    if(pelaporanHistoryDetailScreen.results.length > 0){
      console.log("masuk sini");
      let res = [];
      pelaporanHistoryDetailScreen.results.map((row, idx) => {
        const obj = {
          title: `${moment(row.date_create, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")}`,
          cardTitle: row.nm_inventaris,
          cardSubtitle: ``,
          cardDetailedText: ``,
          timelineContent: <BadgeStatus status={row.status}/>
        };
        res.push(obj);
      });
      console.log(res);
      setHistoryList(res);
    }
  }, [pelaporanHistoryDetailScreen.results]);

  // const renderDetail = (rowData, sectionID, rowID) => {
  //   let title = <Text style={[styles.title]}>{rowData.nm_inventaris}</Text>
  //   var desc = null
  //   if(rowData.status)
  //     desc = (
  //       <View style={styles.descriptionContainer}>
  //         {/* <Text style={[styles.textDescription]}>{rowData.status}</Text> */}
  //         <BadgeStatus status={rowData.status}/>
  //       </View>
  //     )

  //   return (
  //     <View style={{flex:1}}>
  //       {title}
  //       {desc}
  //     </View>
  //   )
  // }

  // const renderTime = (rowData, sectionID, rowID) => {
  //   return (
  //     <Box minWidth={100}>
  //       <Center>
  //         <Text fontWeight="bold">{moment(rowData.date_create, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY")}</Text>
  //         <Text color="muted.800">{moment(rowData.date_create, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}</Text>
  //       </Center>
  //     </Box>
  //   )
  // }

  const items = [
    {
      title: "May 1940",
      cardTitle: "Dunkirk",
      url: "http://google.com",
      cardDetailedText: `On 10 May 1940, Hitler began his long-awaited offensive in the west by invading neutral Holland and Belgium and attacking northern France.`
    },
    {
      title: "25 July 1940",
      cardTitle: "The Battle of Britain",
      cardDetailedText: `After France’s surrender in June 1940, Churchill told the British people, “Hitler knows that he will have to break us in this island or lose the war”.`
    },
    {
      title: "June 1941",
      cardTitle: "Operation Barbarossa",
      cardSubtitle: ``,
      cardDetailedText: ``,
      timelineContent: <BadgeStatus status="created"/>
    }
  ];
  
  return (
        <ContainerPage options={{statusBar: true, prevPage: origin_screen, title: 'Pelaporan Kerusakan', params:{...history.location.state}}}>
          <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
          <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor} mx={3} mt={7}>
            <VStack>
              <Center>
                <Avatar size="lg" bg="primary.900" mb={4}>
                    {helper.getInitials(rawData.nm_gedung)}
                </Avatar>
              </Center>
              <Center>
                <Text color="darkText" fontSize="md" fontWeight="bold">{rawData.nm_ruang}</Text>
              </Center>
              <Center>
                <Text color="muted.400" textAlign="center" fontSize="sm">{rawData.addres}</Text>
              </Center>
              <Center>
                <Text mt={10} color="darkText" fontSize="sm">{moment(rawData.date_report, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")}</Text>
              </Center>
            </VStack>
          </Box>
          <Flex flex={1} mx={3}>
            <Box my={5} p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                <VStack>
                  <Flex mb="2.5">
                    <ImageSlider images={pelaporanHistoryDetailScreen.attachment}/>
                  </Flex>
                  <Box mb={2}>
                    <Text fontWeight="bold">
                      {console.log("dt-respon-index-new : ",pelaporanHistoryDetailScreen)}
                      {history_type == 1 &&
                        pelaporanHistoryDetailScreen.raw_detail.nm_inventory
                      }

                      {history_type == 2 &&
                        pelaporanHistoryDetailScreen.raw_detail.nm_inventaris
                      }

                      {history_type == 3 &&
                        pelaporanHistoryDetailScreen.raw_detail.nama_inventaris
                      }
                    </Text>
                  </Box>
                  <HStack flex={1} mb={2}>
                    <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                      <Box>
                        <Icon color="muted.400" as={<Box><FaCalendarAlt/></Box>} size={3}></Icon>
                      </Box>
                      <Box flex={1} ml={1}>
                        <Text color="muted.400" fontWeight={500} fontSize="xs">{moment(pelaporan.date_create, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")}</Text>
                      </Box>
                    </Stack>
                    <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                      <Box>
                        <Icon color="muted.400" as={<Box><FaBuilding/></Box>} size={3}></Icon>
                      </Box>
                      <Box flex={1} ml={1}>
                        <Text color="muted.400" fontWeight={500} fontSize="xs">
                          {history_type == 1 &&
                            pelaporanHistoryDetailScreen.raw_detail.nm_ruang
                          }

                          {history_type == 2 &&
                            pelaporanHistoryDetailScreen.raw_detail.nm_ruangan
                          }

                          {history_type == 3 &&
                            pelaporanHistoryDetailScreen.raw_detail.nama_ruang
                          }
                        </Text>
                      </Box>
                    </Stack>
                  </HStack>

                  {(pelaporan.status == 'Review' || pelaporan.status == 'Aprove' || pelaporan.status == 'Penawaran Vendor' || pelaporan.status == 'WO Created' || pelaporan.status == 'Progres' || pelaporan.status == 'Done') &&
                  <HStack flex={1} mb={2}>
                    <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                      <Box>
                      {history_type == 2 &&
                        <Icon color="muted.400" as={<Box><FaUser /></Box>} size={3}></Icon>
                      }

                      {history_type == 3 &&
                        <Icon color="muted.400" as={<Box><FaWrench /></Box>} size={3}></Icon>
                      }
                      </Box>
                      <Box flex={1} ml={1}>
                        <Text color="muted.400" fontWeight={500} fontSize="xs">

                          {history_type == 2 &&
                            pelaporanHistoryDetailScreen.raw_detail.nm_verifikator
                          }

                          {history_type == 3 &&
                            pelaporanHistoryDetailScreen.raw_detail.nm_vendor
                          }
                        </Text>
                      </Box>
                    </Stack>
                  </HStack>
                  }

                  <Box mb={2}>
                    <Text color="darkText" textAlign="justify">
                      {history_type == 1 &&
                        pelaporanHistoryDetailScreen.raw_detail.description
                      }

                      {history_type == 2 &&
                        pelaporanHistoryDetailScreen.raw_detail.desc_verifikasi_pelaporan
                      }

                      {history_type == 3 &&
                        pelaporanHistoryDetailScreen.raw_detail.description_feedback
                      }
                    </Text>
                  </Box>
                  <Box mb={2} borderTopWidth={1} borderTopStyle="dashed">
                    <Flex mt={3} alignItems="start">
                      <BadgeStatus status={pelaporan.status}/>
                    </Flex>
                  </Box>
                </VStack>
            </Box>
          </Flex>
          
          </ScrollView>
        </ContainerPage>
      );
    
}

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     padding: 20,
// 	  paddingTop:65,
//     backgroundColor:'white'
//   },
//   list: {
//     flex: 1,
//     marginTop:20,
//   },
//   title:{
//     fontSize:16,
//     fontWeight: 'bold'
//   },
//   descriptionContainer:{
//     flexDirection: 'row',
//     paddingRight: 50,
//   },
//   textDescription: {
//     color: 'gray'
//   }
// });

const mapStateToProps = createStructuredSelector({
  pelaporanHistoryDetailScreen: makeSelectPelaporanHistoryDetailScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetResults: (id_pelaporan, history_type) => dispatch(getPelaporanHistory(id_pelaporan, history_type)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(PelaporanHistoryDetailScreen);