import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import api from '../../util/api';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getUserPrivileges(id_akun) {
  return {
    type: constants.GET_USER_PRIVILEGES,
    id_akun
  };
}

export function getUserPrivilegesLoading() {
  return {
    type: constants.GET_USER_PRIVILEGES_LOADING
  };
}

export function getUserPrivilegesFail(error) {
  return {
    type: constants.GET_USER_PRIVILEGES_FAIL,
    error
  };
}

export function getUserPrivilegesSuccess(results) {
  console.log("privileges:", results);
  api.setUserPrivilege(results);
  return {
    type: constants.GET_USER_PRIVILEGES_SUCCESS,
    results
  };
}