import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import api from '../../util/api';
  import helper from '../../util/helper';
import { getWorkOrderStatusFail, getWorkOrderStatusSuccess } from './actions';
  import * as constants from './constants';
  
  import makeSelectVendorKroscekHistoryScreen from './selector';
  
  export function* doGetWorkOrderStatus(action) {
    const dataset = yield select(makeSelectVendorKroscekHistoryScreen());
    try {
      const response = yield call(api.work_order.getListStatus, action.id_work_order);
      const {data} = response.data;
      console.log("res:", data);
      yield put(
        getWorkOrderStatusSuccess((helper.isEmpty(data) ? [] : data)),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getWorkOrderStatusFail(err));
    }
  }
  // Individual exports for testing
  export default function* vendorKroscekHistoryScreenSaga() {
    yield all([
      takeLatest(constants.GET_WORK_ORDER_STATUS, doGetWorkOrderStatus),
    ]);
  }
  