import React, {memo} from "react";
import { commonStyle } from "../styles";
import {
    Icon,
    HStack, 
    Avatar, 
    Heading, 
    VStack, 
    Box, 
    Center,
    Stack,
    Badge,
    Input,
    Image,
    IconButton,
    Pressable,
    Divider,
    HamburgerIcon,
    Menu,
    Text, 
    Flex } from 'native-base';
  import BadgeStatus from "./BadgeStatus";
  import helper from "../util/helper";
  import {MdRemoveRedEye, MdModeEdit, MdHistory, MdMoreVert } from 'react-icons/md';
  import {FaCalendarAlt, FaWrench} from 'react-icons/fa';
import history from "../util/history";

const CardVerifPelaporanList = (props) => {
  const {data, access, id_ruang} = props;
  
  return (
    <Box width="100%" backgroundColor={commonStyle.baseColor} shadow={3} borderRadius="lg">
      <VStack>
        <Box px={5} py={3} backgroundColor="#ffd13c" borderTopRadius="lg">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Icon color="darkText" as={<Box><FaCalendarAlt/></Box>} size={5}></Icon>
            </Box>
            <Box flex={1} ml={1}>
              <Text color="darkText" fontWeight={500} >{data.date_create}</Text>
            </Box>
            <Box ml={3}>
              <BadgeStatus status={data.status_name}/>
            </Box>
            <Box ml={2} alignItems="center" justifyContent="center" width={8} height={8}>
              <Menu mt={2} py={2} placement="bottom right" trigger={triggerProps => {
                return <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                        <Icon as={<Box><MdMoreVert/></Box>} size={8} color="yellow.600" />
                      </Pressable>;
              }}>
                {access.includes('03:VIEW') && 
                <Menu.Item p={1} onPress={() => history.push('verif-pelaporan-form',{rawData:data, id_korektif: data.id_korektif, id_ruang: id_ruang, readOnly: true})}>
                  <HStack space={2} justifyContent="center" alignItems="center">
                    <Icon as={<Box><MdRemoveRedEye/></Box>} size="md" color="info.700" />
                    <Text color="darkText" fontSize="md">
                      View
                    </Text>
                  </HStack>
                </Menu.Item>
                }
                {/* <Divider w="100%" /> */}
                {(access.includes('03:EDIT')) && 
                <Menu.Item p={1} onPress={() => history.push('verif-pelaporan-form',{rawData:data, id_korektif: data.id_korektif, id_ruang: id_ruang, readOnly: false})}>
                  <HStack space={2} justifyContent="center" alignItems="center">
                    <Icon as={<Box><MdModeEdit/></Box>} size="md" color="info.700" />
                    <Text color="darkText" fontSize="md">
                      Verifikasi
                    </Text>
                  </HStack>
                </Menu.Item>
                }

                {/* <Divider w="100%" /> */}
                {access.includes('03:VIEW') && 
                <Menu.Item p={1} onPress={() => history.push('verif-pelaporan-history',{rawData:data, id_korektif: data.id_korektif, id_ruang: id_ruang, readOnly: false})}>
                  <HStack space={2} justifyContent="center" alignItems="center">
                    <Icon as={<Box><MdHistory/></Box>} size="md" color="info.700" />
                    <Text color="darkText" fontSize="md">
                      Riwayat Perbaikan
                    </Text>
                  </HStack>
                </Menu.Item>
                }
              </Menu>
            </Box>
          </Stack>
        </Box>
        <Box p={5}>
          <VStack>
            <Flex flexDirection="row">
              <Box>
                <Center>
                  <Avatar size="md" bg="primary.900" mr="1">
                      {helper.getInitials(data.nm_ruangan)}
                  </Avatar>
                </Center>
              </Box>
              <VStack width="90%">
                <Text ml={3} fontSize="md" color={commonStyle.primaryColor} isTruncated width="80%" fontWeight="bold">{data.nm_ruangan}</Text>
                <Text ml={3} fontSize="sm" color="muted.500" isTruncated width="80%">{data.nm_grp_item}</Text>
              </VStack>
            </Flex>
            <Flex flexDirection="row" mt={4}>
              <Box>
                <Center>
                  <Avatar size="md" bg="primary.900" mr="1">
                      {helper.getInitials(data.name_employee)}
                  </Avatar>
                </Center>
              </Box>
              <VStack width="90%" justifyContent="center">
                <Text ml={3} fontSize="md" color={commonStyle.primaryColor} isTruncated width="80%" fontWeight="bold">{data.name_employee}</Text>
                {/* <Text ml={3} fontSize="sm" color="muted.500" isTruncated width="80%">{data.hp}</Text> */}
              </VStack>
            </Flex>
            <Box mt={5} backgroundColor="light.200" borderRadius="lg" width="100%">
              <HStack>
                <Flex flex={1} p={2}>
                  <Flex flexDirection="row" alignItems="center">
                    <Icon as={<Box><FaWrench/></Box>} size={4} color="muted.400" />
                    <Text ml={2} color="darkText" isTruncated maxW="300" width="90%" fontWeight="bold">{data.nm_inventaris}</Text>
                  </Flex>
                </Flex>
              </HStack>
            </Box>
            
          </VStack>
        </Box>
      </VStack>
  </Box>
  );
}

export default memo(CardVerifPelaporanList);