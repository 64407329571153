import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the VerifPelaporanInputScreen state domain
 */

const selectVerifPelaporanInputScreenDomain = state =>
  state.verifPelaporanInputScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by VerifPelaporanInputScreen
 */

const makeSelectVerifPelaporanInputScreen = () =>
  createSelector(
    selectVerifPelaporanInputScreenDomain,
    substate => substate,
  );

export default makeSelectVerifPelaporanInputScreen;
export { selectVerifPelaporanInputScreenDomain };
