import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the VendorKroscekHistoryScreen state domain
 */

const selectVendorKroscekHistoryScreenDomain = state =>
  state.vendorKroscekHistoryScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by VendorKroscekHistoryScreen
 */

const makeSelectVendorKroscekHistoryScreen = () =>
  createSelector(
    selectVendorKroscekHistoryScreenDomain,
    substate => substate,
  );

export default makeSelectVendorKroscekHistoryScreen;
export { selectVendorKroscekHistoryScreenDomain };
