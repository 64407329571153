/*
 *
 * VerifPelaporanInputScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export const initialState = {
    error: 0,
    error_msg: '',
    responseState: '',
    loading: false,
    loading_submit: false,
    notes: '',
    id_korektif: 0,
    id_item: 0,
    assets:[],
    assets_verif:[],
    desc_verifikasi_pelaporan: null,
    id_verifikasi_kerusakan: null,
    pelaporan: null,
};

/* eslint-disable default-case, no-param-reassign */
const VerifPelaporanInputScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        return {...initialState};
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.GET_VERIFY_PELAPORAN:
        draft.loading = true;
        break;
      case constants.GET_VERIFY_PELAPORAN_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data pelaporan.';
        draft.results = [];
        break;
      case constants.ADD_IMAGE:{
        let cur_arr = draft.assets_verif;
        const image = action.asset;
        cur_arr.push({path: image});
        draft.assets_verif = cur_arr;
      }
        break;
      case constants.DEL_IMAGE:
        draft.assets_verif = [...draft.assets_verif.slice(0, action.idx), ...draft.assets_verif.slice(action.idx + 1)];
        break;
      case constants.GET_VERIFY_PELAPORAN_SUCCESS:
        draft.pelaporan = action.results;
        draft.id_verifikasi_kerusakan = action.results.id_verifikasi_kerusakan;
        draft.desc_verifikasi_pelaporan = action.results.desc_verifikasi_pelaporan;
        draft.notes = action.results.desc_verifikasi_pelaporan;
        draft.assets = action.attachment;
        draft.assets_verif = action.attachment_verif;
        draft.loading = false;
        break;
      
      case constants.SUBMIT_PELAPORAN:
        draft.loading_submit = true;
        draft.responseState = '';
        break;
      case constants.SUBMIT_PELAPORAN_FAIL:
        draft.error = true;
        draft.loading_submit = false;
        draft.responseState = 'error';
        draft.error_msg= 'Maaf, Gagal melakukan approval.';
        break;
      case constants.SUBMIT_PELAPORAN_SUCCESS:
        draft.loading_submit = false;
        draft.error = false;
        draft.id_verifikasi_kerusakan = 1; //make verify success
        draft.desc_verifikasi_pelaporan = draft.notes;
        if(action.action_type == '20'){
          draft.responseState = 'approved';
        }else if(action.action_type == '30'){
          draft.responseState = 'done';
        } else {
          draft.responseState = 'rejected';
        }
        break;
    }
  });

export default VerifPelaporanInputScreenReducer;
