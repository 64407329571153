import React from 'react';
import axios from 'axios';
import helper from './helper';
import {
  FaFileSignature,
  FaUserClock,
  FaCalendarDay,
  FaFileArchive,
  FaClock,
  FaClipboardList,
  FaClipboardCheck,
  FaUserAlt,
  FaAddressBook} from 'react-icons/fa';

const PROTOCOL = window.location.protocol;
const PORT = window.location.port;
const BASE_URL = PROTOCOL+'//'+window.location.hostname+(PORT != '' ? ':'+PORT : '');
let API_URL_STR = 'https://appadikandung.bgsproject.tech/api/';
let DB_TRANS = 'DB_TRANS_ADIKANDUNG';
if(BASE_URL.indexOf('local') !== -1){
  API_URL_STR = 'https:'+'//appadikandung.bgsproject.tech/api';
  DB_TRANS = 'POS_V01_TES_20210912_01_001';
}else if(BASE_URL.indexOf('staging') !== -1){
  API_URL_STR = 'https:'+'//appadikandung.bgsproject.tech/api';
  DB_TRANS = 'POS_V01_TES_20210912_01_001';
}else if(BASE_URL.indexOf('bgsproject') !== -1){
  API_URL_STR = 'https:'+'//appadikandung.bgsproject.tech/api';
  DB_TRANS = 'POS_V01_TES_20210912_01_001';
}else if(BASE_URL.indexOf('100.6:8000') !== -1){
  API_URL_STR = 'http:'+'//192.168.100.6:8081/api';
  DB_TRANS = 'POS_V01_TES_20210912_01_001';
}else if(BASE_URL.indexOf(':3031') !== -1){
  API_URL_STR = 'http:'+'//127.0.0.1:8081/api';
  DB_TRANS = 'POS_V01_TES_20210912_01_001';
}else if(BASE_URL.indexOf('127.0.0.1') !== -1){
  API_URL_STR = 'http:'+'//127.0.0.1:8081/api';
  DB_TRANS = 'POS_V01_TES_20210912_01_001_ASLI';
}else{
	API_URL_STR = PROTOCOL+'//adikandung.sarpras.unair.ac.id/api/';
  DB_TRANS = 'DB_TRANS_ADIKANDUNG';
}

export const API_URL = API_URL_STR; //'https://apps.sarpras.unair.ac.id/api/'; //https://appsiparu.bgsproject.tech/, http://172.16.15.59, http://adikandung.sarpras.unair.ac.id/
export const DATABASE_NAME = DB_TRANS;//'POS_V01_TES_20210912_01_001'; //POS_V01_TES_20210912_01_001, //DB_TRANS_ADIKANDUNG
export const MODUL_ID = 15;

// Getter
const getMenuScreen = () => {
  return [
    {code: '01', name:'Pelaporan', screen:'pelaporan', screen_params:{}, icon: <FaFileSignature/>, featured: true},
    {code: '02', name:'Pemeliharaan Preventif', screen:'preventif', screen_params:{}, icon: <FaUserClock/>, featured: true},
    {code: '03', name:'Verifikasi Kerusakan', screen:'scanner', screen_params:{data_type:"verif_pelaporan", origin_screen: "home", target_screen: "verif_pelaporan"}, icon: <FaCalendarDay/>, featured: true},
    {code: '04', name:'Penawaran Vendor', screen:'vendor-offer', screen_params:{}, icon: <FaFileArchive/>, featured: true},
    {code: '05', name:'Progress Perbaikan', screen:'vendor-progress', screen_params:{}, icon: <FaClock/>, featured: true},
    {code: '06', name:'Kroscek Perbaikan', screen:'scanner', screen_params:{data_type:"kroscek_progres", origin_screen: "home", target_screen: "vendor_kroscek"}, icon: <FaClipboardCheck/>, featured: true},
    {code: '07', name:'Task List', screen:'', screen_params:{}, icon: <FaClipboardList/>, featured: false},
    {code: '08', name:'Work Order List', screen:'', screen_params:{}, icon: <FaClipboardList/>, featured: false},
    {code: '09', name:'Profile', screen:'', screen_params:{}, icon: <FaUserAlt/>, featured: false},
    {code: '10', name:'Aproval Pemeliharaan', screen:'approval-maintenance', screen_params:{}, icon: <FaAddressBook/>, featured: true},
  ];
}

const getToken = () => {
  return localStorage.getItem('adikandung:token');
}

const getTokenDevice = () => {
  return localStorage.getItem('adikandung:token_device');
}

const getUserData = () => {
  let datas = localStorage.getItem('adikandung:user_data');
  try {
      datas = JSON.parse(datas);
  } catch (e) {
      datas = null;
  }
  return datas;
}
const getUserPrivileges = () => {
  let datas = localStorage.getItem('adikandung:user_privilege');
  try {
      datas = JSON.parse(datas);
  } catch (e) {
      datas = null;
  }
  return datas;
}

const getUserNotif = () => {
  let datas = localStorage.getItem('adikandung:notif');
  try {
      datas = JSON.parse(datas);
  } catch (e) {
      datas = null;
  }
  return datas;
}

const getClientIP = async () => {
  let IPv4 = null;
  try {
    const res = await axios.get('https://geolocation-db.com/json/');
    console.log(res.data);
    IPv4 = res.data.IPv4;
  } catch (e) {
    IPv4 = null;
  }
  
  return IPv4;
}
// end Getter

// Setter
const setToken = (value) => {
  return localStorage.setItem('adikandung:token', value);
}

const setTokenDevice = (value) => {
  return localStorage.setItem('adikandung:token_device', value);
}

const setUserData = (value) => {
  const jsonValue = JSON.stringify(value)
  return localStorage.setItem('adikandung:user_data', jsonValue);
}
const setUserPrivilege = (value) => {
  const jsonValue = JSON.stringify(value)
  return localStorage.setItem('adikandung:user_privilege', jsonValue);
}
// end setter

// other func
const getCurrInputPelaporan = () => {
  let datas = localStorage.getItem('adikandung:input_pelaporan');
  try {
      datas = JSON.parse(datas);
  } catch (e) {
      datas = null;
  }
  return datas;
}

const saveCurrInputPelaporan = (id_aset, id_pelaporan) => {
    let currLaporan = [];
    const rowValue = localStorage.getItem('adikandung:input_pelaporan');
    console.log("currInput:", rowValue);

    if(rowValue != null){
      currLaporan = JSON.parse(rowValue);
      const idx = currLaporan.findIndex(
        result => result.id_aset === id_aset,
      );
      
      if(idx < 0){
        currLaporan.push({id_aset: id_aset, id_pelaporan: id_pelaporan});
      }

      // save new record
      localStorage.setItem('adikandung:input_pelaporan', JSON.stringify(currLaporan));
      console.log(currLaporan);
    }else{
      // save new record
      currLaporan.push({id_aset: id_aset, id_pelaporan: id_pelaporan});
      localStorage.setItem('adikandung:input_pelaporan', JSON.stringify(currLaporan));
      console.log(currLaporan);
    }
}

const clearCurrInputPelaporan = () => {
  localStorage.removeItem("adikandung:input_pelaporan");
  console.log('Done');
}

const getCurrInputPreventif = () => {
  let datas = localStorage.getItem('adikandung:input_preventif');
  try {
      datas = JSON.parse(datas);
  } catch (e) {
      datas = null;
  }
  return datas;
}

const saveCurrInputPreventif = (id_aset, id_pelaporan) => {
    let currPreventif = [];
    const rowValue = localStorage.getItem('adikandung:input_preventif');
    console.log("currInput:", rowValue);

    if(rowValue != null){
      currPreventif = JSON.parse(rowValue);
      const idx = currPreventif.findIndex(
        result => result.id_aset === id_aset,
      );
      
      if(idx < 0){
        currPreventif.push({id_aset: id_aset, id_pelaporan: id_pelaporan});
      }

      // save new record
      localStorage.setItem('adikandung:input_preventif', JSON.stringify(currPreventif));
      console.log(currPreventif);
    }else{
      // save new record
      currPreventif.push({id_aset: id_aset, id_pelaporan: id_pelaporan});
      localStorage.setItem('adikandung:input_preventif', JSON.stringify(currPreventif));


      console.log(currPreventif);
    }
    // console.log(currentUser)
}

const clearCurrInputPreventif = () => {
  localStorage.removeItem("adikandung:input_preventif");
  console.log('Done');
}
// end othe func

const clearAllStorage = () => {
  localStorage.clear();
}

const clearUserData = () => {
  localStorage.removeItem('adikandung:token');
  localStorage.removeItem('adikandung:user_data');
  localStorage.removeItem('adikandung:user_privilege');
  localStorage.removeItem('adikandung:token_device');
  console.log('Done')
}

const clearUserNotif = () => {
  localStorage.removeItem('adikandung:notif');
  console.log('Done');
}


const getAxiosInstance = (additionalHeaders = {}, additionalOptions = {}, token = "", url = "") => {
    let cur_token = getToken();
    let baseUrl = API_URL;

    if(token != "") cur_token = token;
    if(url != "") baseUrl = url;

    // console.log("api_token:", cur_token);

    const ax = axios.create({
        ...additionalOptions,
        baseURL: baseUrl,
        headers: {
            ...additionalHeaders,
            'Authorization': 'Bearer '+cur_token,
            'Accept': 'application/json',
        },
        timeout: (1000 * 60),
    });
    ax.interceptors.response.use(function (response) {
        // console.log('axios-res:', response, [200, 201].includes(response.status));
        if ([200, 201].includes(response.status)){
            return response;
        } else {
            let error = {
                response
            };
            return Promise.reject(error);
        }
    }, function (error) {
        if(error) {
            return Promise.reject(error);
        }
    });
    return ax;
}

const api = {
    login: (payloads) => {
        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v1/login', payloads);
    },
    forgetPass: (params) => {
      const payloads = {
        db_name: DATABASE_NAME,
        email: params.email,
      }

      const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
      return ax.post('adikandung/v1/reset_pass', payloads);
    },
    user: {
      getPrivileges: (id_akun) => {
        const payloads = {
          db_name: DATABASE_NAME,
          id_akun: id_akun,
          id_modul: MODUL_ID
        }
  
        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v1/list_privilege', payloads);
      },
      changePassword: (params) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_akun: user.akun_id,
          email: user.email,
          old_password: params.old_pass,
          new_password: params.new_pass
        }
  
        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v1/change_password', payloads);
      },
      getData: (params) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_pegawai: user.id_pegawai
        }
  
        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v1/get_dt_pegawai', payloads);
      },
      submitData: (params) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_pegawai: user.id_pegawai,
          username: params.username,
          name_employee: params.name,
          address: params.address,
          hp: params.no_hp,
          // phone: params.no_phone,
          email: params.email
        }
  
        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v1/set_dt_pegawai', payloads);
      },
      notifications: (params) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_pegawai: user.id_pegawai,
          id_notif: params.id_notif,
        }
  
        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v1/list_notifikasi', payloads);
      }    
    },
    unit:{
      getAsset: (id_branch, id_item) => {
        const payloads = {
          db_name: DATABASE_NAME,
          id_branch: id_branch,
          id_item: id_item
        }

        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v1/aset_in_room', payloads);
      },
      getGedung: () => {
        const payloads = {
          db_name: DATABASE_NAME,
        }

        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v1/list_gedung', payloads);
      },
    },
    pelaporan:{
      getList: (params, page) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_user: user.id_user,
        }

        let submit_payload = Object.assign(payloads, params);

        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v2/list_pelaporan_kerusakan?page='+page, submit_payload);
      },
      submitPelaporan: (params) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_user: user.id_user,
          id_pegawai_report: user.id_pegawai,
          id_pelaporan: params.id_pelaporan,
          id_ruang: params.id_ruang,
          id_inventaris: params.id_inventaris,
          description: params.description,
          notes: params.notes,
          attachment: JSON.stringify(params.attachment),
        }
        
        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v1/set_pelaporan_kerusakan', payloads);
      },
      getPelaporan: (id_pelaporan) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_pelaporan: id_pelaporan
        }
        
        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v1/get_pelaporan_kerusakan', payloads);
      },
      getPelaporanHistory: (id_pelaporan) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_pelaporan: id_pelaporan
        }
        
        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v2/list_pelaporan_history', payloads);
      },
      getPelaporanDetailHistory: (params) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME
        }

        let submit_payload = Object.assign(payloads, params);
        
        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v2/get_detail_history', submit_payload);
      },
      getVerifyPelaporanList: (params, page) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_pegawai: user.id_pegawai,
        }

        let submit_payload = Object.assign(payloads, params);
        
        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v2/list_verifikasi_pelaporan?page='+page, submit_payload);
      },
      getVerifyPelaporan: (id_korektif) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_pegawai: user.id_pegawai,
          id_korektif: id_korektif,
        }
        
        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v2/detail_verifikasi_pelaporan', payloads);
      },
      getHistoyKorektif: (id_korektif) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_korektif: id_korektif,
        }
        
        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v1/list_korektif_history', payloads);
      },
      submitVerifyPelaporan: (params) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_pegawai: user.id_pegawai,
          id_user: user.id_user,
          id_korektif: params.id_korektif,
          tipe: params.tipe,
          id_pelaporan_kerusakan: params.id_pelaporan_kerusakan,
          id_emp_report: params.id_emp_report,
          description: params.description,
          attachment: JSON.stringify(params.attachment),
        }
        
        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v2/set_verifikasi_pelaporan', payloads);
      }
    },
    preventif:{
      getList: (params, page) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_pegawai: user.id_pegawai,
        }

        let submit_payload = Object.assign(payloads, params);

        console.log(payloads);

        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v2/list_realisasi_preventif?page='+page, submit_payload);
      },
      getPreventif: (id) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_pegawai: user.id_pegawai,
          id_realisasi_preventif: id,
        }

        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v1/detail_preventif_item', payloads);
      },
      getPreventifTodo: (id, id_inventaris) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_pegawai: user.id_pegawai,
          id_realisasi_preventif: id,
          id_inventaris: id_inventaris,
        }

        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v1/detail_preventif_todo', payloads);
      },
      submitPreventif: (params) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_pegawai: user.id_pegawai,
          id_user: user.id_user,
          id_preventif_exec: params.id_preventif_exec,
          id_inventaris: params.id_inventaris,
          id_realisasi_preventif: params.id_realisasi_preventif,
          id_item: params.id_item,
          id_condition: params.id_condition,
          description: params.description,
          attachments: params.attachments,
        }

        console.log('pay-preven:', payloads);
        
        if(helper.isEmpty(params.id_preventif_exec)) delete payloads.id_preventif_exec;

        const ax = getAxiosInstance({'Content-Type': 'application/json'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v1/set_preventif_result', payloads);
      }
    },
    task:{
      getList: (start_dt, end_dt, id_gedung) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_pegawai: user.id_pegawai,
          tgl_awal: start_dt,
          tgl_akhir: end_dt,
          id_gedung: id_gedung,
        }

        if(id_gedung == ""){
          delete payloads.id_gedung;
        }

        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v1/list_task', payloads);
      }
    },
    vendor:{
      getListOffer: (params, page) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_vendor: user.id_vendor
        }

        // console.log(payloads);
        let submit_payload = Object.assign(payloads, params);

        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v2/list_penawaran_request?page='+page, submit_payload);
      },
      getDetailOffer: (id_offer) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_vendor: user.id_vendor,
          id_penawaran_request: id_offer,
        }

        // console.log(payloads);

        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v1/detail_penawaran_request', payloads);
      },
      submitOffer: (params) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_pegawai: user.id_pegawai,
          id_user: user.id_user,
          id_vendor: user.id_vendor,
          id_penawaran_request: params.id_penawaran_request,
          perkiraan_tanggal_selesai: params.perkiraan_tanggal_selesai,
          nominal_penawaran: params.nominal_penawaran,
          description: params.description,
          attachments: params.attachments,
        }

        console.log('pay-offer:', payloads);

        const ax = getAxiosInstance({'Content-Type': 'application/json'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v1/set_penawaran_vendor', payloads);
      },
      submitNegoOffer: (params) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_user: user.id_user
        }

        let submit_payload = Object.assign(payloads, params);

        console.log('pay-nego-offer:', submit_payload);

        const ax = getAxiosInstance({'Content-Type': 'application/json'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v2/set_nego_penawaran_vendor', submit_payload);
      },
      getDetailNegoOffer: (id_offer) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_user: user.id_user,
          id_submit_penawaran: id_offer
        }

        const ax = getAxiosInstance({'Content-Type': 'application/json'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v2/detail_nego_penawaran_vendor', payloads);
      },
      submitApvNegoOffer: (params) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_user: user.id_user,
          id_vendor: user.id_vendor,
        }

        let submit_payload = Object.assign(payloads, params);

        console.log('pay-nego-offer:', submit_payload);

        const ax = getAxiosInstance({'Content-Type': 'application/json'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v2/update_nego_penawaran_vendor', submit_payload);
      }
    },
    work_order:{
      getList: (start_dt, end_dt) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_vendor: user.id_vendor,
          date_start: start_dt,
          date_end: end_dt,
        }
        
        // console.log(payloads);

        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v1/list_work_order', payloads);
      },
      getReports: (params, page) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_vendor: user.id_vendor,
        }

        let submit_payload = Object.assign(payloads, params);
        // console.log(payloads);

        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v2/list_work_order_report?page='+page, submit_payload);
      },
      getListProgress: () => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_vendor: user.id_vendor,
        }

        // console.log(payloads);

        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v1/list_work_order_in_progres', payloads);
      },
      getListStatus: (id_work_order) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_work_order: id_work_order,
        }

        // console.log(payloads);

        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v1/list_work_order_status', payloads);
      },
      getProgressDetail: (id_work_order) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_vendor: user.id_vendor,
          id_work_order: id_work_order,
        }

        // console.log(payloads);

        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v1/detail_work_order', payloads);
      },
      getProgressReport: (id_work_order_detail) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_vendor: user.id_vendor,
          id_work_order_detail: id_work_order_detail,
        }

        // console.log(payloads);

        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v1/detail_work_order_report', payloads);
      },
      submitProgressReport: (params) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_vendor: user.id_vendor,
          id_pegawai: user.id_pegawai,
          id_user: user.id_user,
          id_work_order: params.id_work_order,
          id_work_order_detail: params.id_work_order_detail,
          id_work_order_report: params.id_work_order_report,
          percent_progress: params.progress,
          status: params.status,
          deskripsi: params.description,
          tgl_perkiraan_selesai: params.tgl_deadline,
          attachments: params.assets
        }

        // console.log(payloads);
        if(helper.isEmpty(params.id_work_order_report)) delete payloads.id_work_order_report;

        const ax = getAxiosInstance({'Content-Type': 'application/json'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v1/set_work_order_report', payloads);
      },
      getVerifyWorkOrder: (params, page) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_pegawai: user.id_pegawai
        }

        // console.log(payloads);
        let submit_payload = Object.assign(payloads, params);
        
        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v2/list_work_order_report_verification?page='+page, submit_payload);
      },
      getVerifyAssets: (id_work_order_report) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_work_order_report: id_work_order_report,
        }

        console.log(payloads);
        const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v2/work_order_report_attachment', payloads);
      },
      submitVerifyWO: (params) => {
        let user = getUserData();
        const payloads = {
          db_name: DATABASE_NAME,
          id_pegawai: user.id_pegawai,
          id_user: user.id_user,
          id_work_order_report: params.id_work_order_report,
          attachment: JSON.stringify(params.attachment),
          description: params.description,
          approved: params.approved
        }
        
        const ax = getAxiosInstance({'Content-Type': 'application/json'});//'Content-Type': 'application/json'
        return ax.post('adikandung/v2/set_work_order_report_verification', payloads);
      }
    },
    getApprovalMaintain: (start_dt, end_dt, id_gedung, code_proses, status) => {
      let user = getUserData();
      const payloads = {
        db_name: DATABASE_NAME,
        id_pegawai: user.id_pegawai,
        id_gedung: id_gedung,
        date_start: start_dt,
        date_end: end_dt,
        code_proses: code_proses,
        status: status
      }

      if(helper.isEmpty(id_gedung)) delete payloads.id_gedung;
      if(helper.isEmpty(code_proses)) delete payloads.code_proses;

      console.log(payloads);
      
      const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
      return ax.post('adikandung/v1/list_approval_task', payloads);
    },
    getTotalApprovalMaintain: (start_dt, end_dt, id_gedung, code_proses, status) => {
      let user = getUserData();
      const payloads = {
        db_name: DATABASE_NAME,
        id_pegawai: user.id_pegawai,
        id_gedung: id_gedung,
        date_start: start_dt,
        date_end: end_dt,
        code_proses: code_proses,
        status: status
      }

      if(helper.isEmpty(id_gedung)) delete payloads.id_gedung;
      if(helper.isEmpty(code_proses)) delete payloads.code_proses;
      console.log(payloads);
      
      const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
      return ax.post('adikandung/v1/get_jml_approval', payloads);
    },
    getApprovalMaintainDetail: (id_apv, code_proses, dt_realisasi) => {
      let user = getUserData();
      const payloads = {
        db_name: DATABASE_NAME,
        id_pegawai: user.id_pegawai,
        id: id_apv,
        code_proses: code_proses,
        date_realisasi: dt_realisasi
      }
      console.log(payloads);
      
      const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
      return ax.post('adikandung/v1/get_approval_detail', payloads);
    },
    getApprovalMaintainPreventifDetail: (params) => {
      let user = getUserData();
      const payloads = {
        db_name: DATABASE_NAME,
        id_pegawai: user.id_pegawai
      }
      console.log(payloads);
      let submit_payload = Object.assign(payloads, params);
      
      const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
      return ax.post('adikandung/v1/preventif_approval_detail', submit_payload);
    },
    submitApproval: (params, code_proses) => {
      let user = getUserData();
      const payloads = {
        db_name: DATABASE_NAME,
        id_user: user.id_user
      }
      let submit_payload = Object.assign(payloads, params);
      console.log(submit_payload);
      
      let uri_api = '';
      switch (code_proses) {
        case 2:
          uri_api = 'adikandung/v1/set_approval_proses_2';
          break;
        case 4:
          uri_api = 'adikandung/v1/set_approval_proses_4';
          break;
        default:
          uri_api = 'adikandung/v1/set_approval_proses_2';
          break;
      }
      
      const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
      return ax.post(uri_api, submit_payload);
    },
    submitApprovalVendor: (params, code_proses) => {
      let user = getUserData();
      const payloads = {
        db_name: DATABASE_NAME,
        id_user: user.id_user,
        id_peg_approv: user.id_pegawai,
      }
      let submit_payload = Object.assign(payloads, params);
      console.log(submit_payload);
      
      let uri_api = 'adikandung/v1/set_approval_proses_3';
      
      const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
      return ax.post(uri_api, submit_payload);
    },
    submitApprovalPreventif: (params) => {
      let user = getUserData();
      const payloads = {
        db_name: DATABASE_NAME,
        id_user: user.id_user
      }
      let submit_payload = Object.assign(payloads, params);
      console.log(submit_payload);
      
      let uri_api = '';
          uri_api = 'adikandung/v1/set_approval_proses_1'
      
      const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
      return ax.post(uri_api, submit_payload);
    },
    getAllGedung: () => {
      let user = getUserData();
      const payloads = {
        db_name: DATABASE_NAME
      }

      const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
      return ax.post('adikandung/v1/list_gedung', payloads);
    },
    getLantaiGedung: (id_gedung) => {
      let user = getUserData();
      const payloads = {
        db_name: DATABASE_NAME,
        id_gedung: id_gedung
      }

      const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
      return ax.post('adikandung/v1/get_dt_lantai_gedung', payloads);
    },
    getListPegawai: () => {
      let user = getUserData();
      const payloads = {
        db_name: DATABASE_NAME
      }

      const ax = getAxiosInstance({'Content-Type': 'multipart/form-data'});//'Content-Type': 'application/json'
      return ax.post('adikandung/v1/get_dt_all_pegawai', payloads);
    },
    getMenuScreen: () => getMenuScreen(),
    clearAllStorage: () => clearAllStorage(),
    clearUserData: () => clearUserData(),
    clearUserNotif: () => clearUserNotif(),
    getToken: () => getToken(),
    getTokenDevice: () => getTokenDevice(),
    getUserData: () => getUserData(),
    getUserNotif: () => getUserNotif(),
    getUserPrivileges: () => getUserPrivileges(),
    setToken: (value) => setToken(value),
    setUserData: (value) => setUserData(value),
    setTokenDevice: (value) => setTokenDevice(value),
    setUserPrivilege: (value) => setUserPrivilege(value),
    saveCurrInputPelaporan: (id_aset, id_pelaporan) => saveCurrInputPelaporan(id_aset, id_pelaporan),
    getCurrInputPelaporan: () => getCurrInputPelaporan(),
    clearCurrInputPelaporan: () => clearCurrInputPelaporan(),
    saveCurrInputPreventif: (id_aset, id_pelaporan) => saveCurrInputPreventif(id_aset, id_pelaporan),
    getCurrInputPreventif: () => getCurrInputPreventif(),
    clearCurrInputPreventif: () => clearCurrInputPreventif(),
    getClientIP: () => getClientIP()
}

export default api;