import React from 'react';
import {
  HStack, 
  VStack, 
  Text,
  Alert,
  IconButton,
  CloseIcon,
  Toast
} from 'native-base';

const ToastAlert = ({
  id,
  status,
  variant,
  title,
  description,
  ...rest
}) => <Alert maxWidth="100%" alignSelf="center" flexDirection="row" status={status ? status : "info"} variant="subtle" {...rest}>
    <VStack space={1} flexShrink={1} w="100%">
      <HStack flexShrink={1} alignItems="center" justifyContent="space-between">
        <HStack space={2} flexShrink={1} alignItems="center">
          <Alert.Icon />
          <Text fontSize="md" fontWeight="medium" flexShrink={1} color="darkText">
            {title}
          </Text>
        </HStack>
      </HStack>
      <Text px="6" color="darkText">
        {description}
      </Text>
    </VStack>
  </Alert>;

const helper = {
    generatePrivileges: menus => {
      let allowedMenu = [];
      menus.forEach(row => {
        row.action.forEach(act => {
          allowedMenu.push(`${row.kode_menu}:${act.action_name}`)
        });
      });
      return allowedMenu;
    },
    isEmpty: obj => {
      if (typeof obj === 'undefined' || obj == null || obj == '' || obj == false) {
        return true;
      }
      return false;
    },
    isNull: obj => {
      if (typeof obj === 'undefined' || obj == null) {
        return true;
      }
      return false;
    },
    getInitials: string => {
        let initials = '-';
        if(helper.isEmpty(string)) return initials;
        const names = string.split(' ');
        initials = names[0].substring(0, 1).toUpperCase();
        if (names. length > 1) {
            initials += names[names. length-1].substring(0, 1).toUpperCase();
        }
        return initials;
    },
    getGreetingText: () => {
      let msg = 'Selamat Pagi';
      const d = new Date();
      const hours = d.getHours();
      if(hours < 11){
        msg = 'Selamat Pagi';
      } else if(hours < 15) {
        msg = 'Selamat Siang';
      } else if(hours < 19) {
        msg = 'Selamat Sore';
      } else {
        msg = 'Selamat Malam';
      }

      console.log("hours:", hours);

      return msg;
    },
    successToast: (message, duration = 2500, position = 'bottom') => {
      Toast.show({
        title: message,
        duration: duration,
        placement: position,
        render: ({
          id
        }) => {
          return <ToastAlert id={id} title="Sukses" description={message} status="success" />;
        },
      });
    },
    warningToast: (message, duration = 2500, position = 'bottom') => {
      Toast.show({
        title: message,
        duration: duration,
        placement: position,
        render: ({
          id
        }) => {
          return <ToastAlert id={id} title="Perhatian" description={message} status="warning" />;
        },
      });
    },
    infoToast: (message, duration = 2500, position = 'bottom') => {
      Toast.show({
        title: message,
        duration: duration,
        placement: position,
        render: ({
          id
        }) => {
          return <ToastAlert id={id} title="Info" description={message} status="info" />;
        },
      });
    },
    errorToast: (message, duration = 2500, position = 'bottom') => {
      // ToastAndroid.show(message, duration);
      Toast.show({
        title: message,
        duration: duration,
        placement: position,
        render: ({
          id
        }) => {
          return <ToastAlert id={id} title="Error" description={message} status="error" />;
        },
      });
    },
    checkBrowserNotif: () => {
      if (!("Notification" in window)) {
        // Check if the browser supports notifications
        alert("This browser does not support desktop notification");
      } else if (Notification.permission === "granted") {
        // Check whether notification permissions have already been granted;
        // if so, create a notification
        const notification = new Notification("Hi there!");
        // …
      } else if (Notification.permission !== "denied") {
        // We need to ask the user for permission
        Notification.requestPermission().then((permission) => {
          // If the user accepts, let's create a notification
          if (permission === "granted") {
            const notification = new Notification("Hi there!");
            // …
          }
        });
      }
    }
}

export default helper;