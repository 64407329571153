import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import moment from 'moment';
  import api from '../../util/api';
  import helper from '../../util/helper';
  
  import makeSelectVendorProgressDetailScreen from './selector';
import { getProgressDetailFail, getProgressDetailSuccess } from './actions';
  
  export function* doGetProgressDetail(action) {
    const dataset = yield select(makeSelectVendorProgressDetailScreen());
    try {
      const response = yield call(api.work_order.getProgressDetail, action.id_work_order);
      const {data} = response.data;
      console.log("res:", data);
      yield put(
        getProgressDetailSuccess((helper.isEmpty(data) ? null : data)),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getProgressDetailFail(err));
    }
  }
  // Individual exports for testing
  export default function* vendorProgressDetailScreenSaga() {
    yield all([
      takeLatest(constants.GET_PROGRESS_DETAIL, doGetProgressDetail),
    ]);
  }
  