import React from "react";
import { Badge, VStack, Box, Button, Icon, Center, Text, Pressable, Flex, HStack } from 'native-base';
import { commonStyle } from "../styles";
import {FaRegFolder, FaArrowLeft, FaFilter, FaGripHorizontal} from 'react-icons/fa';
import history from "../util/history";
import helper from "../util/helper";
import { MdOutlineFilterList } from "react-icons/md";

const StatusBar = (props) => {

  const handleOnPress = () => {
    if(helper.isEmpty(props.params)){
      history.replace(props.prevPage);
    }else{
      history.replace(props.prevPage, props.params);
    }
    return;
  }

  const handleOnPressAct = () => props.onPressAct();

  return (
    <Box backgroundColor="white" shadow={2} p={3} flexDirection="row" justifyContent="space-between" alignItems="center">
      <HStack space={3} alignItems="center">
        <Pressable onPress={() => handleOnPress()}>
          <Box px={2}>
                <Icon as={<Box><FaArrowLeft/></Box>} size={5} color="black" />
          </Box>
        </Pressable>
        <Box>
            <Text fontSize="lg">{props.title}</Text>
        </Box>
      </HStack>
      {props.enableAct &&
        <Pressable onPress={() => handleOnPressAct()}>
          <Box px={2}>
            <Icon as={<Box><MdOutlineFilterList/></Box>} size={7} color="black" />
          </Box>
        </Pressable>
      }
    </Box>
  );
}

export default StatusBar;