import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the TaskPreventifFormScreen state domain
 */

const selectTaskPreventifFormScreenDomain = state =>
  state.taskPreventifFormScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by TaskPreventifFormScreen
 */

const makeSelectTaskPreventifFormScreen = () =>
  createSelector(
    selectTaskPreventifFormScreenDomain,
    substate => substate,
  );

export default makeSelectTaskPreventifFormScreen;
export { selectTaskPreventifFormScreenDomain };
