import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import api from '../../util/api';
  import helper from '../../util/helper';
  import makeSelectVerifPelaporanInputScreen from './selector';
import { getVerifyPelaporanFail, getVerifyPelaporanSuccess, submitPelaporanFail, submitPelaporanSuccess } from './actions';
import history from '../../util/history';
  
  export function* doGetVerifyPelaporan(action) {
    const dataset = yield select(makeSelectVerifPelaporanInputScreen());
    try {
      const response = yield call(api.pelaporan.getVerifyPelaporan, action.id_korektif);
      const {data, attachment, attachment_verif} = response.data;
      console.log(data);
      yield put(
        getVerifyPelaporanSuccess(data[0], attachment, attachment_verif),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal mengambil data");
      yield put(getVerifyPelaporanFail(err));
    }
  }

  export function* doSubmitVerifyPelaporan(action) {
    const {action_type} = action;
    const dataset = yield select(makeSelectVerifPelaporanInputScreen());
    try {
      const params = {
          description: dataset.notes,
          id_korektif: dataset.pelaporan.id_korektif,
          tipe: action_type,
          id_pelaporan_kerusakan: dataset.pelaporan.id_pelaporan_kerusakan,
          id_emp_report: dataset.pelaporan.id_emp_report,
          attachment: dataset.assets_verif,
      };

      // console.log(params);

      const response = yield call(api.pelaporan.submitVerifyPelaporan, params);
      console.log(response);
      yield put(
        submitPelaporanSuccess(response.data, action_type),
      );
      history.push('verif-pelaporan', {id_item: dataset.id_item});
    } catch (err) {
      console.log(err);
      yield put(submitPelaporanFail(err));
    }
  }

  // Individual exports for testing
  export default function* verifPelaporanInputScreenSaga() {
    yield all([
      takeLatest(constants.GET_VERIFY_PELAPORAN, doGetVerifyPelaporan),
      takeLatest(constants.SUBMIT_PELAPORAN, doSubmitVerifyPelaporan),
    ]);
  }
  