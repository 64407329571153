import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import helper from '../../util/helper';
  import api from '../../util/api';
  import makeSelectVendorOfferDetailScreen from './selector';
import { getOfferDetailFail, getOfferDetailSuccess } from './actions';
  
  export function* doGetOfferDetail(action) {
    const dataset = yield select(makeSelectVendorOfferDetailScreen());
    try {
      const response = yield call(api.vendor.getDetailOffer, action.id_offer);
      const {data} = response.data;
  
      yield put(
        getOfferDetailSuccess((helper.isEmpty(data) ? null : data)),
      );
      console.log("res:", data);
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getOfferDetailFail(err));
    }
  }

  // Individual exports for testing
  export default function* vendorOfferDetailScreenSaga() {
    yield all([
      takeLatest(constants.GET_OFFER_DETAIL, doGetOfferDetail),
    ]);
  }
  