import React from 'react';
import { Flex } from 'native-base';

export const ContainerScreen = (props) => {
    return (
      <Flex w={{
        sm: '100%',
        md: '100%',
        lg: 500,
        xl: 500,
      }} p={3}>
        {props.children}
      </Flex>
    );
}

const style = {
  containerStyle: {
    padding: 16,
    width: '100%',
  },
  cardStyle: {
    flex: 1
  },
  linearGradient: {
    flex: 1,
  },
  backgroundImage: {
    flex: 1,
    resizeMode: 'repeat',
  }
}