export const DEFAULT_ACTION = 'screen/PelaporanDetailInputScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/PelaporanDetailInputScreen/UPDATE_INPUT';

export const SUBMIT_PELAPORAN = 'screen/PelaporanDetailInputScreen/SUBMIT_PELAPORAN';
export const SUBMIT_PELAPORAN_LOADING = 'screen/PelaporanDetailInputScreen/SUBMIT_PELAPORAN_LOADING';
export const SUBMIT_PELAPORAN_SUCCESS = 'screen/PelaporanDetailInputScreen/SUBMIT_PELAPORAN_SUCCESS';
export const SUBMIT_PELAPORAN_FAIL = 'screen/PelaporanDetailInputScreen/SUBMIT_PELAPORAN_FAIL';

export const ADD_IMAGE = 'screen/PelaporanDetailInputScreen/ADD_IMAGE';
export const DEL_IMAGE = 'screen/PelaporanDetailInputScreen/DEL_IMAGE';

export const GET_PELAPORAN = 'screen/PelaporanDetailInputScreen/GET_PELAPORAN';
export const GET_PELAPORAN_LOADING = 'screen/PelaporanDetailInputScreen/GET_PELAPORAN_LOADING';
export const GET_PELAPORAN_SUCCESS = 'screen/PelaporanDetailInputScreen/GET_PELAPORAN_SUCCESS';
export const GET_PELAPORAN_FAIL = 'screen/PelaporanDetailInputScreen/GET_PELAPORAN_FAIL';