import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getListApproval() {
  return {
    type: constants.GET_LIST_APPROVAL
  };
}

export function getListApprovalLoading() {
  return {
    type: constants.GET_LIST_APPROVAL_LOADING
  };
}

export function getListApprovalFail(error) {
  return {
    type: constants.GET_LIST_APPROVAL_FAIL,
    error
  };
}

export function getListApprovalSuccess(results) {
  return {
    type: constants.GET_LIST_APPROVAL_SUCCESS,
    results
  };
}

export function getTotalApproval() {
  return {
    type: constants.GET_TOTAL_APPROVAL
  };
}

export function getTotalApprovalLoading() {
  return {
    type: constants.GET_TOTAL_APPROVAL_LOADING
  };
}

export function getTotalApprovalFail(error) {
  return {
    type: constants.GET_TOTAL_APPROVAL_FAIL,
    error
  };
}

export function getTotalApprovalSuccess(results) {
  return {
    type: constants.GET_TOTAL_APPROVAL_SUCCESS,
    results
  };
}

export function getApprovalDetail(rawApv) {
  return {
    type: constants.GET_APPROVAL_DETAIL,
    rawApv
  };
}

export function getApprovalDetailLoading() {
  return {
    type: constants.GET_APPROVAL_DETAIL_LOADING
  };
}

export function getApprovalDetailFail(error) {
  return {
    type: constants.GET_APPROVAL_DETAIL_FAIL,
    error
  };
}

export function getApprovalDetailSuccess(results, data_approval) {
  // console.log(results);
  return {
    type: constants.GET_APPROVAL_DETAIL_SUCCESS,
    results,
    data_approval
  };
}

export function submitApproval() {
  return {
    type: constants.SUBMIT_APPROVAL
  };
}

export function submitApprovalFail(error) {
  return {
    type: constants.SUBMIT_APPROVAL_FAIL,
    error
  };
}

export function submitApprovalSuccess(results) {
  return {
    type: constants.SUBMIT_APPROVAL_SUCCESS,
    results
  };
}