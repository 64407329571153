import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import moment from 'moment';
  import api from '../../util/api';
  import helper from '../../util/helper';
  
  import makeSelectVendorProgressScreen from './selector';
import { getMoreWOReportFail, getMoreWOReportSuccess, getWorkOrderReportFail, getWorkOrderReportSuccess } from './actions';
  
  export function* doGetListWorkOrder(action) {
    const dataset = yield select(makeSelectVendorProgressScreen());
    try {
      const tglAwal = moment(dataset.start_dt).format("DD-MM-YYYY");
      const tglAkhir = moment(dataset.end_dt).format("DD-MM-YYYY");
      
      const params = {
        date_start: tglAwal,
        date_end: tglAkhir,
        search: dataset.q,
        status: dataset.filter_status
      };

      if(helper.isEmpty(dataset.start_dt)) delete params.date_start;
      if(helper.isEmpty(dataset.end_dt)) delete params.date_end;
      if(helper.isEmpty(dataset.q)) delete params.search;
      if(helper.isEmpty(dataset.filter_status)) delete params.status;

      const response = yield call(api.work_order.getReports, params, dataset.page);
      const {data} = response.data;
      console.log("res:", data);
      if(dataset.page == 1){
        yield put(
          getWorkOrderReportSuccess((helper.isEmpty(data) ? [] : data)),
        );
      } else {
        yield put(
          getMoreWOReportSuccess((helper.isEmpty(data) ? [] : data)),
        );
      }
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      if(dataset.page == 1){
        yield put(getWorkOrderReportFail(err));
      } else {
        yield put(getMoreWOReportFail(err));
      }
    }
  }

  // Individual exports for testing
  export default function* vendorProgressScreenSaga() {
    yield all([
      takeLatest(constants.GET_WORK_ORDER_REPORT, doGetListWorkOrder),
      takeLatest(constants.GET_MORE_WO_REPORT, doGetListWorkOrder),
    ]);
  }
  