import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
  import moment from 'moment';
  import api from '../../util/api';
  import helper from '../../util/helper';
  import * as constants from './constants';
  
  import makeSelectApprovalMaintainFormScreen from './selector';
import { getApprovalDetailFail, getApprovalDetailSuccess, getVerifyAssetsFail, getVerifyAssetsSuccess, submitApprovalFail, submitApprovalSuccess } from './actions';
import history from '../../util/history';

  export function* doGetApprovalDetail(action) {
    console.log('called');
    const {id_apv, code_proses, dt_realisasi} = action;
    const dataset = yield select(makeSelectApprovalMaintainFormScreen());
    try {
      const dtRealisasi = moment(dt_realisasi).format("YYYY-MM-DD");
      const response = yield call(api.getApprovalMaintainDetail, id_apv, (parseInt(code_proses) || 0), dtRealisasi);
      const {data} = response.data;
      console.log("res:", response.data);
      yield put(
        getApprovalDetailSuccess((helper.isEmpty(data) ? [] : data)),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getApprovalDetailFail(err));
    }
  }

  export function* doSubmitApproval(action) {
    const {code_proses, action_type} = action;
    const dataset = yield select(makeSelectApprovalMaintainFormScreen());
    const {data_approval, rawApv} = dataset;
    try {
      let params = {};
      switch (code_proses) {
        case 2:
          params = {
              id: data_approval.id,
              id_item: data_approval.id_item,
              id_pelapor: data_approval.id_pegawai_report,
              id_petugas: dataset.id_petugas,
              id_inventaris: data_approval.id_inventaris,
              code_proses: code_proses,
              description: dataset.notes
          };
          break;
        case 4:
          params = {
            id: data_approval.id,
            id_pem_korektif: data_approval.wo_detail.id_pemeliharaan_korektif,
            id_vendor: data_approval.id_vendor,
            id_item: data_approval.wo_detail.id_item,
            id_inventaris: data_approval.wo_detail.id_inventaris,
            id_petugas: dataset.id_petugas,
            description: dataset.notes,
            txtNmInven: data_approval.wo_detail.ms_inventaris.nama,
            txtNmVendor: data_approval.ms_vendor.nama,
        };
          break;
        default:
          break;
      }

      console.log(params, code_proses);
      const response = yield call(api.submitApproval, params, code_proses);
      console.log(response);
      yield put(
        submitApprovalSuccess(response.data, action_type),
      );
      // history.replace('vendor-kroscek', {id_item: dataset.id_ruang});
    } catch (err) {
      console.log(err);
      yield put(submitApprovalFail(err));
    }
  }
  // Individual exports for testing
  export default function* approvalMaintainFormScreenSaga() {
    yield all([
      takeLatest(constants.GET_APPROVAL_DETAIL, doGetApprovalDetail),
      takeLatest(constants.SUBMIT_APPROVAL, doSubmitApproval),
      // takeLatest(constants.SUBMIT_WORK_ORDER_VERIF_SUCCESS, doGetApprovalDetail),
    ]);
  }
  