/*
 *
 * VendorProgressFormScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import moment from 'moment';
import helper from '../../util/helper';

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    responseState: '',
    loading_submit: false,
    new_data: true,
    description: '',
    id_work_order_report: '',
    id_work_order_detail: '',
    id_work_order: '',
    progress: 0,
    status: '30',
    tgl_deadline: new Date(),
    assets:[],
    dtProgress: null,
    dtProgressDetail: null,
};

/* eslint-disable default-case, no-param-reassign */
const VendorProgressFormScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
      return {...initialState};
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      
      case constants.GET_PROGRESS_REPORT:
        draft.loading = true;
        break;
      case constants.GET_PROGRESS_REPORT_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data progress.';
        break;
      case constants.GET_PROGRESS_REPORT_SUCCESS: {
        const resReport = action.results;
        draft.dtProgressDetail = resReport;
        draft.loading = false;
        draft.progress = resReport.percentage_progres;
        if(!draft.new_data){
          draft.description = resReport.description_progres;
          draft.tgl_deadline = moment(resReport.forcast_date_finish).toDate();
          draft.status = resReport.status;
          draft.id_work_order_report = resReport.id_report;
          if(!helper.isEmpty(resReport.attachments)) draft.assets = resReport.attachments;
        }
      }
        break;
      case constants.ADD_IMAGE: {
        let cur_arr = draft.assets;
        const image = action.asset;
        cur_arr.push({path: image, status: true});
        draft.assets = cur_arr;
        }
        break;
      case constants.DEL_IMAGE:
        draft.assets = [...draft.assets.slice(0, action.idx), ...draft.assets.slice(action.idx + 1)];
        break;
      case constants.SUBMIT_PROGRESS:
        draft.loading_submit = true;
        draft.responseState = "";
        break;
      case constants.SUBMIT_PROGRESS_FAIL:
        draft.error = true;
        draft.loading_submit = false;
        draft.responseState = 'error';
        break;
      case constants.SUBMIT_PROGRESS_SUCCESS:
        draft.progressReport = action.results;
        draft.loading_submit = false;
        draft.error = false;
        draft.responseState = 'success';
        break;
    }
  });

export default VendorProgressFormScreenReducer;
