import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getWorkOrderReport() {
  return {
    type: constants.GET_WORK_ORDER_REPORT
  };
}

export function getWorkOrderReportLoading() {
  return {
    type: constants.GET_WORK_ORDER_REPORT_LOADING
  };
}

export function getWorkOrderReportFail(error) {
  return {
    type: constants.GET_WORK_ORDER_REPORT_FAIL,
    error
  };
}

export function getWorkOrderReportSuccess(results) {
  return {
    type: constants.GET_WORK_ORDER_REPORT_SUCCESS,
    results
  };
}

export function getMoreWOReport() {
  return {
    type: constants.GET_MORE_WO_REPORT
  };
}

export function getMoreWOReportLoading() {
  return {
    type: constants.GET_MORE_WO_REPORT_LOADING
  };
}

export function getMoreWOReportFail(error) {
  return {
    type: constants.GET_MORE_WO_REPORT_FAIL,
    error
  };
}

export function getMoreWOReportSuccess(results) {
  return {
    type: constants.GET_MORE_WO_REPORT_SUCCESS,
    results
  };
}