import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
  import moment from 'moment';
  import api from '../../util/api';
  import helper from '../../util/helper';
  import * as constants from './constants';
  
  import makeSelectVendorKroscekScreen from './selector';
import { getMoreWOVerifFail, getMoreWOVerifSuccess, getWorkOrderVerifFail, getWorkOrderVerifSuccess } from './actions';
  
  export function* doGetWorkOrderVerif(action) {
    const dataset = yield select(makeSelectVendorKroscekScreen());
    try {
      const tglAwal = moment(dataset.start_dt).format("DD-MM-YYYY");
      const tglAkhir = moment(dataset.end_dt).format("DD-MM-YYYY");

      const params = {
        id_ruang: action.id_ruang,
        date_start: tglAwal,
        date_end: tglAkhir,
        search: dataset.q,
        status: dataset.filter_status
      };

      if(helper.isEmpty(dataset.start_dt)) delete params.date_start;
      if(helper.isEmpty(dataset.end_dt)) delete params.date_end;
      if(helper.isEmpty(dataset.q)) delete params.search;
      if(helper.isEmpty(dataset.filter_status)) delete params.status;

      const response = yield call(api.work_order.getVerifyWorkOrder, params, dataset.page);
      const {data} = response.data;
      console.log("res:", response.data);
      if(dataset.page == 1){
        yield put(
          getWorkOrderVerifSuccess((helper.isEmpty(data) ? [] : data)),
        );
      }else{
        yield put(
          getMoreWOVerifSuccess((helper.isEmpty(data) ? [] : data)),
        );
      }
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      if(dataset.page == 1){
        yield put(getWorkOrderVerifFail(err));
      }else{
        yield put(getMoreWOVerifFail(err));
      }
    }
  }

  // Individual exports for testing
  export default function* vendorKroscekScreenSaga() {
    yield all([
      takeLatest(constants.GET_WORK_ORDER_VERIF, doGetWorkOrderVerif),
      takeLatest(constants.GET_MORE_WO_VERIF, doGetWorkOrderVerif),
    ]);
  }
  