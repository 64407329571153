import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, deleteToken } from "firebase/messaging";
// import { getMessaging } from "firebase/messaging/sw";
import api from './util/api';

const firebaseConfig = {
    apiKey: "AIzaSyAIyc-cYH2D09Q2Fi3dYQ7NvxBTf4tr_-s",
    authDomain: "pesan-ruang-sarpras-unair.firebaseapp.com",
    projectId: "pesan-ruang-sarpras-unair",
    storageBucket: "pesan-ruang-sarpras-unair.appspot.com",
    messagingSenderId: "489346285228",
    appId: "1:489346285228:web:294b7a8e2b8b0fba3ea909",
    measurementId: "G-NYKF0BCFW5"
};

initializeApp(firebaseConfig);

export const messaging = getMessaging();

export const deleteFCMToken = () => deleteToken(messaging);

export const checkFCMToken = () => {return getToken(messaging, { vapidKey: 'BGVXpBKy8GTjqVZF9hYSCCkz3X_RokGENe_MTw0LLjV8GmIF7Fx6g6MutTAB32nwasRRhtlvuV_72BhRUikfT0Q' })};

export const requestForToken = () => {
  console.log('get FCM');
  return getToken(messaging, { vapidKey: 'BGVXpBKy8GTjqVZF9hYSCCkz3X_RokGENe_MTw0LLjV8GmIF7Fx6g6MutTAB32nwasRRhtlvuV_72BhRUikfT0Q' })
    .then((currentToken) => {
    if (currentToken) {
        console.log('current token for client: ', currentToken);
        api.setTokenDevice(currentToken);
        // Perform any other neccessary action with the token
    } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
    }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // alert('Izinkan Aplikasi untuk menggunakan fitur notifikasi');
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});
