import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
  import moment from 'moment';
  import * as constants from './constants';
  
  import makeSelectPelaporanScreen from './selector';
  import api from '../../util/api';
  import helper from '../../util/helper';
  import { getMorePelaporanFail, getMorePelaporanSuccess, getPelaporanFail, getPelaporanSuccess } from './actions';

  export function* doGetListPelaporan(action) {
    const dataset = yield select(makeSelectPelaporanScreen());
    try {
      let tglAwal = '';
      let tglAkhir = '';

      if(!helper.isEmpty(dataset.start_dt) && !helper.isEmpty(dataset.end_dt)){
        tglAwal = moment(dataset.start_dt).format("YYYY-MM-DD 00:00:00");
        tglAkhir = moment(dataset.end_dt).format("YYYY-MM-DD 23:59:59");
      }

      const params = {
        tgl_awal: tglAwal,
        tgl_akhir: tglAkhir,
        search: dataset.q,
        status: dataset.filter_status
      };

      if(helper.isEmpty(dataset.start_dt)) delete params.tgl_awal;
      if(helper.isEmpty(dataset.end_dt)) delete params.tgl_akhir;
      if(helper.isEmpty(dataset.q)) delete params.search;
      if(helper.isEmpty(dataset.filter_status)) delete params.status;

      const response = yield call(api.pelaporan.getList, params, dataset.page);
      const {data} = response.data;
  
      yield put(
        getPelaporanSuccess((helper.isEmpty(data) ? [] : data)),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getPelaporanFail(err));
    }
  }

  export function* doGetMoreListPelaporan(action) {
    const dataset = yield select(makeSelectPelaporanScreen());
    try {
      let tglAwal = '';
      let tglAkhir = '';

      if(!helper.isEmpty(dataset.start_dt) && !helper.isEmpty(dataset.end_dt)){
        tglAwal = moment(dataset.start_dt).format("YYYY-MM-DD 00:00:00");
        tglAkhir = moment(dataset.end_dt).format("YYYY-MM-DD 23:59:59");
      }

      const params = {
        tgl_awal: tglAwal,
        tgl_akhir: tglAkhir,
        search: dataset.q,
        status: dataset.filter_status
      };

      if(helper.isEmpty(dataset.start_dt)) delete params.tgl_awal;
      if(helper.isEmpty(dataset.end_dt)) delete params.tgl_akhir;
      if(helper.isEmpty(dataset.q)) delete params.search;
      if(helper.isEmpty(dataset.filter_status)) delete params.status;

      const response = yield call(api.pelaporan.getList, params, dataset.page);
      const {data} = response.data;
  
      yield put(
        getMorePelaporanSuccess((helper.isEmpty(data) ? [] : data)),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getMorePelaporanFail(err));
    }
  }
  
  // Individual exports for testing
  export default function* pelaporanScreenSaga() {
    yield all([
      takeLatest(constants.GET_PELAPORAN, doGetListPelaporan),
      takeLatest(constants.GET_MORE_PELAPORAN, doGetMoreListPelaporan),
    ]);
  }
  