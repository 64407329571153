/*
 *
 * TaskScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import moment from 'moment';

const date = new Date();
const from = moment(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)).toDate(); // one days ago
const to = moment(date).toDate();

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    loading_gedung: false,
    id_gedung: '',
    start_dt: from,
    end_dt: to,
    gedung: [],
    results: [],
};

/* eslint-disable default-case, no-param-reassign */
const TaskScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        return {...initialState};
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.GET_GEDUNG:
        draft.loading_gedung = true;
        break;
      case constants.GET_GEDUNG_FAIL:
        draft.error = true;
        draft.loading_gedung = false;
        draft.gedung = [];
        break;
      case constants.GET_GEDUNG_SUCCESS: {
        let dtg = action.results;
        dtg.unshift([{id: 0, code: "", nm_gedung: "Semua"}][0]);
        draft.gedung = dtg;

        draft.loading_gedung = false;
        }
        break;
      case constants.GET_TASKS:
        draft.loading = true;
        break;
      case constants.GET_TASKS_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.results = [];
        break;
      case constants.GET_TASKS_SUCCESS:
        draft.results = action.results;
        draft.loading = false;
        break;
    }
  });

export default TaskScreenReducer;
