import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectVendorProgressSurketScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView, TouchableWithoutFeedback, RefreshControl } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Image,
  Pressable,
  Divider, 
  Flex,
  Stack,
  Fab,
  ScrollView, 
  Button} from 'native-base';
import {FaEnvelope, FaChevronRight} from 'react-icons/fa';
import moment from 'moment';
import { ContainerPage } from '../../components/ContainerPage';
import ImageSlider from '../../components/ImageSlider';
import TaskDetailPreloader from '../../components/TaskDetailPreloader';
import MyAlert from '../../components/MyAlert';
import { commonStyle } from '../../styles';
import { getWorkOrderProgress, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import history from '../../util/history';

export function VendorProgressSurketScreen({
  dispatch,
  route,
  navigation,
  vendorProgressSurketScreen,
  onUpdateInput,
  onGetResult
}){

  useEffect(() => {
    onGetResult();
  },[]);

  const handleGetData = () => {
    onGetResult();
  }
  return (
        <ContainerPage options={{statusBar: true, prevPage: 'vendor-progress', title: 'Progress Perbaikan'}}>
          <Flex flex={1}>
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              {vendorProgressSurketScreen.loading && <TaskDetailPreloader/> }
              {!vendorProgressSurketScreen.loading && 
              <Flex mt={3}>
              {!vendorProgressSurketScreen.results && <MyAlert status="info" title="Maaf, Data belum ditemukan."/>}
              {vendorProgressSurketScreen.results &&
              <VStack space={7} mt={2} mb={20} mx={3}>
                <Box p={5} mt={3} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    <VStack>
                      <Center>
                        <Avatar size="lg" bg="primary.900" mb={4}>
                            {helper.getInitials(vendorProgressSurketScreen.results.nama_vendor)}
                        </Avatar>
                      </Center>
                      <Center>
                        <Text color="darkText" isTruncated maxWidth="90%" fontSize="md" fontWeight="bold">{vendorProgressSurketScreen.results.nama_vendor}</Text>
                      </Center>
                      <Center>
                        <Text color="muted.400" isTruncated maxWidth="90%" fontSize="sm">{vendorProgressSurketScreen.results.nama_cp}</Text>
                      </Center>
                      <Center>
                        <Text mt={10} color="darkText" fontSize="sm">{vendorProgressSurketScreen.results.hp_cp}</Text>
                      </Center>
                    </VStack>
                </Box>
                <Box borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                  <VStack m={4}>
                  {vendorProgressSurketScreen.list_work_order.length == 0 && 
                    <Box borderRadius="lg" p={3} shadow={3} backgroundColor={commonStyle.baseColor}>
                      <Text color="muted.500" textAlign="center" italic>Belum ada data</Text>
                    </Box>
                  }
                  {vendorProgressSurketScreen.list_work_order.map((x, idx) => (
                    <Pressable key={`k${idx}`} onPress={() => history.push('vendor-progress-detail', {id_work_order: x.id_work_order})}>
                      <HStack justifyContent="center" alignItems="center">
                        <Box width={8} height={8} borderRadius="lg" backgroundColor="primary.400" justifyContent="center">
                          <Center>
                            <Icon color="white" as={<Box><FaEnvelope/></Box>} size={5}></Icon>
                          </Center>
                        </Box>
                        <Flex flex={1} mx={3}>
                          <VStack>
                            <Text color="darkText" bold>{x.code_work_order}</Text>
                            <Text color="darkText" fontSize="xs">{moment(x.date_create_work_order, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY HH:mm")}</Text>
                          </VStack>
                        </Flex>
                        <Icon as={<Box><FaChevronRight/></Box>} size="sm" color="muted.400"/>
                      </HStack>
                      {(vendorProgressSurketScreen.list_work_order.length > 1 && idx < (vendorProgressSurketScreen.list_work_order.length-1)) && <Divider my={3}/>}
                    </Pressable> 
                  ))}
                  </VStack>
                </Box>
              </VStack>
              }
              </Flex>
              }
            </ScrollView>
          </Flex>
          {/* <Fab onPress={() => navigation.navigate('vendor_offer_form')} renderInPortal={false} shadow={2} size="lg" icon={<Icon color="white" as={AntDesign} name="doubleright" size="lg" />} /> */}
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  vendorProgressSurketScreen: makeSelectVendorProgressSurketScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetResult: () => dispatch(getWorkOrderProgress()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(VendorProgressSurketScreen);