import { Box } from "native-base";
import React, {memo, useRef, useState} from "react";

const MyTextArea = memo((props) => {
    const {value, onChangeText} = props;
    return (
        <Box>
            <textarea name="mytext" className="myTextArea" placeholder="Ketik disini..." rows={3} value={value} onChange={evt => onChangeText(evt.target.value)}></textarea>
        </Box>
    );
  })
  
export default MyTextArea;