import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import moment from 'moment';
  import helper from '../../util/helper';
  import api from '../../util/api';
  import makeSelectVendorOfferScreen from './selector';
import { getMoreOfferFail, getMoreOfferSuccess, getOffersFail, getOffersSuccess } from './actions';
  
  export function* doGetListOffer(action) {
    const dataset = yield select(makeSelectVendorOfferScreen());
    try {
      const tglAwal = moment(dataset.start_dt).format("DD-MM-YYYY");
      const tglAkhir = moment(dataset.end_dt).format("DD-MM-YYYY");

      const params = {
        date_start: tglAwal,
        date_end: tglAkhir,
        search: dataset.q,
        status_penawaran_request: dataset.filter_status
      };

      if(helper.isEmpty(dataset.start_dt)) delete params.date_start;
      if(helper.isEmpty(dataset.end_dt)) delete params.date_end;
      if(helper.isEmpty(dataset.q)) delete params.search;
      if(helper.isEmpty(dataset.filter_status)) delete params.status_penawaran_request;

      const response = yield call(api.vendor.getListOffer, params, dataset.page);
      const {data} = response.data;
      if(dataset.page == 1){
        yield put(
          getOffersSuccess((helper.isEmpty(data) ? [] : data)),
        );
      }else{
        yield put(
          getMoreOfferSuccess((helper.isEmpty(data) ? [] : data)),
        );
      }
      console.log("res:", data);
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      if(dataset.page == 1){
        yield put(getOffersFail(err));
      }else{
        yield put(getMoreOfferFail(err));
      }
    }
  }
  // Individual exports for testing
  export default function* vendorOfferScreenSaga() {
    yield all([
      takeLatest(constants.GET_OFFERS, doGetListOffer),
      takeLatest(constants.GET_MORE_OFFERS, doGetListOffer),
    ]);
  }
  