import React, { useState } from 'react';
import { Badge, VStack, Box, Button, Icon } from 'native-base';
import { commonStyle } from "../styles";
import helper from "../util/helper";
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

const CameraScreen = (props) => {
  function handleTakePhoto (dataUri) {
    console.log('img:', dataUri);
    // Do stuff with the photo...
    console.log('takePhoto');
  }

  function handleTakePhotoAnimationDone (dataUri) {
    // Do stuff with the photo...
    console.log('takePhoto');
  }

  function handleCameraError (error) {
    console.log('handleCameraError', error);
  }

  function handleCameraStart (stream) {
    console.log('handleCameraStart');
  }

  function handleCameraStop () {
    console.log('handleCameraStop');
  }

  return (
  <Box h="100vh" w="100%" backgroundColor="black">
  <Camera
    onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
    onTakePhotoAnimationDone = { (dataUri) => { handleTakePhotoAnimationDone(dataUri); } }
    onCameraError = { (error) => { handleCameraError(error); } }
    idealFacingMode = {FACING_MODES.ENVIRONMENT}
    idealResolution = {{width: 640, height: 800}}
    imageType = {IMAGE_TYPES.JPG}
    imageCompression = {0.70}
    isMaxResolution = {true}
    isImageMirror = {false}
    isSilentMode = {false}
    isDisplayStartCameraError = {true}
    isFullscreen = {true}
    sizeFactor = {1}
    onCameraStart = { (stream) => { handleCameraStart(stream); } }
    onCameraStop = { () => { handleCameraStop(); } }
  /></Box>);
  
}

export default CameraScreen;