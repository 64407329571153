/*
 *
 * ApprovalMaintainScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import moment from 'moment';

const date = new Date();
const from = moment(new Date(Date.now() - 90 * 24 * 60 * 60 * 1000)).toDate(); // 60 days ago 334
const to = moment(date).toDate();

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    loading_approval: false,
    start_dt: from,
    end_dt: to,
    id_gedung: '0',
    id_lantai: '0',
    code_proses: '0',
    status: 'permintaan',
    results: [],
    jml_permintaan: 0,
    jml_disetujui: 0,
    jml_selesai: 0,
    raw_approval: null,
    detail_approval: null,
};

/* eslint-disable default-case, no-param-reassign */
const ApprovalMaintainScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        return {...initialState};
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.GET_LIST_APPROVAL:
        draft.loading = true;
        break;
      case constants.GET_LIST_APPROVAL_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data pelaporan.';
        draft.results = [];
        break;
      case constants.GET_LIST_APPROVAL_SUCCESS:
        draft.results = action.results;
        draft.loading = false;
        break;
      case constants.GET_TOTAL_APPROVAL:
        draft.loading = true;
        break;
      case constants.GET_TOTAL_APPROVAL_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data pelaporan.';
        draft.result_total = [];
        break;
      case constants.GET_TOTAL_APPROVAL_SUCCESS:
        draft.result_total = action.results;
        if(action.results.length > 0){
          draft.jml_permintaan = action.results[0].jml_permintaan;
          draft.jml_disetujui = action.results[0].jml_disetujui;
          draft.jml_selesai = action.results[0].jml_selesai;
        }else{
          draft.jml_permintaan = 0;
          draft.jml_disetujui = 0;
          draft.jml_selesai = 0;
        }
        
        draft.loading = false;
        break;
      case constants.GET_APPROVAL_DETAIL:
        draft.loading_approval = true;
        break;
      case constants.GET_APPROVAL_DETAIL_FAIL:
        draft.error = true;
        draft.loading_approval = false;
        draft.error_msg= 'Maaf, Gagal mengambil data.';
        draft.detail_approval = null;
        break;
      case constants.GET_APPROVAL_DETAIL_SUCCESS:{
        const rawDetail = action.results;
        draft.raw_approval = action.data_approval;
        draft.detail_approval = action.results;
        // draft.loading_approval = false;
      }
        break;
      case constants.SUBMIT_APPROVAL:
        draft.loading_approval = true;
        draft.responseState = '';
        break;
      case constants.SUBMIT_APPROVAL_FAIL:
        draft.error = true;
        draft.loading_approval = false;
        draft.responseState = 'error';
        draft.error_msg= 'Maaf, Gagal melakukan approval.';
        break;
      case constants.SUBMIT_APPROVAL_SUCCESS:
        draft.loading_approval = false;
        draft.error = false;
        draft.responseState = 'approved';
        break;
      
    }
  });

export default ApprovalMaintainScreenReducer;
