export const DEFAULT_ACTION = 'screen/VendorKroscekScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/VendorKroscekScreen/UPDATE_INPUT';

export const GET_WORK_ORDER_VERIF = 'screen/VendorKroscekScreen/GET_WORK_ORDER_VERIF';
export const GET_WORK_ORDER_VERIF_LOADING = 'screen/VendorKroscekScreen/GET_WORK_ORDER_VERIF_LOADING';
export const GET_WORK_ORDER_VERIF_SUCCESS = 'screen/VendorKroscekScreen/GET_WORK_ORDER_VERIF_SUCCESS';
export const GET_WORK_ORDER_VERIF_FAIL = 'screen/VendorKroscekScreen/GET_WORK_ORDER_VERIF_FAIL';

export const GET_MORE_WO_VERIF = 'screen/VendorKroscekScreen/GET_MORE_WO_VERIF';
export const GET_MORE_WO_VERIF_LOADING = 'screen/VendorKroscekScreen/GET_MORE_WO_VERIF_LOADING';
export const GET_MORE_WO_VERIF_SUCCESS = 'screen/VendorKroscekScreen/GET_MORE_WO_VERIF_SUCCESS';
export const GET_MORE_WO_VERIF_FAIL = 'screen/VendorKroscekScreen/GET_MORE_WO_VERIF_FAIL';