import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the ApprovalMaintainFormScreen state domain
 */

const selectApprovalMaintainFormScreenDomain = state =>
  state.approvalMaintainFormScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by ApprovalMaintainFormScreen
 */

const makeSelectApprovalMaintainFormScreen = () =>
  createSelector(
    selectApprovalMaintainFormScreenDomain,
    substate => substate,
  );

export default makeSelectApprovalMaintainFormScreen;
export { selectApprovalMaintainFormScreenDomain };
