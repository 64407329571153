import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function delImage(idx) {
  return {
    type: constants.DEL_IMAGE,
    idx
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}


export function getWorkOrderVerif(id_ruang, start_dt, end_dt) {
  return {
    type: constants.GET_WORK_ORDER_VERIF,
    id_ruang,
    start_dt,
    end_dt
  };
}

export function getWorkOrderVerifLoading() {
  return {
    type: constants.GET_WORK_ORDER_VERIF_LOADING
  };
}

export function getWorkOrderVerifFail(error) {
  return {
    type: constants.GET_WORK_ORDER_VERIF_FAIL,
    error
  };
}

export function getWorkOrderVerifSuccess(results) {
  // console.log(results);
  return {
    type: constants.GET_WORK_ORDER_VERIF_SUCCESS,
    results
  };
}

export function submitWOVerif(action_type, id_wo) {
  return {
    type: constants.SUBMIT_WORK_ORDER_VERIF,
    action_type,
    id_wo
  };
}

export function submitWOVerifFail(error) {
  return {
    type: constants.SUBMIT_WORK_ORDER_VERIF_FAIL,
    error
  };
}

export function submitWOVerifSuccess(results, action_type) {
  return {
    type: constants.SUBMIT_WORK_ORDER_VERIF_SUCCESS,
    results,
    action_type
  };
}

export function getVerifyAssets(id_work_order_report) {
  return {
    type: constants.GET_VERIF_ASSET,
    id_work_order_report
  };
}

export function getVerifyAssetsLoading() {
  return {
    type: constants.GET_VERIF_ASSET_LOADING
  };
}

export function getVerifyAssetsFail(error) {
  return {
    type: constants.GET_VERIF_ASSET_FAIL,
    error
  };
}

export function addImage(asset) {
  return {
    type: constants.ADD_IMAGE,
    asset
  };
}

export function getVerifyAssetsSuccess(results, results_verif) {
  console.log('action-new1 : ',results);
  return {
    type: constants.GET_VERIF_ASSET_SUCCESS,
    results,
    results_verif
  };
}