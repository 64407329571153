import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function forgetPass() {
  return {
    type: constants.FORGET_PASS,
  };
}

export function forgetPassSuccess(result) {
  return {
    type: constants.FORGET_PASS_SUCCESS,
    result
  };
}

export function forgetPassFail(msg) {
  return {
    type: constants.FORGET_PASS_FAIL,
    msg
  };
}