import React, {memo} from "react";
import { commonStyle } from "../styles";
import {
    Icon,
    HStack, 
    Avatar, 
    Heading, 
    VStack, 
    Box, 
    Center,
    Stack,
    Badge,
    Input,
    Image,
    IconButton,
    Pressable,
    Divider,
    HamburgerIcon,
    Progress,
    Menu,
    Text, 
    Flex } from 'native-base';
  import {MdRemoveRedEye, MdModeEdit, MdHistory, MdMoreVert } from 'react-icons/md';
  import {FaCalendarAlt, FaEnvelope, FaAngleDown, FaCalendarTimes, FaCalendarCheck, FaWrench } from 'react-icons/fa';
  import moment from "moment";
  import BadgeStatus from "./BadgeStatus";
  import helper from "../util/helper";
  import history from "../util/history";

const CardVendorProgressList = (props) => {
  const {data, access} = props;
  return (
    <Box width="100%" backgroundColor={commonStyle.baseColor} shadow={3} borderRadius="lg">
      <VStack>
        <Box px={5} py={3} backgroundColor="#ffd13c" borderTopRadius="lg">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Icon color="darkText" as={<Box><FaCalendarAlt/></Box>} size={5}></Icon>
            </Box>
            <Box flex={1} ml={1}>
              <Text color="darkText" fontWeight={500} >{moment(data.date_report, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")}</Text>
            </Box>
            <Box ml={3}>
              <BadgeStatus status={data.status_report_text}/>
            </Box>
            <Box ml={2} alignItems="center" justifyContent="center" width={8} height={8}>
              <Menu mt={2} py={2} placement="bottom right" trigger={triggerProps => {
                return <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                        <Icon as={<Box><MdMoreVert/></Box>} size={8} color="yellow.600" />
                      </Pressable>;
              }}>
                {access.includes('05:VIEW') && 
                <Menu.Item p={1} onPress={() => history.push('vendor-progress-form',{readOnly: true, dtProgress: data, id_work_order: data.id_work_order, id_work_order_detail: data.id_work_order_detail, addNew: false, origin_screen: 'vendor-progress'})}>
                  <HStack space={2} justifyContent="center" alignItems="center">
                    <Icon as={<Box><MdRemoveRedEye/></Box>} size="md" color="info.700" />
                    <Text color="darkText" fontSize="md">
                      View
                    </Text>
                  </HStack>
                </Menu.Item>
                }
                {access.includes('05:EDIT') && 
                <Menu.Item p={1} onPress={() => history.push('vendor-progress-form',{readOnly: false, dtProgress: data, id_work_order: data.id_work_order, id_work_order_detail: data.id_work_order_detail, addNew: false, origin_screen: 'vendor-progress'})}>
                  <HStack space={2} justifyContent="center" alignItems="center">
                    <Icon as={<Box><MdModeEdit/></Box>} size="md" color="info.700" />
                    <Text color="darkText" fontSize="md">
                      Update Progress
                    </Text>
                  </HStack>
                </Menu.Item>
                }
                <Menu.Item p={1} onPress={() => history.push('vendor-progress-history',{dtProgress: data})}>
                  <HStack space={2} justifyContent="center" alignItems="center">
                    <Icon as={<Box><MdHistory/></Box>} size="md" color="info.700" />
                    <Text color="darkText" fontSize="md">
                      Riwayat Perbaikan
                    </Text>
                  </HStack>
                </Menu.Item>
              </Menu>
            </Box>
          </Stack>
        </Box>
        <Box p={5}>
          <VStack>
            <Flex flexDirection="row" mb={3} alignItems="center">
              <Box width={8} height={8} borderRadius="lg" backgroundColor="primary.400" justifyContent="center">
                <Center>
                  <Icon color="white" as={<Box><FaEnvelope/></Box>} size={5}></Icon>
                </Center>
              </Box>
              <Box ml={2}>
                <Text color="darkText">{data.code_work_order}</Text>
              </Box>
            </Flex>
            <HStack flex={1} mb={3} space={2}>
              <Box flex={1}>
                <VStack>
                  <Text color="darkText" bold>Deadline Penawaran</Text>
                  <Text color="darkText">{data.forcast_date_finish ? moment(data.forcast_date_finish, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm") : '-'}</Text>
                </VStack>
              </Box>
              <Box flex={1}>
                <VStack space={1}>
                  <Text color="darkText" bold>Progress Perbaikan</Text>
                  <Box>
                    <Progress size="lg" colorScheme="success" mb={4} value={data.percent_progres} />
                    <Text fontSize="xs" textAlign="center" style={{position: 'absolute', width:'100%', top: 0}}>{`${data.percent_progres}%`}</Text>
                  </Box>
                </VStack>
              </Box>
            </HStack>
            
            <Flex flexDirection="row" mb={3}>
              <Box>
                <Center>
                  <Avatar size="md" bg="primary.900" mr="1">
                      {helper.getInitials(data.nama_pegawai_report)}
                  </Avatar>
                </Center>
              </Box>
              <VStack width="90%">
                <Text ml={3} fontSize="lg" color={commonStyle.primaryColor} isTruncated width="80%" fontWeight="bold">
                  {data.nama_pegawai_report}
                </Text>
                <Text ml={3} fontSize="sm" color="muted.500" isTruncated width="80%">
                  {data.hp_pegawai_report}
                </Text>
              </VStack>
            </Flex>
            <Box backgroundColor="light.200" p={3} borderRadius="lg" width="100%">
              <VStack space={2}>
                <Box>
                  <HStack justifyContent="center" alignItems="center">
                    <Box>
                      <Icon color="darkText" as={<Box><FaWrench/></Box>} size={4}></Icon>
                    </Box>
                    <Box flex={1} ml={2}>
                      <Text color="darkText" fontWeight={500} >{data.nama_inventaris}</Text>
                    </Box>
                  </HStack>
                </Box>
              </VStack>
            </Box>
            
          </VStack>
        </Box>
      </VStack>
  </Box>
  );
}

export default memo(CardVendorProgressList);