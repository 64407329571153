import React, { Component, useState, useEffect, createRef, Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';
import {FaRegFolder, FaArrowLeft} from 'react-icons/fa';
import makeSelectTaskScannerScreen from './selector';
import reducer from './reducer';
import saga from './saga';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Image, 
  Flex,
  Pressable,
  Alert,
  Spinner, 
  Button, 
  ScrollView} from 'native-base';
// import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
// import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { commonStyle, scanStyle } from '../../styles';
// import QRCodeScanner from 'react-native-qrcode-scanner';
// import { RNCamera } from 'react-native-camera';
import { QrReader } from 'react-qr-reader';
import { ContainerScreen } from '../../components/ContainerScreen';
import { defaultAction, getRoomAsset, resetScan, scanResult, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import history from '../../util/history';

export function TaskScannerScreen({
  dispatch,
  taskScannerScreen,
  onUpdateInput,
  onGetRoomAsset,
  onScanResult,
  onResetScan,
  location
}){
  const {data_type, origin_screen, target_screen} = location.state;
  const history = useHistory();
  const[userData, setUserData] = useState();
  const[scan, setScan] = useState(true);
  const[scanResult, setScanResult] = useState(false);
  const[result, setResult] = useState();
  const[flashStatus, setFlasStatus] = useState('off');
  const scannerRef = useRef();
  
  useEffect(() => {
      dispatch(defaultAction());
      onUpdateInput('data_type', data_type);
      // const token = api.getUserData().then(user => {
      //   console.log("saved_user:", user);
      //   console.log("data_type:", data_type);
      //   console.log("initial:", helper.getInitials(user.user_name));
      //   setUserData(user);
      // });
      // if (history.location.state && history.location.state.data_type) {
      //   setDataType(history.location.state.data_type);
      // }
      // if (history.location.state && history.location.state.origin_screen) {
      //   setOriginScreen(history.location.state.origin_screen);
      // }
      console.log('scan-type:', data_type);
  },[]);

  const closeCam = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: true,
    });
    stream.getTracks().forEach(function (track) {
        track.stop();
        track.enabled = false;
    });
    scannerRef.current.stopCamera();
};

  const onSuccess = (str) => {
    if (!str) return;
    // This callback will keep existing even after 
    // this component is unmounted
    // So ignore it (only in this reference) if result keeps repeating
    if (scannerRef.current === str) {
      return
    }
    
    scannerRef.current = str;
    onScanResult(str, data_type);

    console.log('str', str);
    console.log('type:', data_type);
    // closeCam();
  
        // if(!scanResult){
        //   const check = str.substring(0, 4);
        //   console.log('scanned data:' + str);
        //   console.log('data_type:' + data_type);
        //   setScan(false);
        //   setScanResult(true);
        //   setResult(str);
        //   switch (data_type) {
        //     case 'pelaporan':
        //       onGetRoomAsset(str, data_type);
        //       break;
        //     case 'verif_pelaporan':
        //       onGetRoomAsset(str, data_type);
        //       break;
        //     case 'preventif':
        //       onGetRoomAsset(str, data_type);
        //       break;
        //     case 'kroscek_progres':
        //       onGetRoomAsset(str, data_type);
        //       break;
        //     default:
        //       break;
        //   }
        // }
    }
  const activeQR = () => {
        setScan(true);
    }
  const scanAgain = () => {
        setScan(true);
        setScanResult(false);
    }
  
  const handleFlash = () => {
    if(flashStatus == 'off'){
      setFlasStatus('on');
    }else{
      setFlasStatus('off');
    }
  }

  const handleErrorWebCam = (error) => {
    console.log(error);
    helper.errorToast("Error when opening camera!");
  }

  return (
        <Flex w={{
          sm: '100%',
          md: '100%',
          lg: 500,
          xl: 500,
        }} p={3} h="100vh" backgroundColor="#035397">
          <Box backgroundColor="#035397">
            <HStack space={3} alignItems="center">
              <Box px={2}>
                  <Pressable onPress={() => window.location.href = `${origin_screen}`}>
                    <Icon as={<Box><FaArrowLeft/></Box>} size={5} color={commonStyle.baseColor} />
                  </Pressable>
              </Box>
              <Box>
                  <Text bold color={commonStyle.baseColor}>Scan QR</Text>
              </Box>
            </HStack>
          </Box>
          <ScrollView>
          <VStack minW='300px' space={2}>
            <VStack flex={1}>
              <Box width="200px" mt={5} alignSelf="center">
                <Text fontSize='md' textAlign="center" color={commonStyle.baseColor}>Posisikan kamera Anda pada QR Code</Text>
              </Box>
              <Box>
              <QrReader
                  ref={scannerRef}
                  scanDelay={500}
                  constraints={{
                    facingMode: 'environment'
                  }}
                  onResult={(result, error) => {
                    const result_ = Boolean(result);
                    const error_ = Boolean(error);
                    if (result_) {
                      onSuccess(result?.text);
                    }
                    
                    if (error_) {
                      console.info(error);
                    }
                  }}
                  style={{ width: '100%' }}
                />
              </Box>
              <VStack space={3}>
                {taskScannerScreen.loading && <HStack space={2} justifyContent="center">
                  <Spinner accessibilityLabel="Loading" color="white" />
                  <Heading color="white" fontSize="md">
                    Searching
                  </Heading>
                </HStack>}
                {/* <Box>
                  <Text>{taskScannerScreen.scan_result}</Text>
                </Box> */}
                {(!taskScannerScreen.loading) &&
                <VStack space={3}>
                  {taskScannerScreen.error &&
                  <Box>
                    <Alert w="100%" status="danger">
                      <VStack space={2} flexShrink={1} w="100%">
                        <HStack flexShrink={1} space={2} justifyContent="space-between">
                          <HStack space={2} flexShrink={1}>
                            <Alert.Icon mt="1" />
                            <Text fontSize="md" color="coolGray.800">
                              {taskScannerScreen.error_msg}
                            </Text>
                          </HStack>
                        </HStack>
                      </VStack>
                    </Alert>
                  </Box>
                  }
                  {taskScannerScreen.scanned &&
                  <Box alignItems="center">
                    <Button variant="subtle" colorScheme="light" onPress={() => onResetScan()}>Scan Ulang</Button>
                  </Box>
                  }
                </VStack>
                }
              </VStack>
            </VStack>

          </VStack>
          </ScrollView>
        </Flex>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  taskScannerScreen: makeSelectTaskScannerScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetRoomAsset: (result, type) => dispatch(getRoomAsset(result, type)),
    onScanResult: (str, data_type) => dispatch(scanResult(str, data_type)),
    onResetScan: () => dispatch(resetScan()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(TaskScannerScreen);