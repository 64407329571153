import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import api from '../../util/api';
  import helper from '../../util/helper';
  import moment from 'moment';
  
  import makeSelectLoginScreen from './selector';
import { getTasksFail, getTasksSuccess } from './actions';

  export function* doGetListTask(action) {
    const dataset = yield select(makeSelectLoginScreen());
    try {
      const tglAwal = moment(dataset.start_dt).format("YYYY-MM-DD 00:00:00");
      const tglAkhir = moment(dataset.end_dt).format("YYYY-MM-DD 23:59:59");
      const response = yield call(api.task.getList, tglAwal, tglAkhir, dataset.id_gedung);
      const {data} = response.data;
      console.log("res:", data);
      yield put(
        getTasksSuccess((helper.isEmpty(data) ? [] : data)),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal mengambil data task");
      yield put(getTasksFail(err));
    }
  }
  
  // Individual exports for testing
  export default function* loginScreenSaga() {
    yield all([
      takeLatest(constants.GET_TASKS, doGetListTask),
    ]);
  }
  